import React from 'react';

import { SContainer, SContent, SIcon, SInput, SRadio } from './Radio.styles';

type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'ref' | 'type'
>;

type RadioProps = {
  hasError?: boolean;
};

export type Props = RadioProps & InputProps;

export const Radio: React.FC<Props> = ({
  className,
  children,
  disabled = false,
  checked = false,
  hasError = false,
  required,
  // Omit capture attr as its applicable for file input type only
  capture,
  ...rest
}) => {
  return (
    <SContainer className={className} disabled={disabled}>
      <SInput type="radio" disabled={disabled} checked={checked} {...rest} />
      <SRadio checked={checked} disabled={disabled} hasError={hasError}>
        <SIcon disabled={disabled} hasError={hasError} />
      </SRadio>
      {children && <SContent>{children}</SContent>}
    </SContainer>
  );
};

export default Radio;
