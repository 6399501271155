import { Resource } from './resources';

export const permissionsByEntity = {
  [Resource.GlobalAccount]: {
    READ: 'read:global_account',
    FULL: 'full:global_account',
  },
  [Resource.GlobalUser]: {
    READ: 'read:global_user',
    FULL: 'full:global_user',
  },
  [Resource.GlobalRole]: {
    READ: 'read:global_role',
    FULL: 'full:global_role',
  },
  [Resource.GlobalApiKey]: {
    READ: 'read:global_api_key',
    FULL: 'full:global_api_key',
  },
  [Resource.ApiKey]: {
    READ: 'read:api_key',
    FULL: 'full:api_key',
  },
  [Resource.Firm]: {
    READ: 'read:firm',
    FULL: 'full:firm',
  },
  [Resource.Account]: {
    READ: 'read:account',
    FULL: 'full:account',
  },
  [Resource.User]: {
    READ: 'read:user',
    FULL: 'full:user',
  },
  [Resource.Role]: {
    READ: 'read:role',
    FULL: 'full:role',
  },
  [Resource.SignupToken]: {
    READ: 'read:signup_token',
    FULL: 'full:signup_token',
  },
  [Resource.Team]: {
    READ: 'read:team',
    FULL: 'full:team',
  },
  [Resource.Conflict]: {
    READ: 'read:conflict',
    FULL: 'full:conflict',
  },
  [Resource.Contact]: {
    READ: 'read:contact',
    FULL: 'full:contact',
  },
  [Resource.Offering]: {
    READ: 'read:offering',
    FULL: 'full:offering',
  },
  [Resource.PublishedOffering]: {
    READ: 'read:published_offering',
    FULL: 'full:published_offering',
  },
  [Resource.Ioi]: {
    READ: 'read:ioi',
    FULL: 'full:ioi',
  },
  [Resource.FundIoi]: {
    READ: 'read:fund_ioi',
    FULL: 'full:fund_ioi',
  },
  [Resource.CustomSectors]: {
    READ: 'read:custom_sector',
    FULL: 'full:custom_sector',
  },
  /** @deprecated */
  [Resource.ApiDocs]: {
    READ: 'read:api_docs',
  },
  [Resource.SharedReports]: {
    READ: 'read:shared_report',
    FULL: 'full:shared_report',
  },
  [Resource.SharedFollowedOfferings]: {
    READ: 'read:shared_followed_offerings',
  },
  [Resource.OrderBook]: {
    READ: 'read:order_book',
    FULL: 'full:order_book',
  },
  [Resource.InstitutionalIndication]: {
    READ: 'read:institutional_indication',
    FULL: 'full:institutional_indication',
  },
  [Resource.InstitutionalAllocation]: {
    READ: 'read:institutional_allocation',
    FULL: 'full:institutional_allocation',
  },
  [Resource.RetailDemand]: {
    READ: 'read:retail_demand',
    FULL: 'full:retail_demand',
  },
  [Resource.RetailRetention]: {
    READ: 'read:retail_retention',
    FULL: 'full:retail_retention',
  },
  [Resource.CoveredAccountsCrm]: {
    READ: 'read:covered_accounts_crm',
  },
  [Resource.CoveredAccountsInstitutionalIndication]: {
    READ: 'read:covered_accounts_institutional_indication',
    FULL: 'full:covered_accounts_institutional_indication',
  },
  [Resource.CoveredAccountsInstitutionalAllocation]: {
    READ: 'read:covered_accounts_institutional_allocation',
    FULL: 'full:covered_accounts_institutional_allocation',
  },
  [Resource.RegulatoryFiling]: {
    READ: 'read:regulatory_filing',
    FULL: 'full:regulatory_filing',
  },
  [Resource.SyndicateWire]: {
    READ: 'read:syndicate_wire',
    FULL: 'full:syndicate_wire',
  },
  [Resource.Datalab]: {
    READ: 'read:data_lab',
  },
  [Resource.DatalabUI]: {
    READ: 'read:data_lab_ui',
  },
  [Resource.CrmBasicInvestorFirm]: {
    READ: 'read:crm_basic_investor_firm',
    FULL: 'full:crm_basic_investor_firm',
  },
  [Resource.CRM]: {
    READ: 'read:crm',
    FULL: 'full:crm',
  },
  [Resource.CertificateLibrary]: {
    READ: 'read:certificate_library',
  },
  [Resource.CertificateReview]: {
    READ: 'read:certificate_review',
    FULL: 'full:certificate_review',
  },
  [Resource.Company]: {
    READ: 'read:company',
    FULL: 'full:company',
  },
  [Resource.OffPlatformOffering]: {
    READ: 'read:off_platform_offering',
    FULL: 'full:off_platform_offering',
  },
  [Resource.InternationalOffering]: {
    READ: 'read:international_offering',
  },
  [Resource.GlobalCrm]: {
    READ: 'read:global_crm',
    FULL: 'full:global_crm',
  },
  [Resource.GlobalInstitutionalIndication]: {
    READ: 'read:global_institutional_indication',
    FULL: 'full:global_institutional_indication',
  },
  [Resource.Underwriter]: {
    READ: 'read:underwriter',
  },
  [Resource.FullSyndicateLineUp]: {
    READ: 'read:full_syndicate_line_up',
  },
  [Resource.Designation]: {
    READ: 'read:designation',
    FULL: 'full:designation',
  },
  [Resource.Expense]: {
    READ: 'read:expense',
    FULL: 'full:expense',
  },
  [Resource.Stabilization]: {
    READ: 'read:stabilization',
    FULL: 'full:stabilization',
  },
  [Resource.Accounting]: {
    READ: 'read:accounting',
    FULL: 'full:accounting',
  },
  [Resource.Letter]: {
    READ: 'read:letter',
    FULL: 'full:letter',
  },
  [Resource.SalesCredit]: {
    READ: 'read:sales_credit',
    FULL: 'full:sales_credit',
  },
  [Resource.Trade]: {
    READ: 'read:trade',
    FULL: 'full:trade',
  },
  [Resource.ConvertsOffering]: {
    READ: 'read:converts_offering',
  },
  [Resource.Atm]: {
    READ: 'read:atm_offering',
  },
  [Resource.Converts]: {
    READ: 'read:converts_offering',
  },
  [Resource.AtmSelldown]: {
    READ: 'read:atm_selldown',
  },
  [Resource.DlExcelDownload]: {
    READ: 'read:dl_excel_download',
  },
  [Resource.SyndicateMember]: {
    READ: 'read:syndicate_member',
  },
};
