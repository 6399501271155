import React from 'react';
import styled from 'styled-components/macro';

import * as apiTypes from '../../../api/types/apiTypes';

export const Sul = styled.ul`
  margin: 0;
  padding: 0;
`;

export const Sli = styled.li`
  margin-left: 10px;
`;
export type Props = apiTypes.GenericResponseDataFailure;

/**
 * Takes our standard representation of a server error and prints out the individual
 * messages in a list.
 */
const ServerErrors: React.FC<Props> = ({ error }) => {
  if (error.details && error.details.length) {
    return (
      <Sul>
        {error.details.map(detailItem => (
          <Sli key={detailItem.message + detailItem.target}>{detailItem.message}</Sli>
        ))}
      </Sul>
    );
  }

  return (
    <Sul>
      <Sli>{error.message}</Sli>
    </Sul>
  );
};

export default ServerErrors;
