import { DLGWCalendarOffering } from '@capital-markets-gateway/api-client-datalab-gateway';
import { datalabApi } from '@cmg/api';
import { numericUtil, timeUtil } from '@cmg/common';
import React from 'react';
import { Link } from 'react-router-dom';

import { formatNullableBoolean } from '../../../common/helpers/helpers';
import routeFactory from '../../../common/util/routeFactory';
import { getFeatureToggles } from '../../../config/appSettings';
import { ISODate, ISODateTime } from '../../../types/common';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import {
  InternationalOfferingTypeLabels,
  OfferingStatus,
  OfferingType,
  offeringTypeLabels,
} from '../../../types/domain/offering/constants';
import { formatSectorName } from '../../../types/domain/sector/cmgSectorConstants';
import { DatalabScreens } from '../../datalab/constants';
import {
  canViewConvertData,
  isConvertsPhaseTwoOn,
  isInternationalOfferingsOn,
  isInternationalOfferingTypesOn,
} from '../../datalab/model/utils';
import { CornerstoneTooltip } from '../../shared/components/cornerstone-old/tooltip/CornerstoneTooltip';
import {
  getCurrencyFormat,
  getCurrencyFormatInMillions,
  getFormattedPercentageRange,
} from '../../shared/model/utils';
import CountryRenderer from '../components/CountryRenderer';
import HeaderLabelRenderer from '../components/HeaderLabelRenderer';
import IndicationTooltip from '../components/IndicationTooltip';
import IssuerFirmNameRenderer from '../components/IssuerFirmNameRenderer';
import LeftLeadFirmNameRenderer from '../components/LeftLeadFirmNameRenderer';
import OfferingProfileLinkRenderer from '../components/OfferingProfileLinkRenderer';
import PerformancePercentsRenderer from '../components/PerformancePercentsRenderer';
import PriceRangeRenderer from '../components/PriceRangeRenderer';
import ShareholderRenderer from '../components/ShareholderRenderer';
import SizeSharesRenderer from '../components/SizeSharesRenderer';
import { getCurrentDatalabScreen } from '../utils';
import { FilterValues } from './calendar-filters';

type Renderer = (
  v,
  row: datalabApi.CalendarOffering,
  _?: any,
  __?: any,
  metaData?: any
) => React.ReactNode;

export type Column = {
  field: string;
  label: string;
  renderer?: Renderer;
  groupHeaderRenderer?: Renderer;
  headerRenderer?: Renderer;
  fixedMinWidth?: number;
  canGroup?: boolean;
  textAlign?: string;
};

export const tbdValue = 'TBD';

const formatDateWithDay = (date: ISODate | ISODateTime | null) =>
  date && timeUtil.formatAsDisplayDate(date)
    ? `${timeUtil.formatAsDisplayDay(date)}, ${timeUtil.formatAsDisplayDate(date)}`
    : null;

export const issuerFirmNameColumn: Column = {
  field: 'issuerFirmName',
  label: 'Issuer',
  renderer: (v, row: datalabApi.CalendarOffering, _, __, metaData) => (
    <IssuerFirmNameRenderer
      offering={row}
      showIoiBadge
      onToggleFollowOffering={metaData.handleFollowClick}
    />
  ),
};

export const issuerFirmNameForMyOfferingColumn: Column = {
  field: 'issuerFirmName',
  label: 'Issuer',
  renderer: (v, row: datalabApi.CalendarOffering, _, __, metaData) => (
    <IssuerFirmNameRenderer
      offering={row}
      showLive
      onToggleFollowOffering={metaData.handleFollowClick}
    />
  ),
};

export const tickerColumn: Column = {
  field: 'ticker',
  label: 'Ticker',
  renderer: (v, row: datalabApi.CalendarOffering) => {
    const { symbol, ticker, countryCode, status } = row;

    if (
      countryCode === 'US' &&
      getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
      status === OfferingStatus.FILED
    ) {
      return symbol && symbol.length > 0 ? symbol : ticker ?? '';
    }

    return (
      <OfferingProfileLinkRenderer
        offering={row}
        label={symbol && symbol.length > 0 ? symbol : ticker ?? ''}
      />
    );
  },
};

export const sectorColumn: Column = {
  field: 'sector',
  label: 'Sector',
  canGroup: true,
  renderer: (v, row: datalabApi.CalendarOffering) => formatSectorName(row.sector),
};

export const customSectorColumn: Column = {
  field: 'sector',
  label: 'Sector',
  canGroup: true,
  renderer: (v, row: datalabApi.CalendarOffering) => row.customSectorName,
};

export const offeringTypeColumn: Column = {
  field: 'type',
  label: 'Type',
  canGroup: true,
  renderer: (v, row) =>
    row.pricingCurrencyCode !== 'USD' && isInternationalOfferingTypesOn()
      ? InternationalOfferingTypeLabels[v]
      : offeringTypeLabels[v],
};

export const expectedPricingDateColumn: Column = {
  field: 'pricingDate',
  label: 'Expected Pricing Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v) || tbdValue,
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v) || tbdValue,
};

export const pricingDateColumn: Column = {
  field: 'pricingDate',
  label: 'Pricing Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v) || tbdValue,
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v) || tbdValue,
};

const getMarketCapTooltip = () => {
  const screen = getCurrentDatalabScreen();
  switch (screen) {
    case CalendarCategory.LIVE:
    case CalendarCategory.POSTPONED:
      return 'Market Cap in USD is calculated using the exchange rate on the later of filing or launch date.';
    default:
      return 'Market Cap in USD is calculated using the exchange rate as of end of day on the later of filing or launch date.';
  }
};

export const marketCapColumn: Column = {
  field: 'marketCap',
  label: 'Market Cap ($M)',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrencyInMillions(v, true),
  headerRenderer: v => (
    <HeaderLabelRenderer label="Market Cap ($M)" tooltip={getMarketCapTooltip()} />
  ),
};

export const marketCapAtPricingColumn: Column = {
  field: 'marketCap',
  label: 'Market Cap ($M)',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrencyInMillions(v, true),
};

const getSizeUsdTooltip = () => {
  const screen = getCurrentDatalabScreen();
  switch (screen) {
    case CalendarCategory.FILED:
      return 'Size in USD is calculated using the exchange rate on the filing date.';
    case CalendarCategory.LIVE:
    case CalendarCategory.POSTPONED:
      return 'Size in USD is calculated using the exchange rate on the later of filing or launch date.';
    default:
      return 'Size in USD is calculated using the exchange rate as of end of day on the filing date.';
  }
};

export const sizeDollarsColumn: Column = {
  field: 'sizeInDollars',
  label: 'Size ($M)',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrencyInMillions(v, true),
  headerRenderer: v => <HeaderLabelRenderer label="Size ($M)" tooltip={getSizeUsdTooltip()} />,
};

export const sizeSharesColumn: Column = {
  field: 'sizeInShares',
  label: 'Size (Shares)',
  fixedMinWidth: 120,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => <SizeSharesRenderer offering={row} />,
};

export const priceRangeLowColumn: Column = {
  field: 'priceRangeLow',
  label: 'Price Range',
  fixedMinWidth: 150,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) =>
    row.type === OfferingType.CONVERTIBLE ? '-' : <PriceRangeRenderer offering={row} />,
};

export const priceRangeLivePricedColumn: Column = {
  field: 'priceRangeLivePricedColumn',
  label: 'Price Range',
  fixedMinWidth: 150,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => {
    const {
      type,
      couponTalkPercentageLow,
      couponTalkPercentageHigh,
      premiumTalkLowPercentage,
      premiumTalkHighPercentage,
    } = row;
    return type === OfferingType.CONVERTIBLE ? (
      `${getFormattedPercentageRange(
        couponTalkPercentageLow,
        couponTalkPercentageHigh,
        3
      )} / ${getFormattedPercentageRange(premiumTalkLowPercentage, premiumTalkHighPercentage, 2)}`
    ) : (
      <PriceRangeRenderer offering={row} />
    );
  },
};

export const leftLeadColumn: Column = {
  field: 'leftLeadFirmName',
  label: 'Left Lead',
  renderer: (v, row: datalabApi.CalendarOffering) => <LeftLeadFirmNameRenderer offering={row} />,
  canGroup: true,
  groupHeaderRenderer: (v, row) => (
    <Link
      to={routeFactory.datalab.getUrlPath({
        screen: DatalabScreens.UNDERWRITER_OFFERINGS,
        type: 'table',
        query: { underwriter: row.leftLeadFirmId },
      })}
    >
      {v}
    </Link>
  ),
};

export const shareholderColumn: Column = {
  field: 'primaryShareholderName',
  label: 'Shareholder',
  renderer: (v, row: datalabApi.CalendarOffering) => <ShareholderRenderer offering={row} />,
  canGroup: true,
  groupHeaderRenderer: (v, row) => (
    <Link
      to={routeFactory.datalab.getUrlPath({
        screen: DatalabScreens.MARKET_PULSE,
        type: 'table',
        query: {
          sponsor: row.primaryShareholderFirmId,
        },
      })}
    >
      {v}
    </Link>
  ),
};

export const offerPriceColumn: Column = {
  field: 'offerPrice',
  label: 'Offer Price',
  fixedMinWidth: 85,
  textAlign: 'right',
  renderer: v => numericUtil.formatCurrency(v),
};

export const offerToCurrentColumn: Column = {
  field: 'offerToCurrent',
  label: 'To Current',
  fixedMinWidth: 65,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => (
    <PerformancePercentsRenderer tooltipValue={row.currentSharePrice!} value={v} />
  ),
};

export const firstTradeDateColumn: Column = {
  field: 'firstTradeDate',
  label: 'First Trade Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v) ?? tbdValue,
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v) ?? tbdValue,
};

export const discountToLastColumn: Column = {
  field: 'discountToLast',
  label: 'To Last Trade',
  fixedMinWidth: 65,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => (
    <PerformancePercentsRenderer tooltipValue={row.lastTradeBeforeOffer!} value={v} />
  ),
};

export const offerTo1dayColumn: Column = {
  field: 'offerTo1Day',
  label: 'To 1 Day',
  fixedMinWidth: 65,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => (
    <PerformancePercentsRenderer tooltipValue={row.day1SharePrice!} value={row.offerTo1Day!} />
  ),
};

export const publicFilingDateColumn: Column = {
  field: 'publicFilingDate',
  label: 'Filing Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v),
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v),
};

export const postponedDateColumn: Column = {
  field: 'postponedDate',
  label: 'Postponed Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v),
  canGroup: true,
  groupHeaderRenderer: v => formatDateWithDay(v),
};

export const lockUpExpirationDateColumn: Column = {
  field: 'lockUpExpirationDate',
  label: 'Lock-Up Expiration Date',
  fixedMinWidth: 85,
  renderer: v => timeUtil.formatAsDisplayDate(v),
  canGroup: true,
};

export const allocationColumn: Column = {
  field: 'allocation',
  label: 'Allocation',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => (
    <Link to={routeFactory.ioi.getUrlPath({ id: row.id })}>{numericUtil.formatInteger(v)}</Link>
  ),
};

export const ioiTypeColumn: Column = {
  field: 'ioiType',
  label: 'Indication',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => (
    <Link to={routeFactory.ioi.getUrlPath({ id: row.id })}>
      <IndicationTooltip indicationType={v} offering={row} />
    </Link>
  ),
};

export const secondarySharesColumn: Column = {
  field: 'pctSecondaryShares',
  label: '% Secondary',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: v => numericUtil.formatPercents(v, 1),
};

export const totalBookRunnersColumn: Column = {
  field: 'totalBookrunners',
  label: '# of Bookrunners',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: v => numericUtil.formatInteger(v),
};

export const totalNonBookRunnersColumn: Column = {
  field: 'totalNonBookrunners',
  label: '# of Non-Bookrunners',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: v => numericUtil.formatInteger(v),
};

export const conditionalLockUpColumn: Column = {
  field: 'conditionalLockUp',
  label: 'Conditional Lock-up',
  fixedMinWidth: 70,
  textAlign: 'right',
  renderer: v => formatNullableBoolean(v),
};

export const multipleLockUpsColumn: Column = {
  field: 'multipleLockUps',
  label: 'Multiple Lock-ups',
  fixedMinWidth: 70,
  textAlign: 'right',
  renderer: v => formatNullableBoolean(v),
};

export const regionColumn: Column = {
  field: 'region',
  label: 'Exchange Region',
  canGroup: true,
  renderer: v => (v ? v.toUpperCase() : v),
};

export const hasCornerstoneColumn: Column = {
  field: 'hasCornerstoneInvestors',
  label: 'Cornerstone Investors (Y/N)',
  renderer: (v, row: datalabApi.CalendarOffering) => (
    <CornerstoneTooltip {...(row as DLGWCalendarOffering)} />
  ),
};

export const countryColumn: Column = {
  field: 'countryDisplayName',
  label: 'Exchange Country',
  canGroup: true,
  renderer: (v, row) => (
    <CountryRenderer countryDisplayName={row.countryDisplayName} countryCode={row.countryCode} />
  ),
};

export const offerPriceLocalCurrColumn: Column = {
  field: 'offerPriceLocalCurr',
  label: 'Offer Price',
  fixedMinWidth: 85,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) =>
    getCurrencyFormat({
      value: row.offerPriceLocalCurr,
      pricingCurrencyCode: row.pricingCurrencyCode ?? 'USD',
      showInternational: true,
    }),
};

export const marketCapAtPricingLocalCurrColumn: Column = {
  field: 'marketCapLocalCurr',
  label: 'Market Cap',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) =>
    getCurrencyFormatInMillions({
      value: row.marketCapLocalCurr,
      pricingCurrencyCode: row.pricingCurrencyCode ?? 'USD',
      showInternational: true,
      keepSmallValues: true,
    }),
};

export const sizeLocalCurrColumn: Column = {
  field: 'sizeInLocalCurr',
  label: 'Size',
  fixedMinWidth: 110,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) => {
    const { type, sizeInLocalCurr, sizeInDollars, pricingCurrencyCode } = row;
    // For Convertible offering type, sizeInLocalCurr has special logic in the backend
    // to return aggregatePrincipalAmount or grossProceedsTotal depends on calendar type
    const sizeValue = type === OfferingType.CONVERTIBLE ? sizeInLocalCurr : sizeInDollars;
    return getCurrencyFormatInMillions({
      value: sizeValue,
      pricingCurrencyCode: pricingCurrencyCode ?? 'USD',
      showInternational: true,
      keepSmallValues: true,
    });
  },
};

export const couponColumn: Column = {
  field: 'couponPercentage',
  label: 'Coupon',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: v => numericUtil.formatPercents(v, 3),
};

export const premiumColumn: Column = {
  field: 'premiumPercentage',
  label: 'Premium',
  fixedMinWidth: 90,
  textAlign: 'right',
  renderer: v => numericUtil.formatPercents(v, 2),
};

export const conversionPriceColumn: Column = {
  field: 'conversionPrice',
  label: 'Conversion Price',
  fixedMinWidth: 85,
  textAlign: 'right',
  renderer: (v, row: datalabApi.CalendarOffering) =>
    getCurrencyFormat({
      value: row.conversionPrice,
      pricingCurrencyCode: row.pricingCurrencyCode ?? 'USD',
      showInternational: true,
    }),
};

export const convertibleFields: string[] = [
  couponColumn.field,
  premiumColumn.field,
  conversionPriceColumn.field,
];

export const internationalFields: string[] = [
  regionColumn.field,
  countryColumn.field,
  offerPriceLocalCurrColumn.field,
  sizeDollarsColumn.field,
  marketCapAtPricingLocalCurrColumn.field,
];

export const internationalFieldForLiveFiledPostponed: string[] = [
  regionColumn.field,
  countryColumn.field,
  offerPriceLocalCurrColumn.field,
  sizeDollarsColumn.field,
  marketCapColumn.field,
];

export const getInternationalFields = (category: CalendarCategory) => {
  if (
    category === CalendarCategory.LIVE ||
    category === CalendarCategory.FILED ||
    category === CalendarCategory.POSTPONED
  ) {
    return internationalFieldForLiveFiledPostponed;
  }

  return internationalFields;
};

export const getColumnsConfig = (
  columns: Column[],
  category: CalendarCategory,
  filters: FilterValues
): Column[] => {
  const showInternational = isInternationalOfferingsOn();
  const showConvertPhaseTwo = isConvertsPhaseTwoOn();

  let columnsConfig = showInternational
    ? columns.map(overrideIntlProps)
    : columns.filter(col => !getInternationalFields(category).includes(col.field));

  if (canViewConvertData()) {
    columnsConfig = columnsConfig.map(overrideConvertibleProps);
  }

  if (!showConvertPhaseTwo) {
    columnsConfig = columnsConfig.filter(col => !convertibleFields.includes(col.field));
  } else if (filters.offeringType.toString() !== 'CONVERTIBLE') {
    // filters out conversionPricecolumn if CONVERTIBLE is not the only Offering Type filter selected
    columnsConfig = columnsConfig.filter(col => col.field !== conversionPriceColumn.field);
  }
  return columnsConfig;
};

export type overridingProp = {
  [x: string]: Partial<Column>;
};

const intlOverridingProps: overridingProp = {
  [offerPriceColumn.field]: {
    label: 'Offer Price $',
  },
  [sizeLocalCurrColumn.field]: {
    // If international is on, this column will display sizeInLocalCurr
    // otherwise it will be sizeInDollar
    renderer: (v, row: datalabApi.CalendarOffering) => {
      const { sizeInLocalCurr, pricingCurrencyCode } = row;
      return getCurrencyFormatInMillions({
        value: sizeInLocalCurr,
        pricingCurrencyCode: pricingCurrencyCode ?? 'USD',
        showInternational: true,
        keepSmallValues: true,
      });
    },
  },
};

export const overrideIntlProps = (column: Column): Column =>
  Object.keys(intlOverridingProps).includes(column.field)
    ? {
        ...column,
        ...intlOverridingProps[column.field],
      }
    : column;

const convertibleOverridingProps: overridingProp = {
  [priceRangeLivePricedColumn.field]: {
    fixedMinWidth: 300,
  },
};

export const overrideConvertibleProps = (column: Column): Column =>
  Object.keys(convertibleOverridingProps).includes(column.field)
    ? {
        ...column,
        ...convertibleOverridingProps[column.field],
      }
    : column;
