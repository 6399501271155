import { datalabApi } from '@cmg/api';
import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { apiUtil, duckPartFactory, Option, ToastManager } from '@cmg/common';
import saveAs from 'file-saver';
import { AnyAction, combineReducers } from 'redux';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import dlApi from '../../api/datalab-api';
import * as datalabGatewayApi from '../../api/dlgw/datalabGatewayApi';
import {
  createActionCreator,
  createActionType,
  createApiActionCreators,
  createReducer,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../../common/redux/reduxHelpers';
import { getFeatureToggles } from '../../config/appSettings';
import {
  getDatalabUserPreferences,
  setDatalabUserPreferences,
} from '../../config/datalabUserPreferences';
import { UUID } from '../../types/common';
import { CalendarCategory } from '../../types/domain/calendar/constants';
import { SpacFilterOptions } from '../../types/domain/offering/constants';
import { isInternationalOfferingsOn } from '../datalab/model/utils';
import {
  FilterValues,
  getAllLeftLeads,
  getAllOfferingOptions,
  getAllSectors,
  getAllSpacOptions,
  getAllUnderwriters,
  getRegionAndCountryOptions,
  InternalOfferingType,
  useGetAllOfferingValues,
} from './model/calendar-filters';

export type DownloadPayload = {
  includeOfferingNotes?: boolean;
  includeIoiNotes?: boolean;
  includeUnderwriters?: boolean;
  includeSponsors?: boolean;
  includeFundIoi?: boolean;
  includeAdvisors?: boolean;
  screen?: string;
};

export type FilterOptions = {
  offeringType: Option[];
  sector: Option[];
  isSpac: Option[];
  leftLead: Option[];
  underwriter: Option[];
  country: Option[];
};

/**
 * ACTION TYPES
 */
const FETCH_OFFERINGS = 'calendar/FETCH_OFFERINGS';
const SET_FOLLOW_OFFERING = 'calendar/SET_FOLLOW_OFFERING';
const FETCH_DATALAB_OPTIONS = 'calendar/FETCH_DATALAB_OPTIONS';
const CALENDAR_DOWNLOAD = 'calendar/CALENDAR_DOWNLOAD';
const FILTER_CHANGE = 'calendar/FILTER_CHANGE';
const UPDATE_FILTER = 'calendar/UPDATE_FILTER';
const INIT_FILTER = 'calendar/INIT_FILTER';

/**
 * ACTIONS
 */
export const fetchOfferingsActions = createApiActionCreators<
  { category: CalendarCategory },
  any,
  any
>(FETCH_OFFERINGS);
export const fetchDatalabOptionsActions = createApiActionCreators(FETCH_DATALAB_OPTIONS);

export const downloadCalendarActions = createApiActionCreators<DownloadPayload, any, any>(
  CALENDAR_DOWNLOAD
);
export const filterChange = createActionCreator<FilterValues>(FILTER_CHANGE);
export const filterUpdate = createActionCreator<FilterValues>(UPDATE_FILTER);
export const initFilter = createActionCreator<never>(INIT_FILTER);

type SetFollowOfferingParams = {
  offeringId: UUID;
  isFollowing: boolean;
  category: CalendarCategory;
};
export const {
  actionCreators: {
    request: setFollowOfferingRequest,
    success: setFollowOfferingSucceeded,
    failure: setFollowOfferingFailed,
  },
  initialState: setFollowOfferingInitialState,
  reducer: setFollowOfferingReducer,
  actionTypes: { REQUEST: setFollowOfferingRequestType, SUCCESS: setFollowingOfferingSuccessType },
} = duckPartFactory.makeAPIDuckParts<SetFollowOfferingParams, SetFollowOfferingParams>({
  prefix: SET_FOLLOW_OFFERING,
});

export type SetFollowOfferingAction = ReturnType<typeof setFollowOfferingRequest>;
export type SetFollowOfferingSuccessAction = ReturnType<typeof setFollowOfferingSucceeded>;

/**
 * REDUCERS
 */
type CalendarReducer<T> = (state: T | undefined, action: AnyAction) => T;

type CalendarOfferingState = {
  [CalendarCategory.LIVE]: datalabApi.CalendarOffering[];
  [CalendarCategory.PRICED]: datalabApi.CalendarOffering[];
  [CalendarCategory.FILED]: datalabApi.CalendarOffering[];
  [CalendarCategory.POSTPONED]: datalabApi.CalendarOffering[];
  [CalendarCategory.LOCK_UP_EXPIRATION]: datalabApi.CalendarOffering[];
  [CalendarCategory.MY_OFFERINGS]: datalabApi.CalendarOffering[];
  [CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS]: datalabApi.CalendarOffering[];
  [CalendarCategory.DRAFT]: datalabApi.CalendarOffering[];
};
export type State = {
  offerings: CalendarOfferingState;
  advisoryOptions: any[];
  fundOptions: any[];
  filterValues: FilterValues;
  filterOptions: FilterOptions;

  /**
   * temporary adding state till we switch to ducksPartFactory
   */
  isLoading: boolean;
};

export const initialState: State = {
  offerings: {
    [CalendarCategory.LIVE]: [],
    [CalendarCategory.PRICED]: [],
    [CalendarCategory.FILED]: [],
    [CalendarCategory.POSTPONED]: [],
    [CalendarCategory.LOCK_UP_EXPIRATION]: [],
    [CalendarCategory.MY_OFFERINGS]: [],
    [CalendarCategory.MY_OFFERINGS_WITH_ALLOCATIONS]: [],
    [CalendarCategory.DRAFT]: [],
  },
  advisoryOptions: [],
  fundOptions: [],
  filterValues: {
    offeringType: [],
    sector: [],
    customSectorId: [],
    spac: SpacFilterOptions.INCLUDE,
    sizeInDollars: {},
    marketCap: {},
    leftLeadFirmId: [],
    managerFirmId: [],
    useCustomSectors: false,
    countries: [],
  },
  filterOptions: {
    offeringType: [],
    sector: [],
    isSpac: [{ label: 'Include SPACs', value: SpacFilterOptions.INCLUDE }],
    leftLead: [],
    underwriter: [],
    country: [],
  },
  isLoading: false,
};

const filterValuesReducer: CalendarReducer<FilterValues> = createReducer(
  initialState.filterValues,
  {
    [FETCH_OFFERINGS]: {
      [SUCCESS]: (state: FilterValues, { offerings }): FilterValues => {
        function filter(options: string[], originalValues: string[]) {
          return originalValues.filter(value => options.includes(value));
        }
        const sectorOptions = getAllSectors().map(o => o.value);
        const leftLeadOptions = getAllLeftLeads(offerings).map(o => o.value);
        const underwriterOptions = getAllUnderwriters(offerings).map(o => o.value);
        const countryOptions = getRegionAndCountryOptions(offerings).map(region =>
          region.children.map(country => country.value)
        );
        const offeringType =
          state.offeringType.length === 0 ? useGetAllOfferingValues() : state.offeringType;

        return {
          offeringType: offeringType,
          sector: filter(state.sector, sectorOptions),
          customSectorId: state.customSectorId,
          sizeInDollars: state.sizeInDollars,
          marketCap: state.marketCap,
          spac: state.spac,
          leftLeadFirmId: filter(state.leftLeadFirmId, leftLeadOptions),
          managerFirmId: filter(state.managerFirmId, underwriterOptions),
          useCustomSectors: state.useCustomSectors,
          countries: filter(state.countries, countryOptions.flat()),
        };
      },
    },
    [UPDATE_FILTER]: (state: FilterValues, values: Partial<FilterValues>) => ({
      ...state,
      ...values,
    }),
  }
);

const filterOptionsReducer: CalendarReducer<FilterOptions> = createReducer(
  initialState.filterOptions,
  {
    [FETCH_OFFERINGS]: {
      [SUCCESS]: (state: FilterOptions, { offerings }) => ({
        offeringType: getAllOfferingOptions(),
        sector: getAllSectors(),
        leftLead: getAllLeftLeads(offerings),
        underwriter: getAllUnderwriters(offerings),
        isSpac: getAllSpacOptions(),
        country: getRegionAndCountryOptions(offerings),
      }),
    },
  }
);

const offeringsReducer: CalendarReducer<CalendarOfferingState> = createReducer(
  initialState.offerings,
  {
    [FETCH_OFFERINGS]: {
      [SUCCESS]: (state, { category, offerings }) => ({ ...state, [category]: offerings }),
    },
    [setFollowingOfferingSuccessType]: (
      state: datalabApi.CalendarOffering,
      payload: SetFollowOfferingSuccessAction['payload']
    ) => ({
      ...state,

      [payload.category]: state[payload.category].map(offering =>
        offering.id === payload.offeringId
          ? { ...offering, userIsFollowing: payload.isFollowing }
          : offering
      ),
    }),
  }
);

const advisoryOptionsReducer: CalendarReducer<any[]> = createReducer(initialState.advisoryOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.advisoryOptions,
  },
});

const fundOptionsReducer: CalendarReducer<any[]> = createReducer(initialState.fundOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.fundOptions,
  },
});

const isLoadingReducer: CalendarReducer<boolean> = createReducer(initialState.isLoading, {
  [FETCH_OFFERINGS]: {
    [REQUEST]: () => true,
    [SUCCESS]: () => false,
    [FAILURE]: () => false,
  },
});

const reducer: CalendarReducer<State> = combineReducers({
  advisoryOptions: advisoryOptionsReducer,
  fundOptions: fundOptionsReducer,
  filterValues: filterValuesReducer,
  filterOptions: filterOptionsReducer,
  offerings: offeringsReducer,
  isLoading: isLoadingReducer,
});
export default reducer;

/**
 * SELECTORS
 */
const selectState: (state) => State = state => state.calendar;
export const selectOfferings = (state, type) => selectState(state).offerings[type];
export const selectAdvisoryOptions = state => selectState(state).advisoryOptions;
export const selectFundOptions = state => selectState(state).fundOptions;
export const selectFilterOptions = state => selectState(state).filterOptions;
export const selectFilterValues = state => selectState(state).filterValues;
export const selectIsCalendarLoading = state => selectState(state).isLoading;

/**
 * SAGAS
 */

/**
 * TODO: This should be replaced with DLGW api pagination mechanism in new Datalab UI.
 */
const CALENDAR_OFFERINGS_PAGE_SIZE = 1000;

export function* fetchCalendarOfferingsSaga({
  payload,
}: ReturnType<typeof fetchOfferingsActions.request>) {
  const { category } = payload!;

  const resp = yield call(
    datalabApi.getCalendarOfferings,
    {},
    {
      category,
      perPage: CALENDAR_OFFERINGS_PAGE_SIZE,
    }
  );

  if (resp.ok) {
    const offerings: datalabApi.CalendarOffering[] = resp.data.data;
    yield put(fetchOfferingsActions.success({ category, offerings }));
  } else {
    yield put(fetchOfferingsActions.failure());
    ToastManager.error('Failed to load calendar');
  }
}

export function* setFollowOfferingSaga({ payload }: SetFollowOfferingAction) {
  const { offeringId, isFollowing } = payload;

  const resp: datalabGatewayApi.SetFollowOfferingResponse = yield call(
    datalabGatewayApi.setFollowOffering,
    { offeringId },
    { isFollowing }
  );

  if (resp.ok) {
    yield put(setFollowOfferingSucceeded(payload));
  } else {
    yield put(setFollowOfferingFailed(resp.data.error));
  }
}

export function* fetchDatalabOptionsSaga() {
  const canFetchFunds = checkPermissions(getUserPermissions(), [permissionsByEntity.FundIoi.READ]);

  const [advisory, fund] = yield [
    call(dlApi.fetchAdvisoryOptions),
    canFetchFunds ? call(dlApi.fetchFundOptions) : { ok: true, data: [] },
  ];

  if (advisory.ok && fund.ok) {
    const result = {
      advisoryOptions: advisory.data,
      fundOptions: fund.data,
    };
    yield put(fetchDatalabOptionsActions.success(result));
  }
}

const hasCornerstoneInvestorsEnabled = (screen?: string) => {
  if (!getFeatureToggles().isCornerstoneInvestorInDLOn) {
    return false;
  }
  switch (screen) {
    case CalendarCategory.LIVE:
    case CalendarCategory.PRICED:
      return true;
    default:
      return false;
  }
};
const filterOutInternalOfferingTypes = (type: string): boolean => {
  return (
    type !== InternalOfferingType.IPO_SPACS &&
    type !== InternalOfferingType.ABB_ABO &&
    type !== InternalOfferingType.BLOCK &&
    type !== InternalOfferingType.FULLY_MARKETED_OFFERING
  );
};
/**
 * Download calendar offerings to an excel sheet. This is only applicable for the datalab calendar.
 */
export function* downloadCalendarSaga({ payload }: { payload: DownloadPayload }) {
  const state = yield select();
  const filterValues = selectFilterValues(state);
  const {
    offeringType,
    sector,
    customSectorId,
    leftLeadFirmId,
    managerFirmId,
    useCustomSectors,
    sizeInDollars,
    marketCap,
    countries,
  } = filterValues;
  const { includeOfferingNotes, includeIoiNotes, includeFundIoi, screen } = payload;
  const canReadCustomSectors = checkPermissions(getUserPermissions(), [
    permissionsByEntity.CustomSectors.READ,
  ]);
  let displayCustomSectors: string[] | undefined = undefined;
  if (canReadCustomSectors && useCustomSectors) {
    displayCustomSectors = customSectorId;
  }
  const displaySectors: string[] | undefined = displayCustomSectors ? undefined : sector;
  const showInternational = isInternationalOfferingsOn();

  const queryBody: datalabApi.DownloadCalendarOfferingsRequestBody = {
    offeringTypes: offeringType?.filter(filterOutInternalOfferingTypes),
    sectors: displaySectors,
    customSectors: displayCustomSectors,
    leftLeads: leftLeadFirmId,
    hasCornerstoneInvestors: hasCornerstoneInvestorsEnabled(screen) ? true : undefined,
    sizeUsd: {
      min: sizeInDollars.min ? sizeInDollars.min * 1000000 : undefined,
      max: sizeInDollars.max ? sizeInDollars.max * 1000000 : undefined,
    },
    marketCapUsd: {
      min: marketCap.min ? marketCap.min * 1000000 : undefined,
      max: marketCap.max ? marketCap.max * 1000000 : undefined,
    },
    underwriters: managerFirmId,
    includeOfferingNotes: includeOfferingNotes,
    includeIoiNotes: includeIoiNotes,
    includeFundIoi: includeFundIoi,
    useCustomSectors: !!displayCustomSectors,
    countries: showInternational ? countries : undefined,
  };
  const resp: datalabApi.DownloadCalendarOfferingsResponse = yield call(
    datalabApi.downloadCalendarOfferingsLegacy,
    queryBody,
    {}
  );

  if (resp.ok) {
    saveAs(
      resp.data,
      apiUtil.getFilenameFromContentDisposition(
        resp.headers['content-disposition'],
        'calendar-download.xlsx'
      )
    );
  }
}

export function* initFilterSaga() {
  const savedFilters = getDatalabUserPreferences<FilterValues>('calendarFilters');

  if (savedFilters) {
    yield put(filterUpdate(savedFilters));
    return;
  } else {
    const state = yield select();
    const filterValues = selectFilterValues(state);
    yield put(filterUpdate(filterValues));
  }
}

export function* updateFilterSaga(filterAction: ReturnType<typeof filterChange>) {
  if (!filterAction.payload) {
    return;
  }

  yield put(filterUpdate(filterAction.payload));

  setDatalabUserPreferences('calendarFilters', filterAction.payload);
}

export function* calendarSaga() {
  // @ts-ignore
  yield takeLatest(createActionType(FETCH_OFFERINGS, REQUEST), fetchCalendarOfferingsSaga);
  yield takeEvery<SetFollowOfferingAction>(setFollowOfferingRequestType, setFollowOfferingSaga);
  // @ts-ignore
  yield takeEvery(createActionType(FETCH_DATALAB_OPTIONS, REQUEST), fetchDatalabOptionsSaga);
  // @ts-ignore
  yield takeEvery(createActionType(CALENDAR_DOWNLOAD, REQUEST), downloadCalendarSaga);
  yield takeEvery<ReturnType<typeof filterChange>>(FILTER_CHANGE, updateFilterSaga);
  yield takeLatest(INIT_FILTER, initFilterSaga);
}
