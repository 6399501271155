import 'focus-visible';

import { createGlobalStyle, css } from 'styled-components/macro';
import { normalize } from 'styled-normalize';

import theme from '../../../styles/theme';

// extracted and exported for regression testing purposes
export const customGlobalStyles = css`
  * {
    box-sizing: border-box;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }

  /**
   * Disable outline for elements selected by mouse
   * {@link https://github.com/WICG/focus-visible}
   */
  *:focus:not(.focus-visible) {
    outline: none;
  }

  body {
    font-family: ${theme.text.family.regular};
    font-size: ${theme.text.size.medium};
    line-height: 1.43; /* same as historical bootstrap value */
  }

  a {
    color: ${theme.text.color.link};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  input::-ms-clear {
    display: none; /* disable clear-sign on right side of input in IE */
  }

  dl,
  dd,
  dt,
  ul,
  li,
  ol {
    margin: 0;
    padding: 0;
  }

  /* chrome adds margin-inline-start: 40px to dd elements while its not supported in edge and IE */
  dd {
    margin-inline-start: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${normalize}

  ${customGlobalStyles}
`;

export default GlobalStyle;
