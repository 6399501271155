import styled, { css } from 'styled-components/macro';

export type TextAreaResize = 'vertical' | 'horizontal' | 'none';

export const STextArea = styled.textarea<{ resize?: TextAreaResize }>`
  ${({ resize }) => css`
    display: block;
    line-height: 1.45;
    resize: ${resize};
    width: 100%;
  `};
`;
