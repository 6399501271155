import React from 'react';
import { Provider } from 'react-redux';

import { AuthContext } from '../../auth/components/auth-provider/AuthProvider';
import { RootState } from '../../common/redux/rootReducer';
import { OIDCUserType } from '../../types/domain/oidc-user/oidc-user';
import { makeOidcUser, makeOidcUserProfile } from '../mocks/domain/oidc-user/oidcUser';
import { makeState } from '../mocks/redux/state';
import { makeStore } from '../mocks/redux/store';

function getStateWithOIDCUser(state: RootState, oidcUser?: OIDCUserType): Partial<RootState> {
  if (oidcUser === undefined) {
    return state;
  }

  const auth = { ...state.auth, oidcUser };
  return { ...state, auth };
}

export type Props = {
  override?: Partial<RootState>;
  withPermissions?: string[];
};

const ReduxProvider: React.FC<Props> = ({ override, children, withPermissions = [] }) => {
  const oidcUser = makeOidcUser({
    profile: makeOidcUserProfile({
      permissions: withPermissions,
    }),
  });
  const state = getStateWithOIDCUser(makeState(override), !override ? oidcUser : undefined);
  const store = makeStore(state);

  return (
    <Provider store={store} context={AuthContext}>
      {children}
    </Provider>
  );
};

export default ReduxProvider;
