import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { bindActionCreators } from 'redux';

import { UserNote } from '../../../../../types/domain/offering/user-note';
import useFollowOfferingMutation from '../../../../shared/follow-offering/useFollowOfferingMutation';
import {
  fetchOfferingActions,
  selectError as selectOfferingError,
  selectOffering,
} from '../../../shared/ducks';
import {
  fetchUserNoteRequest,
  resetState,
  saveUserNoteRequest,
  selectError,
  selectUserNote,
} from '../../ducks';
import OfferingProfileScreen from './OfferingProfileScreen';

const mapStateToProps = state => ({
  profileError: selectError(state),
  offeringError: selectOfferingError(state),
  offering: selectOffering(state),
  userNote: selectUserNote(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchOffering: fetchOfferingActions.request,
      fetchUserNote: fetchUserNoteRequest,
      saveUserNote: saveUserNoteRequest,
      resetState,
    },
    dispatch
  ),
});

type RouterProps = RouteComponentProps<{ offeringId: string }>;

type OwnProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & RouterProps;

export const OfferingProfileContainerComponent: React.FC<Props> = ({
  actions,
  match,
  offering,
  userNote,
  profileError,
  offeringError,
}) => {
  const { offeringId } = match.params;

  const { setFollowingOffering } = useFollowOfferingMutation({
    refetch: () => actions.fetchOffering({ offeringId }),
  });

  useEffect(() => {
    actions.fetchOffering({ offeringId });
    actions.fetchUserNote({ offeringId });
  }, [actions, offeringId]);

  useEffect(
    () => () => {
      actions.resetState();
    },
    [actions]
  );

  const onSaveUserNote = (userNoteDto: Partial<UserNote>) => {
    actions.saveUserNote({
      offeringId,
      userNote: {
        id: userNoteDto.id!,
        note: userNoteDto.note!,
      },
    });
  };

  const onToggleFollowOffering = async (follow: boolean) => {
    await setFollowingOffering(offeringId, follow);
  };

  const error = offeringError || profileError;

  if (!offering && !error) {
    return null;
  }

  return (
    <OfferingProfileScreen
      offeringId={offering!.id}
      offeringType={offering!.type}
      userNote={userNote}
      onSaveUserNote={onSaveUserNote}
      isFollowing={offering.userIsFollowing}
      onToggleFollowOffering={() => onToggleFollowOffering(!offering.userIsFollowing)}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferingProfileContainerComponent);
