import React from 'react';

import { getCurrencySymbol } from '../../../data/currencies';
import { createAgEditor } from '../../lists/data-grid/aggrid/createAgEditor';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import NumericInput from '../numeric-input/NumericInput';
import { FormControlEvents } from '../types';

export interface Props
  extends FieldControlProps<number, number | null>,
    FormControlEvents,
    Pick<React.AriaAttributes, 'aria-label'> {
  className?: string;
  placeholder?: string;
  precision?: number;
  digit?: number;
  currencyCode?: string;
  innerRef?: React.Ref<HTMLInputElement>;
  testId?: string;
}

const CurrencyInputComponent: React.FC<Props> = ({ currencyCode = 'USD', ...restProps }) => {
  const currencySymbol = getCurrencySymbol(currencyCode);

  return <NumericInput {...restProps} prefix={currencySymbol} />;
};

const CurrencyInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <CurrencyInputComponent innerRef={ref} {...props} />;
});
CurrencyInput.displayName = `CurrencyInput`;

export default CurrencyInput;

export const CurrencyInputField = createFormikField<number, number | null, HTMLInputElement, Props>(
  CurrencyInput
);

export const CurrencyAgEditor = createAgEditor(CurrencyInput);
