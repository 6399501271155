import React from 'react';

type RouteLifecycleTrackerProps = {
  onRouteEntered?: () => void;
  onRouteExited?: () => void;
  children: React.ReactChild;
};

/**
 * Since react-router mounts and unmounts route components we use that to hook into enter/exit logic
 * for routes.
 * Currently this is meant to be private and only used for functionality that runs within PrivateRoute
 * on a transition from private <-> not private routes. (since route specific logic can be built directly into those components)
 */
class RouteLifecycleTracker extends React.Component<RouteLifecycleTrackerProps> {
  componentDidMount() {
    this.props.onRouteEntered?.();
  }

  componentWillUnmount() {
    this.props.onRouteExited?.();
  }

  render() {
    return this.props.children;
  }
}

export default RouteLifecycleTracker;
