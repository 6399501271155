import React, { useEffect, useState } from 'react';
import ReactSelect, { components, MultiValue } from 'react-select';
import { useTheme } from 'styled-components/macro';

import PrimaryButton from '../../../components/buttons/button/PrimaryButton';
import SecondaryButton from '../../../components/buttons/button/SecondaryButton';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import {
  checkboxStyles,
  SButtonsContainer,
  SIconContainer,
  SInputContainer,
  SLabel,
  SOptionContainer,
  SSelectAllContainer,
  StyledIcon,
} from './CheckboxSelect.styles';
import { Option } from './types';

export type SingleSyncSelectProps<TOptionValue> = {
  options?: Option<TOptionValue>[];
};

export type OwnProps<TOptionValue> = {
  resetValues?: { value: TOptionValue; label: string }[];
  width?: number;
  testId?: string;
};

export type Props<TOptionValue> = OwnProps<TOptionValue> &
  FieldControlProps<TOptionValue[] | null, TOptionValue[]> &
  SingleSyncSelectProps<TOptionValue>;

export const getSingleSelectedOptions = <TOptionValue extends any>(
  options: Option<TOptionValue>[] = [],
  values: TOptionValue[] | null | undefined = null
): MultiValue<Option<TOptionValue>> => {
  return options.filter(o => (values ?? []).includes(o.value));
};

const getOptions = <T,>(values: { value: T; label: string }[]): Option<T>[] => {
  return values.map(x => {
    return {
      value: x.value,
      label: x.label,
    };
  });
};

export default function CheckboxSelect<T = string>({
  options = [],
  onChange,
  value,
  disabled,
  width,
  resetValues = [],
  testId,
}: Props<T>): JSX.Element {
  const theme = useTheme();

  const handleOnChange = (values: MultiValue<Option<T>>) =>
    onChange && onChange(values.map(x => x.value));

  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState<MultiValue<Option<T>>>(
    getSingleSelectedOptions(options, value)
  );

  useEffect(() => {
    setCurrentValue(getSingleSelectedOptions(options, value));
  }, [options, value]);

  return (
    <div style={{ width }} data-test-id={testId}>
      <ReactSelect<Option<T>, true>
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti
        options={options}
        styles={checkboxStyles(theme)}
        onChange={_value => setCurrentValue(_value)}
        value={currentValue}
        isDisabled={disabled}
        menuIsOpen={isOpen}
        onMenuOpen={() => !disabled && setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        onBlur={() => setIsOpen(false)}
        isClearable={false}
        isSearchable={false}
        components={{
          Option: props => {
            return (
              <components.Option {...props}>
                <SOptionContainer role="checkbox" aria-checked={props.isSelected}>
                  <SIconContainer isChecked={props.isSelected}>
                    {props.isSelected && <StyledIcon name="check" variant="solid" size="xs" />}
                  </SIconContainer>
                  <SLabel>{props.data.label}</SLabel>
                </SOptionContainer>
              </components.Option>
            );
          },
          MenuList: ({ children, ...props }) => {
            const isSelected = props.getValue().length > 0;
            const onChange = () => {
              if (props.getValue().length === options.length) {
                props.setValue([], 'deselect-option');
              } else {
                props.setValue(options, 'select-option');
              }
            };

            let ariaChecked: 'true' | 'false' | 'mixed' = 'false';

            if (isSelected) {
              ariaChecked = props.getValue().length < options.length ? 'mixed' : 'true';
            }
            return (
              <components.MenuList {...props}>
                <SSelectAllContainer onClick={() => onChange()}>
                  <SOptionContainer role="checkbox" aria-checked={ariaChecked}>
                    <SIconContainer isChecked={isSelected}>
                      {isSelected && (
                        <StyledIcon
                          name={props.getValue().length < options.length ? 'minus' : 'check'}
                          variant="solid"
                          size="xs"
                        />
                      )}
                    </SIconContainer>
                    <SLabel>Select All</SLabel>
                  </SOptionContainer>
                </SSelectAllContainer>
                {children}
                <SButtonsContainer>
                  <PrimaryButton
                    onClick={() => {
                      handleOnChange(props.getValue());
                      setIsOpen(false);
                    }}
                  >
                    Apply
                  </PrimaryButton>
                  <SecondaryButton
                    onClick={() => props.setValue(getOptions(resetValues), 'select-option')}
                  >
                    Reset
                  </SecondaryButton>
                </SButtonsContainer>
              </components.MenuList>
            );
          },
          MultiValueRemove: () => {
            return null;
          },
          MultiValue: props => {
            return (
              <components.MultiValue {...props}>
                <span></span>
              </components.MultiValue>
            );
          },
          ValueContainer: ({ children, ...props }) => {
            if (!props.hasValue) {
              return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
            }

            return (
              <components.ValueContainer
                {...props}
                innerProps={{
                  ...props.innerProps,
                  onClick: () => !disabled && setIsOpen(!isOpen),
                }}
              >
                <SInputContainer>
                  {currentValue.map(v => v.label).join(', ')}
                  {children}
                </SInputContainer>
              </components.ValueContainer>
            );
          },
        }}
      />
    </div>
  );
}

export const CheckboxSelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(
  CheckboxSelect
);
