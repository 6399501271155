import { checkIsFollowingOffering } from '../../shared/follow-offering/checkIsFollowingOffering';
import sharedUseFollowOfferingMutation from '../../shared/follow-offering/useFollowOfferingMutation';
import { type Calendar_OfferingFieldsFragment } from '../graphql';

type Params = { refetch?: () => void };

export default function useFollowOfferingMutation({ refetch }: Params = {}) {
  const { setFollowingOffering } = sharedUseFollowOfferingMutation({ refetch });

  const handleToggleFollowOffering = async (offering: Calendar_OfferingFieldsFragment) => {
    const isFollowing = checkIsFollowingOffering(offering);
    await setFollowingOffering(offering.id, !isFollowing);
  };

  return {
    handleToggleFollowOffering,
  };
}
