import styled, { css } from 'styled-components/macro';

export const SFormField = styled.div<{ withMargin?: boolean; fullWidth?: boolean }>`
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: 15px;
      margin-right: 15px;
    `}

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          display: block;
          flex: auto;
        `
      : css`
          display: inline-block;
          min-width: 250px;
        `}
`;

export const SError = styled.span`
  color: ${({ theme }) => theme.text.color.error};
`;
