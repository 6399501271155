import { makeBaseServerEntity } from '../../../core/BaseServerEntity.mock';
import { makeEntity } from '../../../utils';
import { Account } from '../types/Account';
import { AccountStatus } from '../types/AccountStatus';
import { AccountType } from '../types/AccountType';
import { LicenseTypes } from '../types/LicenseTypes';
import { makePasswordPolicy } from './PasswordPolicy.mock';

export const makeAccount = makeEntity<Account>({
  name: 'mock-name',
  subdomain: 'mock-subdomain',
  firmId: 'mock-firmId',
  cmgEntityKey: 'mock-cmgEntityKey',
  databaseName: 'mock-databaseName',
  databaseHost: 'mock-databaseHost',
  accountType: AccountType.BUY_SIDE,
  status: AccountStatus.ACTIVE,
  licenseType: LicenseTypes.PAID,
  licenseCount: 10,
  remainingLicenseCount: 10,
  trialPeriodEndsOn: 'mock-trialPeriodEndsOn',
  localLoginEnabled: true,
  requireTwoFactorAuth: true,
  enforceIpWhitelist: true,
  ipWhitelist: [],
  passwordPolicy: makePasswordPolicy(),
  identityProviders: [],
  ...makeBaseServerEntity(),
});
