import { InterstitialPage } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { oidcLogin } from '../../ducks';
import { AuthContext } from '../auth-provider/AuthProvider';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      oidcLogin,
    },
    dispatch
  ),
});

type OwnProps = {
  /** returnUrl consumer return url after a successful login */
  returnUrl: string;
  /** onError error callback invoked when the consumer tries to login with a subdomain that is not valid */
  onError: (error: Error) => void;
};

type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

/**
 * OIDCLoginComponent is the oidc login component that should be used
 * when a consumer application routes into an oidc login url
 */
const OIDCLoginComponent: React.FC<Props> = ({ actions, returnUrl, onError }) => {
  React.useEffect(() => {
    actions.oidcLogin({ returnUrl, onError });
  }, [actions, returnUrl, onError]);

  return <InterstitialPage />;
};

const OIDCLogin = connect(undefined, mapDispatchToProps, undefined, { context: AuthContext })(
  OIDCLoginComponent
);

/**
 * Anytime a user is not authenticated they can be directed to this route to kick off
 * the oidc login flow.
 */
export default OIDCLogin;
