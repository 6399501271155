import React from 'react';
import styled from 'styled-components/macro';

export type Props = { size: number; bgColor: string; textColor: string; testId?: string };

export const Ribbon: React.FC<Props> = ({ children, bgColor, textColor, size, testId }) => {
  return (
    <SRibbon size={size} bgColor={bgColor} textColor={textColor} data-test-id={testId}>
      <SRibbonText>{children}</SRibbonText>
      <SRibbonBackground />
    </SRibbon>
  );
};

export const SRibbon = styled.div<{ size: number; bgColor: string; textColor: string }>`
  --ribbon-width: ${({ size }) => size}px;
  --ribbon-bg-color: ${({ bgColor }) => bgColor};
  --ribbon-text-color: ${({ textColor }) => textColor};

  transform: rotate(-45deg) translateY(calc(var(--ribbon-width) / 2));
  transform-origin: top center;
  text-align: center;
  width: var(--ribbon-width);
  position: absolute;
  top: 0;
  left: calc(var(--ribbon-width) / -2);
  isolation: isolate; // Create an isolated z-index stack
`;

export const SWrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

export const SRibbonBackground = styled.div`
  // This way it will always be larger than the screen, whether it's vertical or horizontal
  width: calc(100vw + 100vh);
  background: var(--ribbon-bg-color);
  height: 100%;
  position: absolute;
  top: 0;
  left: calc((100vw + 100vh) / -2);
`;

export const SRibbonText = styled.div`
  padding: 4px 0;
  color: var(--ribbon-text-color);
  word-break: break-word;
  font-size: ${({ theme }) => theme.text.size.tiny};
  letter-spacing: 0.05em;
  position: relative;
  z-index: 1;
`;

export default Object.assign(Ribbon, { Wrapper: SWrapper });
