import styled from 'styled-components/macro';

// todo rewrite InputWrapper to styled component
export const SDateTimePicker = styled.div`
  display: flex;
  width: 100%;

  > * {
    flex: auto;
  }

  .input-wrapper input {
    min-width: 180px;
    border-radius: 0;
  }

  .input-wrapper:first-child input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .input-wrapper:last-child input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .react-datepicker-popper {
    z-index: 3;
    min-width: 325px;
  }
`;
