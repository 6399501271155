import { makeBaseServerEntity } from '../../../core/BaseServerEntity.mock';
import { makeEntity } from '../../../utils';
import { UserBasic } from '../types/UserBasic';
import { UserJobFunction } from '../types/UserJobFunction';
import { UserStatus } from '../types/UserStatus';

export const makeUserBasic = makeEntity<UserBasic>({
  email: 'mock-email@cmg.com',
  accountConfirmed: true,
  firstName: 'mock-firstName',
  lastName: 'mock-lastName',
  jobFunction: UserJobFunction.SALES_SUPERVISOR,
  lastIpAddress: '10.0.0.1',
  lastLoginTime: '2018-11-29T00:00:00+00:00',
  status: UserStatus.ACTIVE,
  isLockedOut: false,
  lockoutEnd: null,
  ...makeBaseServerEntity(),
});
