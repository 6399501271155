import { loggerUtil, mixpanelUtil } from '@cmg/common';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import mixpanelMiddleware from 'remimi';

import { AuthProviderConfig } from '../../types/api/AuthProviderConfig';
import { getUserFamilyName, getUserGivenName, getUserId } from '../authService';
import browserStorageManager from '../util/browserStorageManager';
import rootReducer, { RootState } from './rootReducer';
import rootSaga from './rootSaga';

type StoreInstanceType = {
  store: Store | null;
};

/**
 * This is a unique case for a pattern that we typically should not use.
 * Unfortunately store creation is dependent on authConfig from the AuthProvider which forced us to expose the store.
 *
 * Store is exposed due to the requirement of js functions to have access to the redux store outside of react context
 * Setting the store variable in getStore() and exposing it allows us to use it in other components
 */
let storeInstance: StoreInstanceType = { store: null };

/**
 * getStore creates the store if it does not exist using the authConfig
 *
 * @param authConfig
 */
export function getStore(authConfig: AuthProviderConfig): Store {
  if (storeInstance.store) {
    return storeInstance.store;
  }

  const sagaMiddleware = createSagaMiddleware({
    ...(!authConfig.env || !authConfig.env.isDevelopment
      ? {
          onError: (error, errorInfo) => {
            loggerUtil.error(error, errorInfo);
          },
        }
      : {}),
  });

  // redux-thunk support because redux-idle-monitor dispatches async actions that redux-saga cannot handle (https://github.com/noderaider/redux-idle-monitor#how-it-works)
  const middlewares = [sagaMiddleware];

  if (authConfig.logging && authConfig.logging.mixPanelToken) {
    const mixpanelOptions = mixpanelUtil.getMixPanelOptions(
      getUserId,
      getUserGivenName,
      getUserFamilyName
    );

    middlewares.push(mixpanelMiddleware(authConfig.logging.mixPanelToken, mixpanelOptions));
  }

  const oidcUser = browserStorageManager.getOidcUser(
    authConfig.auth.oidcAuthorityBaseUrl,
    authConfig.auth.clientId
  );

  const persistedState = {
    auth: {
      oidcUser,
      authConfig,
    },
  } as RootState;

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  storeInstance.store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  return storeInstance.store;
}

/** To access the store we need to import storeInstance and access storeInstance.store
 * see getAccessToken() for reference
 */
export default storeInstance;
