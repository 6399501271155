import styled, { css } from 'styled-components/macro';

export const SBox = styled.div<{ withMargin?: boolean }>`
  display: flex;
  flex: auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background.color.white};
  position: relative;
  border-radius: 2px;
  margin: ${({ withMargin }) => (withMargin ? '14px' : 0)};
  margin-bottom: 14px;
`;

export const SBoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.border.color.light};
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const STitle = styled.div`
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.text.weight.medium}; /* semibold */
  text-transform: uppercase;
  padding: 12px 10px 8px 10px;
`;

export const STabs = styled.div`
  max-height: none;
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
`;

export const STabButton = styled.button<{ active?: boolean }>`
  cursor: pointer;
  text-transform: uppercase;
  border: 0;
  border-bottom: 3px solid transparent;
  padding: 12px 15px 5px 15px;
  box-sizing: border-box;
  background-color: transparent;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.text.weight.medium}; /* semibold */

  ${({ active }) =>
    active &&
    css`
      border-color: ${({ theme }) => theme.interactive.primary};
      color: ${({ theme }) => theme.interactive.primary};
    `}
`;

export const SRight = styled.div`
  display: flex;
  margin-left: auto; // aligns to right
  flex-wrap: wrap;
`;

export const SBoxContent = styled.div<{ large?: boolean }>`
  padding: ${({ large }) => (large ? '20px' : '10px')};
`;

export const SBoxFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;
