import { loggerUtil } from '@cmg/common';
import { InternalAxiosRequestConfig } from 'axios';

import {
  oidcLogout,
  selectAccessToken,
  selectOidcUserAccountTraits,
  selectOidcUserAccountType,
  selectOidcUserFamilyName,
  selectOidcUserGivenName,
  selectOidcUserId,
  selectOidcUserProfile,
  selectTokenExpiration,
  selectUserFirmId,
  selectUserPermissions,
} from '../auth/ducks';
import storeInstance from './redux/store';
import { isAccessTokenExpired } from './util/authorization';

/**
 * Gets the oidc user access token from the redux store
 */
export const getAccessToken = () => {
  return storeInstance.store ? selectAccessToken(storeInstance.store.getState()) : null;
};

/**
 * Gets the oidc user profile from the redux store
 */
export const getUserProfile = () => {
  return storeInstance.store ? selectOidcUserProfile(storeInstance.store.getState()) : null;
};

/**
 * Gets the oidc user account type from the redux store
 */
export const getUserAccountType = () => {
  return storeInstance.store ? selectOidcUserAccountType(storeInstance.store.getState()) : null;
};

/**
 * Gets the expiration time of the user's token from the redux store
 */
export const getAccessTokenExpiration = () => {
  return storeInstance.store ? selectTokenExpiration(storeInstance.store.getState()) : null;
};

/**
 * Gets the oidc user permissions from the redux store
 */
export const getUserPermissions = () => {
  return storeInstance.store ? selectUserPermissions(storeInstance.store.getState()) : [];
};

/**
 * Gets the oidc account traits from the redux store
 */
export const getAccountTraits = () => {
  return storeInstance.store ? selectOidcUserAccountTraits(storeInstance.store.getState()) : [];
};

/**
 * Gets the oidc user firm id from the redux store
 */
export const getUserFirmId = () => {
  return storeInstance.store ? selectUserFirmId(storeInstance.store.getState()) : null;
};

/**
 * Gets the oidc user id from the redux store
 */
export const getUserId = () => {
  return storeInstance.store ? selectOidcUserId(storeInstance.store.getState()) : null;
};

/**
 * Gets the oidc user given name from the redux store
 */
export const getUserGivenName = () => {
  return storeInstance.store ? selectOidcUserGivenName(storeInstance.store.getState()) : null;
};

/**
 * Gets the oidc user family name from the redux store
 */
export const getUserFamilyName = () => {
  return storeInstance.store ? selectOidcUserFamilyName(storeInstance.store.getState()) : null;
};

/**
 * Gets the oidc user information, which is safe to be used as log context
 */
export const getUserProfileLogContext = () => {
  return {
    userId: getUserId(),
    firmId: getUserFirmId(),
    accountType: getUserAccountType(),
    userPermissions: getUserPermissions(),
    accessTokenExpiration: getAccessTokenExpiration(),
    systemTime: Date.now() / 1000,
  };
};

/**
 * accessTokenExpirationRequestInterceptor - used as an axios request interceptor
 * checks if the user access token is expired and if so triggers a softlogout
 */
export const accessTokenExpirationRequestInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const tokenExpiration = getAccessTokenExpiration();

  // if the access token is expired trigger softlogout
  if (isAccessTokenExpired(tokenExpiration)) {
    loggerUtil.info('@cmg/auth: accessTokenExpirationRequestInterceptor Access Token Expired');

    storeInstance.store?.dispatch(oidcLogout());
  }

  return config; // not expired
};

export const authApiInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  accessTokenExpirationRequestInterceptor(config);

  const token = getAccessToken();

  if (token) {
    config.headers.set('Authorization', `Bearer ${token}`);
  }

  return config;
};

/**
 * Dispatches soft logout action if UNAUTHENTICATED error code is returned from GraphQL server and user token is expired.
 */
export const accessTokenExpirationGraphqlInterceptor = (errorCodes: string[]) => {
  const tokenExpiration = getAccessTokenExpiration();
  const isTokenExpired = errorCodes.some(code => code === 'UNAUTHENTICATED');

  if (isTokenExpired && isAccessTokenExpired(tokenExpiration)) {
    storeInstance.store?.dispatch(oidcLogout());
  }
};
