import React from 'react';

import { assertIsError } from '../../util/error';

export enum SavingStatusType {
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  NOT_SAVED = 'NOT_SAVED',
  ERROR = 'ERROR',
}

export interface SavingStatusContextState {
  /**
   * Current saving status for consumer(s)
   */
  savingStatus: SavingStatusType;
  /**
   * Last date the a save was finished
   */
  lastSavedAt: string | null;
  /**
   * Callback to be called when the save has started
   */
  saveStarted: () => void;
  /**
   * Callback to be called when the save has finished
   */
  saveFinished: () => void;
  /**
   * error set by consumer
   */
  error: Error | null;
  /**
   * Callback to be called on error
   */
  onError: (error) => void;
  /**
   * Callback to clear error
   */
  clearError: () => void;
  /**
   * Callback to be called when the save action happens
   */
  handleSave: <TResult>(promise: Promise<TResult>) => Promise<TResult>;
}

const initialState: SavingStatusContextState = {
  savingStatus: SavingStatusType.NOT_SAVED,
  lastSavedAt: null,
  saveStarted: () => {},
  saveFinished: () => {},
  onError: () => {},
  clearError: () => {},
  error: null,
  handleSave: async promise => {
    return await promise;
  },
};
export const SavingStatusContext = React.createContext<SavingStatusContextState>(initialState);

/**
 * Provider that keeps track of the screen's saving status. This to display information to the user about the auto save process.
 * @example
 * const FormComponent = () => {
 *    const formik = useFormik({
 *      ...formikConfig
 *    });
 *    return (
 *      <FormikProvider value={formik}>
 *        <SavingStatusProvider>
 *          ...form fields
 *        </SavingStatusProvider>
 *      </FormikProvider>
 *    )
 * };
 */
export const SavingStatusProvider: React.FC = ({ children }) => {
  const [savingStatus, setSavingStatus] = React.useState<SavingStatusType>(
    SavingStatusType.NOT_SAVED
  );
  const [lastSavedAt, setLastSavedAt] = React.useState<string | null>(null);
  const [error, setError] = React.useState<Error | null>(null);

  /**
   * Function to update saving status with wrapping provider once the auto save has started
   */
  const saveStarted = React.useCallback(() => {
    setSavingStatus(SavingStatusType.SAVING);
  }, [setSavingStatus]);

  /**
   * Function to update saving status with wrapping provider once the auto save has finished
   */
  const saveFinished = React.useCallback(() => {
    setSavingStatus(SavingStatusType.SAVED);
    setLastSavedAt(new Date().toISOString());
  }, [setSavingStatus, setLastSavedAt]);

  const handleSave = React.useCallback(
    async (promise: Promise<any>) => {
      saveStarted();
      try {
        return await promise;
      } catch (error: any) {
        assertIsError(error);
        throw error;
      } finally {
        saveFinished();
      }
    },
    [saveStarted, saveFinished]
  );

  const onError = React.useCallback(
    error => {
      if (error instanceof Error) {
        setError(error);
      } else {
        setError(new Error(error));
      }
      setSavingStatus(SavingStatusType.ERROR);
    },
    [setError]
  );

  const clearError = React.useCallback(() => {
    setError(null);
    setSavingStatus(SavingStatusType.NOT_SAVED);
  }, [setError]);

  const value = React.useMemo(
    () => ({
      savingStatus,
      lastSavedAt,
      saveStarted,
      saveFinished,
      handleSave,
      error,
      onError,
      clearError,
    }),
    [savingStatus, lastSavedAt, saveStarted, saveFinished, handleSave, error, onError, clearError]
  );

  return <SavingStatusContext.Provider value={value}>{children}</SavingStatusContext.Provider>;
};
