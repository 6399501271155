import React, { createContext } from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook, Provider } from 'react-redux';

import { getStore } from '../../../common/redux/store';
import { AuthProviderConfig } from '../../../types/api/AuthProviderConfig';

export const AuthContext: any = createContext(null);

// Export your custom hooks if you wish to use them in other files.
export const useStore = createStoreHook(AuthContext);
export const useDispatch = createDispatchHook(AuthContext);
export const useSelector = createSelectorHook(AuthContext);

/**
 * Auth Provider Props
 */
type Props = {
  /** authConfig Auth configuration object that is required for setting up the consumer's oidc auth */
  authConfig: AuthProviderConfig;
  /** children  Consumer app root content */
  children: React.ReactElement;
};

/**
 * AuthProvider Authentication provider that provides OIDC User details and Login/Logout methods to components that use the withAuth HOC connector.
 * 1. Wrap the root of the application with this Provider
 * 2. Connect your component using useAuth() hook or AuthConsumer to access Authentication details
 *
 * @param authConfig - authentication and identity authConfiguration values.
 * @param children - child components that need access to user authentication
 */
const AuthProvider: React.FC<Props> = ({ authConfig, children }) => {
  const store = getStore(authConfig);

  return (
    <Provider store={store} context={AuthContext}>
      {children}
    </Provider>
  );
};

export default AuthProvider;
