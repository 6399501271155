import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import TextInput from '../text-input/TextInput';
import { FormControlEvents } from '../types';
import {
  filterTypedValue,
  formatDate,
  getDefaultPresetOptions,
  getPresetsArray,
  getValueFromPreset,
  labelFromValue,
  parseDisplayValue,
  parseISO,
  Preset,
  PresetOptions,
  PresetTypes,
} from './DateRange.model';
import {
  SButton,
  SButtons,
  SInputWrapper,
  SRangeWrapper,
  STopGreyLine,
  StyledPopover,
} from './DateRange.styled';

export type DateRangeValue = { start?: string; end?: string; type?: string };
export interface Props
  extends FieldControlProps<DateRangeValue, DateRangeValue>,
    FormControlEvents {
  className?: string;
  id?: string;
  value: DateRangeValue;
  placeholder?: string;
  showYearDropdown?: boolean;
  onChange?: (value: DateRangeValue) => void;
  presetOptions?: PresetOptions;
  suffix?: React.ReactNode;
  testId?: string;
  monthsShown?: number;
  minDate?: Date;
  maxDate?: Date;
  selectsRange?: boolean;
  useDebounceApplyDisplayValue?: boolean;
  dateRangeLimit?: number;
}
export const DateRange: React.FC<Props> = ({
  placeholder,
  value,
  className,
  onChange,
  suffix,
  presetOptions = getDefaultPresetOptions(new Date()),
  testId,
  monthsShown = 2,
  minDate,
  maxDate,
  selectsRange = false,
  useDebounceApplyDisplayValue = true,
  dateRangeLimit,
}) => {
  /**
   * getPresetsArray function was called on every render when using standard dependency comparison used by React.
   * Using JSON.stringify to deep compare presetOptions solves the issue.
   *
   * WARNING: JSON.stringify performance is fine for small and not too deep objects only!
   */
  const presetOptionsStringified = JSON.stringify(presetOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const presets = React.useMemo(() => getPresetsArray(presetOptions), [presetOptionsStringified]);
  const [selectStart, setSelectStart] = useState(true);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [ownValue, setOwnValue] = useState(getValueFromPreset(value, presets));
  const [displayValue, setDisplayValue] = useState(
    labelFromValue(getValueFromPreset(value, presets), presets, selectsRange)
  );
  const [hasError, setHasError] = useState(false);

  const textInputRef = useRef<HTMLInputElement>(null);

  // Use a ref to access the current ownValue in an async callback.
  const ownValueRef = useRef(ownValue);
  ownValueRef.current = ownValue;

  const debouncedApplyDisplayValue = useRef(
    debounce((displayValue: string) => {
      if (value.start && value.end) {
        applyDisplayValue(displayValue);
      }
    }, 2000)
  );
  useEffect(() => {
    if (useDebounceApplyDisplayValue) {
      debouncedApplyDisplayValue.current(displayValue);
    }
  }, [displayValue, useDebounceApplyDisplayValue]);

  // external change of value will change ownValue & stringValue
  useEffect(() => {
    setOwnValue(getValueFromPreset(value, presets));
  }, [presets, value]);

  useEffect(() => {
    setHasError(false); // during value-changes keep error-off
  }, [value, ownValue, displayValue]);

  useEffect(() => {
    setDisplayValue(labelFromValue(ownValue, presets, selectsRange)); // ownValue changes => displayValue changes
  }, [ownValue, presets, selectsRange]);

  const triggerChange = (newValue: DateRangeValue, isVisible = false) => {
    onChange && onChange(newValue);
    setPopoverVisible(isVisible);
  };

  const handlePickerChange = (dateRaw: Date) => {
    if (selectsRange && Array.isArray(dateRaw)) {
      const [start, end] = dateRaw;
      setOwnValue({
        start: formatDate(start),
        end: end ? formatDate(end) : undefined,
        type: PresetTypes.CUSTOM,
      });
      triggerChange(
        {
          start: formatDate(start),
          end: end ? formatDate(end) : undefined,
          type: PresetTypes.CUSTOM,
        },
        end ? false : true
      );
    } else {
      const date = formatDate(dateRaw);
      const endBeforeStart = !selectStart && ownValue && ownValue.start && ownValue.start > date;
      if (selectStart || endBeforeStart) {
        setOwnValue({ start: date, end: undefined, type: PresetTypes.CUSTOM });
      } else if (ownValue) {
        triggerChange({ start: ownValue.start, end: date, type: PresetTypes.CUSTOM });
      }
      !endBeforeStart && setSelectStart(!selectStart);
    }
  };

  const handleButtonChange = ({ value }: Preset) => {
    if (value.type === PresetTypes.CUSTOM) {
      setOwnValue({ ...ownValue, type: value.type });
      triggerChange({ ...ownValue, type: value.type });
    } else {
      setOwnValue(value);
      triggerChange(value);
    }
    setSelectStart(true);
  };

  const applyDisplayValue = displayValue => {
    const parsed = parseDisplayValue(displayValue, dateRangeLimit);

    if (parsed !== false) {
      if (
        ownValueRef.current &&
        (parsed.start !== ownValueRef.current.start || parsed.end !== ownValueRef.current.end)
      ) {
        if (selectsRange) {
          setOwnValue({ ...parsed, type: PresetTypes.CUSTOM });
        }
        triggerChange({ ...parsed, type: PresetTypes.CUSTOM });
      }
      textInputRef.current && textInputRef.current.blur();
      return;
    }
    // between selecting start & end in picker it's ok, otherwise error
    selectStart && setHasError(true);
  };

  const handleKeyDown = e => {
    // on ENTER -> check if value is valid & trigger blur (applyDisplayValue) or show error
    if (e.keyCode === 13) {
      if (parseDisplayValue(displayValue) === false) {
        setHasError(true);
        return;
      }
      textInputRef.current && textInputRef.current.blur();
    }
  };

  const handleTyping = (typedValue: string | null) => {
    if (selectsRange) {
      setDisplayValue(typedValue!);
      return;
    }
    const filteredValue = filterTypedValue(typedValue, presets);
    filteredValue !== false && setDisplayValue(filteredValue);
  };

  return (
    <React.Fragment>
      <StyledPopover
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={newVisible => {
          setPopoverVisible(newVisible);
        }}
        placement="bottomLeft"
        hideArrow
        content={
          <SRangeWrapper>
            {presets.length > 0 && (
              <SButtons>
                {presets.map(preset => (
                  <SButton
                    key={preset.value.type}
                    onClick={() => handleButtonChange(preset)}
                    active={ownValue && preset.value.type === ownValue.type}
                  >
                    {preset.label}
                  </SButton>
                ))}
              </SButtons>
            )}
            <ReactDatePicker
              inline
              allowSameDay
              scrollableYearDropdown
              startDate={parseISO(ownValue && ownValue.start)}
              endDate={parseISO(ownValue && ownValue.end)}
              className={cn('datepicker-input', className)}
              selected={ownValue && parseISO(selectStart ? ownValue.end : ownValue.start)}
              placeholderText={placeholder || 'MM/DD/YYYY'}
              selectsEnd={!selectStart}
              onChange={handlePickerChange}
              monthsShown={monthsShown}
              minDate={minDate ?? null} // https://github.com/Hacker0x01/react-datepicker/issues/2565
              maxDate={maxDate ?? null}
              selectsRange={selectsRange}
            />
            <STopGreyLine
              style={{
                width: textInputRef.current ? textInputRef.current.offsetWidth - 2 : 0,
              }}
            />
          </SRangeWrapper>
        }
      >
        <SInputWrapper visiblePopup={popoverVisible}>
          <TextInput
            placeholder={placeholder}
            className="date-range-input"
            hasError={hasError}
            value={displayValue}
            onChange={handleTyping}
            onBlur={() => applyDisplayValue(displayValue)}
            onKeyDown={handleKeyDown}
            ref={textInputRef}
            suffix={suffix}
            testId={testId}
          />
        </SInputWrapper>
      </StyledPopover>
    </React.Fragment>
  );
};

export const DateRangeField = createFormikField<
  DateRangeValue,
  DateRangeValue,
  HTMLInputElement,
  Props
>(DateRange);
