import React from 'react';
import styled from 'styled-components/macro';

import { ServiceErrorFragment, ServiceErrorPartsFragment } from '../../../api/types/ServiceError';

export const SList = styled.ul<{ singleError?: boolean }>`
  margin: 0;
  padding: 0;
  ${({ singleError = false }) => singleError && 'list-style-type: none;'}
`;

export const SListItem = styled.li`
  margin-left: 10px;
`;
export type ServiceErrorsProps = { error: ServiceErrorFragment | ServiceErrorPartsFragment };
/**
 * Takes our standard representation of a service error and prints out the individual
 * messages in a list.
 */
const ServiceErrors: React.FC<ServiceErrorsProps> = ({ error }) => {
  if (error.details && error.details.length) {
    return (
      <SList singleError={error.details.length === 1}>
        {error.details.map(detailItem => (
          <SListItem key={(detailItem.message || '') + (detailItem.target || '')}>
            {detailItem.message}
          </SListItem>
        ))}
      </SList>
    );
  }

  return (
    <SList singleError={true}>
      <SListItem>{error.message}</SListItem>
    </SList>
  );
};

export default ServiceErrors;
