import memoize from 'memoize-one';
import React from 'react';
import { MultiValue } from 'react-select';
import ReactSelect, { CreatableProps } from 'react-select/creatable';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import {
  getReactSelectProps,
  getSelectedOptions,
  PublicMultiSelectProps,
  PublicProps,
  SyncSelectProps,
} from './BaseSelect.model';
import { SSelectWrapper } from './BaseSelect.styles';
import { Option } from './types';

export type Props<TOptionValue> = FieldControlProps<TOptionValue[] | null, TOptionValue[]> &
  PublicProps<TOptionValue, true> &
  SyncSelectProps<TOptionValue> &
  CreatableProps<TOptionValue, true, any> &
  PublicMultiSelectProps;

export default class CreatableSelect<TOptionValue = string> extends React.Component<
  Props<TOptionValue>
> {
  getSelectedOptions = memoize(getSelectedOptions<TOptionValue>);

  handleOnChange = (values: MultiValue<Option<TOptionValue>>) => {
    const { onChange } = this.props;

    onChange && onChange(values ? values.map(v => v.value) : []);
  };

  render() {
    const { className, options, testId, value } = this.props;

    const selectProps = getReactSelectProps({
      ...this.props,
      isClearable: false,
    });

    return (
      <SSelectWrapper className={className} data-test-id={testId}>
        <ReactSelect
          {...selectProps}
          isMulti
          value={this.getSelectedOptions(options, value)}
          onChange={this.handleOnChange}
          components={{
            ...selectProps.components,
          }}
        />
      </SSelectWrapper>
    );
  }
}

export const CreatableSelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(
  CreatableSelect
);
