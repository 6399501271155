import { Resource } from './resources';

export const entitlementsByEntity = {
  [Resource.InstitutionalIndication]: {
    READ: 'READ:INSTITUTIONAL_INDICATION',
    UPDATE: 'UPDATE:INSTITUTIONAL_INDICATION',
    FULL: 'FULL:INSTITUTIONAL_INDICATION',
  },
  [Resource.DeliveryInstructions]: {
    READ: 'READ:DELIVERY_INSTRUCTIONS',
    UPDATE: 'UPDATE:DELIVERY_INSTRUCTIONS',
    FULL: 'FULL:DELIVERY_INSTRUCTIONS',
  },
};
