import { datalabApi } from '@cmg/api';
import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { theme, useDebouncedCalendarRefreshEvent } from '@cmg/common';
import memoize from 'memoize-one';
import React from 'react';
import { bindActionCreators } from 'redux';

import VirtualizedTableWidget from '../../../obsolete/components/ui/table/VirtualizedTableWidget';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import CalendarFilter from '../components/CalendarFilter';
import {
  DownloadPayload,
  fetchDatalabOptionsActions,
  fetchOfferingsActions,
  setFollowOfferingRequest,
} from '../ducks';
import { applyFilters, FilterValues, getFilterConfig } from '../model/calendar-filters';
import { CalendarTabType } from '../model/calendar-tabs';
import {
  Column,
  customSectorColumn,
  getColumnsConfig,
  sectorColumn,
} from '../model/offeringListColumns';

export const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchCalendarOfferings: fetchOfferingsActions.request,
      fetchDatalabOptions: fetchDatalabOptionsActions.request,
      onToggleFollowOffering: setFollowOfferingRequest,
    },
    dispatch
  ),
});

type Props = {
  filters: FilterValues;
  offerings: datalabApi.CalendarOffering[];
  tabs: CalendarTabType[];
  onExport: (props: DownloadPayload) => void;
  onTabChange: any;
} & ReturnType<typeof mapDispatchToProps>;

type State = {
  visibleColumns: string[];
};

export class BaseDatalabCalendar extends React.Component<Props, State> {
  calendarCategory: CalendarCategory = CalendarCategory.LIVE;
  defaultGroupBy: string = '';
  defaultGroupByOrderType: string = '';
  defaultOrderBy: string = '';
  defaultOrderByType: string = '';
  columnsConfig: any[] = [];
  staticColumns: Column[] = [];

  handleRefreshCalendar = () => {
    this.props.actions.fetchCalendarOfferings({
      category: this.calendarCategory,
    });
  };

  componentDidMount() {
    const { actions } = this.props;

    actions.fetchCalendarOfferings({
      category: this.calendarCategory,
    });
    actions.fetchDatalabOptions();
  }

  applyFilters = memoize((offerings: datalabApi.CalendarOffering[], filters: FilterValues) =>
    applyFilters(offerings, getFilterConfig(filters))
  );

  handleOnToggleFollowOffering = (offering: datalabApi.CalendarOffering) => {
    this.props.actions.onToggleFollowOffering({
      offeringId: offering.id,
      isFollowing: !offering.userIsFollowing,
      category: this.calendarCategory,
    });
  };

  handleToggleViewableFields = visibleColumns => {
    this.setState({ visibleColumns: visibleColumns });
  };

  renderFilters = () => {
    return <CalendarFilter category={this.calendarCategory} />;
  };

  render() {
    const { filters, offerings, tabs, onExport, onTabChange } = this.props;
    const activeTab = tabs.find(tab => tab.value === this.calendarCategory);
    const metadata = {
      handleFollowClick: this.handleOnToggleFollowOffering,
    };
    const filteredRows = this.applyFilters(offerings, filters);

    const columnsConfig = getColumnsConfig(this.columnsConfig, this.calendarCategory, filters);
    const canReadCustomSectors = checkPermissions(getUserPermissions(), [
      permissionsByEntity.CustomSectors.READ,
    ]);
    let isSectorVisible = true;
    if (canReadCustomSectors && filters.useCustomSectors) {
      isSectorVisible = false;
    }

    return (
      <React.Fragment>
        <CalendarRefresher onRefresh={this.handleRefreshCalendar} />
        <VirtualizedTableWidget
          className="widget-offering-list"
          screen="calendar"
          activeTab={activeTab}
          tabs={tabs}
          columnsConfig={columnsConfig.filter(c => {
            if (c === sectorColumn) {
              return isSectorVisible;
            }
            if (c === customSectorColumn) {
              return !isSectorVisible;
            }
            return true;
          })}
          staticColumns={this.staticColumns.map(({ field }) => field)}
          rows={filteredRows}
          groupBy={this.defaultGroupBy}
          groupByOrderType={this.defaultGroupByOrderType}
          orderBy={this.defaultOrderBy}
          orderByType={this.defaultOrderByType}
          metaData={metadata}
          fillViewport={true}
          renderFilters={this.renderFilters}
          visibleColumns={this.state?.visibleColumns}
          handleToggleViewableFields={this.handleToggleViewableFields}
          downloadExport={onExport}
          onChangeTab={onTabChange}
          tableWrapperStyles={{ padding: '0px 20px 0px 10px', fontSize: theme.text.size.xs }}
        />
      </React.Fragment>
    );
  }
}

// Separated from <BaseDatalabCalendar /> since we want to use a hook.
// Exported for testing purposes.
export const CalendarRefresher: React.FC<{ onRefresh: () => void }> = ({ onRefresh }) => {
  useDebouncedCalendarRefreshEvent({ callback: onRefresh });

  return null;
};

export default BaseDatalabCalendar;
