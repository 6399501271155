import React from 'react';
import styled, { css } from 'styled-components/macro';

import { darkLogo } from '../../../assets';
import logo from '../../../assets/logo.png';

export type Props = {
  hideBackground?: boolean;
};

const SWrapper = styled.div`
  display: inline-block;
`;

const SLogo = styled.img<{ hideBackground: boolean }>`
  width: 100%;
  height: 100%;
  ${props =>
    props.hideBackground &&
    css`
      width: 194px;
      height: 64px;
    `}
`;

const CMGLogo: React.FC<Props> = ({ hideBackground }) => (
  <SWrapper>
    {hideBackground ? (
      <SLogo hideBackground src={darkLogo} alt="CMG Dark Logo" />
    ) : (
      <SLogo hideBackground src={logo} alt="CMG Logo" />
    )}
  </SWrapper>
);

export default CMGLogo;
