import { getAuthorityUrl } from '../../auth/oidcFactory';
import { OIDCUserType } from '../../types/domain/oidc-user/oidc-user';

const USER_REQUESTED_PATH_KEY = 'cmg_user_requested_path';

// Location that oidc-client stores the user info. We can directly access via session storage.
export const getUserSessionStorageKey = (oidcAuthority, clientId) => {
  /**
   * wrapping the oidcAuthority with a URL ensures that the url will
   * contain '/' at the end which is how the key is stored
   * in sessionStorage by the oidc-client
   * */
  const normalizedOidcAuthorityUrl = new URL(oidcAuthority);

  return `oidc.user:${normalizedOidcAuthorityUrl.href}:${clientId}`;
};

/**
 * Abstraction module around browser storage (i.e. Local Storage, Session Storage)
 */
const browserStorageManager = {
  // This item is set by the oidc client, so we only can read it.
  getOidcUser: (oidcAuthorityBaseUrl, clientId): OIDCUserType | null => {
    // takes base url for example http://id.local.oncmg.io and concatenates account subdomain
    const oidcAuthorityUrl = getAuthorityUrl(oidcAuthorityBaseUrl);
    // retrieve session storage key
    const stringifiedOidcUser = sessionStorage.getItem(
      getUserSessionStorageKey(oidcAuthorityUrl, clientId)
    );

    return stringifiedOidcUser ? JSON.parse(stringifiedOidcUser) : null;
  },

  // only used for oidc
  getUserRequestedPath: () => sessionStorage.getItem(USER_REQUESTED_PATH_KEY),
  saveUserRequestedPath: path => sessionStorage.setItem(USER_REQUESTED_PATH_KEY, path),
  removeUserRequestedPath: () => sessionStorage.removeItem(USER_REQUESTED_PATH_KEY),
};

export default browserStorageManager;
