import commonTheme from '../../../styles/theme';
import { StyledConfig } from './types';

type WrapperProps = {
  hasError?: boolean;
};

function getBorderColor(hasError?: boolean, isFocused?: boolean, menuIsOpen?: boolean) {
  if (hasError) {
    return commonTheme.form.control.errorColor;
  }
  return isFocused || menuIsOpen
    ? commonTheme.interactive.primary
    : commonTheme.form.control.borderColor;
}

const controlStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig = {}) =>
  (originalStyles, controlProps: { isFocused?: boolean; menuIsOpen?: boolean } = {}) => {
    const { hasError } = wrapperProps;
    const { isFocused, menuIsOpen } = controlProps;

    const newStyles = {
      ...originalStyles,
      minHeight: commonTheme.form.control.height,
      boxShadow: 'none',
      borderColor: getBorderColor(hasError, isFocused, menuIsOpen),
      borderBottomColor: menuIsOpen
        ? commonTheme.form.control.borderColor
        : getBorderColor(hasError, isFocused, menuIsOpen),
      borderBottomLeftRadius: menuIsOpen ? 0 : commonTheme.form.control.borderRadius,
      borderBottomRightRadius: menuIsOpen ? 0 : commonTheme.form.control.borderRadius,
      borderTopLeftRadius: commonTheme.form.control.borderRadius,
      borderTopRightRadius: commonTheme.form.control.borderRadius,
      paddingTop: 2,
      ':hover': {
        ...originalStyles[':hover'],
        borderColor: getBorderColor(hasError, isFocused, menuIsOpen),
        borderBottomColor: menuIsOpen
          ? commonTheme.form.control.borderColor
          : getBorderColor(hasError, isFocused, menuIsOpen),
      },
    };

    return {
      ...newStyles,
      ...(outSideStyles.control
        ? outSideStyles.control({ hasError, isFocused, menuIsOpen }, newStyles)
        : {}),
    };
  };

const clearIndicatorStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => originalStyles => ({
    ...originalStyles,
    padding: '4px 6px',
  });

const dropdownIndicatorStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => originalStyles => ({
    ...originalStyles,
    padding: '4px 6px',
  });

const indicatorSeparatorStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => originalStyles => ({
    ...originalStyles,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: commonTheme.form.control.borderColor,
  });

const inputStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => originalStyles => ({
    ...originalStyles,
    height: 18,
    lineHeight: '14px',
    paddingTop: 1,
    paddingBottom: 0,
    minWidth: 25,
    width: 5,
  });

const menuStyles = (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => originalStyles => ({
  ...originalStyles,
  boxShadow: 'none',
  borderColor: commonTheme.interactive.primary,
  borderStyle: 'solid',
  borderWidth: 1,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderTopWidth: 0,
  marginTop: 0,
  'z-index': commonTheme.zIndex.selectMenu,
});

const menuListStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => originalStyles => ({
    ...originalStyles,
    paddingTop: 0,
    paddingBottom: 0,
  });

const multiValueStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => (originalStyles, props) => ({
    ...originalStyles,
    backgroundColor: props.isDisabled
      ? commonTheme.interactive.disabled
      : commonTheme.interactive.primary,
    color: props.isDisabled
      ? commonTheme.interactive.disabledContrast
      : commonTheme.interactive.primaryContrast,
    justifyContent: 'space-between',
  });

const multiValueLabelStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => (originalStyles, props) => ({
    ...originalStyles,
    color: props.isDisabled
      ? commonTheme.interactive.disabledContrast
      : commonTheme.interactive.primaryContrast,
    paddingRight: props && props.data && props.data.clearableValue === false ? '6px' : 'inherit',
  });

/*
 * react-select v2 never ported option.clearableValue from v1
 * https://github.com/JedWatson/react-select/blob/2654ce0505d9e3820e169109cc413778fc20f843/.TODO.md#maybe
 * Below hides the “X” on a selected option with clearableValue = false
 */
const multiValueRemoveStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) =>
  (originalStyles, { data }) => {
    return {
      ...originalStyles,
      display: data && data.clearableValue === false ? 'none' : 'inherit',
    };
  };

const optionStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig = {}) =>
  (originalStyles, controlProps: { isFocused?: boolean } = {}) => ({
    ...originalStyles,
    ...(outSideStyles.option
      ? outSideStyles.option(wrapperProps, controlProps, originalStyles)
      : {}),
    ':last-of-type': {
      ...originalStyles[':last-of-type'],
      borderBottomLeftRadius: commonTheme.form.control.borderRadius,
      borderBottomRightRadius: commonTheme.form.control.borderRadius,
    },
  });

const placeholderStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig) => originalStyles => ({
    ...originalStyles,
    color: commonTheme.form.control.placeholderColor,
  });

const valueContainerStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig = {}) =>
  (originalStyles, { hasValue, isMulti }: { hasValue?: boolean; isMulti?: boolean } = {}) => ({
    ...originalStyles,
    paddingTop: 2,
    paddingRight: 4,
    paddingBottom: 2,
    paddingLeft: hasValue && isMulti ? 4 : 10,
  });

const groupStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig = {}) =>
  originalStyles => ({
    ...originalStyles,
    padding: 0,
  });

const groupHeadingStyles =
  (wrapperProps: WrapperProps, outSideStyles: StyledConfig = {}) =>
  originalStyles => ({
    ...originalStyles,
    padding: 0,
    margin: 0,
  });

export const styles = (wrapperProps: WrapperProps, outSideStyles: StyledConfig = {}) => ({
  clearIndicator: clearIndicatorStyles(wrapperProps, outSideStyles),
  control: controlStyles(wrapperProps, outSideStyles),
  dropdownIndicator: dropdownIndicatorStyles(wrapperProps, outSideStyles),
  indicatorSeparator: indicatorSeparatorStyles(wrapperProps, outSideStyles),
  input: inputStyles(wrapperProps, outSideStyles),
  menu: menuStyles(wrapperProps, outSideStyles),
  menuList: menuListStyles(wrapperProps, outSideStyles),
  multiValue: multiValueStyles(wrapperProps, outSideStyles),
  multiValueLabel: multiValueLabelStyles(wrapperProps, outSideStyles),
  multiValueRemove: multiValueRemoveStyles(wrapperProps, outSideStyles),
  option: optionStyles(wrapperProps, outSideStyles),
  placeholder: placeholderStyles(wrapperProps, outSideStyles),
  valueContainer: valueContainerStyles(wrapperProps, outSideStyles),
  group: groupStyles(wrapperProps, outSideStyles),
  groupHeading: groupHeadingStyles(wrapperProps, outSideStyles),
});
