import 'antd/lib/grid/style/index.css';

import { Row as AntRow } from 'antd';
import React from 'react';

export type Props = {
  className?: string;
  testId?: string;
};

const Row: React.FC<Props> = ({ testId, ...restProps }) => (
  <AntRow {...restProps} data-test-id={testId} gutter={16} />
);

export default Row;
