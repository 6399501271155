import { makeEntity } from '../../../utils/mocks';
import { CalendarShareholder } from '../types/CalendarShareholder';

export const makeCalendarShareholder = makeEntity<CalendarShareholder>({
  firmId: 'mock-firmId',
  firmName: 'mock-firmName',
  preOfferingOwnership: 123,
  pctPreOfferingOwnership: 32,
  postOfferingOwnership: 41,
  pctPostOfferingOwnership: 134,
});
