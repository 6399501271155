import { GenericResponseDataFailure } from '../types/ApiClientResponses';

export const defaultDataFailure: GenericResponseDataFailure = {
  error: {
    code: 'UNKNOWN_ERROR',
    message: 'Unknown Error',
    target: 'unknown',
    details: [],
  },
};
