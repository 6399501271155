import { GroupBase, StylesConfig } from 'react-select';

export const expandableSelectStyles = <
  TOptionValue,
  IsMulti extends boolean,
  Group extends GroupBase<TOptionValue>
>(
  baseStyles: StylesConfig<TOptionValue, IsMulti, Group> = {}
): StylesConfig<TOptionValue, IsMulti, Group> => ({
  ...baseStyles,
  option: (base, props) => ({
    ...(baseStyles.option ? baseStyles.option(base, props) : {}),
    paddingLeft: 30,
  }),
});
