import React from 'react';

import { Group, Option } from '../../form/select/types';
import CheckboxTree from '../../lists/checkbox-tree/CheckboxTree';
import { StyledFilterInput, StyledInputIcon } from './DropdownSelect.styles';

// Exported for testing only
export type Props<TValue> = {
  categorizedItems: (Group<TValue> | Option<TValue>)[];
  onChange: (values: TValue[]) => void;
  values?: TValue[];
  searchable?: boolean;
};

const DropdownSelectCategorizedContent: <TValue = string>(
  p: Props<TValue>
) => React.ReactElement<Props<TValue>> = ({ categorizedItems, onChange, values, searchable }) => {
  return (
    <CheckboxTree
      maxHeight={searchable ? 342 : 380}
      searchable={searchable}
      onChange={onChange}
      renderSearchInput={({ onChange: onSearch, value }) => (
        <StyledFilterInput
          value={value || ''}
          onChange={onSearch}
          placeholder="Filter"
          prefix={<StyledInputIcon name="filter" />}
          suffix={
            <span onClick={() => onSearch('')}>
              <StyledInputIcon name="times-circle" />
            </span>
          }
        />
      )}
      options={categorizedItems}
      values={values}
    />
  );
};

export default DropdownSelectCategorizedContent;
