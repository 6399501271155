import { makeEntity } from '../utils/mocks';
import { Pagination } from './Pagination';

export const makePagination = makeEntity<Pagination>({
  activePage: 3,
  perPage: 10,
  totalPages: 30,
  totalCount: 297,
  hasPrevious: true,
  hasNext: true,
});
