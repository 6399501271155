import React from 'react';
import { ThemeConsumer } from 'styled-components/macro';

import Tag from '../tag/Tag';

export type Props = {
  readonly activeLabel?: string;
  readonly inactiveLabel?: string;
  readonly isActive: boolean;
  readonly testId?: string;
};

const Status: React.FC<Props> = ({
  activeLabel = 'Active',
  inactiveLabel = 'Inactive',
  isActive,
  testId,
}) => (
  <ThemeConsumer>
    {theme => (
      <Tag color={isActive ? theme.brand.color.success : theme.brand.color.danger} testId={testId}>
        {isActive ? activeLabel : inactiveLabel}
      </Tag>
    )}
  </ThemeConsumer>
);

export default Status;
