import { InterstitialPage } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { oidcLoginCallback } from '../../ducks';
import { AuthContext } from '../auth-provider/AuthProvider';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      oidcLoginCallback,
    },
    dispatch
  ),
});

type OwnProps = {
  onSuccess: (returnUrl: string) => void;
};

type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

class OIDCLoginCallback extends React.Component<Props> {
  componentDidMount() {
    const { actions, onSuccess } = this.props;

    actions.oidcLoginCallback({ onSuccess });
  }

  render() {
    return <InterstitialPage testId="auth/oidc-login-callback" />;
  }
}

export default connect(null, mapDispatchToProps, undefined, { context: AuthContext })(
  OIDCLoginCallback
);
