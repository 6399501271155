import React, { ReactNode } from 'react';
import { CSSProp } from 'styled-components/macro';

import Icon, { Props as IconProps } from '../../graphics/icon/Icon';
import { SButton, SButtonContent, SContent, withSButtonPropsStopper } from './Button.styles';

const ButtonContent: React.FC<{
  iconLeft?: IconProps;
  iconRight?: IconProps;
}> = ({ iconRight, iconLeft, children }) => (
  <SButtonContent>
    {iconLeft && <Icon size="1x" {...iconLeft} fixedWidth />}
    <SContent withRightMargin={!!iconRight} withLeftMargin={!!iconLeft}>
      {children}
    </SContent>
    {iconRight && <Icon size="1x" {...iconRight} fixedWidth />}
  </SButtonContent>
);

type Size = 'small' | 'medium' | 'large';

type ElementTypeProps =
  | {
      // render as a <button type="submit">
      type?: 'submit';
      onClick?: never;
      loading?: boolean;
      loadingLabel?: ReactNode;
      href?: never;
      asComponent?: never;
      to?: never;
      target?: never;
      download?: never;
    }
  | {
      // render as a <button type="button">
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
      type?: 'button';
      loading?: boolean;
      loadingLabel?: ReactNode;
      href?: never;
      asComponent?: never;
      to?: never;
      target?: never;
      download?: never;
    }
  | {
      // render as an <a>
      href?: HTMLAnchorElement['href'];
      download?: string;
      target?: string;
      loading?: never;
      loadingLabel?: never;
      onClick?: never;
      type?: never;
      asComponent?: never;
      to?: never;
    }
  | {
      // render as a custom component (typically <Link> from react-router)
      asComponent: React.ComponentType<any>; // TODO when we switch to RR v4 in ECM, change this to only accept `Link`
      to: any; // TODO when we switch to RR v4 in ECM, change this to be History.LocationDescriptor
      loading?: never;
      loadingLabel?: never;
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
      type?: never;
      href?: never;
      target?: never;
      download?: never;
    };

export type ButtonProps = {
  className?: string;
  // Takes up the width of it's parent when set
  fullWidth?: boolean;
  size?: Size;
  disabled?: boolean;
  testId?: string;
  iconLeft?: IconProps;
  iconRight?: IconProps;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  withBorder?: boolean;
  title?: string;
} & ElementTypeProps;

export type StyledConfig = {
  color?: CSSProp;
  backgroundColor?: CSSProp;
  hoverBackgroundColor?: CSSProp;
  hoverColor?: CSSProp;
  borderColor?: CSSProp;
  hoverBorderColor?: CSSProp;
  disabledColor?: CSSProp;
  disabledBackgroundColor?: CSSProp;
  disabledBorderColor?: CSSProp;
};

type BaseButtonProps = {
  styledConfig: StyledConfig;
} & ButtonProps;

const BaseButton: React.FC<BaseButtonProps> = ({
  className,
  children,
  download,
  fullWidth = false,
  size = 'medium',
  disabled = false,
  iconLeft,
  iconRight,
  testId,
  styledConfig,
  onClick,
  type = 'button',
  loading = false,
  loadingLabel = 'Loading...',
  href,
  target,
  asComponent,
  to,
  onMouseEnter,
  onMouseLeave,
  withBorder = true,
  title,
}) => {
  const notActionable = disabled || loading;
  const hrefProp = href ? { href } : {};
  const downloadProp = download ? { download } : {};
  const targetProp = href && target ? { target } : {};
  const typeProp = type && !href && !asComponent ? { type } : {};
  const disabledProps = notActionable
    ? {
        'aria-disabled': true,
        tabIndex: -1,
      }
    : {};
  const handleClick = e => {
    if (loading) {
      e.preventDefault();
    }
    if (notActionable) {
      e.stopPropagation();
      return;
    }

    onClick && onClick(e);
  };

  let asElement: React.ComponentType<any> | 'a' | 'button' = href ? 'a' : 'button';
  if (asComponent) {
    asElement = withSButtonPropsStopper(asComponent);
  }

  return (
    <SButton
      {...hrefProp}
      {...downloadProp}
      {...targetProp}
      {...disabledProps}
      {...typeProp}
      className={className}
      disabled={notActionable}
      styledConfig={styledConfig}
      onClick={handleClick}
      data-test-id={testId}
      fullWidth={fullWidth}
      size={size}
      as={asElement} // uses https://www.styled-components.com/docs/api#as-polymorphic-prop
      to={to}
      role="button"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      withBorder={withBorder}
      title={title}
      aria-label={title}
    >
      {loading ? (
        <ButtonContent iconLeft={{ name: 'spinner-third', spin: true, size: 'sm' }}>
          {loadingLabel}
        </ButtonContent>
      ) : (
        <ButtonContent iconRight={iconRight} iconLeft={iconLeft}>
          {children}
        </ButtonContent>
      )}
    </SButton>
  );
};

export default BaseButton;
