import React from 'react';

import { SPercentCompleteWrapper, SPercentIncompleteWrapper, SWrapper } from './Progressbar.styles';

export type Props = {
  total: number;
  progress: number;
  height?: number;
};

/**
 * Renders a progressbar.
 * The numeric values provided can be anything - the relative
 * progress will be calculated (e.g. a total value of 200 and a progress
 * value of 50 will render a progressbar that's 25% filled).
 *
 * @total An integer representing the total/complete value for the
 *  entity whose progress is being displayed.
 * @progress An integer representing the progress value.
 * @height The height of the progress bar (optional).
 */
const Progressbar: React.FC<Props> = ({ total = 0, progress = 0, height = 6 }) => {
  const absProgress = progress > 0 ? progress : 0;
  const percentComplete = total > 0 ? (absProgress / total) * 100 : 0;
  const percentIncomplete = 100 - percentComplete;

  return (
    <SWrapper barHeight={height}>
      <SPercentCompleteWrapper
        aria-label="progress bar percent complete"
        data-testid="progress-bar-percent-complete"
        percentComplete={percentComplete}
      />
      <SPercentIncompleteWrapper
        aria-label="progress bar percent incomplete"
        data-testid="progress-bar-percent-incomplete"
        percentIncomplete={percentIncomplete}
      />
    </SWrapper>
  );
};

export default Progressbar;
