import { InterstitialPage } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { oidcLogoutCallback } from '../../ducks';
import { AuthContext } from '../auth-provider/AuthProvider';

type DispatchProps = {
  actions: {
    oidcLogoutCallback: Function;
  };
};
type OwnProps = {
  /** onSuccess success callback that is invoked upon successful logout */
  onSuccess: () => void;
};
type Props = OwnProps & DispatchProps;

/**
 * OIDCLogoutCallback is the oidc logout callback component that should be used
 * when a consumer application routes into an oidc logout callback url
 */
class OIDCLogoutCallback extends React.Component<Props> {
  componentDidMount() {
    const { actions, onSuccess } = this.props;

    actions.oidcLogoutCallback({ onSuccess });
  }

  render() {
    return <InterstitialPage />;
  }
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  actions: bindActionCreators(
    {
      oidcLogoutCallback,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps, undefined, { context: AuthContext })(
  OIDCLogoutCallback
);
