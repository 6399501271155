import React from 'react';
import { components, ValueContainerProps } from 'react-select';
import styled from 'styled-components/macro';

import Popover, { PopoverVariant } from '../../../overlays/popover/Popover';

/** style the value the same as a multi select value item */
const STruncatedValues = styled.div`
  background-color: ${({ theme }) => theme.interactive.primary};
  color: ${({ theme }) => theme.interactive.primaryContrast};
  font-size: 11px;
  padding: 4px;
  border-radius: 2px;
`;

type Props<TOptionValue> = {
  maxDisplayedValues: number;
} & ValueContainerProps<TOptionValue>;

const { ValueContainer } = components;

/**
 * TruncatedValueContainer is a custom override to the ValueContainer component
 * it takes maxDisplayedValues as a prop and checks if the selected item count has reached the maxDisplayedValues
 * if so the results will be truncated into the initial selected item and a + <additional count>...
 */
const TruncatedValueContainer = <TOptionValue extends unknown = string>({
  children,
  maxDisplayedValues,
  ...rest
}: Props<TOptionValue>) => {
  const value = rest.getValue();
  const selectedCount = value.length;
  const shouldShowSelected = selectedCount <= maxDisplayedValues;
  const [selectedValues, selectInputElement] = children as [React.ReactNode[], React.ReactNode];
  const truncatedValues = Array.isArray(selectedValues)
    ? selectedValues.slice(maxDisplayedValues)
    : [];

  return (
    <ValueContainer {...rest}>
      {shouldShowSelected && children}
      {!shouldShowSelected && (
        <React.Fragment>
          {selectedValues.splice(0, maxDisplayedValues)}
          <Popover variant={PopoverVariant.LIGHT} content={truncatedValues} trigger="click">
            <STruncatedValues>+{selectedCount - maxDisplayedValues}...</STruncatedValues>
          </Popover>
          {selectInputElement}
        </React.Fragment>
      )}
    </ValueContainer>
  );
};

export default TruncatedValueContainer;
