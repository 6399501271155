import React from 'react';

import { useSavingStatusContext } from '../hooks/useSavingStatus';
import { EnableAutoSave } from './EnableAutoSave';

export const EnableAutoSavingStatus: React.FC = ({ children }) => {
  const { saveStarted, saveFinished, onError } = useSavingStatusContext();
  return (
    <React.Fragment>
      <EnableAutoSave saveStarted={saveStarted} saveFinished={saveFinished} onError={onError} />
      {children}
    </React.Fragment>
  );
};
