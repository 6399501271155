import { Popover, Tag } from '@cmg/common';
import { useTheme } from '@cmg/design-system';
import isNil from 'lodash/isNil';
import React from 'react';
import styled from 'styled-components/macro';

import { getFeatureToggles } from '../../../config/appSettings';
import { getFormattedCurrency } from '../../shared/model/utils';
import { type Calendar_OfferingFieldsFragment, Country, OfferingStatus } from '../graphql';

const SPriceRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const STagWrapper = styled.div`
  margin-top: -3px;
  margin-right: 5px;
`;

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const PriceRangeRenderer: React.FC<Props> = ({ offering }) => {
  const {
    status,
    exchangeCountry,
    pricingCurrency,
    revisedIpoRangeLowUsd,
    revisedIpoRangeHighUsd,
    initialIpoRangeLowUsd,
    initialIpoRangeHighUsd,
    priceRangeLow,
    priceRangeHigh,
  } = offering.attributes || {};

  const isIpoPriceRangeRevised = !isNil(revisedIpoRangeLowUsd) && !isNil(revisedIpoRangeHighUsd);

  const themes = useTheme();

  const hideRevisedTag =
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
    exchangeCountry === Country.Us &&
    status === OfferingStatus.Filed;

  return (
    <SPriceRangeWrapper>
      {isIpoPriceRangeRevised &&
        initialIpoRangeLowUsd &&
        initialIpoRangeHighUsd &&
        !hideRevisedTag && (
          <Popover
            placement="top"
            variant="TOOLTIP"
            content={
              <span>
                Initial Range: {getFormattedCurrency(pricingCurrency, initialIpoRangeLowUsd)}
                {' \u2013 '}
                {getFormattedCurrency(pricingCurrency, initialIpoRangeHighUsd)}
              </span>
            }
          >
            <STagWrapper>
              <Tag color={themes.palette.info.main}>R</Tag>
            </STagWrapper>
          </Popover>
        )}
      <span>
        {priceRangeLow && priceRangeHigh
          ? `${getFormattedCurrency(pricingCurrency, priceRangeLow)} \u2013
         ${getFormattedCurrency(pricingCurrency, priceRangeHigh)}`
          : '-'}
      </span>
    </SPriceRangeWrapper>
  );
};

export default PriceRangeRenderer;
