import React from 'react';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import { FormControlEvents } from '../types';
import { SInputWrapper, StyledMeridiemPeriodSelect, StyledTimePicker } from './TimePicker.styles';

type OwnProps = {
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
};

export type Meridiem = 'AM' | 'PM';

export type MeridiemTimeTuple = [string | null, Meridiem];

export type Props = FieldControlProps<MeridiemTimeTuple, MeridiemTimeTuple> &
  FormControlEvents &
  OwnProps;

/**
 * Form time input allows user to select time in 12h format and AM/PM period.
 * Value is represented by the tuple in format ['12:00', 'PM'].
 */
export const TimePickerComponent: React.FC<Props> = ({
  onFocus,
  onBlur,
  onChange,
  value = ['12:00', 'AM'],
  disabled,
  className,
  hasError,
  innerRef,
}) => {
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const [time, meridiemPeriod] = value;

  const handleFocus = event => {
    setHasFocus(true);
    onFocus && onFocus(event);
  };

  const handleBlur = event => {
    setHasFocus(false);
    onBlur && onBlur(event);
  };

  const handleTimeChange = (nextTime: string | null) => {
    onChange && onChange([nextTime, meridiemPeriod]);
  };

  const handlePeriodChange = (nextPeriod: Meridiem) => {
    onChange && onChange([time, nextPeriod]);
  };

  return (
    <SInputWrapper ref={innerRef} className={className}>
      <StyledTimePicker
        hasError={hasError}
        hasFocus={hasFocus}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disableClock
        value={time!}
        onChange={value => handleTimeChange(value as string | null)}
        disabled={disabled}
        format="hh:mm"
        maxDetail="minute"
      />
      <StyledMeridiemPeriodSelect
        hasError={hasError}
        isSearchable={false}
        isClearable={false}
        disabled={disabled}
        value={meridiemPeriod}
        options={[
          { label: 'AM', value: 'AM' },
          { label: 'PM', value: 'PM' },
        ]}
        onChange={nextPeriod => handlePeriodChange(nextPeriod as Meridiem)}
      />
    </SInputWrapper>
  );
};

const TimePicker = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return <TimePickerComponent innerRef={ref} {...props} />;
});
TimePicker.displayName = `TextPicker`;

export default TimePicker;

export const TimePickerField = createFormikField<
  MeridiemTimeTuple,
  MeridiemTimeTuple,
  HTMLInputElement,
  Props
>(TimePickerComponent);
