import React, { useState } from 'react';

import * as apiTypes from '../../../api/types/apiTypes';
import ServerErrors from '../server-errors/ServerErrors';
import { SDescription, SPage, STitle, StyledPrimaryButton } from './PageError.styles';

type OwnProps = {
  className?: string;
};

export type Props = OwnProps & apiTypes.GenericResponseDataFailure;

/**
 * Takes standard error object or string and prints it in complex full-page component.
 * Allows user to expand/collapse more detailed error information.
 * Should be used when loading url-based resource from the server such as company or offering detail.
 *
 * @param className allows style adjustments trough styled-components
 * @param error error object or string, usually generated by server
 */
const PageError: React.FC<Props> = ({ className = '', error }) => {
  const [isExpanded, setExpanded] = useState(false);
  const hasDetails = error.details && error.details.length > 0;

  return (
    <SPage className={className}>
      <STitle>This content is not available</STitle>
      <SDescription>{error.message}</SDescription>
      {isExpanded && <ServerErrors error={error} />}
      <StyledPrimaryButton onClick={() => setExpanded(!isExpanded)} disabled={!hasDetails}>
        {isExpanded ? 'Show less info' : 'Show more info'}
      </StyledPrimaryButton>
    </SPage>
  );
};

export default PageError;
