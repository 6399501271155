import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';

import calendar from '../../features/calendar/ducks';
import calendarGraphql from '../../features/calendar-graphql/ducks';
import datalab from '../../features/datalab/ducks';
import { DatalabState } from '../../features/datalab/types';
import myDashboard, { ReducerState as MyDashboardState } from '../../features/my-dashboard/ducks';
import ioiTracker from '../../features/offering-dl/ioi-tracker/ducks';
import {
  reducer as offeringProfile,
  ReducerState as OfferingProfileState,
} from '../../features/offering-dl/offering-profile/ducks';
import offeringDl from '../../features/offering-dl/shared/ducks';
import offeringNotes from '../../features/offering-dl/shared/offering-notes/ducks';
import underwriterCredits, {
  UnderwriterCreditsState,
} from '../../features/offering-dl/underwriter-credits/ducks';
import shared, { SharedState } from '../../features/shared/ducks';
import notifications, {
  ReducerState as NotificationsState,
} from '../../features/shared/notifications/ducks';
import spinner from '../../features/shared/spinner/ducks';

export type RootState = {
  calendar: any;
  calendarGraphql: any;
  datalab: DatalabState;
  ioiTracker: any;
  modal: any;
  notifications: NotificationsState;
  offeringDl: any;
  offeringNotes: any;
  offeringProfile: OfferingProfileState;
  shared: SharedState;
  spinner: any;
  underwriterCredits: UnderwriterCreditsState;
  myDashboard: MyDashboardState;
};

const createRootReducer = () =>
  combineReducers<RootState>({
    calendar,
    calendarGraphql,
    datalab,
    ioiTracker,
    modal,
    offeringDl,
    offeringNotes,
    offeringProfile,
    shared,
    spinner,
    underwriterCredits,
    notifications,
    myDashboard,
  });

export default createRootReducer;
