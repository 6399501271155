import isEqual from 'lodash/isEqual';
import unionBy from 'lodash/unionBy';
import memoize from 'memoize-one';
import React, { useCallback, useState } from 'react';
import { components, MultiValue } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import { useDebouncedCallback } from 'use-debounce';

import { createFormikField, FieldControlProps, FieldProps } from '../formik/createFormikField';
import { getAsyncSelectComponents, getAsyncSelectStyles } from './AsyncSelect.model';
import {
  getReactSelectProps,
  getSelectedOptions,
  PublicMultiSelectProps,
  PublicProps,
} from './BaseSelect.model';
import { SSelectWrapper } from './BaseSelect.styles';
import TruncatedValueContainer from './components/TruncatedValueContainer';
import { Group, Option } from './types';

type AsyncMultiSelectProps<TOptionValue> = {
  /** boolean supported by ReactSelectAsync - calls loadOptions after mount when true */
  defaultOptions?: Option<TOptionValue>[] | Group<TOptionValue>[] | boolean;
  loadOptions: (inputValue: string) => Promise<any>;
  loadMissingOptions?: (optionValues: TOptionValue[]) => Promise<Option<TOptionValue>[]>;
};

export type Props<TOptionValue> = PublicProps<TOptionValue, true> &
  FieldControlProps<TOptionValue[] | null, TOptionValue[] | null> &
  AsyncMultiSelectProps<TOptionValue> &
  PublicMultiSelectProps;

export type AsyncMultiSelectFieldProps<TOptionValue> = Props<TOptionValue> & FieldProps;

/**
 * Multi-select component with asynchronously loaded options.
 * It works off the assumption that two asyncronous calls are needed.
 *  One for option initialization -- e.g. values is a set of ids [1,2,3], fetch to get options for those values
 *  The other for text search -- e.g. user types in 'Test', fetch to get options that match that text
 */
const AsyncMultiSelect = <TOptionValue extends unknown = string>(props: Props<TOptionValue>) => {
  // All options that have been loaded
  const [loadedOptions, setLoadedOptions] = useState<Option<TOptionValue>[]>([]);
  // Loading state for call that gets options for values that don't have them yet
  const [loadingMissingOptions, setLoadingMissingOptions] = useState(false);
  const {
    className,
    testId,
    value,
    defaultOptions = [],
    loadOptions,
    loadMissingOptions,
    onChange,
    placeholder = 'Search...',
    noOptionsMessage = () => 'No options found...',
    maxDisplayedValues,
    renderMultiValueLabel,
  } = props;

  const getSelectedOptionsCached = memoize(getSelectedOptions<TOptionValue>);

  const handleOnChange = (values: MultiValue<Option<TOptionValue>>) => {
    onChange && onChange(values ? values.map(v => v.value) : []);
  };

  const getMissingValues = useCallback(
    values => values.filter(v => !loadedOptions.some(option => isEqual(option.value, v))),
    [loadedOptions]
  );

  const addOptions = useCallback(
    newOptions => {
      const nextOptions = unionBy([...loadedOptions, ...newOptions], 'value');
      setLoadedOptions(nextOptions);
    },
    [loadedOptions]
  );

  // Whenever the value (array of ids) changes, we make sure we have options for it.
  // If there are no options, then a request is made out to the datasource to get those options.
  React.useEffect(() => {
    if (!value || !loadMissingOptions) {
      return;
    }

    // Are there values we don't have an option for?
    const missingValues = getMissingValues(value);

    if (missingValues.length === 0) {
      return;
    }

    setLoadingMissingOptions(true);
    loadMissingOptions(missingValues).then(newOptions => {
      addOptions(newOptions);
      setLoadingMissingOptions(false);
    });
  }, [value, loadMissingOptions, getMissingValues, addOptions]);

  /**
   * User searching by text, call out to our datasource to get matching options.
   */
  const debouncedLoadOptions = useDebouncedCallback(
    (
      input: string,
      loadedOptions: Option<TOptionValue>[],
      callback: (options: Option<TOptionValue>[]) => void
    ) => {
      loadOptions(input).then(options => {
        const nextOptions = unionBy([...loadedOptions, ...options], 'value');
        setLoadedOptions(nextOptions);
        callback(options);
      });
    },
    300
  );

  /**
   * Interface between react-select and our debounced loading function
   */
  const handleLoadOptions = (
    input: string,
    callback: (options: Option<TOptionValue>[]) => void
  ) => {
    debouncedLoadOptions(input, loadedOptions, callback);
  };

  const { styles = {}, ...selectProps } = getReactSelectProps({ ...props });

  // Look at all of our available options and get the selected ones, based off current value array.
  const allOptions = unionBy(
    [...(typeof defaultOptions === 'boolean' ? [] : defaultOptions), ...loadedOptions],
    'value'
  );
  const selectedOptions = getSelectedOptionsCached(allOptions, value);

  const valueContainerComponent = maxDisplayedValues
    ? {
        ValueContainer: props => (
          <TruncatedValueContainer<Option<TOptionValue>>
            {...props}
            maxDisplayedValues={maxDisplayedValues}
          />
        ),
      }
    : {};

  return (
    <SSelectWrapper className={className} data-test-id={testId}>
      <ReactSelectAsync<Option<TOptionValue>, true, Group<TOptionValue>>
        {...selectProps}
        isMulti
        // Async select currently has a bug where loading prop isn't used. It's merged, waiting on new release of react-select (https://github.com/JedWatson/react-select/pull/3690/files)
        isLoading={loadingMissingOptions}
        loadOptions={handleLoadOptions}
        value={selectedOptions}
        onChange={handleOnChange}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        components={{
          ...selectProps.components,
          ...getAsyncSelectComponents(),
          ...valueContainerComponent,
          ...(renderMultiValueLabel
            ? {
                MultiValueLabel: props => {
                  return (
                    <components.MultiValueLabel {...props}>
                      {renderMultiValueLabel(props.children)}
                    </components.MultiValueLabel>
                  );
                },
              }
            : {}),
        }}
        styles={getAsyncSelectStyles(styles)}
        /**
         *  @see [Async React-Select cacheOptions behavior]{@link https://capitalmarketsgateway.atlassian.net/l/c/cyEGm0Xf}
         */
        cacheOptions={false}
      />
    </SSelectWrapper>
  );
};

export default AsyncMultiSelect;

// TODO why are these set to `any`?
export const AsyncMultiSelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(
  AsyncMultiSelect
);
