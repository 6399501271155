import React from 'react';

import { SLabelWrapper, StyledIcon } from './ExpandableGroupLabel.styles';
import { Group } from './types';

type Props<TOptionValue> = {
  group: Group<TOptionValue>;
};

/**
 * Group label with chevron icon indicating expanded/collapsed group state
 */
export function ExpandableGroupLabel<TOptionValue>({ group }: Props<TOptionValue>) {
  const isExpanded = group.data && group.data.expanded;

  return (
    <SLabelWrapper>
      <StyledIcon name={isExpanded ? 'caret-down' : 'caret-right'} variant="solid" />
      {group.label} ({group.options.length})
    </SLabelWrapper>
  );
}
