/**
 * Map of codes included in Service Error
 * response objects returned from CMG APIs.
 */
export enum ServiceErrorCode {
  NOT_FOUND = 'NOT_FOUND',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  EDIT_CONFLICT = 'EDIT_CONFLICT',
  FORBIDDEN = 'FORBIDDEN',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  LOCKED_OUT = 'LOCKED_OUT',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
  EMAIL_NOT_VALID = 'EMAIL_NOT_VALID',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  EMAIL_ERROR = 'EMAIL_ERROR',
  DUPLICATE = 'DUPLICATE',
  IP_BLOCKED = 'IP_BLOCKED',
  UNHANDLED_ERROR = 'UNHANDLED_ERROR',
  NETWORK_FAILURE = 'NETWORK_FAILURE',
}
