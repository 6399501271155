import { numericUtil, Popover, Tag } from '@cmg/common';
import { useTheme } from '@cmg/design-system';
import React from 'react';
import styled from 'styled-components/macro';

import { getFeatureToggles } from '../../../config/appSettings';
import { type Calendar_OfferingFieldsFragment, Country, OfferingStatus } from '../graphql';

const SSizeSharesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const STagWrapper = styled.div`
  margin-top: -3px;
  margin-right: 5px;
`;

export type Props = {
  offering: Calendar_OfferingFieldsFragment;
};

const SizeSharesRenderer: React.FC<Props> = ({ offering }) => {
  const hideRevisedTag =
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn &&
    offering.attributes?.exchangeCountry === Country.Us &&
    offering.attributes?.status === OfferingStatus.Filed;

  const isOfferingSizeZero = offering.attributes?.latestSizeInSecuritiesTotal === 0;

  const themes = useTheme();

  return (
    <SSizeSharesWrapper>
      {offering.attributes?.initialSizeInSecuritiesTotal &&
        offering.attributes?.initialSizeInSecuritiesTotal !==
          offering.attributes?.latestSizeInSecuritiesTotal &&
        !hideRevisedTag &&
        !isOfferingSizeZero && (
          <Popover
            variant="TOOLTIP"
            placement="top"
            content={
              <span>
                Initial Size:{' '}
                {numericUtil.formatInteger(offering.attributes?.initialSizeInSecuritiesTotal)}
              </span>
            }
          >
            <STagWrapper>
              <Tag color={themes.palette.info.main}>R</Tag>
            </STagWrapper>
          </Popover>
        )}
      <span>
        {!isOfferingSizeZero
          ? numericUtil.formatInteger(offering.attributes?.latestSizeInSecuritiesTotal)
          : ''}
      </span>
    </SSizeSharesWrapper>
  );
};

export default SizeSharesRenderer;
