import { LogLevel, SignalRContextProvider } from '@cmg/common';
import React from 'react';

import useAccessToken from '../auth/components/hooks/useAccessToken';

export const AuthenticatedSignalRContextProvider: React.FC<{ logLevel?: LogLevel }> = ({
  children,
  logLevel,
}) => {
  const token = useAccessToken();

  return (
    <SignalRContextProvider token={token} logLevel={logLevel}>
      {children}
    </SignalRContextProvider>
  );
};
