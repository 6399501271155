import { TooltipProps } from 'antd/lib/tooltip';
import React, { PropsWithChildren, useState } from 'react';

import { PopoverVariant } from '../../overlays/popover/Popover';
import { StyledPopover } from './DropdownContainer.styles';

export enum DropdownVariant {
  DARK = PopoverVariant.DARK,
  LIGHT = PopoverVariant.LIGHT,
}

type ContentArgs = {
  setVisibility: (visible: boolean) => void;
  isVisible: boolean;
};

type Props = PropsWithChildren<{
  /**
   * Render popover content
   */
  content: (args: ContentArgs) => React.ReactNode;
  /**
   * Called on visibility change
   */
  onVisibilityChange?: (visibility: boolean) => void;
  /**
   * Enable/disable popover position adjustment in case it overflows page height
   */
  autoAdjustOverflow?: boolean;
  /**
   * Set popover style variant
   */
  popoverVariant?: keyof typeof DropdownVariant;
  /**
   * Decide when popover will be opened
   */
  trigger?: TooltipProps['trigger'];
  className?: string | undefined;
}>;

/**
 * Wraps dropdown content into styled poppver.
 */
const DropdownContainer: React.FC<Props> = ({
  children,
  className,
  content,
  onVisibilityChange,
  autoAdjustOverflow,
  popoverVariant = 'DARK',
  trigger = 'click',
}) => {
  const [visible, setVisible] = useState(false);

  const handleVisibilityChange = (visible: boolean) => {
    setVisible(visible);
    onVisibilityChange && onVisibilityChange(visible);
  };

  return (
    <StyledPopover
      className={className}
      visible={visible}
      onVisibleChange={handleVisibilityChange}
      placement="bottomRight" // When searchable the popover size changes as the user types, so we keep it static instead.
      autoAdjustOverflow={autoAdjustOverflow}
      trigger={trigger}
      variant={popoverVariant}
      hideArrow
      content={content({ setVisibility: handleVisibilityChange, isVisible: visible })}
    >
      {children}
    </StyledPopover>
  );
};

export default DropdownContainer;
