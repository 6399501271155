import * as datalabApi from './datalab';
import { apiClient as dlApiClient } from './datalab/apiClient';
import * as identityApi from './identity';
import { AuthInterceptor, ClientErrorInterceptor, ClientResponseInterceptor } from './utils';

export * from './core';
export { datalabApi, identityApi };

export function registerAuthInterceptor(authInterceptor: AuthInterceptor) {
  dlApiClient.interceptors.request.use(authInterceptor);
}

export function registerClientInterceptor<TResponseData = any, TErrorData = any>(
  responseInterceptor?: ClientResponseInterceptor<TResponseData>,
  errorInterceptor?: ClientErrorInterceptor<TErrorData>
) {
  dlApiClient.interceptors.response.use(responseInterceptor, errorInterceptor);
}
