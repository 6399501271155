import unionBy from 'lodash/unionBy';
import memoize from 'memoize-one';
import React, { useCallback, useState } from 'react';
import { Options, SingleValue } from 'react-select';
import ReactSelectAsync from 'react-select/async';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import { getAsyncSelectComponents, getAsyncSelectStyles } from './AsyncSelect.model';
import { getReactSelectProps, getSelectedOption, PublicProps } from './BaseSelect.model';
import { SSelectWrapper } from './BaseSelect.styles';
import { Group, Option } from './types';

type AsyncSelectProps<TOptionValue> = {
  /** boolean supported by ReactSelectAsync - calls loadOptions after mount when true */
  defaultOptions?: Option<TOptionValue>[] | Group<TOptionValue>[] | boolean;
  loadOptions: (
    inputValue: string,
    callback: (options: Options<Option<TOptionValue>>) => Promise<any> | void
  ) => Promise<any> | void;
  loadMissingOption?: (optionValue) => Promise<Option<TOptionValue>[]>;
  cacheOptions?: boolean;
};

export type Props<TOptionValue> = PublicProps<TOptionValue> &
  FieldControlProps<TOptionValue | null, TOptionValue | null> &
  AsyncSelectProps<TOptionValue>;

const AsyncSelect = <TOptionValue extends unknown = string>(props: Props<TOptionValue>) => {
  const {
    className,
    defaultOptions = [],
    testId,
    value,
    loadOptions,
    loadMissingOption,
    onChange,
    isClearable = true,
    placeholder = 'Search...',
    noOptionsMessage = () => 'No options found...',
  } = props;

  const [loadedOptions, setLoadedOptions] = useState<Option<TOptionValue>[]>([]);
  // Loading state for call that gets options for values that don't have them yet
  const [loadingMissingOptions, setLoadingMissingOptions] = useState(false);

  const getSelectedOptionCached = memoize(getSelectedOption<TOptionValue>);

  const handleOnChange = (value: SingleValue<Option<TOptionValue>>) => {
    onChange && onChange(value ? value.value : null);
  };

  const handleLoadOptions = (
    input: string,
    callback: (options: readonly Option<TOptionValue>[]) => void
  ) => {
    loadOptions(input, options => {
      const nextOptions = unionBy([...loadedOptions, ...options], 'value');
      setLoadedOptions(nextOptions);
      callback(options);
    });
  };

  const isValueMissing = useCallback(
    value => loadedOptions.find(option => option.value === value) === undefined,
    [loadedOptions]
  );

  const addOptions = useCallback(
    newOptions => {
      const nextOptions = unionBy([...loadedOptions, ...newOptions], 'value');
      setLoadedOptions(nextOptions);
    },
    [loadedOptions]
  );

  // Whenever the value (array of ids) changes, we make sure we have options for it.
  // If there are no options, then a request is made out to the datasource to get those options.
  React.useEffect(() => {
    if (!value || !loadMissingOption) {
      return;
    }

    // Do we have option for selected value?
    if (!isValueMissing(value)) {
      return;
    }

    setLoadingMissingOptions(true);
    loadMissingOption(value).then(newOptions => {
      addOptions(newOptions);
      setLoadingMissingOptions(false);
    });
  }, [value, loadMissingOption, isValueMissing, addOptions]);

  const { styles = {}, ...selectProps } = getReactSelectProps(props);

  const options = unionBy(
    [...(typeof defaultOptions === 'boolean' ? [] : defaultOptions), ...loadedOptions],
    'value'
  );

  return (
    <SSelectWrapper className={className} data-test-id={testId}>
      <ReactSelectAsync
        isClearable={isClearable}
        {...selectProps}
        isLoading={loadingMissingOptions}
        loadOptions={handleLoadOptions}
        /**
         *  @see [Async React-Select cacheOptions behavior]{@link https://capitalmarketsgateway.atlassian.net/l/c/cyEGm0Xf}
         */
        cacheOptions={false}
        value={getSelectedOptionCached(options, value)}
        defaultOptions={defaultOptions}
        onChange={handleOnChange}
        placeholder={placeholder}
        components={{
          ...selectProps.components,
          ...getAsyncSelectComponents(),
        }}
        noOptionsMessage={noOptionsMessage}
        styles={getAsyncSelectStyles(styles)}
      />
    </SSelectWrapper>
  );
};

export default AsyncSelect;

// TODO why are these set to `any`?
export const AsyncSelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(
  AsyncSelect
);
