import React from 'react';

import { ServiceErrorFragment, ServiceErrorPartsFragment } from '../../../api/types/ServiceError';
import Banner from '../banner/Banner';
import ServiceErrors from './ServiceErrors';

export type ServiceErrorBannerProps = { error: ServiceErrorFragment | ServiceErrorPartsFragment };
const ServiceErrorBanner = ({ error }: ServiceErrorBannerProps) => {
  return (
    <Banner variant="error">
      <ServiceErrors error={error} />
    </Banner>
  );
};

export default ServiceErrorBanner;
