import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import queryString from 'query-string';

import { FILE_MIME_TYPES } from '../core';
import { normalizeErrorResponse, normalizeSuccessResponse } from './response-normalizers';

export function makeGetRequest<ResponseDto, Query extends {} = {}, Parameters extends {} = {}>(
  apiClient: AxiosInstance,
  getRoute: (params: Parameters) => string
) {
  return async (parameters: Parameters, query: Query = {} as Query) => {
    // creating a concrete url based on provided parameters
    const url = getRoute(parameters);
    const queryStringValue = queryString.stringify(query, { arrayFormat: 'comma' });

    try {
      const response = await apiClient.get<ResponseDto>(
        `${url}${queryStringValue ? `?${queryStringValue}` : ''}`
      );
      return normalizeSuccessResponse(response);
    } catch (error) {
      return normalizeErrorResponse(error as AxiosError);
    }
  };
}

export function makePostRequest<RequestDto, ResponseDto, Parameters extends {} = {}>(
  apiClient: AxiosInstance,
  getRoute: (params: Parameters) => string
) {
  return async (data: RequestDto, parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = getRoute(parameters);

    try {
      const response = await apiClient.post<RequestDto, AxiosResponse<ResponseDto>>(url, data);
      return normalizeSuccessResponse(response);
    } catch (error) {
      return normalizeErrorResponse(error as AxiosError);
    }
  };
}

export function makePutRequest<RequestDto, ResponseDto, Parameters extends {} = {}>(
  apiClient: AxiosInstance,
  getRoute: (params: Parameters) => string
) {
  return async (data: RequestDto, parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = getRoute(parameters);

    try {
      const response = await apiClient.put<RequestDto, AxiosResponse<ResponseDto>>(url, data);
      return normalizeSuccessResponse(response);
    } catch (error) {
      return normalizeErrorResponse(error as AxiosError);
    }
  };
}

export function makeDeleteRequest<Parameters extends {} = {}>(
  apiClient: AxiosInstance,
  getRoute: (params: Parameters) => string
) {
  return async (parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = getRoute(parameters);
    try {
      const response = await apiClient.delete<undefined>(url);
      return normalizeSuccessResponse(response);
    } catch (error) {
      return normalizeErrorResponse(error as AxiosError);
    }
  };
}

export function makeGetDownloadRequest<
  ResponseDto,
  Query extends Record<string, any>,
  Parameters extends object = {}
>(apiClient: AxiosInstance, getRoute: (params: Parameters) => string) {
  return async (parameters: Parameters, query: Query = {} as Query) => {
    // creating a concrete url based on provided parameters
    const url = getRoute(parameters);
    const queryStringValue = queryString.stringify(query, { arrayFormat: 'comma' });

    try {
      const response = await apiClient.get<ResponseDto>(
        `${url}${queryStringValue ? `?${queryStringValue}` : ''}`,
        {
          responseType: 'blob',
          headers: { Accept: FILE_MIME_TYPES.XLSX },
        }
      );

      return normalizeSuccessResponse(response);
    } catch (error) {
      return normalizeErrorResponse(error as AxiosError);
    }
  };
}

export function makePostDownloadRequest<RequestDto, Parameters extends object = {}>(
  apiClient: AxiosInstance,
  getRoute: (params: Parameters) => string
) {
  return async (data: RequestDto, parameters: Parameters) => {
    // creating a concrete url based on provided parameters
    const url = getRoute(parameters);

    try {
      const response = await apiClient.post<RequestDto, AxiosResponse<Blob>>(url, data, {
        responseType: 'blob',
        headers: { Accept: FILE_MIME_TYPES.XLSX },
      });

      return normalizeSuccessResponse(response);
    } catch (error) {
      return normalizeErrorResponse(error as AxiosError);
    }
  };
}
