import { createStore, Reducer, Store } from 'redux';

import { RootState } from '../../../common/redux/rootReducer';
import { makeState } from './state';

export function makeStore(override?: Partial<RootState>): Store {
  const state: RootState = makeState(override);
  const reducer: Reducer = () => state;

  return createStore(reducer);
}
