import React from 'react';

import {
  SBox,
  SBoxContent,
  SBoxFooter,
  SBoxHeader,
  SRight,
  STabButton,
  STabs,
  STitle,
} from './Box.styles';

export type Tab = {
  value: string;
  label: string;
};

export type BoxProps = {
  children: React.ReactNode;
  className?: string;
  withMargin?: boolean;
  testId?: string;
};

export type HeaderProps = {
  children?: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
  tabs?: Tab[];
  activeTab?: Tab;
  onChangeTab?: (tab: Tab) => void;
};

export type ContentProps = {
  children: React.ReactNode;
  className?: string;
  large?: boolean; // 20px padding instead 10px
};

export type FooterProps = {
  children: React.ReactNode;
};

export default class Box extends React.Component<BoxProps> {
  render() {
    const { children, className, testId, withMargin } = this.props;

    return (
      <SBox withMargin={withMargin} className={className} data-test-id={testId}>
        {children}
      </SBox>
    );
  }

  static Header: React.FC<HeaderProps> = ({
    children,
    title,
    tabs,
    activeTab,
    onChangeTab,
    className,
  }) => (
    <SBoxHeader className={className}>
      {title && <STitle>{title}</STitle>}
      {tabs && tabs.length > 0 && (
        <STabs>
          {tabs.map(tab => (
            <STabButton
              active={activeTab && activeTab.value === tab.value}
              onClick={() => onChangeTab && onChangeTab(tab)}
              key={tab.value}
            >
              {tab.label}
            </STabButton>
          ))}
        </STabs>
      )}
      <SRight>{children}</SRight>
    </SBoxHeader>
  );

  static Content: React.FC<ContentProps> = props => <SBoxContent {...props} />;

  static Footer: React.FC<FooterProps> = props => <SBoxFooter {...props} />;
}
