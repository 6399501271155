import { parseISO, startOfMinute } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { ISODateTime } from '../../../types/common';

export type DateTimeValue = { date: ISODateTime | null | undefined; timezone: string | null };

export function getTimezone(value: DateTimeValue | null | undefined, fallbackTimezone: string) {
  return value?.timezone ?? fallbackTimezone;
}

/**
 * This function converts ISO date string to a Date instance that emulates local value as it would be in a given timezone
 * To avoid unintended rounding, we strip seconds and milliseconds
 * @param value
 * @param fallbackTimezone
 */
export function parseDateTime(
  value: DateTimeValue | null | undefined,
  fallbackTimezone: string
): Date | null {
  if (!value || !value.date) {
    return null;
  }

  const timezone = getTimezone(value, fallbackTimezone);
  const local = parseISO(value.date);

  return startOfMinute(utcToZonedTime(local, timezone));
}
