import styled from 'styled-components/macro';

import Icon from '../../components/graphics/icon/Icon';
import theme from '../../styles/theme';

export const SContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSavingIcon = styled(Icon).attrs({
  name: 'sync',
  size: '1x',
  color: theme.text.color.darkGray,
})``;

export const SContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledCheckCircle = styled(Icon).attrs({
  name: 'check-circle',
  variant: 'solid',
  size: '1x',
  color: theme.brand.color.success,
})``;
