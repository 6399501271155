import styled from 'styled-components/macro';

export const SDisclaimer = styled.p`
  padding-top: 5px;
  color: ${({ theme }) => theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.tiny};
  text-align: left;
  white-space: normal;
  align-items: end;
  margin-top: auto;
`;
