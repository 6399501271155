import cn from 'classnames';
import React from 'react';

import { InputWrapperVariant, SInputWrapper, SPrefix, SSuffix } from './InputWrapper.styles';

type Props = {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  textAlign?: 'left' | 'right';
  size?: 'medium' | 'large';
  rounded?: 'top' | 'bottom' | 'left' | 'right' | 'none';
  variant?: InputWrapperVariant;
  width?: number;
};

/**
 * Both our TextInput and NumericInput need the same underlying styles and ability to have
 * a prefix and suffix.
 * This can wrap any `<input type="text">` or `<Cleave>` to provide these capabilities and
 * styles to the inputs themselves.
 *
 * When we introduce styled-components we might be able to create a StyledInput and StyledCleave
 * that both share the same properties instead of nesting input selectors in the styles of
 * this component.
 *
 * For now SInputWrapper has variant strings which handle various input element selectors
 */
const InputWrapper: React.FC<Props> = props => {
  const {
    children,
    className,
    disabled,
    prefix,
    suffix,
    hasError,
    textAlign,
    size,
    rounded,
    variant,
    width,
  } = props;

  return (
    <SInputWrapper
      disabled={disabled}
      hasError={hasError}
      textAlign={textAlign}
      size={size}
      rounded={rounded}
      variant={variant}
      width={width}
      className={cn('input-wrapper', className)}
      hasPrefix={!!prefix}
      hasSuffix={!!suffix}
    >
      {prefix && <SPrefix size={size}>{prefix}</SPrefix>}
      {children}
      {suffix && <SSuffix size={size}>{suffix}</SSuffix>}
    </SInputWrapper>
  );
};

export default InputWrapper;
