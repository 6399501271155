import React from 'react';

import { checkPermissions } from '../../../common/util/authorization';
import { AccountType } from '../../../lib';
import useAuth from '../hooks/useAuth';

export type Props = {
  /**
   * children should already be in React.FC but because of some bug in TS it's not working without specifying it directly
   * try to remove this line if you meet this comment in greater TS version then 3.5.3
   * */
  children?: React.ReactNode;
  /** requiredPermissions permissions that are needed for the protected component to be displayed */
  requiredPermissions?: string[];
  /** requireAllPermissions when true, requires the user to have all permissions in 'requiredPermissions' */
  requireAllPermissions?: boolean;
  /** accessCheck if logic is more complex than a permissions check, a custom function to determine access */
  requiredAccountType?: AccountType | 'SYSTEM';
  accessCheck?: (userPermissions: string[]) => boolean;
  /** renderNoAccess runs in cases the user does not pass the access checks */
  renderNoAccess?: (isLoggedIn: boolean) => React.ReactNode;
};

/**
 * AccessControl acts as a gate for protecting components from being rendered that require specific
 * authorization levels to be seen.
 * Checks: user is logged in + have the required permissions + custom check passes.
 *
 * In the consuming SPA apps this component should be connected to redux
 * to supply both the `userPermissions` and `isLoggedIn` flags. That connected
 * version of the component is to be used.
 */
export const AccessControl: React.FC<Props> = ({
  requiredPermissions = [],
  requireAllPermissions = true,
  requiredAccountType = null,
  children,
  renderNoAccess = () => null,
  accessCheck,
}) => {
  const { isLoggedIn, userPermissions, oidcUserAccountType } = useAuth();

  if (!isLoggedIn) {
    return <React.Fragment>{renderNoAccess(isLoggedIn)}</React.Fragment>;
  }

  const accessCheckPasses = accessCheck ? accessCheck(userPermissions) : true;
  const permissionsCheckPasses = checkPermissions(
    userPermissions,
    requiredPermissions,
    requireAllPermissions
  );
  const accountTypeCheckPasses = requiredAccountType
    ? requiredAccountType === oidcUserAccountType
    : true;

  if (accessCheckPasses && permissionsCheckPasses && accountTypeCheckPasses) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <React.Fragment>{renderNoAccess(isLoggedIn)}</React.Fragment>;
};

export default AccessControl;
