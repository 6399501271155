import styled, { css } from 'styled-components/macro';

export const SButtonsContainer = styled.div<{
  direction: 'row' | 'column';
  margin: number;
  justifyContent: 'left' | 'right' | 'center';
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justifyContent }) => {
    switch (justifyContent) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
    }
  }};

  ${({ direction, margin }) =>
    direction === 'row' &&
    css`
      > * {
        margin-right: ${margin}px;
      }
      *:last-child {
        margin-right: 0;
      }
    `}

  ${({ direction, margin }) =>
    direction === 'column' &&
    css`
      > * {
        margin-bottom: ${margin}px;
      }
      *:last-child {
        margin-bottom: 0;
      }
    `}
`;
