import { datalabApi } from '@cmg/api';
import { checkPermissions, permissionsByEntity, PublicAuthStateProps, withAuth } from '@cmg/auth';
import { ISODate, Popover, Tag, timeUtil } from '@cmg/common';
import { calendarOfferingIssuerNameSelector } from '@cmg/e2e-selectors';
import { isValid } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';

import routeFactory from '../../../common/util/routeFactory';
import { getFeatureToggles } from '../../../config/appSettings';
import { OfferingStatus, PricingType } from '../../../types/domain/offering/constants';
import { IoiType } from '../../../types/domain/offering/ioi/constants';
import FollowOfferingButton from './FollowOfferingButton';
import IoiSummary from './IoiSummary';
import { SIssuerButtons, SIssuerTags } from './IssuerFirmNameRenderer.styles';
import OfferingProfileLinkRenderer from './OfferingProfileLinkRenderer';

function isPassTagVisible(offering: datalabApi.CalendarOffering, userPermissions?: string[]) {
  return (
    userPermissions &&
    checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]) &&
    offering.ioiType === IoiType.PASS
  );
}

function isRegMTagVisible(offering: datalabApi.CalendarOffering, userPermissions?: string[]) {
  return (
    userPermissions &&
    checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]) &&
    offering.ioiType === IoiType.REG_M
  );
}

function isIoiTagVisible(offering: datalabApi.CalendarOffering, userPermissions?: string[]) {
  return (
    userPermissions &&
    checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]) &&
    (offering.ioiType === null || offering.ioiType === IoiType.NOT_INDICATED)
  );
}

function isScalarIoiTagVisible(offering: datalabApi.CalendarOffering, userPermissions?: string[]) {
  return isIoiTagVisible(offering, userPermissions) && offering.indicationOfInterest.length >= 2;
}

function isMarketIoiTagVisible(offering: datalabApi.CalendarOffering, userPermissions?: string[]) {
  return (
    isIoiTagVisible(offering, userPermissions) &&
    offering.indicationOfInterest &&
    offering.indicationOfInterest.length === 1 &&
    offering.indicationOfInterest[0].pricingType === PricingType.MARKET
  );
}

function isLimitIoiTagVisible(offering: datalabApi.CalendarOffering, userPermissions?: string[]) {
  return (
    isIoiTagVisible(offering, userPermissions) &&
    offering.indicationOfInterest &&
    offering.indicationOfInterest.length === 1 &&
    offering.indicationOfInterest[0].pricingType === PricingType.LIMIT
  );
}

function isLinkGrayedOut(offering: datalabApi.CalendarOffering) {
  const { countryCode, status } = offering;

  return (
    countryCode === 'US' &&
    status === OfferingStatus.FILED &&
    getFeatureToggles().isRestrictFiledUSOfferingsInDLOn
  );
}

const isToday = (date: ISODate) => {
  const comparedDate = timeUtil.getDate(date);
  const today = new Date();
  if (!isValid(comparedDate)) {
    return false;
  }
  return (
    comparedDate.getFullYear() === today.getFullYear() &&
    comparedDate.getMonth() === today.getMonth() &&
    comparedDate.getDate() === today.getDate()
  );
};

type OwnProps = {
  offering: datalabApi.CalendarOffering;
  showIoiBadge?: boolean;
  showLive?: boolean;
  onToggleFollowOffering?: any;
};

export type Props = OwnProps & PublicAuthStateProps;

export const IssuerFirmNameRendererComponent: React.FC<Props> = ({
  offering,
  showIoiBadge,
  showLive,
  onToggleFollowOffering,
  userPermissions,
}) => {
  return (
    <div style={{ display: 'flex' }} data-test-id={calendarOfferingIssuerNameSelector.testId}>
      {onToggleFollowOffering && (
        <SIssuerButtons>
          {onToggleFollowOffering && (
            <FollowOfferingButton offering={offering} onClick={onToggleFollowOffering} />
          )}
        </SIssuerButtons>
      )}
      {isLinkGrayedOut(offering) ? (
        <span style={{ flex: '1 1 auto', paddingRight: 10 }}>{offering.issuerFirmName}</span>
      ) : (
        <OfferingProfileLinkRenderer label={offering.issuerFirmName || ''} offering={offering} />
      )}
      <SIssuerTags>
        {showIoiBadge &&
          isPassTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color="#bac0c4">Pass</Tag>
          ) : (
            <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
              <Tag color="#bac0c4">Pass</Tag>
            </Link>
          ))}

        {showIoiBadge &&
          isRegMTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color="#ff464e">Reg-m</Tag>
          ) : (
            <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
              <Tag color="#ff464e">Reg-m</Tag>
            </Link>
          ))}

        {showIoiBadge &&
          isScalarIoiTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color="#03a9f4">Scaled</Tag>
          ) : (
            <Popover
              content={<IoiSummary iois={offering.indicationOfInterest} />}
              variant="TOOLTIP"
              trigger="hover"
            >
              <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
                <Tag color="#03a9f4">Scaled</Tag>
              </Link>
            </Popover>
          ))}

        {showIoiBadge &&
          isMarketIoiTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color="#0277bd">Market</Tag>
          ) : (
            <Popover
              content={<IoiSummary iois={offering.indicationOfInterest} />}
              variant="TOOLTIP"
              trigger="hover"
            >
              <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
                <Tag color="#0277bd">Market</Tag>
              </Link>
            </Popover>
          ))}

        {showIoiBadge &&
          isLimitIoiTagVisible(offering, userPermissions) &&
          (isLinkGrayedOut(offering) ? (
            <Tag color="#5eb8e6">Limit</Tag>
          ) : (
            <Popover
              content={<IoiSummary iois={offering.indicationOfInterest} />}
              variant="TOOLTIP"
              trigger="hover"
            >
              <Link to={routeFactory.ioi.getUrlPath({ id: offering.id })}>
                <Tag color="#5eb8e6">Limit</Tag>
              </Link>
            </Popover>
          ))}

        {offering.accelerated && <Tag color="#f9a825">Accelerated</Tag>}

        {showLive && offering.status === OfferingStatus.LIVE && <Tag color="#5eb8e6">Live</Tag>}

        <React.Fragment>
          {/*
           TODO - offering.launchDate is a YYYY-MM-DD date string. Given this data, we
           can't reliably calculate whether the launch date is the current date based on
           user/client timezones.
         */}
          {offering.launchDate && isToday(offering.launchDate) && <Tag color="#309c56">Today</Tag>}
        </React.Fragment>
      </SIssuerTags>
    </div>
  );
};

export default withAuth(IssuerFirmNameRendererComponent);
