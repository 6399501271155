import styled, { css } from 'styled-components/macro';

export const SToggleContainer = styled.div<{ disabled?: boolean }>`
  background-color: ${({ theme }) => theme.background.color.light};
  display: flex;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  cursor: pointer;

  ${({ theme, disabled }) =>
    disabled &&
    css`
      cursor: unset;
      user-select: none;
      pointer-events: none;

      ${SOption} {
        color: ${theme.text.color.disabled};
      }
    `};
`;

export const SOption = styled.div<{ isSelected?: boolean }>`
  flex: 1;
  padding: 6px 12px;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  text-align: center;
  transition: background-color 200ms;
  white-space: nowrap;

  ${({ theme, isSelected }) =>
    isSelected
      ? css`
          border: ${theme.border.width.small} solid ${theme.border.color.dark};
          background-color: ${theme.background.color.white};
        `
      : css`
          margin: 1px;
          color: ${theme.text.color.light};
        `}
`;
