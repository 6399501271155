import styled, { css } from 'styled-components/macro';

import { FlagProps } from './FlagIcons';

export const SFlag = styled.span<FlagProps>`
  ${props =>
    props.size === 'small' &&
    props.shape === 'circle' &&
    css`
      background-position: center !important;
      background-size: 30px 30px !important;
      border-radius: 50%;
      border: 1px solid #dfdfdf;
      height: 20px;
      width: 20px;
    `}
  ${props =>
    props.styledConfig &&
    css`
      border: 1px solid ${props.styledConfig.borderColor};
    `}
`;
