import React from 'react';

import { SFlexWrapper, SWrapper } from './ButtonGroup.styles';

export type Props = {};

const ButtonGroup: React.FC<Props> = ({ children }) => (
  <SWrapper>
    <SFlexWrapper>{children}</SFlexWrapper>
  </SWrapper>
);

export default ButtonGroup;
