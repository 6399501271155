import './createRangeInput.scss';

import React from 'react';

import { FieldControlProps } from '../../formik/createFormikField';
import { FormControlEvents } from '../../types';

export type RangeInputValue = {
  min?: number;
  max?: number;
};

type InputProps = {
  disabled?: boolean;
  placeholder?: string;
  precision?: number;
  prefix?: string;
  suffix?: string;
};

export interface Props
  extends FieldControlProps<RangeInputValue, RangeInputValue>,
    FormControlEvents {
  inputsProps?: InputProps;
  inputMinProps?: InputProps;
  inputMaxProps?: InputProps;
}

export function createRangeInput(Input) {
  return class RangeInput extends React.Component<Props> {
    handleOnMinChange = min => {
      const { value, onChange } = this.props;
      onChange && onChange({ min: min, max: value && value.max });
    };

    handleOnMaxChange = max => {
      const { value, onChange } = this.props;
      onChange && onChange({ max: max, min: value && value.min });
    };

    render = () => {
      const {
        inputsProps,
        inputMaxProps,
        inputMinProps,
        value,
        onClick,
        onMouseEnter,
        onMouseLeave,
        onBlur,
        onFocus,
      } = this.props;

      return (
        <div
          className="range-input-wrapper"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div className="range-input-group">
            <Input
              placeholder="Min"
              {...inputsProps}
              {...inputMinProps}
              value={value && value.min}
              onChange={this.handleOnMinChange}
              onClick={onClick}
              onBlur={onBlur}
              onFocus={onFocus}
            />
            <Input
              placeholder="Max"
              {...inputsProps}
              {...inputMaxProps}
              value={value && value.max}
              onChange={this.handleOnMaxChange}
              onClick={onClick}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </div>
        </div>
      );
    };
  };
}
