import { makePagination } from '../../../core';
import { makeEntity } from '../../../utils/mocks';
import { Firm, FirmListDto, FirmListQuery } from '../types/Firm';

export const makeFirm = makeEntity<Firm>({
  id: 'mock-firm-id',
  legalName: 'mock-firm-legal-name',
  displayName: 'mock-firm-display-name',
  firmType: 'UNDERWRITER',
  firmSubType: 'CORPORATE',
  isDefunct: false,
});

export const makeFirmListDto = makeEntity<FirmListDto>({
  data: [makeFirm()],
  pagination: makePagination(),
});

export const makeFirmListQuery = makeEntity<FirmListQuery>({});
