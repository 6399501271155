import styled, { css } from 'styled-components/macro';

export const SContent = styled.div`
  display: block;
  padding: 5px 6px;
  font-weight: ${({ theme }) => theme.text.weight.regular};
  line-height: 1;
  color: ${({ theme }) => theme.text.color.white};
  text-align: center;
  white-space: nowrap;
`;

export const SWrapper = styled.div<{
  color?: string;
  outline?: boolean;
  transparent?: boolean;
  turnOffCaps?: boolean;
  variant?: 'DEFAULT' | 'PILL';
}>`
  display: inline-block;
  text-transform: uppercase;
  /* @todo revisit font-size once theme font-sizes are standardized */
  font-size: 11px;
  border-width: ${({ theme }) => theme.border.width.small};
  border-radius: ${({ theme }) => theme.border.radius.small};
  margin: 1px 2px;

  /* rounded */
  ${props =>
    props.variant === 'PILL' &&
    css`
      border-radius: ${({ theme }) => theme.border.radius.xxl};
    `}

  /* outlined */
  ${props =>
    props.color &&
    props.outline &&
    css`
      & > ${SContent} {
        color: ${props.color};
        border: ${({ theme }) => `${theme.border.width.small} solid ${props.color}`};
        border-radius: ${({ theme }) =>
          props.variant === 'PILL' ? theme.border.radius.xxl : theme.border.radius.small};
        background-color: ${({ theme }) =>
          props.transparent === true ? 'transparent' : theme.background.color.white};
      }
    `}

  /* regular background color */
  ${props =>
    props.color &&
    !props.outline &&
    css`
      background-color: ${props.color};
    `}

  /* remove uppercasing */
  ${props =>
    props.turnOffCaps &&
    css`
      text-transform: none;
    `}
`;
