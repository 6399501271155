import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
`;

export const SContent = styled.div`
  background-color: #202b3c; /* TODO theme */
  padding: 20px;
  border-radius: 5px; /* TODO theme */
`;

export const SLogoHolder = styled.div`
  text-align: center;
`;

export const SHeading = styled.div`
  font-size: 16px; /* TODO theme */
  color: #fff;
`;
