import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ToastManager } from '@cmg/common';
import React from 'react';

import { useOfferingsFilterForm_CustomSectorsQuery } from '../graphql/__generated__/OfferingsFilterForm_CustomSectors';
import { CustomSectorOption, useCreateCustomSectorOptions } from '../OfferingsFilterForm.model';

export const useFetchCustomSectors = ({ skip }: { skip?: boolean } = {}): CustomSectorOption[] => {
  const canReadCustomSectors = useCheckPermissions([permissionsByEntity.CustomSectors.READ]);

  const { data, error, loading } = useOfferingsFilterForm_CustomSectorsQuery({
    skip: skip || !canReadCustomSectors,
  });
  const customSectors = useCreateCustomSectorOptions(data);
  const sectorOptions = React.useMemo(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching custom sectors');
    }
    if (!loading && data) {
      return customSectors;
    }
    return [];
  }, [data, error, loading, customSectors]);

  return sectorOptions;
};
