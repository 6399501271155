import trim from 'lodash/trim';

/**
 * File download mime types that we support.
 * An entry from here should be passed as the `Accepts` header on download calls.
 */
export enum FILE_MIME_TYPES {
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

/**
 * Any file download that our apis serve comes with a content-disposition header. Within that header
 * there is a filename attribute. We extract this attribute here so it can be used to convert an api
 * response to a client side file save.
 * @param contentDispositionHeader from somewhere like axiosResponse.headers['content-disposition']
 * @param filenameFallback: if the filename doesn't exist, this will be used. include the extension.
 */
export const getFilenameFromContentDisposition = (
  contentDispositionHeader: string,
  filenameFallback: string
) => {
  const filenameMatches = contentDispositionHeader.match(/filename=(.*);/);

  if (filenameMatches) {
    // 0 item in array includes 'filename=;' in string. second item in array is only the variable matching string.
    // also, sometimes the filename will have double quotes surrounding it. we trim them off if they're there.
    const theMatch = trim(filenameMatches[1], '"');
    return decodeURI(theMatch);
  }

  return filenameFallback;
};
