import { AccountType, TraitCode } from '@capital-markets-gateway/api-client-identity';
import { User, UserProfile } from 'oidc-client-ts';

import { OIDCUserProfile, OIDCUserType } from '../../../../types/domain/oidc-user/oidc-user';
import makeEntity from '../../entityFactory';

export const makeOidcUserProfile = makeEntity<OIDCUserProfile>({
  account_traits: [TraitCode.DATALAB_UI],
  account_type: AccountType.SELL_SIDE,
  sid: 'mock-sid',
  sub: 'mock-sub',
  auth_time: 1557348362, // 2019-05-08
  idp: 'local',
  amr: [],
  name: 'mock-name',
  given_name: 'mock-given_name',
  family_name: 'mock-family_name',
  email: 'mock-email',
  preferred_username: 'mock-preferred_username',
  roles: 'mock-roles',
  firm_id: 'mock-firm_id',
  firm_code: 'mock-firm_code',
  email_verified: true,
  team_id: 'mock-team_id',
  role_id: 'mock-role_id',
  team_coverage: 'mock-team_coverage',
  permissions: [],
  security_stamp: 'mock-security_stamp',
  cmg_entity_key: 'mock-cmg_entity_key',
  tenant_id: 'mock-tenant_id',
  web_app_settings: {
    IDLE_AWAY_TIMEOUT_IN_MINUTES: '600',
    PROSPECTUS_CONTACT_MANDATORY: 'true',
  },
});

const makeUserProfile = makeEntity<UserProfile>({
  iss: 'mock-issuer',
  sub: 'mock-subject',
  aud: 'mock-audience',
  exp: 120000,
  iat: 1577833200, // 2020-01-01
});

export const oidcUserProfileDefault = makeOidcUserProfile();

export const makeOidcUser = makeEntity<OIDCUserType>({
  id_token: 'mock-id_token',
  session_state: 'mock-session_state',
  access_token: 'mock-access_token',
  token_type: 'mock-token_type',
  scope: 'mock-scope',
  profile: oidcUserProfileDefault,
  expires_at: 2551759200, // 2050-11-11
});

export const oidcUserDefault = makeOidcUser();

/**
 * This is what the oidc-client gives us, and we use toStorageString to get our own
 * seralizable version of the user which we call OIDCUserType
 */
export const oidcUserClass = new User({
  id_token: 'mock-id_token',
  session_state: 'mock-session_state',
  access_token: 'mock-access_token',
  token_type: 'mock-token_type',
  scope: 'mock-scope',
  profile: makeUserProfile(),
  userState: {},
  refresh_token: 'mock-rerfresh_token',
  expires_at: 25517592000, // 2050-11-11
});
