import { apiTypes, Column, Row } from '@cmg/common';
import { DesignSystemProvider } from '@cmg/design-system';
import { companyScreenTestId } from '@cmg/e2e-selectors';
import React from 'react';

import { CompanyLegacy } from '../../../types/domain/company/company';
import { isAtmOn, isInternationalOfferingsOn } from '../../datalab/model/utils';
import ScreenContent from '../../shared/layout/ScreenContent';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import { CompanyProfile_Issuer_FieldsFragment } from '../graphql/__generated__/CompanyProfile';
import CompanyInfo from './company-info/CompanyInfo';
import CompanyOfferingsSummary from './company-offerings-summary/CompanyOfferingsSummary';
import CompanySubHeader from './company-sub-header/CompanySubHeader';
import { SContainer } from './CompanyScreen.styles';

export type Props = {
  company?: CompanyProfile_Issuer_FieldsFragment | null;
  companyLegacy?: CompanyLegacy | null;
  error: apiTypes.GenericServerError | null;
};

const profileBottomPadding = { paddingBottom: '100px' };

const CompanyScreen: React.FC<Props> = ({ company, companyLegacy, error }) => {
  const showAtm = isAtmOn();
  const showInternational = isInternationalOfferingsOn();

  return (
    <DesignSystemProvider>
      <div data-test-id={companyScreenTestId.testId}>
        <ScreenHeader />
        <ScreenContent
          renderSubHeader={() =>
            company && (
              <CompanySubHeader
                showInternational={showInternational}
                company={company}
                showAtm={showAtm}
              />
            )
          }
        >
          <SContainer>
            <Row>
              <Column xs={24}>
                <CompanyInfo company={company} />
              </Column>
            </Row>
            <Row>
              <Column xs={24}>
                <div style={profileBottomPadding}>
                  <CompanyOfferingsSummary
                    offerings={company?.offerings ?? []}
                    hasSplits={company?.hasSplits}
                  />
                </div>
              </Column>
            </Row>
          </SContainer>
        </ScreenContent>
      </div>
    </DesignSystemProvider>
  );
};

export default CompanyScreen;
