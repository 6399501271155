import React, { useCallback, useMemo } from 'react';
import ReactSelect, { SingleValue } from 'react-select';
import { useTheme } from 'styled-components/macro';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import {
  getReactSelectProps,
  getSelectedOption,
  PublicProps,
  SyncSelectProps,
} from './BaseSelect.model';
import { SSelectWrapper } from './BaseSelect.styles';
import { Option } from './types';

export type Props<TOptionValue> = PublicProps<TOptionValue> &
  FieldControlProps<TOptionValue | null, TOptionValue | null> &
  SyncSelectProps<TOptionValue> &
  Pick<React.AriaAttributes, 'aria-label'>;

const Select = <TOptionValue,>(props: Props<TOptionValue>) => {
  const { className, options, testId, value, menuPortalTarget, onChange } = props;
  const theme = useTheme();
  const selectedValue = useMemo(
    () => getSelectedOption<TOptionValue>(options, value),
    [options, value]
  );
  const handleOnChange = useCallback(
    (value: SingleValue<Option<TOptionValue>>) => {
      onChange && onChange(value ? value.value : null);
    },
    [onChange]
  );

  const selectProps = getReactSelectProps(props);
  const mergedProps = useMemo(() => {
    if (menuPortalTarget) {
      return {
        ...selectProps,
        styles: {
          ...selectProps.styles,
          menuPortal: provided => ({
            ...provided,
            ...selectProps.styles?.menu,
            zIndex: theme?.zIndex?.selectMenu,
          }),
        },
      };
    }
    return selectProps;
  }, [menuPortalTarget, selectProps, theme?.zIndex?.selectMenu]);

  return (
    <SSelectWrapper className={className} data-test-id={testId}>
      <ReactSelect {...mergedProps} value={selectedValue} onChange={handleOnChange} />
    </SSelectWrapper>
  );
};

Select.defaultProps = {
  isClearable: true,
};

export default Select;
// TODO why are these set to `any`?
export const SelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(Select);
