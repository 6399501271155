import { Field as FormikField, FieldProps as FormikFieldProps, getIn } from 'formik';
import React from 'react';

import FormField, { Props as FormFieldProps } from '../field/FormField';

function getDisplayName<T>(WrappedComponent: React.ComponentType<T>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'FormControl';
}

function getFieldErrorMessage(error: ReturnType<typeof getIn>) {
  return error?.constructor === Object
    ? Object.entries(error)
        .map(([key, message]) => `${key}: ${message}`)
        .join('; ')
    : error;
}

/**
 * Props used only by HOC
 * Can be set from client code
 */
export type FieldProps = {
  className?: string;
  fullWidth?: boolean;
  hint?: string;
  disablePopover?: boolean;
  renderInfo?: () => React.ReactNode;
  label?: React.ReactNode;
  name: string;
  withMargin?: boolean;
  required?: boolean;
  hideError?: boolean;
  shouldTouchOnChange?: boolean;
};

/**
 * Props shared by HOC and wrapped component
 * Can be set from client code
 */
type PublicControlProps<TChangeValue, TElement = HTMLInputElement> = {
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<TElement>) => void;
  onChange?: (value: TChangeValue) => void;
  required?: boolean;
  fullWidth?: boolean;
};

/**
 * Props used by wrapped component
 * Can't be overriden from client code
 */
type PrivateControlProps<TFieldValue> = {
  hasError?: boolean;
  value?: TFieldValue;
};

/**
 * Minimal props type contract mandatory for wrapped component
 */
export type FieldControlProps<
  TFieldValue,
  TChangeValue,
  TElement = HTMLInputElement
> = PublicControlProps<TChangeValue, TElement> & PrivateControlProps<TFieldValue>;

/**
 * Renders Formik Field with provided form control and provides corresponding prop types
 */
export function createFormikField<
  TInboundValue,
  TOutboundValue,
  TElement = HTMLInputElement,
  TInputProps extends FieldControlProps<
    TInboundValue,
    TOutboundValue,
    TElement
  > = FieldControlProps<TInboundValue, TOutboundValue, TElement>
>(
  FormControl: React.ComponentType<TInputProps>,
  formFieldOverrides?: Pick<FormFieldProps, 'trigger'>
) {
  type Props = Omit<TInputProps & FieldProps, keyof PrivateControlProps<TInboundValue>>;

  const Field: React.FC<Props> = props => {
    const {
      className,
      name,
      fullWidth,
      hint,
      renderInfo,
      label,
      disabled,
      required,
      withMargin,
      onChange,
      onBlur,
      disablePopover,
      hideError = false,
      shouldTouchOnChange = false,
      ...inputProps
    } = props;

    return (
      <FormikField name={name}>
        {({ field, form }: FormikFieldProps) => {
          /**
           * Formik expects DOM events, but our components trigger their onChange with the
           * value directly. By intercepting the onChange, we can set the value directly.
           */
          const handleOnChange = value => {
            form.setFieldValue(field?.name, value);
            shouldTouchOnChange && form.setFieldTouched(field?.name, true, false);
            onChange && onChange(value);
          };

          /**
           * Since we do not pass event handlers directly to inputs as Formik expects,
           * we capture the onBlur here.
           */
          const handleOnBlur = (event: React.FocusEvent<TElement>) => {
            form.setFieldTouched(field?.name, true);
            onBlur?.(event);
          };

          // extracts errors for regular Fields and Fields that are inside a FieldArray
          const error = getFieldErrorMessage(getIn(form.errors, field?.name));
          const touched = getIn(form.touched, field?.name);
          const displayError = !!touched && !!error && !hideError;

          return (
            <FormField
              className={className}
              error={displayError ? error : undefined}
              fullWidth={fullWidth}
              required={required}
              disabled={disabled}
              disablePopover={disablePopover}
              hint={hint}
              renderInfo={renderInfo}
              label={label}
              name={name}
              withMargin={withMargin}
              {...formFieldOverrides}
            >
              <FormControl
                {...(inputProps as TInputProps)}
                hasError={displayError}
                disabled={disabled}
                name={name}
                value={field?.value}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                required={required}
                fullWidth={fullWidth}
              />
            </FormField>
          );
        }}
      </FormikField>
    );
  };

  const FormikFieldWithRef = React.forwardRef<
    HTMLInputElement,
    // FIXME: This is probably a bug with TypeScript.
    //  When I left the original `Props` reference here,
    //  all optional props got transformed from key?: type to key: type | undefined.
    //  I tried copying in the whole definition of `Props` and suddenly it works. Go figure.
    Omit<TInputProps & FieldProps, keyof PrivateControlProps<TInboundValue>>
  >((props, ref) => <Field {...props} innerRef={ref} />);
  FormikFieldWithRef.displayName = `${getDisplayName(FormControl)}Field`;

  return FormikFieldWithRef;
}
