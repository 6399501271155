import styled from 'styled-components/macro';

export const SWrapper = styled.div<{ barHeight: number }>`
  display: flex;
  flex: 1 0 auto;
  box-sizing: content-box;
  height: ${({ barHeight }) => barHeight}px;
  border: ${({ theme }) => `${theme.border.width.small} solid ${theme.background.color.dark}`};
`;

export const SPercentCompleteWrapper = styled.div<{ percentComplete: number }>`
  transition: flex-grow 450ms ease-in-out;
  flex-grow: ${({ percentComplete }) => (percentComplete >= 100 ? 100 : percentComplete)};
  background-color: ${({ theme }) => theme.background.color.dark};
`;

export const SPercentIncompleteWrapper = styled.div<{ percentIncomplete: number }>`
  transition: flex-grow 450ms ease-in-out;
  flex-grow: ${({ percentIncomplete }) => (percentIncomplete >= 100 ? 100 : percentIncomplete)};
  background-color: ${({ theme }) => theme.background.color.light};
`;
