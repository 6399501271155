import React from 'react';

import {
  SCol,
  SHeader,
  SHeaderTitle,
  SPanelHeader,
  SPanelHeaderToolbar,
  SPanelHeaderToolbarItem,
  SRow,
} from './PanelHeader.styles';

const PanelHeaderToolBar: React.FC = ({ children }) => (
  <SPanelHeaderToolbar>{children}</SPanelHeaderToolbar>
);

const PanelHeaderToolBarItem: React.FC = ({ children }) => (
  <SPanelHeaderToolbarItem>{children}</SPanelHeaderToolbarItem>
);

export type Props = {
  /**
   * React children for the PanelHeader
   */
  children?: React.ReactNode;
  /**
   * Content rendered on the right side of the header
   */
  rightContent?: React.ReactNode[] | React.ReactNode;
  /**
   * A data-test-id value
   */
  testId?: string;
  /**
   * Title text to be displayed in the header
   */
  title?: string;
  /**
   * Content rendered on the bottom of the header
   */
  bottomContent?: React.ReactNode;
  /**
   * A CSS class name
   */
  className?: string;
  /**
   * Should the bottom margin be disabled
   */
  withoutMargin?: boolean;
};

/**
 * A subcomponent to Panel. Renders a complimentary header section.
 * @deprecated - bottomContent & rightContent should be renderProps function rather than
 * accepting JSX directly
 */
const PanelHeader: React.FC<Props> = ({
  children,
  rightContent,
  testId,
  title,
  bottomContent = null,
  className,
  withoutMargin,
}) => {
  return (
    <SPanelHeader className={className} data-test-id={testId} withoutMargin={withoutMargin}>
      <SHeader>
        <SCol>
          <SRow>
            {title && <SHeaderTitle>{title}</SHeaderTitle>}
            {children}
          </SRow>
          {bottomContent && <div>{bottomContent}</div>}
        </SCol>
        {rightContent && (
          <PanelHeaderToolBar>
            {Array.isArray(rightContent) ? (
              rightContent
                .filter(el => !!el)
                .map((item, i) => (
                  <PanelHeaderToolBarItem key={`panelHeaderItem_${i}`}>
                    {item}
                  </PanelHeaderToolBarItem>
                ))
            ) : (
              <PanelHeaderToolBarItem>{rightContent}</PanelHeaderToolBarItem>
            )}
          </PanelHeaderToolBar>
        )}
      </SHeader>
    </SPanelHeader>
  );
};

export default PanelHeader;
