import styled from 'styled-components/macro';

import Icon from '../../../components/graphics/icon/Icon';

type IconProps = {
  isChecked: boolean;
};

export const checkboxStyles = theme => {
  return {
    control: base => ({
      ...base,
      minHeight: 34,
      boxShadow: 'none',
      borderColor: theme.color.gray1,
      '&:hover': {
        borderColor: 'gainsboro',
      },
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: base => ({
      ...base,
      padding: 4,
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: 'transparent',
    }),
    valueContainer: base => ({
      ...base,
      padding: '0px 6px',
      whiteSpace: 'nowrap',
      paddingLeft: 10,
      overflow: 'hidden',
    }),
    input: base => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    option: styles => {
      return {
        ...styles,
        color: 'black',
        backgroundColor: theme.color.white,
        '&:active': {
          // this color is not on the theme
          backgroundColor: '#B2D4FF',
        },
      };
    },
    menuList: styles => {
      return {
        ...styles,
        maxHeight: 'fit-content',
      };
    },
    multiValueLabel: styles => {
      return {
        ...styles,
        width: 0,
        margin: 0,
        backgroundColor: 'transparent',
      };
    },
  };
};

export const SRemovedRemoveLabel = styled.div`
  padding-right: 6px;
`;

export const SSelectAllContainer = styled.div`
  padding: 0px 3px 0px 2px;
  margin: 0px;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 8px 0px 8px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.designSystem.colors.gray[100]};
  cursor: pointer;

  &:active {
    // this color is not on the theme
    background-color: #b2d4ff;
  }
`;

export const SButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  gap: 10px;
  height: 50px;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.designSystem.colors.gray[100]};
  padding-right: 9px;
`;

export const SOptionContainer = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
`;

export const SIconContainer = styled.div<IconProps>`
  display: flex;
  width: 18px;
  height: 18px;
  line-height: 12px;
  vertical-align: middle;
  padding-left: 0.5px;
  background: ${({ theme, isChecked }) =>
    isChecked ? theme.interactive.primary : theme.color.white};
  border-radius: ${({ theme }) => theme.border.width.medium};
  border: ${({ theme, isChecked }) =>
    `${isChecked ? 1 : 2}px solid ${isChecked ? theme.interactive.primary : theme.color.gray3}`};
`;

export const StyledIcon = styled(Icon)`
  width: 14px;
  height: 15px;
  color: ${({ theme }) => `${theme.background.color.white}`};
`;

export const SLabel = styled.div`
  display: flex;
  padding-left: 14px;
`;

export const SInputContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
