import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { AtmOfferingRequestDto } from './types/AtmOffering';

/**
 * Download an xlsx of atm offerings
 */
export const downloadAtmOfferings = makePostDownloadRequest<AtmOfferingRequestDto>(
  apiClient,
  () => `/atms/download`
);

export type DownloadAtmOfferingsResponse = UnpackPromise<ReturnType<typeof downloadAtmOfferings>>;
