import { AccountType } from '@capital-markets-gateway/api-client-identity';

import AccessControl from './auth/components/access-control/AccessControl';
import AuthConsumer from './auth/components/auth-consumer/AuthConsumer';
import AuthProvider, { AuthContext } from './auth/components/auth-provider/AuthProvider';
import useAccessToken from './auth/components/hooks/useAccessToken';
import useAuth from './auth/components/hooks/useAuth';
import useCheckAccountTraits from './auth/components/hooks/useCheckAccountTraits';
import useCheckAccountType from './auth/components/hooks/useCheckAccountType';
import useCheckPermissions from './auth/components/hooks/useCheckPermissions';
import useStoredPreference from './auth/components/hooks/useStoredPreference';
import OIDCLogin from './auth/components/oidc-login/OIDCLogin';
import OIDCLoginCallback from './auth/components/oidc-login-callback/OIDCLoginCallback';
import OIDCLogout from './auth/components/oidc-logout/OIDCLogout';
import OIDCLogoutCallback from './auth/components/oidc-logout-callback/OIDCLogoutCallback';
import PrivateRouteContent from './auth/components/private-route-content/PrivateRouteContent';
import withAccessToken from './auth/components/with-access-token/withAccessToken';
import withAuth from './auth/components/with-auth/withAuth';
import { entitlementsByEntity } from './auth/constants/entitlements';
import { permissionsByEntity } from './auth/constants/permissions';
import { Resource as Entity } from './auth/constants/resources';
import { AccessTokenProp, PublicAuthState } from './auth/types';
import {
  accessTokenExpirationGraphqlInterceptor,
  accessTokenExpirationRequestInterceptor,
  authApiInterceptor,
  getAccessToken,
  getAccessTokenExpiration,
  getAccountTraits,
  getUserAccountType,
  getUserFamilyName,
  getUserFirmId,
  getUserGivenName,
  getUserId,
  getUserPermissions,
  getUserProfile,
  getUserProfileLogContext,
} from './common/authService';
import { checkAccountTraits, checkPermissions } from './common/util/authorization';
import { isSystemSubdomain, systemSubdomain } from './common/util/url';
import * as testUtils from './test';
import { AuthProviderConfig as AuthProviderConfigType } from './types/api/AuthProviderConfig';

/**
 * This export is the Auth library.
 */
export {
  AccessControl,
  accessTokenExpirationGraphqlInterceptor,
  accessTokenExpirationRequestInterceptor,
  AccountType,
  authApiInterceptor,
  AuthConsumer,
  AuthContext,
  AuthProvider,
  checkAccountTraits,
  checkPermissions,
  entitlementsByEntity,
  Entity,
  getAccessToken,
  getAccessTokenExpiration,
  getAccountTraits,
  getUserAccountType,
  getUserFamilyName,
  getUserFirmId,
  getUserGivenName,
  getUserId,
  getUserPermissions,
  getUserProfile,
  getUserProfileLogContext,
  isSystemSubdomain,
  OIDCLogin,
  OIDCLoginCallback,
  OIDCLogout,
  OIDCLogoutCallback,
  permissionsByEntity,
  PrivateRouteContent,
  systemSubdomain,
  testUtils,
  useAccessToken,
  useAuth,
  useCheckAccountTraits,
  useCheckAccountType,
  useCheckPermissions,
  useStoredPreference,
  withAccessToken,
  withAuth,
};
export * from './providers';

export type PublicAuthStateProps = PublicAuthState;
export type AuthProviderConfig = AuthProviderConfigType;
export type AuthAccessTokenProp = AccessTokenProp;
