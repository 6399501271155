import React, { useState } from 'react';

import { ButtonProps } from '../../buttons/button/Button';
import PrimaryButton from '../../buttons/button/PrimaryButton';
import SecondaryButton from '../../buttons/button/SecondaryButton';
import SuccessButton from '../../buttons/button/SuccessButton';
import { Props as IconProps } from '../../graphics/icon/Icon';
import { StyledPopover } from '../dropdown-container/DropdownContainer.styles';
import DropdownItem, { Props as DropdownItemProps } from '../dropdown-item/DropdownItem';
import { SButtonWrapper, SList } from '../dropdown-select/DropdownSelect.styles';

export type Props = {
  dropDownContent?: (close: () => void) => React.ReactNode;
  /** options - optional data structure to render a list of dropdown items */
  options?: DropdownItemProps[];
  /** iconRight - optional function to override arrow down/up icon */
  iconRight?: (isOpen: boolean) => IconProps;
  /** buttonComponent - button variant to be rendered */
  buttonComponent?: typeof PrimaryButton | typeof SecondaryButton | typeof SuccessButton;
  /** popoverVariant LIGHT or DARK mode defaults to DARK */
  popoverVariant?: 'DARK' | 'LIGHT';
  trigger?: 'click' | 'focus' | 'contextMenu';
  /** optional parameters that gets executed when the dropdown is visibility is changed */
  onVisibleChange?: (isOpen: boolean) => void;
} & Pick<ButtonProps, 'loading' | 'disabled' | 'testId' | 'iconLeft' | 'size' | 'withBorder'>;

/**
 * Should be used to allow users to make a selection from a group of options.
 */
const DropdownButton: React.FC<Props> = ({
  options,
  children,
  buttonComponent = SecondaryButton,
  popoverVariant = 'DARK',
  dropDownContent,
  trigger = 'focus',
  onVisibleChange,
  iconRight,
  ...rest
}) => {
  const Button = buttonComponent;
  const [isOpen, setIsOpen] = useState(false);

  const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <StyledPopover
      variant={popoverVariant}
      trigger={trigger}
      onVisibleChange={value => {
        onVisibleChange && onVisibleChange(value);
        setIsOpen(value);
      }}
      visible={isOpen}
      placement="bottomRight"
      content={
        <React.Fragment>
          {options && (
            <SList>
              {options.map((option, index) => (
                <DropdownItem key={index} {...option} />
              ))}
            </SList>
          )}
          {dropDownContent && dropDownContent(close)}
        </React.Fragment>
      }
      hideArrow
    >
      <SButtonWrapper>
        <Button
          {...rest}
          type="button"
          iconRight={
            iconRight ? iconRight(isOpen) : { name: isOpen ? 'chevron-up' : 'chevron-down' }
          }
          onClick={event => {
            // In Firefox and Safari, buttons do not receive focus
            // when they're clicked.  As we're using the "focus"
            // trigger in the Popover containing this node, we
            // need to manually trigger focus so that the Popover appears.
            event.currentTarget.focus();
          }}
        >
          {children}
        </Button>
      </SButtonWrapper>
    </StyledPopover>
  );
};

export default DropdownButton;
