import React from 'react';

import { SDisclaimer } from './Disclaimer.styles';

// Note: Disclaimer text is consumed by both the disclaimer and the no rows overlay for the calendar. Editing the text of the disclaimer here will also edit the text of the disclaimer in the no rows overlay.
const disclaimerText = `The information on this page does not constitute an offer to sell or the solicitation of an offer to buy any security. No securities may be subscribed for or purchased in reliance on the information on this page and any such securities may only be subscribed for or purchased on the basis of information in any applicable prospectus or other offer documentation for such transaction. Each listing on this page is subject to any legends or disclaimers set forth in the prospectus or other offer documentation for such transaction. Neither this information nor any copy of it may be taken, transmitted or distributed, directly or indirectly, in or into or from any jurisdiction where such taking, transmission or distribution would be unlawful, nor provided to any person in a manner that would be unlawful. Any failure to comply with this restriction may constitute a violation of applicable securities laws. For the avoidance of doubt, there shall be no sale of any security to any person or in any state or jurisdiction which would result in such sale, or any offer or solicitation, being unlawful.`;

export const disclaimerAssets = {
  disclaimerText,
};

/**
 * Disclaimer displays on views that contain lists of offerings, for buy-side users only.
 *
 */

export type DisclaimerProps = {
  style?: React.CSSProperties;
};

const Disclaimer: React.FC<DisclaimerProps> = ({ style }) => {
  return <SDisclaimer style={style}>{disclaimerText}</SDisclaimer>;
};

export default Disclaimer;
