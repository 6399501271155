import { createTestId } from '../../../common/selectors';

const create = name => createTestId(`account-api-keys-${name}`);

export const apiKeyCreateScreen = create('create-screen');
export const apiKeyCreateHeader = create('create-header');
export const apiKeyLabel = create('api-key-label');
export const apiKeyCreateSuccessModal = create('create-success-modal');
export const apiKeyModalCloseButton = create('modal-close-button');
export const apiKeyGenerateButton = create('generate-button');
export const apiKeyDetailScreen = create('detail-screen');
export const apiKeyDetailHeader = create('detail-header');
export const apiKeyListScreen = create('list-screen');
export const apiKeyListHeader = create('list-header');
export const apiKeyListCreateAccount = create('list-create-account');
export const apiKeyListLabel = create('list-label');
export const apiKeyRevokeButton = create('revoke-button');
export const apiKeyRevokeConfirmModal = create('revoke-confirm-modal');
export const apiKeyRegenerateModal = create('regenerate-modal');
export const apiKeyRegenerateAccount = create('regenerate-account');
export const apiKeyRegenerateSuccessModal = create('regenerate-success-modal');

// selectors for group level selection for both READ ONLY and FULL access
export const apiKeyAdminGroupAllNoAccess = createTestId('administration-all-no-access');
export const apiKeyAdminGroupAllReadAccess = createTestId('administration-all-read-access');
export const apiKeyAdminGroupAllFullAccess = createTestId('administration-all-full-access');

export const apiKeyOfferingsGroupAllNoAccess = createTestId('offerings-all-no-access');
export const apiKeyOfferingsGroupAllReadAccess = createTestId('offerings-all-read-access');
export const apiKeyOfferingsGroupAllFullAccess = createTestId('offerings-all-full-access');

export const apiKeyDatalabGroupAllNoAccess = createTestId('datalab-all-no-access');
export const apiKeyDatalabGroupAllReadAccess = createTestId('datalab-all-read-access');
export const apiKeyDatalabGroupAllFullAccess = createTestId('datalab-all-full-access');

export const apiKeyOrderBookGroupAllNoAccess = createTestId('order-book-all-no-access');
export const apiKeyOrderBookGroupAllReadAccess = createTestId('order-book-all-read-access');
export const apiKeyOrderBookGroupAllFullAccess = createTestId('order-book-all-full-access');

export const apiKeyRegulatoryFilingsGroupAllNoAccess = createTestId(
  'regulatory-filings-all-no-access'
);
export const apiKeyRegulatoryFilingsGroupAllReadAccess = createTestId(
  'regulatory-filings-all-read-access'
);
export const apiKeyRegulatoryFilingsGroupAllFullAccess = createTestId(
  'regulatory-filings-all-full-access'
);

export const apiKeySyndicateWiresGroupAllNoAccess = createTestId('syndicate-wires-all-no-access');
export const apiKeySyndicateWiresGroupAllReadAccess = createTestId(
  'syndicate-wires-all-read-access'
);
export const apiKeySyndicateWiresGroupAllFullAccess = createTestId(
  'syndicate-wires-all-full-access'
);

export const apiKeyFirmsGroupAllNoAccess = createTestId('firms-all-no-access');
export const apiKeyFirmsGroupAllReadAccess = createTestId('firms-all-read-access');
export const apiKeyFirmsGroupAllFullAccess = createTestId('firms-all-full-access');
