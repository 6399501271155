import styled, { css } from 'styled-components/macro';

import LinkButton from '../../buttons/button/LinkButton';
import TextInput from '../../form/text-input/TextInput';
import Icon from '../../graphics/icon/Icon';

export const SPopoverContent = styled.div`
  max-width: 250px;
`;

export const SList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 380px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    border-radius: ${({ theme }) => theme.border.radius.small};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.border.radius.small};
    background-color: ${({ theme }) => theme.interactive.primary};
  }
`;

export const StyledLinkButton = styled(LinkButton)<{ disabled?: boolean }>`
  position: relative;
  padding: 7px 22px 7px 26px;
  border-top: 1px solid ${({ theme }) => theme.background.color.menu};
  display: flex;
  flex-shrink: 0;
  align-items: center;

  &:hover {
    text-decoration: none;
    background: ${({ theme }) => theme.brand.color.darkAlternate};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;

      &:hover {
        background: none;
      }
    `}

  &:first-of-type {
    border-top: none;
  }
`;

export const StyledInputIcon = styled(Icon)`
  margin: 0 10px;
`;

export const StyledCheckIcon = styled(Icon)`
  position: absolute;
  margin-left: -18px;
  color: ${({ theme }) => theme.interactive.primaryLight};
`;

export const SIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledFilterInput = styled(TextInput)`
  input {
    color: ${({ theme }) => theme.text.color.white};
    background: ${({ theme }) => theme.background.color.menu};
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    margin: 3px 0;
    height: 30px;
  }
`;

export const SButtonWrapper = styled.div`
  display: flex;
`;
