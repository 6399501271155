import cn from 'classnames';
import CleaveClass from 'cleave.js';
import { CleaveOptions } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import isNil from 'lodash/isNil';
import React from 'react';

import { createAgEditor } from '../../lists/data-grid/aggrid/createAgEditor';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import InputWrapper from '../input-wrapper/InputWrapper';
import { FormControlEvents } from '../types';

Cleave.displayName = 'CleaveInput';

type InputWrapperProps = React.ComponentProps<typeof InputWrapper>;

export interface Props
  extends FieldControlProps<number, number | null>,
    FormControlEvents,
    Pick<React.AriaAttributes, 'aria-label'> {
  className?: string;
  placeholder?: string;
  precision?: number;
  digit?: number;
  prefix?: InputWrapperProps['prefix'];
  suffix?: InputWrapperProps['suffix'];
  innerRef?: React.Ref<HTMLInputElement>;
  testId?: string;
  displayPrecision?: boolean;
  displayPrecisionOnBlur?: boolean;
  maxLength?: number;
}

export class NumericInputComponent extends React.Component<Props> {
  static defaultProps = {
    precision: 2,
    digit: 15,
    displayPrecision: false,
    displayPrecisionOnBlur: false,
  };

  cleave: CleaveClass | undefined;
  state: { displayValue: string | number | undefined };
  inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      displayValue: this.formatDisplayValue(props.value),
    };
  }

  handleOnChange = rawValue => {
    const { precision, onChange, value } = this.props;
    const numericValue = parseFloat(rawValue);

    if (rawValue !== '' && Number.isNaN(numericValue)) {
      return;
    }
    if (
      value !== null &&
      value !== undefined &&
      parseFloat(rawValue).toFixed(precision) === value.toFixed(precision)
    ) {
      return;
    }

    const nextValue = rawValue === '' ? null : numericValue;
    onChange && onChange(nextValue);
  };

  handleOnBlur = e => {
    const { onBlur, displayPrecisionOnBlur, value } = this.props;

    if (onBlur) {
      onBlur(e);
    }
    if (displayPrecisionOnBlur) {
      this.setState({ displayValue: this.formatDisplayValue(value) });
    }
  };

  formatDisplayValue = value => {
    const { precision, displayPrecision, displayPrecisionOnBlur } = this.props;

    let displayValue;
    if (value === null) {
      displayValue = undefined;
    } else if (!isNil(value) && precision && (displayPrecision || displayPrecisionOnBlur)) {
      displayValue = Number(value).toFixed(precision);
    } else {
      displayValue = value;
    }
    return displayValue;
  };

  // prevent cursor jump if props value change but raw value is equal (even tho it may be formatted differently)
  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (this.props.hasError !== nextProps.hasError) {
      return true;
    }

    if (this.cleave && this.props.value !== nextProps.value) {
      const rawValue = this.cleave.getRawValue();
      const { precision, value } = nextProps;
      if (
        value !== null &&
        value !== undefined &&
        !Number.isNaN(parseFloat(rawValue)) &&
        parseFloat(rawValue).toFixed(precision) === value.toFixed(precision)
      ) {
        return false;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps: Props) {
    const { onChange, value, displayPrecisionOnBlur } = this.props;

    if (this.cleave && prevProps.value !== value) {
      if (displayPrecisionOnBlur) {
        const { activeElement } = document;

        if (
          activeElement !== null &&
          activeElement !== undefined &&
          this.inputRef.current !== null &&
          this.inputRef.current !== undefined &&
          activeElement === this.inputRef.current
        ) {
          this.setState({ displayValue: value });
        } else {
          this.setState({ displayValue: this.formatDisplayValue(value) });
        }
      }

      const rawValue: string = this.cleave.getRawValue();

      if (rawValue !== '' && (value === undefined || value === null)) {
        // if value resets from outside
        this.cleave.setRawValue('');
      } else if (
        rawValue !== '' &&
        !(
          (prevProps.value === undefined && value === null) ||
          (prevProps.value === null && value === undefined)
        )
      ) {
        onChange && onChange(value === undefined ? null : value);
      }
    }
  }

  render() {
    const {
      className,
      disabled,
      hasError,
      precision,
      digit,
      prefix,
      suffix,
      innerRef,
      value,
      required,
      fullWidth,
      testId,
      displayPrecision,
      displayPrecisionOnBlur,
      ...restProps
    } = this.props;

    const options: CleaveOptions = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: precision,
      numeralIntegerScale: digit,
      rawValueTrimPrefix: true,
      noImmediatePrefix: true,
    };

    let val;

    if (displayPrecisionOnBlur) {
      val = this.state.displayValue;
    } else {
      val = this.formatDisplayValue(value);
    }

    return (
      // Including .numeric-input class for backwards compatibility. Ideally classes do not have a public class to use and instead are supplied a className or wrapped in a styled-component where they are used.
      <InputWrapper
        className={cn('numeric-input', className)}
        hasError={hasError}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        textAlign="right"
      >
        <Cleave
          {...restProps}
          disabled={disabled}
          options={options}
          value={val}
          onBlur={this.handleOnBlur}
          onChange={e => this.handleOnChange(e.target.rawValue)}
          onInit={cleave => (this.cleave = cleave as any)}
          // opened cleave issue: https://github.com/nosir/cleave.js/issues/497
          htmlRef={currentRef => {
            // @ts-ignore current is read-only, but Cleave can't handle direct ref
            this.inputRef.current = currentRef;
            if (innerRef) {
              // @ts-ignore current is read-only, but Cleave can't handle direct ref
              innerRef.current = currentRef;
            }
          }}
          data-test-id={testId}
        />
      </InputWrapper>
    );
  }
}

const NumericInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <NumericInputComponent innerRef={ref} {...props} />;
});
NumericInput.displayName = `NumericInput`;

export default NumericInput;

export const NumericInputField = createFormikField<number, number | null, HTMLInputElement, Props>(
  NumericInput
);

export const NumericAgEditor = createAgEditor(NumericInput);
