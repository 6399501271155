import { autocompleteClasses } from '@mui/material/Autocomplete';
import { chipClasses } from '@mui/material/Chip';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    primary: { main: '#007AC2', light: '#1E97E8', dark: '#00629D', contrastText: '#FFFFFF' },
    // For use on a dark background
    primaryDarkBackground: {
      main: '#48B2FA',
      light: '#BEE5FD',
      dark: '#1E97E8',
      contrastText: '#233249',
    },
    secondary: { main: '#2B3E59', light: '#415D86', dark: '#233249', contrastText: '#FFFFFF' },
    // For use on a dark background
    secondaryDarkBackground: {
      main: 'rgba(255, 255, 255, 0.88)',
      light: 'rgba(255, 255, 255, 0.98)',
      dark: 'rgba(255, 255, 255, 0.6)',
      contrastText: '#233249',
    },
    error: { main: '#DD3730', light: '#FF4E42', dark: '#BA1B1B', contrastText: '#FFFFFF' },
    success: { main: '#008940', light: '#24A557', dark: '#006D31', contrastText: '#FFFFFF' },
    warning: { main: '#F09805', light: '#F8B61B', dark: '#CD7504', contrastText: '#FFFFFF' },
    info: { main: '#374F72', light: '#4F70A1', dark: '#2B3E59', contrastText: '#FFFFFF' },
    action: {
      active: 'rgba(35, 50, 73, 0.54);',
      hover: 'rgba(35, 50, 73, 0.06);',
      selected: 'rgba(35, 50, 73, 0.08);',
      disabled: 'rgba(35, 50, 73, 0.4);',
      disabledBackground: 'rgba(35, 50, 73, 0.12);',
      focus: 'rgba(35, 50, 73, 0.12);',
    },
    text: {
      primary: '#030507;',
      secondary: '#0305078c;',
      disabled: '#03050766;',
    },
    background: { lightGrey: '#F0F0F3', lightBlue: '#E5F0FF' },
    blue: {
      50: '#F5FBFF',
      100: '#E0F3FF',
      200: '#BEE5FD',
      300: '#79C9FC',
      400: '#3EAEF9',
      500: '#1E97E8',
      600: '#007AC2',
      700: '#00629D',
      800: '#004A79',
      900: '#003355',
      950: '#001D34',
    },
    darkBlue: {
      50: '#F5F9FF',
      100: '#E5F0FF',
      200: '#CFDFF7',
      300: '#A3BFEB',
      400: '#89AADC',
      500: '#4F70A1',
      600: '#415C86',
      700: '#374F72',
      800: '#2B3E59',
      900: '#182939',
      950: '#030507',
    },
    neutral: {
      50: '#FCFCFF',
      100: '#F0F0F3',
      200: '#E2E2E5',
      300: '#C6C6C9',
      400: '#ABABAE',
      500: '#909194',
      600: '#5D5E61',
      700: '#76777A',
      800: '#45474A',
      900: '#2F3032',
      950: '#1A1C1E',
    },
    neutralVariant: {
      50: '#FCFCFF',
      100: '#EDF1F9',
      200: '#DEE3EB',
      300: '#C2C7CF',
      400: '#A7ACB4',
      500: '#8C9199',
      600: '#72777F',
      700: '#5A5F66',
      800: '#42474D',
      900: '#2C3137',
      950: '#171C22',
    },
    red: {
      50: '#FFF7F5',
      100: '#FFEDE9',
      200: '#FFDAD4',
      300: '#FFB4A9',
      400: '#FF897A',
      500: '#FF4E42',
      600: '#DD3730',
      700: '#BA1B1B',
      800: '#930006',
      900: '#680003',
      // 950: '#410001',
    },
    green: {
      50: '#F3FCF6',
      100: '#E3F7EA',
      200: '#C2F0CE',
      300: '#88DDA0',
      400: '#47C16F',
      500: '#24AB59',
      600: '#008940',
      700: '#006D31',
      800: '#005223',
      900: '#003916',
      950: '#00210A',
    },
    amber: {
      50: '#FFFDF5',
      100: '#FFF8E1',
      200: '#FFEAA8',
      300: '#F9D053',
      400: '#F8B61B',
      500: '#F09805',
      600: '#CD7504',
      700: '#AF5C04',
      800: '#914B00',
      900: '#6B3700',
      950: '#3D2000',
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontSize: '1.714rem', // '24px'
      lineHeight: 1.166, // '28px'
      letterSpacing: '0.00833em', // '0.2px'
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    h2: {
      fontSize: '1.429rem', // '20px'
      lineHeight: 1, // '20px'
      letterSpacing: '0.01em', // '0.2px'
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    h3: {
      fontSize: '1.143rem', // '16px'
      lineHeight: 1.125,
      letterSpacing: '0.0125em', // '0.2px',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    h4: {
      fontSize: '1rem', // '14px'
      lineHeight: 1, // '14px'
      letterSpacing: '0.03571em', // '0.5px'
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    body1: {
      fontSize: '1rem', // '14px'
      lineHeight: 1.286, // '18px'
      letterSpacing: '0em', // '0px'
      fontWeight: 400,
      textTransform: 'none',
    },
    body2: {
      fontSize: '0.857rem', // '12px'
      lineHeight: 1.333, // '16px'
      letterSpacing: '0.0125em', // '0.15px'
      fontWeight: 400,
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: '1rem', // '14px'
      lineHeight: 1.179, // '16.5px'
      letterSpacing: '0.01071em', // '0.15px',
      fontWeight: 700,
      textTransform: 'none',
    },
    subtitle2: {
      fontSize: '0.857rem', // '12px'
      lineHeight: 1.208, // '14.5px'
      letterSpacing: '0.0125em', // '0.15px'
      fontWeight: 700,
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.714rem', // '10px'
      lineHeight: 1.25, // '12.5px'
      letterSpacing: '0.03em', // '0.3px'
      fontWeight: 500,
      textTransform: 'none',
    },
    button: {
      fontSize: '1rem', // '14px'
      lineHeight: 1.714, // '24px'
      letterSpacing: '0.02143em', // '0.3px'
      textTransform: 'capitalize',
    },
    highlight1: {
      fontSize: '1rem', // '14px'
      lineHeight: 1.286, // '18px'
      letterSpacing: '0em', // '0px'
      fontWeight: 500,
      textTransform: 'none',
    },
    highlight2: {
      fontSize: '0.857rem', // '12px'
      lineHeight: 1.333, // '16px'
      letterSpacing: '0.0125em', // '0.15px'
      fontWeight: 500,
      textTransform: 'none',
    },
    specialLabel: {
      fontSize: '0.714rem', // '10px'
      lineHeight: 1, // '10px'
      letterSpacing: '0.05em', // '0.5px'
      fontWeight: 500,
      textTransform: 'uppercase',
      fontStyle: 'inherit',
    },
    tableHeader: {
      fontSize: '0.857rem', // '12px'
      lineHeight: 1.166, // '14px'
      letterSpacing: '0.04167em', // '0.5px'
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    listSubheader: {
      fontSize: '1rem', // '14px'
      lineHeight: 1.286, // '18px'
      letterSpacing: '0.0357em', // '0.5px'
      fontWeight: 500,
      textTransform: 'capitalize',
    },
    tooltip: {
      fontSize: '0.714rem', // '10px'
      lineHeight: 1.4, // '14px'
      letterSpacing: '0em', // '0px'
      fontWeight: 500,
    },
    hint: {
      textDecoration: 'underline',
      textDecorationStyle: 'dotted',
    },
  },
  zIndex: {
    /**
     * Default value is 1100 but that collides with our existing overlays which should be
     * always top most. See common-theme.ts (modal=1050, popover=1055, screenoverlay=1056)
     * */
    appBar: 1049,
  },
  components: {
    MuiAlert: {
      defaultProps: {
        severity: 'info',
      },
      styleOverrides: {
        message: {
          alignSelf: 'center',
        },
        action: {
          flexShrink: 0,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
      },
      variants: [
        {
          props: { size: 'extraSmall' },
          style: ({ theme }) => ({
            [`.${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
              ...theme.typography.body2,
              padding: `${theme.spacing(0.9225)} ${theme.spacing(1)}`,

              [`.${autocompleteClasses.input}`]: {
                padding: 0,
              },
            },
            [`.${chipClasses.deleteIcon}`]: {
              fontSize: theme.spacing(2),
            },
            [`.${autocompleteClasses.tag}`]: {
              ...theme.typography.body2,
              height: theme.spacing(2),
              marginTop: 0,
              marginBottom: 0,
            },
          }),
        },
      ],
    },
    MuiBadge: {
      defaultProps: {
        color: 'primary',
        variant: 'standard',
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        itemsAfterCollapse: 2,
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disabled: false,
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'outlined',
        disableElevation: true,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h2' },
        subheaderTypographyProps: { variant: 'body1' },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        variant: 'indeterminate',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '14px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }),
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        variant: 'body1',
        color: 'inherit',
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        variant: 'indeterminate',
      },
    },
    MuiLink: {
      defaultProps: {
        variant: 'body1',
        underline: 'hover',
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.listSubheader,
          color: theme.palette.text.primary,
          padding: '8px 16px',
        }),
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        /**
         * To perform the server-side hydration, the hook needs to render twice which has a performance cost.
         * As we use client-side rendering only we can disable it by default.
         * Docs: https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering
         */
        noSsr: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.body1,
        }),
        head: ({ theme }) => ({
          ...theme.typography.tableHeader,
          '& .MuiTableCell-head': {
            position: 'relative',
          },
          '& .MuiTableCell-stickyHeader': {
            position: 'sticky',
          },
        }),
      },
    },
    MuiTableCell: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        body: ({ theme }) => ({
          ...theme.typography.body1,
        }),
        head: ({ theme }) => ({
          ...theme.typography.tableHeader,
          color: theme.palette.text.secondary,
          '&:after': {
            position: 'absolute',
            right: 0,
            top: '6px',
            bottom: '6px',
            content: '""',
            width: '2px',
            background: 'rgba(35, 50, 73, 0.12)',
          },
        }),
        sizeSmall: {
          padding: '6px 8px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
        fullWidth: true,
        size: 'small',
        InputLabelProps: { disableAnimation: true, shrink: true },
      },
      variants: [
        {
          props: { size: 'extraSmall' },
          style: ({ theme }) => ({
            [`.${inputBaseClasses.input}`]: {
              ...theme.typography.body2,
              padding: `${theme.spacing(0.9225)} ${theme.spacing(1)}`,
            },
          }),
        },
      ],
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('xs')]: {
            minHeight: '64px',
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme, ownerState }) => {
          const { variant } = ownerState.slotProps?.tooltip ?? {};

          if (variant) {
            return {
              ...theme.typography.tooltip,
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[1],
              color: theme.palette.text.primary,
              borderLeft: `${theme.spacing(0.5)} solid ${theme.palette[variant].main}`,
            };
          }

          return {
            backgroundColor: theme.palette.info.main,
            ...theme.typography.tooltip,
            [`button, a`]: {
              color: theme.palette.primaryDarkBackground.main,
            },
            [`th`]: {
              // TODO: set bottom border color to Light/SecondaryDarkBackground/Shades/50p when available
              color: theme.palette.secondaryDarkBackground.main,
              backgroundColor: theme.palette.info.main,
            },
            [`td`]: {
              borderBottom: 'none',
              color: theme.palette.secondaryDarkBackground.main,
            },
          };
        },
        arrow: ({ theme }) => ({
          color: theme.palette.info.main,
        }),
      },
    },
    AppBarLogo: {
      styleOverrides: {
        root: {
          width: '96px',
          height: '42px',
        },
        img: {
          width: '96px',
          height: '42px',
        },
      },
    },
    AppBarSearchTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondaryDarkBackground.dark,
          [`.${inputBaseClasses.root}`]: {
            color: theme.palette.secondaryDarkBackground.dark,
          },
          [`.${inputBaseClasses.focused}`]: {
            color: theme.palette.secondaryDarkBackground.main,
          },
          [`& label`]: {
            color: theme.palette.secondaryDarkBackground.dark,
          },
          [`& label.Mui-focused`]: {
            color: theme.palette.secondaryDarkBackground.main,
          },
          [`& .MuiOutlinedInput-root`]: {
            '& fieldset': {
              borderColor: theme.palette.secondaryDarkBackground.dark,
            },
            '&:hover fieldset': {
              borderColor: theme.palette.secondaryDarkBackground.dark,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.secondaryDarkBackground.main,
            },
          },
          '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:active':
            {
              WebkitTextFillColor: `${theme.palette.secondaryDarkBackground.dark}`,
              WebkitBoxShadow: `0 0 0 50px ${theme.palette.secondary.main} inset`,
            },
          '& input:-webkit-autofill:focus': {
            WebkitTextFillColor: `${theme.palette.secondaryDarkBackground.main}`,
            WebkitBoxShadow: `0 0 0 50px ${theme.palette.secondary.main} inset`,
          },
        }),
        adornment: ({ theme }) => ({
          color: theme.palette.secondaryDarkBackground.main,
        }),
      },
    },
    DomainNav: {
      styleOverrides: {
        root: {
          height: '44px',
          minHeight: '44px',
        },
      },
    },
    DomainObject: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: 'sticky',
          top: '0px',
          right: '0px',
          left: '0px',
          zIndex: theme.zIndex.appBar,
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    HighlightsItem: {
      styleOverrides: {
        name: ({ theme }) => ({
          ...theme.typography.specialLabel,
          color: theme.palette.text.secondary,
        }),
        value: ({ theme }) => ({
          ...theme.typography.highlight2,
          color: theme.palette.text.primary,
          '& > .MuiTypography-root': {
            ...theme.typography.highlight2,
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    LoadingButton: {
      defaultProps: {
        contentProps: {
          direction: 'row',
          alignItems: 'center',
          gap: 1,
        },
        circularProgressProps: {
          size: 16,
        },
      },
    },
    TableGroupHeader: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.grey[100],
        }),
      },
    },
    TableGroupHeaderCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.highlight1,
        }),
      },
    },
    TableRow: {
      styleOverrides: {
        disabled: ({ theme }) => ({
          color: theme.palette.text.disabled,
          'th, td': {
            color: theme.palette.text.disabled,
          },
        }),
      },
    },
    AppLayout: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    BaseAlert: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    ActionPanelMenuLayout: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.grey[100],
          height: '100%',
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected .MuiListItemIcon-root': {
            color: theme.palette.action.active,
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          hint: 'p',
        },
      },
    },
  },
});
