import { useIdleMonitor } from '@cmg/idle-monitor';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUserProfileLogContext } from '../../../common/authService';
import { oidcLogout, selectIdleSettings, startAuthMonitor, stopAuthMonitor } from '../../ducks';
import AccessControl from '../access-control/AccessControl';
import { AuthContext, useSelector } from '../auth-provider/AuthProvider';
import RouteLifecycleTracker from './RouteLifecycleTracker';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      startAuthMonitor,
      stopAuthMonitor,
      oidcLogout,
    },
    dispatch
  ),
});

type OwnProps = {
  accessCheck?: (userPermissions: string[]) => boolean;
  /** requiredPermissions required permissions for the content to display */
  requiredPermissions?: string[];
  /** requireAllPermissions when true, requires the user to have all permissions in 'requiredPermissions' */
  requireAllPermissions?: boolean;
  /** renderNoAccess function that will be invoked when requiredPermissions fails or if the use is not logged in */
  renderNoAccess: (isLoggedIn: boolean) => React.ReactNode;
  /** children private route content */
  children: React.ReactNode;
};
export type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

/**
 * PrivateRouteContent is a wrapper around component content that should remain private.
 * It uses AccessControl to check if a user is logged in and has valid permissions to view the content.
 * If access is not permitted renderNoAccess will be invoked - In this case the consumer will typically invoke
 * a redirect to the use back into the /login route.
 *
 * When content is mounted RouteLifecycleTracker invokes the auth and idle monitors, when the route is changed
 * this component unmounts and the auth and idle monitors stop.
 */
export const PrivateRouteContentComponent: React.FC<Props> = ({
  children,
  actions,
  renderNoAccess,
  requireAllPermissions = true,
  requiredPermissions = [],
  accessCheck,
}) => {
  const { idleTimeout, rootDomain, secureCookies } = useSelector(selectIdleSettings);
  useIdleMonitor({
    idleTimeout,
    rootDomain,
    secureCookies,
    onIdle: actions.oidcLogout,
    getUserContext: getUserProfileLogContext,
  });

  return (
    <AccessControl
      accessCheck={accessCheck}
      renderNoAccess={renderNoAccess}
      requireAllPermissions={requireAllPermissions}
      requiredPermissions={requiredPermissions}
    >
      <RouteLifecycleTracker
        onRouteEntered={() => {
          actions.startAuthMonitor();
        }}
      >
        <React.Fragment>{children}</React.Fragment>
      </RouteLifecycleTracker>
    </AccessControl>
  );
};

const PrivateRouteContent = connect(null, mapDispatchToProps, undefined, { context: AuthContext })(
  PrivateRouteContentComponent
);

export default PrivateRouteContent;
