import { toDate, zonedTimeToUtc } from 'date-fns-tz';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import { ISODate } from '../../../types/common';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import InputWrapper from '../input-wrapper/InputWrapper';
import TimeZonePicker from '../time-zone-picker/TimeZonePicker';
import { FormControlEvents } from '../types';
import { SWrapper } from './DatePicker.styles';
import { DateTimeValue, getTimezone, parseDateTime } from './DateTimePicker.model';
import { SDateTimePicker } from './DateTimePicker.styles';

function selectStyledConfig() {
  return {
    control: () => ({
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    }),
  };
}

export interface Props
  extends FieldControlProps<DateTimeValue | null, DateTimeValue>,
    FormControlEvents {
  defaultTimezone?: string;
  placeholder?: string;
  showTimezones?: boolean;
  maxDate?: ISODate;
  minDate?: ISODate;
  onCalendarClose?: () => void;
  testId?: string;
}
class DateTimePicker extends React.Component<Props> {
  handleChangeDate = (date: Date | null) => {
    const { defaultTimezone = 'America/New_York', value, onChange } = this.props;

    if (!date) {
      return onChange?.({ date: null, timezone: null });
    }

    const timezone = getTimezone(value, defaultTimezone);
    const isoDateTime = zonedTimeToUtc(date, timezone).toISOString();

    onChange?.({ date: isoDateTime, timezone });
  };

  handleChangeTimezone = (timezone: string | null) => {
    const { defaultTimezone = 'America/New_York', value, onChange } = this.props;
    const currentTimezone = getTimezone(value, defaultTimezone);
    const nextTimezone = timezone || currentTimezone;

    const date = parseDateTime(value, defaultTimezone);

    if (!date || !value || !value.date) {
      onChange?.({ date: null, timezone: nextTimezone });
    } else {
      const utcDate = zonedTimeToUtc(date, nextTimezone);
      const isoDateTime = utcDate.toISOString();
      onChange?.({ date: isoDateTime, timezone: nextTimezone });
    }
  };

  render() {
    const {
      defaultTimezone = 'America/New_York',
      disabled = false,
      hasError,
      placeholder = 'MM/DD/YYYY HH:MM',
      showTimezones = false,
      value,
      minDate,
      maxDate,
      onChange,
      onCalendarClose,
      testId,
      required,
      fullWidth,
      ...restProps
    } = this.props;

    // min and max dates parsed to a date
    const minMaxProps = {
      ...(minDate ? { minDate: toDate(minDate) } : {}),
      ...(maxDate ? { maxDate: toDate(maxDate) } : {}),
    };

    return (
      <SDateTimePicker>
        <InputWrapper disabled={disabled} hasError={hasError}>
          <SWrapper data-test-id={testId}>
            <ReactDatePicker
              {...restProps}
              {...minMaxProps}
              timeFormat="HH:mm"
              timeCaption="Time"
              disabled={disabled}
              disabledKeyboardNavigation
              dropdownMode="scroll"
              isClearable={!disabled}
              placeholderText={placeholder}
              selected={parseDateTime(value, defaultTimezone)}
              dateFormat="MM/dd/yyyy hh:mm a"
              showTimeSelect
              timeIntervals={15}
              onChange={this.handleChangeDate}
              onCalendarClose={onCalendarClose}
            />
          </SWrapper>
        </InputWrapper>

        <TimeZonePicker
          value={value?.timezone}
          date={value?.date}
          disabled={!showTimezones || disabled}
          isClearable={!showTimezones}
          styledConfig={selectStyledConfig()}
          onChange={showTimezones ? this.handleChangeTimezone : undefined}
        />
      </SDateTimePicker>
    );
  }
}

export default DateTimePicker;

/**
 * @todo: figure out how to set field touched here or in createFormikForm when day is selected
 * onBlur is not called when a day is selected
 * when validateOnChange: true & validateOnBlur: false in a form
 * use onCalendarClose callback to setFieldTouched to show error messages
 */
export const DateTimePickerField = createFormikField<
  DateTimeValue | null,
  DateTimeValue,
  HTMLInputElement,
  Props
>(DateTimePicker);
