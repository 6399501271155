import React from 'react';

import { StyledLinkButton } from '../dropdown-select/DropdownSelect.styles';

export type Props =
  | {
      label: React.ReactNode;
      disabled?: boolean;
      testId?: string;
      onClick?: never;
      href?: HTMLAnchorElement['href'];
      target?: string;
    }
  | {
      label: React.ReactNode;
      disabled?: boolean;
      testId?: string;
      onClick?: React.MouseEventHandler<HTMLButtonElement>;
      href?: never;
      target?: never;
    };

const DropdownItem: React.FC<Props> = ({ label, ...rest }) => (
  <StyledLinkButton {...rest}>{typeof label === 'function' ? label() : label}</StyledLinkButton>
);

export default DropdownItem;
