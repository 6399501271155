/**
 * Light svg icons package
 */
export { faAddressBook } from '@fortawesome/pro-light-svg-icons/faAddressBook';
export { faAngleDoubleLeft } from '@fortawesome/pro-light-svg-icons/faAngleDoubleLeft';
export { faAngleDoubleRight } from '@fortawesome/pro-light-svg-icons/faAngleDoubleRight';
export { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
export { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
export { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
export { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
export { faArrowAltCircleDown } from '@fortawesome/pro-light-svg-icons/faArrowAltCircleDown';
export { faArrowAltCircleUp } from '@fortawesome/pro-light-svg-icons/faArrowAltCircleUp';
export { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown';
export { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
export { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
export { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp';
export { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
export { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
export { faBed } from '@fortawesome/pro-light-svg-icons/faBed';
export { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
export { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
export { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
export { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
export { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
export { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck';
export { faCalendarPlus } from '@fortawesome/pro-light-svg-icons/faCalendarPlus';
export { faCar } from '@fortawesome/pro-light-svg-icons/faCar';
export { faCaretDown } from '@fortawesome/pro-light-svg-icons/faCaretDown';
export { faCaretRight } from '@fortawesome/pro-light-svg-icons/faCaretRight';
export { faCaretUp } from '@fortawesome/pro-light-svg-icons/faCaretUp';
export { faChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar';
export { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
export { faChartNetwork } from '@fortawesome/pro-light-svg-icons/faChartNetwork';
export { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
export { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
export { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
export { faCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare';
export { faChevronDoubleLeft } from '@fortawesome/pro-light-svg-icons/faChevronDoubleLeft';
export { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons/faChevronDoubleRight';
export { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
export { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
export { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
export { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
export { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
export { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
export { faClone } from '@fortawesome/pro-light-svg-icons/faClone';
export { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
export { faCoffee } from '@fortawesome/pro-light-svg-icons/faCoffee';
export { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
export { faCogs } from '@fortawesome/pro-light-svg-icons/faCogs';
export { faColumns } from '@fortawesome/pro-light-svg-icons/faColumns';
export { faCommentAltLines } from '@fortawesome/pro-light-svg-icons/faCommentAltLines';
export { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
export { faCubes } from '@fortawesome/pro-light-svg-icons/faCubes';
export { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
export { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
export { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
export { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
export { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
export { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
export { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons/faExternalLinkAlt';
export { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
export { faFileChartLine } from '@fortawesome/pro-light-svg-icons/faFileChartLine';
export { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
export { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
export { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
export { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
export { faFlag } from '@fortawesome/pro-light-svg-icons/faFlag';
export { faGift } from '@fortawesome/pro-light-svg-icons/faGift';
export { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
export { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
export { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
export { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
export { faLaptopCode } from '@fortawesome/pro-light-svg-icons/faLaptopCode';
export { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
export { faListAlt } from '@fortawesome/pro-light-svg-icons/faListAlt';
export { faListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck';
export { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
export { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
export { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
export { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
export { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
export { faMinusSquare } from '@fortawesome/pro-light-svg-icons/faMinusSquare';
export { faMobileAlt } from '@fortawesome/pro-light-svg-icons/faMobileAlt';
export { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
export { faPencilAlt } from '@fortawesome/pro-light-svg-icons/faPencilAlt';
export { faPhoneVolume } from '@fortawesome/pro-light-svg-icons/faPhoneVolume';
export { faPlane } from '@fortawesome/pro-light-svg-icons/faPlane';
export { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
export { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
export { faPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare';
export { faPollH } from '@fortawesome/pro-light-svg-icons/faPollH';
export { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
export { faSackDollar } from '@fortawesome/pro-light-svg-icons/faSackDollar';
export { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
export { faShareAlt } from '@fortawesome/pro-light-svg-icons/faShareAlt';
export { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
export { faSortAmountDown } from '@fortawesome/pro-light-svg-icons/faSortAmountDown';
export { faSortAmountUp } from '@fortawesome/pro-light-svg-icons/faSortAmountUp';
export { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
export { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
export { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
export { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
export { faStickyNote } from '@fortawesome/pro-light-svg-icons/faStickyNote';
export { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
export { faTachometerFast } from '@fortawesome/pro-light-svg-icons/faTachometerFast';
export { faThLarge } from '@fortawesome/pro-light-svg-icons/faThLarge';
export { faThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown';
export { faThumbtack } from '@fortawesome/pro-light-svg-icons/faThumbtack';
export { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
export { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
export { faToggleOff } from '@fortawesome/pro-light-svg-icons/faToggleOff';
export { faToggleOn } from '@fortawesome/pro-light-svg-icons/faToggleOn';
export { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
export { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
export { faUnlink } from '@fortawesome/pro-light-svg-icons/faUnlink';
export { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
export { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
export { faUserEdit } from '@fortawesome/pro-light-svg-icons/faUserEdit';
export { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
export { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
export { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie';

export enum LightIconName {
  'star' = 'faStar',
  'share-alt' = 'faShareAlt',
  'spinner' = 'faSpinner',
  'spinner-third' = 'faSpinnerThird',
  'handshake' = 'faHandshake',
  'flag' = 'faFlag',
  'thumbs-down' = 'faThumbsDown',
  'list-alt' = 'faListAlt',
  'chart-bar' = 'faChartBar',
  'building' = 'faBuilding',
  'trash-alt' = 'faTrashAlt',
  'times-circle' = 'faTimesCircle',
  'check-circle' = 'faCheckCircle',
  'square' = 'faSquare',
  'check-square' = 'faCheckSquare',
  'file-excel' = 'faFileExcel',
  'user' = 'faUser',
  'calendar' = 'faCalendar',
  'envelope' = 'faEnvelope',
  'user-circle' = 'faUserCircle',
  'edit' = 'faEdit',
  'paper-plane' = 'faPaperPlane',
  'clone' = 'faClone',
  'address-book' = 'faAddressBook',
  'calendar-check' = 'faCalendarCheck',
  'calendar-plus' = 'faCalendarPlus',
  'arrow-alt-circle-up' = 'faArrowAltCircleUp',
  'arrow-alt-circle-down' = 'faArrowAltCircleDown',
  'plus-square' = 'faPlusSquare',
  'minus-square' = 'faMinusSquare',
  'minus' = 'faMinus',
  'question-circle' = 'faQuestionCircle',
  'trash' = 'faTrash',
  'info-circle' = 'faInfoCircle',
  'angle-double-left' = 'faAngleDoubleLeft',
  'angle-double-right' = 'faAngleDoubleRight',
  'angle-left' = 'faAngleLeft',
  'angle-right' = 'faAngleRight',
  'angle-down' = 'faAngleDown',
  'angle-up' = 'faAngleUp',
  'chevron-double-left' = 'faChevronDoubleLeft',
  'chevron-double-right' = 'faChevronDoubleRight',
  'chevron-left' = 'faChevronLeft',
  'chevron-right' = 'faChevronRight',
  'chevron-down' = 'faChevronDown',
  'chevron-up' = 'faChevronUp',
  'plus' = 'faPlus',
  'pencil-alt' = 'faPencilAlt',
  'times' = 'faTimes',
  'check' = 'faCheck',
  'cog' = 'faCog',
  'cogs' = 'faCogs',
  'sliders-h' = 'faSlidersH',
  'users' = 'faUsers',
  'cubes' = 'faCubes',
  'thumbtack' = 'faThumbtack',
  'search' = 'faSearch',
  'key' = 'faKey',
  'lock' = 'faLock',
  'lock-alt' = 'faLockAlt',
  'ban' = 'faBan',
  'mobile-alt' = 'faMobileAlt',
  'plus-circle' = 'faPlusCircle',
  'download' = 'faDownload',
  'exclamation-triangle' = 'faExclamationTriangle',
  'exclamation-circle' = 'faExclamationCircle',
  'ellipsis-v' = 'faEllipsisV',
  'briefcase' = 'faBriefcase',
  'external-link-alt' = 'faExternalLinkAlt',
  'gift' = 'faGift',
  'toggle-off' = 'faToggleOff',
  'toggle-on' = 'faToggleOn',
  'sort-amount-up' = 'faSortAmountUp',
  'sort-amount-down' = 'faSortAmountDown',
  'th-large' = 'faThLarge',
  'coffee' = 'faCoffee',
  'minus-circle' = 'faMinusCircle',
  'chart-pie' = 'faChartPie',
  'filter' = 'faFilter',
  'chart-line' = 'faChartLine',
  'file-chart-line' = 'faFileChartLine',
  'car' = 'faCar',
  'bed' = 'faBed',
  'plane' = 'faPlane',
  'phone-volume' = 'faPhoneVolume',
  'circle' = 'faCircle',
  'arrow-up' = 'faArrowUp',
  'arrow-down' = 'faArrowDown',
  'arrow-left' = 'faArrowLeft',
  'arrow-right' = 'faArrowRight',
  'caret-right' = 'faCaretRight',
  'caret-down' = 'faCaretDown',
  'caret-up' = 'faCaretUp',
  'bars' = 'faBars',
  'bell' = 'faBell',
  'file-invoice-dollar' = 'faFileInvoiceDollar',
  'sticky-note' = 'faStickyNote',
  'chart-network' = 'faChartNetwork',
  'sack-dollar' = 'faSackDollar',
  'link' = 'faLink',
  'unlink' = 'faUnlink',
  'laptop-code' = 'faLaptopCode',
  'copy' = 'faCopy',
  'tachometer-fast' = 'faTachometerFast',
  'code' = 'faCode',
  'calendar-alt' = 'faCalendarAlt',
  'file-alt' = 'faFileAlt',
  'comment-alt-lines' = 'faCommentAltLines',
  'map-marker-alt' = 'faMapMarkerAlt',
  'user-edit' = 'faUserEdit',
  'user-friends' = 'faUserFriends',
  'file-export' = 'faFileExport',
  'columns' = 'faColumns',
  'user-tie' = 'faUserTie',
  'poll-h' = 'faPollH',
  'history' = 'faHistory',
  'sync' = 'faSync',
  'clock' = 'faClock',
  'list-check' = 'faListCheck',
}
