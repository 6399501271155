import React from 'react';

import * as logger from '../../../util/logger/logger';
import CMGLogo from '../../graphics/cmg-logo/CMGLogo';
import { SContent, SHeading, SLogoHolder, SWrapper } from './ErrorBoundary.styles';

export default class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  state = { hasError: false };

  componentDidCatch(error: Error /* , info: React.ErrorInfo*/) {
    // Display fallback UI
    this.setState({ hasError: true });

    // log the error because this error boundary swallows it otherwise
    logger.error(error);
  }

  render() {
    if (this.state.hasError) {
      // Render CMG Logo that links to root, refresh link, and error report send button
      return (
        <SWrapper>
          <SContent>
            <SLogoHolder>
              <CMGLogo />
            </SLogoHolder>
            <SHeading>Something went wrong... Please refresh to try again.</SHeading>
          </SContent>
        </SWrapper>
      );
    }
    return this.props.children;
  }
}
