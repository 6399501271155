import AntTreeSelect from 'antd/lib/tree-select';
import memoize from 'memoize-one';
import React from 'react';

import { createFormikField, FieldControlProps } from '../../form/formik/createFormikField';
import { STreeWrapper } from './TreeSelect.styles';

const getPopupContainer = node => node.parentNode;
const transformSectorsToTreeData = memoize(sectors =>
  sectors.map(sector => ({
    ...sector,
    children: sector.children
      ? sector.children.map(subSector => ({
          ...subSector,
        }))
      : [],
  }))
);

export interface Props extends FieldControlProps<any, any> {
  className?: string;
  maxTagCount?: number;
  treeData: any[];
  treeCheckable?: boolean;
  placeholder?: string;
  testId?: string;
}

export type PropsFC = {
  className?: string;
  maxTagCount?: number;
  treeData: any[];
  treeCheckable?: boolean;
  placeholder?: string;
  testId?: string;
};

export default class TreeSelect extends React.Component<Props> {
  // This component must remain class-based. The underlying ant library
  // for this component relies on internal refs, which fail when applied
  // to functional components. Saving the library to our local instance of
  // xc is possible, but it would require a rewrite of the tree select
  // component to use a forwarded ref or the create ref hook at high engineering
  // cost, with an end result of reproducing the functionality that we
  // already have. It would also introduce a second source of truth for
  // this and other ant components, and could result in additional human error.
  static defaultProps = {
    maxTagCount: 3,
    treeCheckable: true,
  };

  render() {
    const {
      disabled,
      value,
      treeData,
      onChange,
      maxTagCount,
      className,
      treeCheckable,
      placeholder = 'Search...',
      testId,
    } = this.props;

    const data = transformSectorsToTreeData(treeData);

    return (
      <STreeWrapper className={className} data-testid={testId}>
        <AntTreeSelect
          disabled={disabled}
          value={value}
          treeData={data}
          onChange={onChange}
          maxTagCount={maxTagCount}
          treeCheckable={treeCheckable}
          showCheckedStrategy={AntTreeSelect.SHOW_PARENT}
          treeNodeFilterProp="title"
          placeholder={placeholder}
          getPopupContainer={getPopupContainer}
          allowClear
          listHeight={400}
        />
      </STreeWrapper>
    );
  }
}

export const TreeSelectField = createFormikField<any, any, HTMLInputElement, Props>(TreeSelect);
