import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';

import {
  oidcLogout,
  selectIsLoggedIn,
  selectIsSystemUser,
  selectOidcUserAccountTraits,
  selectOidcUserAccountType,
  selectOidcUserCmgEntityKey,
  selectOidcUserEmail,
  selectOidcUserFamilyName,
  selectOidcUserFullName,
  selectOidcUserGivenName,
  selectOidcUserId,
  selectOidcUserIdp,
  selectProspectusContactMandatory,
  selectUserFirmId,
  selectUserPermissions,
} from '../../ducks';
import { PublicAuthState } from '../../types';
import { useDispatch, useSelector } from '../auth-provider/AuthProvider';

/**
 * useAuth custom hook selects oidcUser information and actions
 * should be used in a functional component when the consumer needs access to these values/actions
 */
function useAuth() {
  const selectedState = useSelector(
    state => ({
      oidcUserId: selectOidcUserId(state),
      oidcUserAccountTraits: selectOidcUserAccountTraits(state),
      oidcUserAccountType: selectOidcUserAccountType(state),
      oidcUserGivenName: selectOidcUserGivenName(state),
      oidcUserIdp: selectOidcUserIdp(state),
      oidcUserFamilyName: selectOidcUserFamilyName(state),
      oidcUserFullName: selectOidcUserFullName(state),
      oidcUserFirmId: selectUserFirmId(state),
      oidcUserEmail: selectOidcUserEmail(state),
      oidcUserCmgEntityKey: selectOidcUserCmgEntityKey(state),
      userPermissions: selectUserPermissions(state),
      isLoggedIn: selectIsLoggedIn(state),
      isSystemUser: selectIsSystemUser(state),
      prospectusContactMandatory: selectProspectusContactMandatory(state),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const authActions = useMemo(
    () => ({
      oidcLogout: () => dispatch(oidcLogout()),
    }),
    [dispatch]
  );

  return { ...selectedState, authActions } as PublicAuthState;
}

export default useAuth;
