import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makeGetRequest, makePostRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { Firm, FirmListDto, FirmListQuery } from './types/Firm';

export const fetchFirmsByIds = makePostRequest<string[], Firm[]>(
  apiClient,
  () => '/firms/fetchMany'
);
export type FetchFirmsByIdsResponse = UnpackPromise<ReturnType<typeof fetchFirmsByIds>>;

export const fetchFirms = makeGetRequest<FirmListDto, FirmListQuery>(apiClient, () => '/firms');
export type FetchFirmsResponse = UnpackPromise<ReturnType<typeof fetchFirms>>;
