import { makeEntity } from '../../../utils/mocks';
import { CalendarOffering, CalendarOfferingManager } from '../types/CalendarOffering';

export const makeCalendarOfferingManager = makeEntity<CalendarOfferingManager>({
  firmId: '4957',
  firmName: 'Tudor, Pickering, Holt \u0026 Co.',
  role: 'CO_MANAGER',
  economicsPercent: null,
});

export const makeCalendarOffering = makeEntity<CalendarOffering>({
  id: 'offering ID',
  type: 'IPO',
  sectorCode: 'sector ID',
  marketCap: 100200300.44,
  issuerFirmName: 'AFG Holdings, Inc.',
  issuerFirmId: '4175',
  leftLeadFirmName: 'Goldman, Sachs \u0026 Co.',
  leftLeadFirmId: '5243',
  ticker: 'AFGL',
  symbol: 'AFGLS',
  sector: 'ENERGY',
  customSectorId: 'mySectorId',
  customSectorName: 'Some my sector',
  status: 'POSTPONED',
  expectedPricingDate: '2018-07-18',
  pricingDate: '2018-07-18',
  publicFilingDate: '2018-06-01',
  priceRangeLow: 15.0,
  priceRangeHigh: 18.0,
  offerPrice: 16.5,
  discountToLast: 1.3,
  sizeInDollars: 300300000.0,
  sizeInShares: 18200000,
  lockUpExpirationDate: '2018-10-12',
  postponedDate: '2018-07-18',
  firstTradeDate: '2018-07-19',
  launchDate: '2018-07-09',
  managers: [
    {
      firmId: '5243',
      firmName: 'Goldman, Sachs \u0026 Co.',
      role: 'ACTIVE_BOOKRUNNER',
      economicsPercent: null,
    },
    {
      firmId: '4801',
      firmName: 'Credit Suisse',
      role: 'ACTIVE_BOOKRUNNER',
      economicsPercent: null,
    },
    {
      firmId: '4812',
      firmName: 'Piper Jaffray',
      role: 'ACTIVE_BOOKRUNNER',
      economicsPercent: null,
    },
    {
      firmId: '4803',
      firmName: 'Barclays',
      role: 'PASSIVE_BOOKRUNNER',
      economicsPercent: null,
    },
    {
      firmId: '4897',
      firmName: 'Evercore ISI',
      role: 'PASSIVE_BOOKRUNNER',
      economicsPercent: null,
    },
    {
      firmId: '4831',
      firmName: 'UBS Investment Bank',
      role: 'PASSIVE_BOOKRUNNER',
      economicsPercent: null,
    },
    {
      firmId: '4817',
      firmName: 'Wells Fargo Securities',
      role: 'PASSIVE_BOOKRUNNER',
      economicsPercent: null,
    },
    {
      firmId: '4822',
      firmName: 'Jefferies',
      role: 'CO_MANAGER',
      economicsPercent: null,
    },
    {
      firmId: '4810',
      firmName: 'Raymond James',
      role: 'CO_MANAGER',
      economicsPercent: null,
    },
    {
      firmId: '4957',
      firmName: 'Tudor, Pickering, Holt \u0026 Co.',
      role: 'CO_MANAGER',
      economicsPercent: null,
    },
  ],
  offerToCurrent: 1.0,
  currentSharePrice: 15.2,
  isIpoPriceRangeRevised: false,
  initialIpoRangeLow: 15.0,
  initialIpoRangeHigh: 18.0,
  offerTo1Day: 0.0,
  day1SharePrice: 13.0,
  userIsFollowing: false,
  isIpoSizeSharesRevised: false,
  initialSizeShares: 18200000,
  pctSecondaryShares: 0.692308,
  lastTradeBeforeOffer: 14.1,
  accelerated: false,
  allocation: 12,
  customSectorColorCode: null,
  indicationOfInterest: [],
  ioiShares: 0,
  ioiType: 'PASS',
  isFoSizeSharesRevised: false,
  isSpac: false,
  primaryShareholderName: null,
  primaryShareholderFirmId: null,
  shareholders: [],
  totalBookRunners: 2,
  totalNonBookRunners: 5,
  region: 'Namr',
  countryCode: 'US',
  countryDisplayName: 'USA',
  pricingCurrencyCode: 'USD',
  offerPriceLocalCurr: 123,
  marketCapLocalCurr: 1234567,
  sizeInLocalCurr: 2345678,
  conversionPrice: null,
  couponPercentage: null,
  premiumPercentage: null,
  couponTalkPercentageLow: null,
  couponTalkPercentageHigh: null,
  premiumTalkLowPercentage: null,
  premiumTalkHighPercentage: null,
});
