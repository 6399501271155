import 'flag-icons';

import React from 'react';
import { CSSProp } from 'styled-components/macro';

import { SFlag } from './FlagIcons.styles';

type SizeOptions = 'small';

type ShapeOptions = 'circle' | 'rectangle';

export type StyledConfig = {
  borderColor?: CSSProp;
};

export type FlagProps = {
  countryCode: string;
  className?: string;
  size?: SizeOptions;
  shape?: ShapeOptions;
  countryDisplayName?: string;
  styledConfig?: StyledConfig;
};

// Country Code should be an alpha-2 country code in accordance with this site: https://www.iso.org/obp/ui/#search

// The flag-icons library has its own css for the background-position and the background-size css properties.
// both of these properties must be overridden with the !important flag if you wish to add custom css.

export const FlagComponent: React.FC<FlagProps> = ({
  countryCode,
  className = '',
  size = 'small',
  shape = 'circle',
  countryDisplayName,
  styledConfig,
}) => {
  const baseClassName = 'fi fi-';
  return (
    <SFlag
      countryCode={countryCode}
      aria-label={countryDisplayName ? `${countryDisplayName} flag` : ''}
      className={className + ' ' + baseClassName + countryCode.toLowerCase()}
      size={size}
      shape={shape}
      styledConfig={styledConfig}
    />
  );
};
