import React from 'react';
import { MenuPlacement, SelectComponentsConfig } from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { createFormikField, FieldControlProps, FieldProps } from '../formik/createFormikField';
import { getReactSelectProps, PublicProps } from './BaseSelect.model';
import { SSelectWrapper } from './BaseSelect.styles';
import { AdditionalPagination, Group, Option } from './types';

export type OwnProps<TOptionValue> = {
  /** boolean supported by AsyncPaginate - calls loadOptions after mount when true */
  defaultOptions?: Option<TOptionValue>[] | boolean;
  /** Not required. Boolean. If false options will not load on menu opening. */
  loadOptionsOnMenuOpen?: boolean;
  /**
   * Not required. Array. Works as 2nd argument of useEffect hook. When one of items changed, AsyncPaginate cleans all cached options.
   */
  cacheUniqs?: any[];
  /**
   * Required. Async function that take next arguments:
   *  - Current value of search input.
   *  - Loaded options for current search.
   *  - Collected additional data e.g. current page number etc. For first load it is additional from props, for next is additional from previous response for current search. null by default.
   */
  loadOptions: LoadOptions<Option<TOptionValue>, Group<TOptionValue>, AdditionalPagination>;
  menuIsOpen?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  menuPlacement?: MenuPlacement;
};

export type Props<TOptionValue> = PublicProps<TOptionValue> &
  OwnProps<TOptionValue> &
  FieldControlProps<Option<TOptionValue> | null, Option<TOptionValue> | null> & {
    components?: SelectComponentsConfig<Option<TOptionValue>, false, Group<TOptionValue>>;
  };

export type AsyncPaginatedSelectFieldProps<TOptionValue> = Props<TOptionValue> & FieldProps;

export const AsyncPaginatedSelect = <TOptionValue extends unknown = string>(
  props: Props<TOptionValue>
) => {
  const {
    className,
    testId,
    value,
    defaultOptions,
    loadOptions,
    loadOptionsOnMenuOpen = false,
    onChange,
    placeholder = 'Search...',
    noOptionsMessage = () => 'No options found...',
    cacheUniqs = [],
    menuPlacement,
    components,
  } = props;

  const reactSelectProps = getReactSelectProps(props);

  return (
    <SSelectWrapper className={className} data-test-id={testId}>
      <AsyncPaginate<Option<TOptionValue>, Group<TOptionValue>, AdditionalPagination, false>
        {...reactSelectProps}
        components={{ ...reactSelectProps.components, ...(components ?? {}) }}
        cacheUniqs={cacheUniqs}
        value={value}
        placeholder={placeholder}
        debounceTimeout={1000}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        onChange={onChange}
        noOptionsMessage={noOptionsMessage}
        additional={{
          page: 1,
        }}
        menuPlacement={menuPlacement}
      />
    </SSelectWrapper>
  );
};

export default AsyncPaginatedSelect;

export const AsyncPaginatedSelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(
  AsyncPaginatedSelect
);
