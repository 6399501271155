import styled, { css } from 'styled-components/macro';

export const SPanelHeader = styled.div<{ withoutMargin?: boolean }>`
  ${({ withoutMargin }) =>
    !withoutMargin &&
    css`
      margin-bottom: 10px;
    `}
`;

export const SHeaderTitle = styled.h3`
  margin: 0 10px 0 0;
`;

export const SPanelHeaderToolbar = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
`;

export const SPanelHeaderToolbarItem = styled.div`
  margin-left: 10px;
`;

export const SCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

export const SRow = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
`;

export const SHeader = styled.div`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
