import { InterstitialPage } from '@cmg/common';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { oidcLogout } from '../../ducks';
import { AuthContext } from '../auth-provider/AuthProvider';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      oidcLogout,
    },
    dispatch
  ),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = DispatchProps;

/**
 * OIDCLogoutComponent is the oidc logout component that should be used
 * when a consumer application routes into an oidc logout url.
 * It invokes the oidcLogout action on load
 */
const OIDCLogoutComponent: React.FC<Props> = ({ actions }) => {
  React.useEffect(() => {
    actions.oidcLogout();
  }, [actions]);

  return <InterstitialPage />;
};

const OIDCLogout = connect(undefined, mapDispatchToProps, undefined, { context: AuthContext })(
  OIDCLogoutComponent
);

/**
 * Rather than having logout code throughout the app that triggers the oidc client
 * we can direct users to this url path to trigger the logout.
 */
export default OIDCLogout;
