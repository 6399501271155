import { Option } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { createActionCreator, createReducer } from '../../common/redux/reduxHelpers';
import {
  getDatalabUserPreferences,
  setDatalabUserPreferences,
} from '../../config/datalabUserPreferences';
import { FilterValues, getAllOfferingOptions } from './model/calendar-filters';

export type FilterOptions = {
  offeringType: Option[];
};

/**
 * ACTION TYPES
 */
const FILTER_CHANGE = 'calendar-graphql/FILTER_CHANGE';
const UPDATE_FILTER = 'calendar-graphql/UPDATE_FILTER';
const UPDATE_OPTIONS = 'calendar-graphql/UPDATE_OPTIONS';
const INIT_FILTER = 'calendar-graphql/INIT_FILTER';

/**
 * ACTIONS
 */
export const filterChange = createActionCreator<FilterValues>(FILTER_CHANGE);
export const filterUpdate = createActionCreator<FilterValues>(UPDATE_FILTER);
export const optionsUpdate = createActionCreator<FilterOptions>(UPDATE_OPTIONS);
export const initFilter = createActionCreator<never>(INIT_FILTER);

/**
 * REDUCERS
 */
type CalendarReducer<T> = (state: T | undefined, action: AnyAction) => T;

export type State = {
  filterValues: FilterValues;
  filterOptions: FilterOptions;
};

export const initialState: State = {
  filterValues: {
    offeringType: [],
    sector: [],
    customSectorId: [],
    sizeInDollars: {},
    marketCap: {},
    leftLeadFirmId: [],
    managerFirmId: [],
    useCustomSectors: false,
    countries: [],
  },
  filterOptions: {
    offeringType: [],
  },
};

const filterValuesReducer: CalendarReducer<FilterValues> = createReducer(
  initialState.filterValues,
  {
    [UPDATE_FILTER]: (state: FilterValues, values: Partial<FilterValues>) => ({
      ...state,
      ...values,
    }),
  }
);

const filterOptionsReducer: CalendarReducer<FilterOptions> = createReducer(
  initialState.filterOptions,
  {
    [UPDATE_OPTIONS]: (state: FilterOptions, values: Partial<FilterOptions>) => ({
      ...state,
      ...values,
    }),
  }
);

const reducer: CalendarReducer<State> = combineReducers({
  filterValues: filterValuesReducer,
  filterOptions: filterOptionsReducer,
});

export default reducer;

/**
 * SELECTORS
 */
const selectState: (state) => State = state => state.calendarGraphql;
export const selectFilterOptions = state => selectState(state).filterOptions;
export const selectFilterValues = state => selectState(state).filterValues;

/**
 * SAGAS
 */
export function* initFilterSaga() {
  const state = yield select();

  const filterOptions = selectFilterOptions(state);
  yield put(
    optionsUpdate({
      ...filterOptions,
      offeringType: getAllOfferingOptions(),
    })
  );

  const savedFilters = getDatalabUserPreferences<FilterValues>('calendarFiltersGraphql');

  if (savedFilters) {
    yield put(filterUpdate(savedFilters));
    return;
  } else {
    const filterValues = selectFilterValues(state);
    yield put(
      filterUpdate({
        ...filterValues,
        offeringType: getAllOfferingOptions().map(({ value }) => value),
      })
    );
  }
}

export function* updateFilterSaga(filterAction: ReturnType<typeof filterChange>) {
  if (!filterAction.payload) {
    return;
  }

  yield put(filterUpdate(filterAction.payload));

  setDatalabUserPreferences('calendarFiltersGraphql', filterAction.payload);
}

export function* calendarSaga() {
  // @ts-ignore
  yield takeEvery<ReturnType<typeof filterChange>>(FILTER_CHANGE, updateFilterSaga);
  yield takeLatest(INIT_FILTER, initFilterSaga);
}
