import { PublicAuthState } from '../../../auth/types';
import { oidcUserDefault } from '../domain/oidc-user/oidcUser';
import makeEntity from '../entityFactory';

export const makeUseAuthHook = makeEntity<PublicAuthState>({
  oidcUserId: oidcUserDefault.profile.sub,
  oidcUserAccountTraits: oidcUserDefault.profile.account_traits,
  oidcUserAccountType: oidcUserDefault.profile.account_type,
  oidcUserGivenName: oidcUserDefault.profile.given_name,
  oidcUserIdp: oidcUserDefault.profile.idp,
  oidcUserFamilyName: oidcUserDefault.profile.family_name,
  userPermissions: oidcUserDefault.profile.permissions,
  oidcUserEmail: oidcUserDefault.profile.email,
  oidcUserFullName: oidcUserDefault.profile.name,
  oidcUserFirmId: oidcUserDefault.profile.firm_id,
  oidcUserCmgEntityKey: oidcUserDefault.profile.cmg_entity_key,
  isLoggedIn: true,
  isSystemUser: false,
  userSettings: oidcUserDefault.profile.web_app_settings,
  authActions: {
    // @ts-ignore
    oidcLogout: () => null,
  },
});

export const useAuthNoUserDefault = makeUseAuthHook({
  oidcUserId: null,
  oidcUserAccountTraits: [],
  oidcUserAccountType: null,
  oidcUserGivenName: null,
  oidcUserIdp: null,
  oidcUserFamilyName: null,
  oidcUserEmail: null,
  oidcUserFirmId: null,
  oidcUserFullName: null,
  oidcUserCmgEntityKey: null,
  userPermissions: [],
  isLoggedIn: false,
  prospectusContactMandatory: null,
});

export const useAuthHookDefault = makeUseAuthHook();
