import isNumber from 'lodash/isNumber';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import theme from '../../../styles/theme';
import { Theme } from '../../../styles/types';
import IconButton from '../../buttons/button/IconButton';
import {
  SCollapse,
  SLargeViewPort,
  SSmallViewPort,
  SSmallViewPortHeader,
} from './ResponsiveDrawer.styles';

type MediaQueryBreakpoint = keyof Theme['breakpoint'];

export type Props = {
  /** header renderProp provides aboveBreakpoint and the flexibility to determine when to render the header */
  header?: (aboveBreakpoint: boolean) => React.ReactNode | null;
  /** breakpoint is the threshold between displaying the content and collapsing it on smaller views once the query is reached */
  breakpoint?: MediaQueryBreakpoint | number;
  testId?: string;
};

/**
 * ResponsiveDrawer is a responsive container that is meant to expand/collapse
 * content whenever a breakpoint is reached.
 *
 * Once a breakpoint is reached expand/collapse controls are displayed on the provided header.
 * The content will be display as fixed across the entire parent container
 */
const ResponsiveDrawer: React.FC<Props> = ({ header, children, breakpoint = 'xlarge', testId }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const query = `(min-width: ${
    isNumber(breakpoint) ? breakpoint : theme.breakpoint[breakpoint]
  }px)`;
  const mediaQueryMatches = useMediaQuery({ query });

  if (mediaQueryMatches) {
    return (
      <SLargeViewPort>
        {header && header(mediaQueryMatches)}
        {children}
      </SLargeViewPort>
    );
  }

  return (
    <SSmallViewPort>
      <SSmallViewPortHeader>
        {header && header(mediaQueryMatches)}
        <IconButton
          testId={testId}
          icon={{
            name: expanded ? 'times' : 'ellipsis-v',
            size: '2x',
          }}
          onClick={() => setExpanded(!expanded)}
        />
      </SSmallViewPortHeader>
      <SCollapse expanded={expanded}>{children}</SCollapse>
    </SSmallViewPort>
  );
};

export default ResponsiveDrawer;
