import React, { ChangeEvent, KeyboardEvent } from 'react';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import InputWrapper from '../input-wrapper/InputWrapper';
import { InputWrapperVariant } from '../input-wrapper/InputWrapper.styles';
import { FormControlEvents } from '../types';
import { STextArea, TextAreaResize } from './TextArea.styles';

export const defaultRows: number = 4;

export interface Props
  extends FieldControlProps<string | null, string, HTMLTextAreaElement>,
    FormControlEvents<HTMLTextAreaElement>,
    Pick<React.AriaAttributes, 'aria-label'> {
  autoFocus?: boolean;
  className?: string;
  innerRef?: React.Ref<HTMLInputElement>;
  textAlign?: 'left' | 'right';
  size?: 'medium' | 'large';
  rounded?: 'top' | 'bottom' | 'left' | 'right';
  resize?: TextAreaResize;
  variant?: InputWrapperVariant;
  placeholder?: string;
  rows?: number;
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  testId?: string;
}

function fixControlledValue(value?: string | null) {
  if (value === null) {
    return '';
  }
  return value;
}

export const TextAreaComponent: React.FC<Props> = ({
  onChange,
  className,
  rounded,
  disabled,
  hasError,
  size,
  innerRef,
  variant,
  textAlign,
  rows = defaultRows,
  testId,
  value,
  required,
  fullWidth,
  ...restProps
}) => {
  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <InputWrapper
      disabled={disabled}
      hasError={hasError}
      className={className}
      size={size}
      variant={variant}
      textAlign={textAlign}
      rounded={rounded}
    >
      <STextArea
        {...restProps}
        value={fixControlledValue(value)}
        // ensure rows prop is always positive number
        rows={Math.abs(rows)}
        disabled={disabled}
        onChange={handleOnChange}
        data-test-id={testId}
      />
    </InputWrapper>
  );
};

const TextArea = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <TextAreaComponent innerRef={ref} {...props} />;
});

TextArea.displayName = 'TextArea';

export default TextArea;
export const TextAreaField = createFormikField<string | null, string, HTMLTextAreaElement, Props>(
  TextArea
);
