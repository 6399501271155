import React from 'react';
import styled from 'styled-components/macro';

import Icon from '../../graphics/icon/Icon';

/**
 * Height is the full viewport because we can't rely on the ancestor elements to this component
 * to take up the full viewport height (in that case we would do height: 100%).
 **/
const SWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.screenOverlay};
  background-color: ${({ theme }) => theme.background.color.dark};
  color: ${({ theme }) => theme.text.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  testId?: string;
};

/**
 * Provides common page styling for interstitial auth pages. Really, just
 * supplies a blue background and centered loading spinner.
 */
const InterstitialPage: React.FC<Props> = ({ children, testId }) => (
  <SWrapper data-test-id={testId}>
    <Icon name="spinner-third" spin size="3x" />
    {children}
  </SWrapper>
);

export default InterstitialPage;
