import 'antd/lib/switch/style/index.css';

import { Switch as AntSwitch } from 'antd';
import { SwitchProps as AntSwitchProps } from 'antd/lib/switch';
import React from 'react';
import styled from 'styled-components/macro';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import { FormControlEvents } from '../types';

// Note: the typeof below is required. Without it TS fails with
// Exported variable 'StyledSwitch' has or is using name 'CompoundedComponent'
// from external module "node_modules/antd/lib/switch/index" but cannot be named.
//
// Workaround found here: https://github.com/ant-design/ant-design/issues/33091
export const StyledSwitch: typeof AntSwitch = styled(AntSwitch)`
  &&&.ant-switch-checked {
    background-color: ${({ theme }) => theme.interactive.primaryComplement};
  }
`;

type SwitchProps = {
  checkedContent?: React.ReactNode;
  unCheckedContent?: React.ReactNode;
  testId?: string;
} & Pick<AntSwitchProps, 'loading' | 'className' | 'size'>;

export type Props = SwitchProps & FieldControlProps<boolean, boolean> & FormControlEvents;

/**
 * Switch a wrapper around antd Switch allows a user to toggle the checked prop on/off
 * expects the consumer to control the checked prop via the exposed onChange event
 *
 * @ref {@link https://ant.design/components/switch/}
 */
const SwitchComponent: React.FC<Props> = ({
  onChange,
  size = 'default',
  checkedContent,
  unCheckedContent,
  value,
  className,
  disabled,
  loading,
  onClick,
  testId,
}) => {
  const handleOnClick = (checked: boolean, event: MouseEvent) => {
    onClick && onClick(event as unknown as React.MouseEvent<HTMLInputElement>);
  };

  return (
    <StyledSwitch
      checked={value}
      onChange={onChange}
      onClick={handleOnClick}
      size={size}
      checkedChildren={checkedContent}
      unCheckedChildren={unCheckedContent}
      loading={loading}
      className={className}
      disabled={disabled}
      data-test-id={testId}
    />
  );
};

export default SwitchComponent;
export const SwitchField = createFormikField<boolean, boolean, HTMLInputElement, Props>(
  SwitchComponent
);
