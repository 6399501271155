import React from 'react';

import { Group } from './types';

export type Props<TOptionValue> = React.PropsWithChildren<{
  onToggle: (group: Group<TOptionValue>) => void;
  data: Group<TOptionValue>;
  label: React.ReactNode;
}>;

/**
 * Options group renderer. Adds expand/collapse logic when rendering children.
 *
 * onToggle - label on-click callback
 */
export function ExpandableGroup<TOptionValue>(props: Props<TOptionValue>) {
  return (
    <React.Fragment>
      <div onClick={() => props.onToggle(props.data)}>{props.label}</div>
      {props.data && props.data.expanded && <div>{props.children}</div>}
    </React.Fragment>
  );
}
