import memoize from 'memoize-one';
import React from 'react';
import ReactSelect, { components, MultiValue } from 'react-select';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import {
  getReactSelectProps,
  getSelectedOptions,
  PublicMultiSelectProps,
  PublicProps,
  SyncSelectProps,
} from './BaseSelect.model';
import { SSelectWrapper } from './BaseSelect.styles';
import TruncatedValueContainer from './components/TruncatedValueContainer';
import { Group, Option } from './types';

export type Props<TOptionValue> = FieldControlProps<TOptionValue[] | null, TOptionValue[]> &
  PublicProps<TOptionValue, true> &
  SyncSelectProps<TOptionValue> &
  PublicMultiSelectProps;

export default class MultiSelect<TOptionValue = string> extends React.Component<
  Props<TOptionValue>
> {
  getSelectedOptions = memoize(getSelectedOptions<TOptionValue>);

  handleOnChange = (values: MultiValue<Option<TOptionValue>>) => {
    const { onChange } = this.props;

    onChange && onChange(values ? values.map(v => v.value) : []);
  };

  render() {
    const { className, options, testId, value, maxDisplayedValues, renderMultiValueLabel } =
      this.props;

    const valueContainerComponent = maxDisplayedValues
      ? {
          ValueContainer: props => (
            <TruncatedValueContainer<Option<TOptionValue>>
              {...props}
              maxDisplayedValues={maxDisplayedValues}
            />
          ),
        }
      : {};

    const selectProps = getReactSelectProps({
      ...this.props,
      isClearable: false,
    });

    return (
      <SSelectWrapper className={className} data-test-id={testId}>
        <ReactSelect<Option<TOptionValue>, true, Group<TOptionValue>>
          {...selectProps}
          isMulti
          value={this.getSelectedOptions(options, value)}
          onChange={this.handleOnChange}
          components={{
            ...selectProps.components,
            ...valueContainerComponent,
            ...(renderMultiValueLabel
              ? {
                  MultiValueLabel: props => {
                    return (
                      <components.MultiValueLabel {...props}>
                        {renderMultiValueLabel(props.children)}
                      </components.MultiValueLabel>
                    );
                  },
                }
              : {}),
          }}
        />
      </SSelectWrapper>
    );
  }
}

export const MultiSelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(
  MultiSelect
);
