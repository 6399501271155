import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { GlobalLeagueTableRequestDto } from './types/GlobalLeagueTable';

/**
 * Download an xlsx of Global League Table rows
 */
export const downloadGlobalLeagueTable = makePostDownloadRequest<GlobalLeagueTableRequestDto>(
  apiClient,
  () => `/globalLeagueTable/download`
);

export type DownloadGlobalLeagueTableResponse = UnpackPromise<
  ReturnType<typeof downloadGlobalLeagueTable>
>;
