import React from 'react';

import { SaveButton, SContainer, STextContent } from './SaveToastError.styles';

type Props = {
  onClickSave: () => void;
};

export const SaveToastError: React.FC<Props> = ({ onClickSave = () => {} }) => {
  return (
    <SContainer>
      <STextContent>Your changes could not be saved.</STextContent>
      <SaveButton onClick={onClickSave}>Save</SaveButton>
    </SContainer>
  );
};
