import { exchangeMicLabels, numericUtil } from '@cmg/common';
import { Chip, Typography } from '@cmg/design-system';
import isUndefined from 'lodash/isUndefined';
import React from 'react';

import { isDefined } from '../../../../../../common/helpers/helpers';
import {
  AdviserRole,
  Currency,
  LockUpUnit,
  OfferingType,
  SecurityType,
} from '../../../../../../graphql/__generated__/index';
import BooleanIcon from '../../../../../shared/components/boolean-icon/BooleanIcon';
import BooleanIconWithTooltip from '../../../../../shared/components/boolean-icon/BooleanIconWithTooltip';
import {
  getDateLabel,
  getFormattedCurrency,
  getFormattedCurrencyInMillion,
  getFormattedCurrencyValue,
  getFormattedDateValue,
  getFormattedOfferPrice,
  getFormattedPercentageRange,
  getFormattedPercentageValue,
  getSellingRestriction,
  getStatusChipColor,
  isUS,
  managerSortFn,
} from '../../../../../shared/model/utils';
import CountryRenderer from '../../../../shared/components/country-renderer/CountryRenderer';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import {
  CardRowItem,
  emptyValue,
  ManagerRowItem,
  OfferingProfileFieldName,
  SECFeedRowItem,
  SectionHeaderProps,
  TermsTableRowItem,
} from '../types';

const conditionallyDisplayedTimingFields = [
  OfferingProfileFieldName.TerminationDate,
  OfferingProfileFieldName.PostponedDate,
  OfferingProfileFieldName.WithdrawnDate,
  OfferingProfileFieldName.ConfidentialFilingDate,
];

export const useGetCompanySecurityData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const {
      convertibleAttributes,
      securityType,
      securityTypeDisplayName,
      issuer,
      typeDisplayName,
      isRule144A,
      exchangeMic,
      exchangeRegionDisplayName,
      exchangeCountryDisplayName,
      pricingCurrency,
    } = offeringProfile.offeringById ?? {};

    const { securityNote, rankDisplayName, underlyingSymbol } = convertibleAttributes ?? {};

    const securityTypeDisplay =
      securityType === SecurityType.ConvertibleNote ? 'Note' : securityTypeDisplayName;
    return [
      {
        name: OfferingProfileFieldName.Issuer,
        value: issuer?.name ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Type,
        value: typeDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.SecurityType,
        value: securityTypeDisplay ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.InstrumentDescription,
        value: securityNote ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Rank,
        value: rankDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.SellingRestrictions,
        value: isRule144A ? '144A' : emptyValue,
      },
      {
        name: OfferingProfileFieldName.Sector,
        value: issuer?.sectorDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Symbol,
        value: underlyingSymbol ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.Exchange,
        value: exchangeMic ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.RegionCountry,
        value: `${exchangeRegionDisplayName ?? emptyValue}/${
          exchangeCountryDisplayName ?? emptyValue
        }`,
      },
      {
        name: OfferingProfileFieldName.Currency,
        value: pricingCurrency ?? emptyValue,
      },
    ];
  }, [offeringProfile]);

export const getOfferPrice = (
  currency?: Currency | null,
  term?: {
    offerPrice?: number | null;
    pctOfferPrice?: number | null;
  } | null
) => {
  const pctOfferPrice = term?.pctOfferPrice
    ? ` (${getFormattedPercentageValue(term?.pctOfferPrice)})`
    : '';
  return term?.offerPrice
    ? `${getFormattedOfferPrice(currency, term?.offerPrice)}${pctOfferPrice}`
    : '-';
};

export const useGetTimingData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { publicFilingDate, launchDate, pricingDate, firstTradeDate, settlementDate } =
      offeringProfile.offeringById ?? {};
    return [
      {
        name: OfferingProfileFieldName.PublicFilingDate,
        value: getFormattedDateValue(publicFilingDate),
      },
      {
        name: OfferingProfileFieldName.LaunchDate,
        value: getFormattedDateValue(launchDate),
      },
      {
        name: OfferingProfileFieldName.PricingDate,
        value: getFormattedDateValue(pricingDate),
      },
      {
        name: OfferingProfileFieldName.FirstTradeDate,
        value: getFormattedDateValue(firstTradeDate),
      },
      {
        name: OfferingProfileFieldName.SettlementDate,
        value: getFormattedDateValue(settlementDate),
      },
    ];
  }, [offeringProfile.offeringById]);

export const useHasRevisedTerm = (offeringProfile: OfferingProfile_ListQuery): boolean =>
  React.useMemo(() => {
    return !!offeringProfile.offeringById?.latestRevisedTerm;
  }, [offeringProfile.offeringById?.latestRevisedTerm]);

export const useGetTermsData = (offeringProfile: OfferingProfile_ListQuery): TermsTableRowItem[] =>
  React.useMemo(() => {
    const {
      type,
      pricingCurrency,
      attributes,
      initialTerm,
      latestRevisedTerm: revisedTerm,
      finalTerm,
    } = offeringProfile.offeringById ?? {};
    const isConvert = type === OfferingType.Convertible;

    return [
      {
        name: OfferingProfileFieldName.PrincipalAmount,
        initial: getFormattedCurrencyInMillion(
          pricingCurrency,
          initialTerm?.aggregatePrincipalAmount
        ),
        revised: getFormattedCurrencyInMillion(
          pricingCurrency,
          revisedTerm?.aggregatePrincipalAmount
        ),
        final: getFormattedCurrencyInMillion(pricingCurrency, finalTerm?.aggregatePrincipalAmount),
      },
      {
        name: OfferingProfileFieldName.PrincipalAmountOvltAuthorized,
        initial: getFormattedCurrencyInMillion(
          pricingCurrency,
          initialTerm?.principalAmountOverallotmentAuthorized
        ),
        revised: getFormattedCurrencyInMillion(
          pricingCurrency,
          revisedTerm?.principalAmountOverallotmentAuthorized
        ),
        final: getFormattedCurrencyInMillion(
          pricingCurrency,
          finalTerm?.principalAmountOverallotmentAuthorized
        ),
      },
      {
        name: OfferingProfileFieldName.PrincipalAmountOvltExercised,
        initial: getFormattedCurrencyInMillion(
          pricingCurrency,
          initialTerm?.principalAmountOverallotmentExercised
        ),
        revised: getFormattedCurrencyInMillion(
          pricingCurrency,
          revisedTerm?.principalAmountOverallotmentExercised
        ),
        final: getFormattedCurrencyInMillion(
          pricingCurrency,
          finalTerm?.principalAmountOverallotmentExercised
        ),
      },
      {
        name: OfferingProfileFieldName.AggrPrincipalAmntInclOvltExercised,
        initial: getFormattedCurrencyInMillion(
          pricingCurrency,
          initialTerm?.aggregatePrincipalInclOverallotmentExercised
        ),
        revised: getFormattedCurrencyInMillion(
          pricingCurrency,
          revisedTerm?.aggregatePrincipalInclOverallotmentExercised
        ),
        final: getFormattedCurrencyInMillion(
          pricingCurrency,
          finalTerm?.aggregatePrincipalInclOverallotmentExercised
        ),
      },
      {
        name: OfferingProfileFieldName.LatestGrossProceedsBase,
        initial: emptyValue,
        revised: emptyValue,
        final:
          isConvert && !finalTerm
            ? emptyValue
            : getFormattedCurrencyInMillion(pricingCurrency, attributes?.latestGrossProceedsBase),
      },
      {
        name: OfferingProfileFieldName.LatestGrossProceedsTotal,
        initial: emptyValue,
        revised: emptyValue,
        final:
          isConvert && !finalTerm
            ? emptyValue
            : getFormattedCurrencyInMillion(pricingCurrency, attributes?.latestGrossProceedsTotal),
      },
      {
        name: OfferingProfileFieldName.Coupon,
        initial: getFormattedPercentageRange(
          initialTerm?.couponTalkPercentageLow,
          initialTerm?.couponTalkPercentageHigh,
          3
        ),
        revised: getFormattedPercentageRange(
          revisedTerm?.couponTalkPercentageLow,
          revisedTerm?.couponTalkPercentageHigh,
          3
        ),
        final: getFormattedPercentageValue(finalTerm?.couponPercentage, 3),
      },
      {
        name: OfferingProfileFieldName.Premium,
        initial: getFormattedPercentageRange(
          initialTerm?.premiumTalkLowPercentage,
          initialTerm?.premiumTalkHighPercentage
        ),
        revised: getFormattedPercentageRange(
          revisedTerm?.premiumTalkLowPercentage,
          revisedTerm?.premiumTalkHighPercentage
        ),
        final: getFormattedPercentageValue(finalTerm?.premiumPercentage),
      },
      {
        name: OfferingProfileFieldName.OfferPrice,
        initial: getOfferPrice(pricingCurrency, initialTerm),
        revised: getOfferPrice(pricingCurrency, revisedTerm),
        final: getOfferPrice(pricingCurrency, finalTerm),
      },
      {
        name: OfferingProfileFieldName.ParValue,
        initial: getFormattedCurrency(pricingCurrency, initialTerm?.principalAmountPerNote),
        revised: getFormattedCurrency(pricingCurrency, revisedTerm?.principalAmountPerNote),
        final: getFormattedCurrency(pricingCurrency, finalTerm?.principalAmountPerNote),
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetStructureData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const {
      convertibleAttributes,
      finalTerm,
      pricingCurrency,
      maturityDate,
      useOfProceedsDisplayNames,
    } = offeringProfile.offeringById ?? {};

    const {
      conversionPrice,
      conversionRatio,
      effectiveConversionPremium,
      effectiveConversionPrice,
      conversionReferencePrice,
      versusTalkDisplayName,
    } = finalTerm ?? {};
    const {
      isPerpetual,
      tenor,
      isCallable,
      callableDate,
      callPrice,
      hasProvisionalCall,
      provisionalCallNote,
      isPuttable,
      hasDividendProtection,
      hasContingentConversion,
      hasContingentPayment,
      contingentPaymentNote,
      isHedging,
      changeOfControl,
      putNote,
      dividendProtectionNote,
      contingentConversionNote,
      hedgingNote,
    } = convertibleAttributes ?? {};

    return [
      {
        name: OfferingProfileFieldName.ConversionPrice,
        value: getFormattedCurrency(pricingCurrency, conversionPrice),
      },
      {
        name: OfferingProfileFieldName.InitialConversionRatio,
        value: numericUtil.getDisplayValueForNumber(conversionRatio),
      },
      {
        name: OfferingProfileFieldName.EfftConversionPremium,
        value: getFormattedPercentageValue(effectiveConversionPremium),
      },
      {
        name: OfferingProfileFieldName.EfftConversionPrice,
        value: getFormattedCurrency(pricingCurrency, effectiveConversionPrice),
      },
      {
        name: OfferingProfileFieldName.ReferencePrice,
        value: getFormattedCurrency(pricingCurrency, conversionReferencePrice),
      },
      {
        name: OfferingProfileFieldName.VersusTalk,
        value: versusTalkDisplayName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.MaturityTenor,
        value: !isPerpetual && tenor ? `${tenor}` : 'Perpetual',
      },
      {
        name: OfferingProfileFieldName.MaturityDate,
        value: getFormattedDateValue(maturityDate),
      },
      {
        name: OfferingProfileFieldName.Callable,
        value: <BooleanIcon value={isCallable} />,
      },
      {
        name: OfferingProfileFieldName.CallDates,
        value: getFormattedDateValue(callableDate),
      },
      {
        name: OfferingProfileFieldName.CallPrice,
        value: getFormattedPercentageValue(callPrice),
      },
      {
        name: OfferingProfileFieldName.ProvisionalCall,
        value: <BooleanIconWithTooltip value={hasProvisionalCall} tooltip={provisionalCallNote} />,
      },
      {
        name: OfferingProfileFieldName.Puttable,
        value: <BooleanIconWithTooltip value={isPuttable} tooltip={putNote} />,
      },
      {
        name: OfferingProfileFieldName.DividendProtection,
        value: (
          <BooleanIconWithTooltip value={hasDividendProtection} tooltip={dividendProtectionNote} />
        ),
      },
      {
        name: OfferingProfileFieldName.ContingentConversion,
        value: (
          <BooleanIconWithTooltip
            value={hasContingentConversion}
            tooltip={contingentConversionNote}
          />
        ),
      },
      {
        name: OfferingProfileFieldName.ContingentPayment,
        value: (
          <BooleanIconWithTooltip value={hasContingentPayment} tooltip={contingentPaymentNote} />
        ),
      },
      {
        name: OfferingProfileFieldName.Hedging,
        value: <BooleanIconWithTooltip value={isHedging} tooltip={hedgingNote} />,
      },
      {
        name: OfferingProfileFieldName.ChangeOfControl,
        value: (
          <BooleanIconWithTooltip
            value={changeOfControl && changeOfControl.length > 0 ? true : false}
            tooltip={changeOfControl}
          />
        ),
      },
      {
        name: OfferingProfileFieldName.UseOfProceeds,
        value: useOfProceedsDisplayNames?.join(', ') ?? emptyValue,
      },
    ];
  }, [offeringProfile.offeringById]);

export const getUnderwriterCurrencyValue = (
  value: number | null | undefined,
  pricingCurrencyCode?: string | undefined
) => {
  return value
    ? `${getFormattedCurrencyValue({
        value,
        pricingCurrencyCode,
      })}/bond`
    : '-';
};

export const useGetUnderwriterTermsData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const pricingCurrencyCode = 'USD';
    const { attributes } = offeringProfile.offeringById ?? {};
    const {
      pctGrossSpread,
      grossSpreadTotalUsd,
      sellingConcessionUsd,
      managementFeeUsd,
      underwritingFeeUsd,
    } = attributes ?? {};
    return [
      {
        name: OfferingProfileFieldName.GrossSpread,
        value: getFormattedPercentageValue(pctGrossSpread) ?? emptyValue,
        value2: getUnderwriterCurrencyValue(grossSpreadTotalUsd, pricingCurrencyCode),
      },
      {
        name: OfferingProfileFieldName.SellingConcessionUsd,
        value: '60%',
        value2: getUnderwriterCurrencyValue(sellingConcessionUsd, pricingCurrencyCode),
      },
      {
        name: OfferingProfileFieldName.ManagementFee,
        value: '20%',
        value2: getUnderwriterCurrencyValue(managementFeeUsd, pricingCurrencyCode),
      },
      {
        name: OfferingProfileFieldName.UnderwritingFee,
        value: '20%',
        value2: getUnderwriterCurrencyValue(underwritingFeeUsd, pricingCurrencyCode),
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetUnderwriterNamesData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const { attributes } = offeringProfile.offeringById ?? {};
    const { leftLeadName, stabilizationAgentName, settlementAgentName } = attributes ?? {};
    return [
      {
        name: OfferingProfileFieldName.LeftLead,
        value: leftLeadName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.StabilizationAgent,
        value: stabilizationAgentName ?? emptyValue,
      },
      {
        name: OfferingProfileFieldName.SettlementAgent,
        value: settlementAgentName ?? emptyValue,
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetUnderwriterLockupData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const { attributes, lockUps } = offeringProfile.offeringById ?? {};
    const lockUp = lockUps && lockUps.length > 0 ? lockUps[0] : null;
    const { lockUpPeriod, isConditionalLockUp, hasMultipleLockUps } = attributes ?? {};
    const { earlyReleaseDate, expirationDate, period, unit, unitDisplayName } = lockUp ?? {};
    const periodInMonths =
      unit === LockUpUnit.Months && !isUndefined(period) ? `${period} ${unitDisplayName}/` : '';

    return [
      {
        name: OfferingProfileFieldName.LockupPeriod,
        value: lockUpPeriod ? `${periodInMonths}${lockUpPeriod} Days` : emptyValue,
      },
      {
        name: OfferingProfileFieldName.LockupExpirations,
        value: getFormattedDateValue(expirationDate),
      },
      {
        name: OfferingProfileFieldName.LockupEarlyRelease,
        value: getFormattedDateValue(earlyReleaseDate),
      },
      {
        name: OfferingProfileFieldName.ConditionalLockup,
        value: <BooleanIcon value={isConditionalLockUp} />,
      },
      {
        name: OfferingProfileFieldName.MultipleLockup,
        value: <BooleanIcon value={hasMultipleLockUps} />,
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetAdvisorsData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const { advisers = [] } = offeringProfile.offeringById ?? {};
    const getAdviserNameByRole = (adviserRole: AdviserRole) => {
      const advisersByRole =
        advisers?.filter(({ role }) => {
          return role === adviserRole;
        }) ?? [];
      return advisersByRole.length > 0
        ? advisersByRole.map(({ name }) => name).join(', ')
        : emptyValue;
    };
    return [
      {
        name: OfferingProfileFieldName.UnderwriterCounsel,
        value: getAdviserNameByRole(AdviserRole.UnderwriterCounsel),
      },
      {
        name: OfferingProfileFieldName.IssuerCounsel,
        value: getAdviserNameByRole(AdviserRole.IssuerCounsel),
      },
      {
        name: OfferingProfileFieldName.Trustee,
        value: getAdviserNameByRole(AdviserRole.Trustee),
      },
      {
        name: OfferingProfileFieldName.Advisory,
        value: getAdviserNameByRole(AdviserRole.Advisory),
      },
      {
        name: OfferingProfileFieldName.IssuerAuditor,
        value: getAdviserNameByRole(AdviserRole.IssuerAuditor),
      },
    ];
  }, [offeringProfile.offeringById]);

export const useGetManagersData = (offeringProfile: OfferingProfile_ListQuery): ManagerRowItem[] =>
  React.useMemo(() => {
    const { managers = [] } = offeringProfile.offeringById ?? {};
    return managers
      .slice()
      .sort(managerSortFn)
      .map(({ name, roleDisplayName, underwritingBaseShares, pctUnderwritingBaseShares }) => {
        return {
          name: name ?? '-',
          role: roleDisplayName ?? emptyValue,
          underwritingBaseShares: numericUtil.getDisplayValueForInteger(underwritingBaseShares),
          pctUnderwritingBaseShares: getFormattedPercentageValue(pctUnderwritingBaseShares),
        };
      });
  }, [offeringProfile.offeringById]);

export const useGetSECFeedData = (offeringProfile: OfferingProfile_ListQuery): SECFeedRowItem[] =>
  React.useMemo(() => {
    const { news = [] } = offeringProfile.offeringById ?? {};
    return (
      news?.map(({ filingType, filingDate, description, fileUrl }) => {
        return {
          title: `${filingType} Filing on ${getFormattedDateValue(filingDate)}`,
          description: description ?? '',
          url: fileUrl ?? '',
        };
      }) ?? []
    );
  }, [offeringProfile.offeringById]);

export const useGetSideBarData = (offeringProfile: OfferingProfile_ListQuery): CardRowItem[] =>
  React.useMemo(() => {
    const {
      type,
      pricingCurrency,
      attributes,
      atmAttributes,
      symbol,
      typeDisplayName,
      price,
      status,
      statusDisplayName,
      securityTypeDisplayName,
      exchangeMic,
      exchangeCountryDisplayName,
      exchangeRegionDisplayName,
      exchangeCountry,
      issuer,
    } = offeringProfile.offeringById ?? {};
    const {
      isDualListed,
      isUpListing,
      isFdicRegistered,
      isSecRegistered,
      latestGrossProceedsTotal,
      latestPctSecondaryShares,
      isRule144A,
      isRegS,
      isSpac,
    } = attributes ?? {};
    const { latestProgramSize, latestProgramSizeInSecurities } = atmAttributes ?? {};
    const isAtm = type === OfferingType.Atm;
    const isConverts = type === OfferingType.Convertible;
    const displaySymbol =
      isUS(offeringProfile) && !isDefined(symbol) ? issuer?.primarySymbol : symbol;

    const sidebarRows = [
      {
        name: OfferingProfileFieldName.OfferingType,
        value: isSpac ? 'IPO (SPAC)' : typeDisplayName,
      },
      {
        name: OfferingProfileFieldName.OfferingStatus,
        value: status ? (
          <Chip
            label={<Typography variant="highlight2">{statusDisplayName}</Typography>}
            size="small"
            color={getStatusChipColor(status)}
          />
        ) : (
          emptyValue
        ),
      },
      {
        name: OfferingProfileFieldName.SecurityType,
        value: securityTypeDisplayName,
      },
      {
        name: OfferingProfileFieldName.SellingRestrictions2,
        value: getSellingRestriction(isRegS, isRule144A, emptyValue),
        isHidden: !isRule144A && !isRegS,
      },
      {
        name: OfferingProfileFieldName.Symbol,
        value: !!displaySymbol ? displaySymbol.toUpperCase() : emptyValue,
      },
      {
        name: OfferingProfileFieldName.Exchange,
        value:
          exchangeMic && exchangeMicLabels[exchangeMic]
            ? exchangeMicLabels[exchangeMic].shortName
            : exchangeMic,
      },
      {
        name: OfferingProfileFieldName.ExchRegionCountry,
        value: (
          <CountryRenderer
            countryDisplayName={`${exchangeCountryDisplayName} / ${exchangeRegionDisplayName}`}
            countryCode={`${exchangeCountry}`}
          />
        ),
      },
      {
        name: OfferingProfileFieldName.AdditionalListing,
        info: 'Execution of an offering and simultaneous new exchange listing from an issuer with prior trade history.',
        value: <BooleanIcon value={isDualListed} />,
        isHidden: isAtm,
      },
      {
        name: OfferingProfileFieldName.UpListing,
        value: <BooleanIcon value={isUpListing} />,
        isHidden: isAtm || isConverts,
      },
      {
        name: OfferingProfileFieldName.SECRegidterd,
        value: <BooleanIcon value={isSecRegistered} />,
      },
      {
        name: OfferingProfileFieldName.FDICRegistered,
        value: <BooleanIcon value={isFdicRegistered} />,
        isHidden: !isFdicRegistered,
      },
      {
        name: OfferingProfileFieldName.PricingCurrency,
        value: pricingCurrency,
      },
      {
        name: OfferingProfileFieldName.GrossProceedsTotal,
        value: getFormattedCurrencyInMillion(pricingCurrency, latestGrossProceedsTotal),
        isHidden: isAtm,
      },
      {
        name: OfferingProfileFieldName.PctSecondary,
        value: getFormattedPercentageValue(latestPctSecondaryShares) ?? emptyValue,
        isHidden: isAtm,
      },
      {
        name: OfferingProfileFieldName.OfferPrice,
        value: getFormattedOfferPrice(pricingCurrency, price),
        isHidden: isAtm,
      },
      {
        name: latestProgramSize
          ? OfferingProfileFieldName.AtmProgramSize
          : OfferingProfileFieldName.AtmProgramSizeInSecurities,
        value: latestProgramSize
          ? getFormattedCurrencyInMillion(pricingCurrency, latestProgramSize)
          : numericUtil.formatInteger(latestProgramSizeInSecurities),
        isHidden: !isAtm,
      },
    ];
    return sidebarRows.filter(row => !row.isHidden);
  }, [offeringProfile]);

export const profileSectionPrefix = 'offering_profile_section';
export const useGetProfileSections = (
  offeringProfile: OfferingProfile_ListQuery
): SectionHeaderProps[] =>
  React.useMemo(() => {
    const { offeringById } = offeringProfile;
    const { type } = offeringById || {};
    const { totalManagers, cornerstoneInvestorsTotalCount } = offeringById?.attributes ?? {};

    const sections = [
      {
        name: 'Timing',
        id: 'timing',
      },
      {
        name: 'Structure/Pricing',
        id: 'structure',
      },
      {
        name: 'Performance',
        id: 'performance',
      },
      {
        name: `Managers (${totalManagers ?? 0})`,
        id: 'managers',
      },
      {
        name: 'Underwriting Terms',
        id: 'underwritingTerms',
      },
      {
        name: 'Lock-ups',
        id: 'lockups',
      },
      {
        name: 'Shareholders & Investors',
        id: 'shareholders',
      },
      {
        name: `Cornerstone Investors (${cornerstoneInvestorsTotalCount ?? 0})`,
        id: 'cornerstone',
      },
      {
        name: 'Advisors',
        id: 'advisor',
      },
      {
        name: 'Documentation',
        id: 'documentation',
      },
      {
        name: 'Private Notes',
        id: 'notes',
      },
    ];
    const atm_sections = [
      {
        name: 'Timing',
        id: 'timing',
      },
      {
        name: 'Structure/Pricing',
        id: 'structure',
      },
      {
        name: 'Performance',
        id: 'performance',
      },
      {
        name: `Sales Agents (${totalManagers ?? 0})`,
        id: 'sales_agents',
      },
      {
        name: 'Underwriting Terms',
        id: 'underwriting_terms',
      },
      {
        name: 'Advisors',
        id: 'advisor',
      },
      {
        name: 'Documentation',
        id: 'documentation',
      },
      {
        name: 'Private Notes',
        id: 'notes',
      },
    ];
    return (type === OfferingType.Atm ? atm_sections : sections).map(section => {
      return { ...section, id: `${profileSectionPrefix}_${section.id}` };
    });
  }, [offeringProfile]);

export const useGetTimingSectionData = (
  offeringProfile: OfferingProfile_ListQuery
): CardRowItem[] =>
  React.useMemo(() => {
    const {
      publicFilingDate,
      launchDate,
      marketTimingDisplayName,
      pricingDate,
      firstTradeDate,
      settlementDate,
      withdrawnDate,
      terminatedDate,
      postponedDate,
      attributes,
    } = offeringProfile.offeringById ?? {};
    const { confidentialFilingDate, isAccelerated } = attributes ?? {};
    const confidentialFilingRow = !!confidentialFilingDate
      ? [
          {
            name: OfferingProfileFieldName.ConfidentialFilingDate,
            value: getFormattedDateValue(confidentialFilingDate),
          },
        ]
      : [];
    const staticTimingRows = [
      {
        name: OfferingProfileFieldName.AnnouncementDate,
        value: getFormattedDateValue(publicFilingDate),
      },
      {
        name: `${getDateLabel(launchDate)}${OfferingProfileFieldName.LaunchDate}`,
        value: getFormattedDateValue(launchDate),
        value2: marketTimingDisplayName ? (
          <Typography variant="body2">{marketTimingDisplayName}</Typography>
        ) : null,
      },
    ];
    const timingRows = [
      {
        name: OfferingProfileFieldName.PricingDate,
        value: pricingDate,
      },
      {
        name: OfferingProfileFieldName.FirstTradeDate,
        value: firstTradeDate,
      },
      {
        name: OfferingProfileFieldName.SettlementDate,
        value: settlementDate,
      },
      {
        name: OfferingProfileFieldName.WithdrawnDate,
        value: withdrawnDate,
      },
      {
        name: OfferingProfileFieldName.TerminationDate,
        value: terminatedDate,
      },
      {
        name: OfferingProfileFieldName.PostponedDate,
        value: postponedDate,
      },
    ];

    const processedRows = timingRows
      .filter(row => {
        // filter out conditionally displayed fields if they're null
        return !(conditionallyDisplayedTimingFields.includes(row.name) && !row.value);
      })
      .map((row, index) => {
        // add index so we can preserve order when sorting
        return {
          ...row,
          index: index,
        };
      })
      .sort((rowA, rowB) => {
        // convert undefined to null because sort() push undefined to end of array
        const valueA = rowA.value ?? null;
        const valueB = rowB.value ?? null;
        // sort by date
        if (!valueA && !valueB) {
          return rowA.index - rowB.index; // if both are null, preserve order
        } else if (!valueB) {
          return -1; // a before b if b is null
        } else if (!valueA) {
          return 1; // b before a if a is null
        }
        // ascending order
        return new Date(valueA).valueOf() - new Date(valueB).valueOf();
      })
      .map(row => {
        // formatting & add 'Accelerated' to pricing date if applicable
        if (row.name === OfferingProfileFieldName.PricingDate && isAccelerated) {
          return {
            name: `${getDateLabel(row.value)}${row.name}`, // add 'Expected' prefix if applicable
            value: getFormattedDateValue(row.value),
            value2: (
              <Typography variant="body2" color="red">
                Accelerated
              </Typography>
            ),
          };
        }
        return {
          name: `${getDateLabel(row.value)}${row.name}`, // add 'Expected' prefix if applicable
          value: getFormattedDateValue(row.value),
        };
      });
    return [...confidentialFilingRow, ...staticTimingRows, ...processedRows];
  }, [offeringProfile.offeringById]);
