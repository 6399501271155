import styled, { css } from 'styled-components/macro';

export const SLabel = styled.label<{
  required?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, required, disabled }) => css`
    font-size: ${theme.text.size.mediumSmall};
    color: ${theme.text.color.darkGray};
    margin-bottom: 5px;
    display: inline-block;

    ${required &&
    css`
      :after {
        content: '*';
        color: ${({ theme }) => theme.color.red3};
      }
    `}

    ${disabled &&
    css`
      color: ${({ theme }) => theme.color.gray7};
    `}
  `}
`;

export const SInfoIconWrapper = styled.div`
  display: inline;
  margin: 0 5px;
`;
