import { AccountType } from '@capital-markets-gateway/api-client-identity';
import { errorUtil, loggerUtil, mixpanelUtil, reduxUtil } from '@cmg/common';
import isEqual from 'lodash/isEqual';
import { Log } from 'oidc-client-ts';
import { combineReducers } from 'redux';
import { call, delay, put, race, select, take, takeLatest, throttle } from 'redux-saga/effects';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import { RootState } from '../common/redux/rootReducer';
import { isAccessTokenExpired } from '../common/util/authorization';
import browserStorageManager from '../common/util/browserStorageManager';
import { isSystemSubdomain } from '../common/util/url';
import { AuthProviderConfig } from '../types/api/AuthProviderConfig';
import { OIDCUserType } from '../types/domain/oidc-user/oidc-user';
import { DEFAULT_IDLE_AWAY_TIMEOUT_IN_MINUTES } from './constants/idle';
import getUserManager, {
  getCallbackUserManager,
  initOidcLogger,
  User,
  UserManager,
} from './oidcFactory';

const { createMixPanelAction } = mixpanelUtil;

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  OIDC_LOGIN = 'auth/OIDC_LOGIN',
  OIDC_LOGIN_CALLBACK = 'auth/OIDC_LOGIN_CALLBACK',
  OIDC_LOGOUT = 'auth/OIDC_LOGOUT',
  OIDC_LOGOUT_CALLBACK = 'auth/OIDC_LOGOUT_CALLBACK',
  LOGIN_SUCCEEDED = 'auth/LOGIN_SUCCEEDED',
  OIDC_SILENT_LOGIN = 'auth/OIDC_SILENT_LOGIN',
  OIDC_SILENT_LOGIN_CALLBACK = 'auth/OIDC_SILENT_LOGIN_CALLBACK',
  LOGOUT_SUCCEEDED = 'auth/LOGOUT_SUCCEEDED',
  START_AUTH_MONITOR = 'auth/START_AUTH_MONITOR',
  STOP_AUTH_MONITOR = 'auth/STOP_AUTH_MONITOR',
  AUTH_PROVIDER_CONFIG = 'auth/AUTH_PROVIDER_CONFIG',
}

/**
 * ACTION CREATORS
 */
export const oidcLogin = (params: { returnUrl: string; onError: (err: Error) => void }) => ({
  type: ActionTypes.OIDC_LOGIN,
  payload: {
    returnUrl: params.returnUrl,
    onError: params.onError,
  },
});

export const oidcLoginCallback = (params: { onSuccess: (returnUrl: string) => void }) => ({
  type: ActionTypes.OIDC_LOGIN_CALLBACK,
  payload: {
    onSuccess: params.onSuccess,
  },
});

export const setAuthProviderConfig = (params: { config: AuthProviderConfig }) => ({
  type: ActionTypes.AUTH_PROVIDER_CONFIG,
  payload: {
    config: params.config,
  },
});

/**
 * This should only ever be triggered manually by the user (e.g. Pressing Logout link).
 * It will perform an oidc logout which will force them to re-enter their user/pass
 * (or get re-upped by a 3rd party IDP if they are using that).
 */
export const oidcLogout = () => ({
  type: ActionTypes.OIDC_LOGOUT,
});

export const oidcLogoutCallback = (params: { onSuccess: () => void }) => ({
  type: ActionTypes.OIDC_LOGOUT_CALLBACK,
  payload: {
    onSuccess: params.onSuccess,
  },
});

const oidcSilentLogin = () => ({
  type: ActionTypes.OIDC_SILENT_LOGIN,
});

// exported for testing only
export const loginSucceeded = (params: { user: User }) => ({
  type: ActionTypes.LOGIN_SUCCEEDED,
  payload: {
    oidcUser: JSON.parse(params.user.toStorageString()),
  },
});

/**
 * This resets the entire redux store to its initial state. (see rootReducer.ts).
 */
const logoutSucceeded = () => ({
  type: ActionTypes.LOGOUT_SUCCEEDED,
});

/**
 * Dispatch this when we care that a user is logged in (e.g. when they are on a private route)
 * It triggers all token expiration and token renewal efforts.
 */
export const startAuthMonitor = () => {
  loggerUtil.info('@cmg/auth: Starting Auth Monitor');

  return {
    type: ActionTypes.START_AUTH_MONITOR,
  };
};

export const stopAuthMonitor = () => {
  loggerUtil.info('@cmg/auth: Stopping Auth Monitor');

  return {
    type: ActionTypes.STOP_AUTH_MONITOR,
  };
};

/**
 * ACTIONS
 */
type Actions = {
  [ActionTypes.OIDC_LOGIN]: ReturnType<typeof oidcLogin>;
  [ActionTypes.OIDC_LOGIN_CALLBACK]: ReturnType<typeof oidcLoginCallback>;
  [ActionTypes.OIDC_LOGOUT]: ReturnType<typeof oidcLogout>;
  [ActionTypes.OIDC_LOGOUT_CALLBACK]: ReturnType<typeof oidcLogoutCallback>;
  [ActionTypes.OIDC_SILENT_LOGIN]: ReturnType<typeof oidcSilentLogin>;
  [ActionTypes.LOGIN_SUCCEEDED]: ReturnType<typeof loginSucceeded>;
  [ActionTypes.LOGOUT_SUCCEEDED]: ReturnType<typeof logoutSucceeded>;
  [ActionTypes.START_AUTH_MONITOR]: ReturnType<typeof startAuthMonitor>;
  [ActionTypes.STOP_AUTH_MONITOR]: ReturnType<typeof stopAuthMonitor>;
  [ActionTypes.AUTH_PROVIDER_CONFIG]: ReturnType<typeof setAuthProviderConfig>;
};

/**
 * REDUCERS
 */
const { createReducer } = reduxUtil;

export type ReducerState = {
  // The user returned from the oidc client. This should only be set by an action dispatched from a successful login.
  oidcUser?: OIDCUserType | null;
  authConfig: AuthProviderConfig | null;
};

export const initialState = {
  oidcUser: null,
  authConfig: null,
};

const oidcUserReducer = createReducer<ReducerState['oidcUser'], Actions>(initialState.oidcUser, {
  [ActionTypes.LOGIN_SUCCEEDED]: (oidcUserState, action) => {
    return action.payload.oidcUser;
  },
});

const configReducer = createReducer<ReducerState['authConfig'], Actions>(initialState.authConfig, {
  [ActionTypes.AUTH_PROVIDER_CONFIG]: (configState, action) => {
    return action.payload.config;
  },
});

export default combineReducers<ReducerState>({
  oidcUser: oidcUserReducer,
  authConfig: configReducer,
});

/**
 * SELECTORS
 */
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, (a, b) => isEqual(a, b));

const selectState = (state: RootState): ReducerState => state.auth;
export const selectOidcUser = state => selectState(state).oidcUser;
export const selectOidcUserProfile = state => {
  const oidcUser = selectOidcUser(state);
  return oidcUser ? oidcUser.profile : null;
};
export const selectOidcUserId = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.sub : null;
};
export const selectOidcUserTenantId = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.tenant_id : null;
};
export const selectOidcUserIdp = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.idp : null;
};
export const selectOidcUserAccountType = (state): AccountType | 'SYSTEM' | null => {
  const oidcUserProfile = selectOidcUserProfile(state);
  if (isSystemSubdomain()) {
    return 'SYSTEM';
  }

  if (oidcUserProfile && oidcUserProfile.account_type) {
    return oidcUserProfile.account_type;
  }

  return null;
};
export const selectIsSystemUser = state => selectOidcUserAccountType(state) === 'SYSTEM';
export const selectOidcUserGivenName = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.given_name : null;
};
export const selectOidcUserFamilyName = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.family_name : null;
};
export const selectOidcUserFullName = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.name : null;
};
export const selectOidcUserEmail = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.email : null;
};
export const selectOidcUserCmgEntityKey = state => {
  const oidcUserProfile = selectOidcUserProfile(state);
  return oidcUserProfile ? oidcUserProfile.cmg_entity_key : null;
};
export const selectAccessToken = state => {
  const oidcUser = selectOidcUser(state);
  return oidcUser ? oidcUser.access_token : null;
};
export const selectWebAppSettings = state => {
  return selectOidcUserProfile(state)?.web_app_settings ?? null;
};
export const selectIdleSettings = state => {
  const { rootDomain, secureCookies } = selectConfig(state)?.env ?? {};
  const { IDLE_AWAY_TIMEOUT_IN_MINUTES } = selectWebAppSettings(state) ?? {};
  const parsedIdleTimeout = IDLE_AWAY_TIMEOUT_IN_MINUTES
    ? parseInt(IDLE_AWAY_TIMEOUT_IN_MINUTES)
    : NaN;

  return {
    rootDomain: rootDomain ?? window.location.hostname,
    secureCookies,
    idleTimeout: isNaN(parsedIdleTimeout)
      ? DEFAULT_IDLE_AWAY_TIMEOUT_IN_MINUTES
      : parsedIdleTimeout,
  };
};

export const selectProspectusContactMandatory = state => {
  const { PROSPECTUS_CONTACT_MANDATORY } = selectWebAppSettings(state) ?? {};
  if (PROSPECTUS_CONTACT_MANDATORY === 'true') {
    return true;
  }
  if (PROSPECTUS_CONTACT_MANDATORY === 'false') {
    return false;
  }
  return null;
};

// Deep equal memoization so `[]` is detected as cached
export const selectUserPermissions = createDeepEqualSelector(
  state => selectOidcUserProfile(state)?.permissions || [],
  permissions => permissions
);

// Deep equal memoization so `[]` is detected as cached
export const selectOidcUserAccountTraits = createDeepEqualSelector(
  state => selectOidcUserProfile(state)?.account_traits || [],
  traits => traits
);

export const selectIsLoggedIn = state => (selectOidcUser(state) ? true : false);
export const selectTokenExpiration = state => {
  const oidcUser = selectOidcUser(state);
  return oidcUser ? oidcUser.expires_at : null;
};
export const selectUserFirmId = state => {
  const userProfile = selectOidcUserProfile(state);
  return userProfile ? userProfile.firm_id : null;
};
export const selectConfig = state => {
  const authState = selectState(state);
  return authState ? authState.authConfig : null;
};
export const selectClient = (state): { basename: string } | null => {
  const authConfig = selectConfig(state);
  return authConfig ? authConfig.client : null;
};
export const selectLogging = state => {
  const authConfig = selectConfig(state);
  return authConfig ? authConfig.logging : null;
};
export const selectBasename = (state): string | null => {
  const authConfigClient = selectClient(state);
  return authConfigClient ? authConfigClient.basename : null;
};

export const selectAppName = state => {
  const authConfigLogging = selectLogging(state);
  return authConfigLogging ? authConfigLogging.appName : null;
};

export const selectAuth = state => {
  const authConfig = selectConfig(state);
  return authConfig && authConfig.auth;
};
export const selectActions = state => {
  const authConfig = selectConfig(state);
  return authConfig && authConfig.actions;
};

/**
 * SAGAS
 */

/**
 * Trigger an OIDC login
 */
export function* loginSaga(mgr: UserManager, { payload }: Actions[ActionTypes.OIDC_LOGIN]) {
  browserStorageManager.saveUserRequestedPath(payload.returnUrl);

  try {
    yield mgr.signinRedirect();
  } catch (err) {
    errorUtil.assertIsError(err);
    payload.onError(err);
  }
}

/**
 * Trigger an OIDC logout
 */
export function* logoutSaga(mgr: UserManager) {
  yield put(createMixPanelAction(ActionTypes.OIDC_LOGOUT, 'User logged out via OIDC'));

  loggerUtil.info('@cmg/auth: logoutSaga triggered');

  // Missing user guard - Guard against race condition where user may have been removed (e.g. OIDC checksession ran and unloaded the user).
  //  Triggering a sign out redirect when id_token doesn't exist leads to an error during logout.
  const user: User | null = yield mgr.getUser();
  if (user?.id_token) {
    loggerUtil.info('@cmg/auth: logoutSaga user has id token, starting signout redirect');
    yield mgr.signoutRedirect({ id_token_hint: user.id_token });
  }
}

/**
 * After a successful login (user was redirected to OIDC server route, then back to the SPA with a token)
 * this is called to finish the login process.
 *  - Finish OIDC login by having the oidc-client-js package do its work.
 *  - Redirect the user to the SPA route they were initially trying to get to.
 */
export function* loginCallbackSaga(
  mgr: UserManager,
  { payload }: Actions[ActionTypes.OIDC_LOGIN_CALLBACK]
) {
  yield mgr.signinRedirectCallback();
  const user: User = yield mgr.getUser();
  const redirect = browserStorageManager.getUserRequestedPath();
  browserStorageManager.removeUserRequestedPath();

  yield put(loginSucceeded({ user }));
  payload.onSuccess(redirect || '/');
}

export function* logoutCallbackSaga(
  mgr: UserManager,
  { payload }: Actions[ActionTypes.OIDC_LOGOUT_CALLBACK]
) {
  yield mgr.signoutRedirectCallback();
  payload.onSuccess();
  localStorage.removeItem('env');
  yield put(logoutSucceeded());
}

/**
 * Silently renews the user and token via an OIDC silent renewal.
 */
export function* silentLoginSaga(mgr: UserManager) {
  const isLoggedIn = yield select(selectIsLoggedIn);
  if (!isLoggedIn) {
    return;
  }

  // attempt the silent redirect and if its successful update the user in redux
  try {
    const user: User = yield mgr.signinSilent();
    yield put(loginSucceeded({ user }));
  } catch (err) {
    // swallow the error, we can wait for the next silent renewal
  }
}

/**
 * Checks for an expired user token. If it is expired, the user is logged out.
 *
 * The condition to sign the user out will never be met in regular app usage since we are
 * constantly silencing renewing the token. It WILL occur when the user is not on our website
 * past the token expiration (website not open, browser closed, internet disconnected, etc...).
 *
 * exported for testing only
 * @returns true when the token is expired and user is being logged out, false otherwise
 */
export function* tokenExpirationSaga() {
  const tokenExpiration = yield select(selectTokenExpiration);

  if (isAccessTokenExpired(tokenExpiration)) {
    loggerUtil.info('@cmg/auth: tokenExpirationSaga Access Token Expired');

    yield put(oidcLogout());
    return true; // expired
  }

  return false; // not expired
}

/**
 * Runs continuously until the user's token is expired and signs the user out in that case.
 * While the token is not expired, it triggers silent renewals
 */
function* authMonitorSaga() {
  const monitorInterval = 5 * 1000; // 5 seconds

  while (true) {
    // leading edge delay so we only have to put the delay in one spot
    yield delay(monitorInterval);

    // First we check if the users token is expired
    const isExpired = yield call(tokenExpirationSaga);

    // we stop monitoring if the token is expired, since we will have logged the user out.
    if (isExpired) {
      return;
    }

    // Then we call a throttled silent login to refresh the user
    yield put(oidcSilentLogin());
  }
}

/**
 * Starts a race between the auth monitor and an event that cancels the race.
 * The auth monitor will never finish the race, so it runs indefinitely until
 * the cancel condition is met.
 * The whole idea here is that you want the monitor running when on a private route
 * and not running when on a public route.
 */
function* watchAuthMonitorSaga() {
  const appName = yield select(selectAppName);

  yield put(createMixPanelAction(ActionTypes.START_AUTH_MONITOR, `Authenticated: ${appName}`));

  yield race({
    task: call(authMonitorSaga),
    cancel: take(ActionTypes.STOP_AUTH_MONITOR),
  });

  loggerUtil.info('@cmg/auth: watchAuthMonitorSaga Race Condition Ended');
}

export function* authSaga() {
  const config = yield select(selectConfig) ?? {};
  const authConfigAuth = yield select(selectAuth);
  const authConfigClient = yield select(selectClient);
  const authConfigActions = yield select(selectActions);

  initOidcLogger(config.env?.isDevelopment ? Log.DEBUG : Log.INFO);

  const userManager = getUserManager(authConfigAuth, authConfigClient, authConfigActions);
  const callbackUserManager = getCallbackUserManager(authConfigAuth, authConfigClient);
  const silentLoginInterval = (authConfigAuth.tokenRenewalInterval ?? 30) * 60 * 1000;

  yield takeLatest(ActionTypes.OIDC_LOGIN, loginSaga, userManager);
  yield takeLatest(ActionTypes.OIDC_LOGIN_CALLBACK, loginCallbackSaga, callbackUserManager);

  /**
   * Throttled to several seconds because signout calls are not idempotent:
   *  OIDC logout can be triggered by several actions e.g.
   *    - access token expiring
   *    - api call made with expired access token
   *    - idle timeout reached
   *    - user pressing sign out
   *   If a second signout were to cancel the first, the second signout may fail with the first having partially completed signout.
   */
  yield throttle(10 * 1000, ActionTypes.OIDC_LOGOUT, logoutSaga, userManager);
  yield takeLatest(ActionTypes.OIDC_LOGOUT_CALLBACK, logoutCallbackSaga, callbackUserManager);

  // attempt a silent login
  yield throttle(silentLoginInterval, ActionTypes.OIDC_SILENT_LOGIN, silentLoginSaga, userManager);

  yield takeLatest(ActionTypes.START_AUTH_MONITOR, watchAuthMonitorSaga);
}
