import 'react-datepicker/dist/react-datepicker.css';

import styled from 'styled-components/macro';

export const SWrapper = styled.div`
  display: block;
  width: 100%;

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__input-container {
    display: block;

    .react-datepicker__close-icon {
      &::after {
        background-color: ${({ theme }) => theme.interactive.primary};
        border-radius: 2px;
        padding-top: 0;
        font-size: ${({ theme }) => theme.text.size.medium};
      }
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${({ theme }) => theme.border.color.white};
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: ${({ theme }) => theme.interactive.primary};
  }

  .react-datepicker-popper {
    z-index: 3;
  }

  .react-datepicker {
    font-family: 'Roboto', sans-serif, 'Helvetica Neue', Helvetica, Arial;
    font-weight: ${({ theme }) => theme.text.weight.regular};
    font-size: 13px;
    background-color: ${({ theme }) => theme.background.color.white};
    color: ${({ theme }) => theme.text.color.dark};
    border: 1px solid ${({ theme }) => theme.interactive.primary};
    border-radius: ${({ theme }) => theme.border.radius.large};

    .react-datepicker__triangle {
      border-bottom-color: ${({ theme }) => theme.border.color.white};

      &:before {
        border-bottom-color: ${({ theme }) => theme.interactive.primary};
      }
    }

    .react-datepicker__header {
      background-color: ${({ theme }) => theme.background.color.white};
      border-bottom: none;
      border-top-left-radius: ${({ theme }) => theme.border.radius.large};
      border-top-right-radius: ${({ theme }) => theme.border.radius.large};
      padding-top: 14px;
    }

    .react-datepicker-time__header {
      font-weight: ${({ theme }) => theme.text.weight.regular};
      font-size: 16px;
    }

    .react-datepicker__navigation {
      top: 16px;
      z-index: 1;
      border: 7px solid transparent;

      &.react-datepicker__navigation--previous {
        border-right-color: ${({ theme }) => theme.interactive.primary};
      }

      &.react-datepicker__navigation--next {
        border-left-color: ${({ theme }) => theme.interactive.primary};
      }
    }

    .react-datepicker__year-read-view {
      color: ${({ theme }) => theme.text.color.dark};
      font-size: 14px;
      font-weight: ${({ theme }) => theme.text.weight.medium};
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin: 0;

      .react-datepicker__year-read-view--down-arrow {
        border-top-color: ${({ theme }) => theme.interactive.primary};
        float: none;
        margin: 0;
        left: 10px;
        top: 0;
        border-width: 5px;
      }

      &:hover {
        .react-datepicker__year-read-view--down-arrow {
          border-top-color: ${({ theme }) => theme.interactive.primary};
        }
      }
    }

    .react-datepicker__year-dropdown {
      top: 35px;
      background-color: ${({ theme }) => theme.interactive.primary};
      color: ${({ theme }) => theme.text.color.white};
      border-color: ${({ theme }) => theme.interactive.primary};

      .react-datepicker__year-option {
        font-size: 14px;
        line-height: 24px;

        &:first-child,
        &:last-child {
          padding: 6px 0;
        }

        .react-datepicker__navigation--years-upcoming {
          top: -3px;
          border-bottom-color: ${({ theme }) => theme.border.color.white};
        }

        .react-datepicker__navigation--years-previous {
          top: 2px;
          border-top-color: ${({ theme }) => theme.border.color.white};
        }

        &:hover {
          background-color: ${({ theme }) => theme.background.color.white};
          color: ${({ theme }) => theme.interactive.primary};

          .react-datepicker__navigation--years-upcoming {
            border-bottom-color: ${({ theme }) => theme.interactive.primary};
          }

          .react-datepicker__navigation--years-previous {
            border-top-color: ${({ theme }) => theme.interactive.primary};
          }
        }
      }
    }

    .react-datepicker__current-month {
      color: ${({ theme }) => theme.text.color.dark};
      font-size: 16px;
      font-weight: ${({ theme }) => theme.text.weight.regular};
    }

    .react-datepicker__day-name {
      font-weight: ${({ theme }) => theme.text.weight.medium};
      color: ${({ theme }) => theme.text.color.dark};
      padding: 14px 0 0 0;
      width: 29px;
      line-height: 26px;
      margin: 1px;
    }

    .react-datepicker__month {
      margin: 0 10px 10px;

      .react-datepicker__day {
        font-weight: ${({ theme }) => theme.text.weight.medium};
        color: ${({ theme }) => theme.text.color.dark};
        width: 29px;
        line-height: 26px;
        margin: 1px;
        padding: 2px 1px 0 1px;

        &:hover {
          border-radius: 5px;
          background-color: ${({ theme }) => theme.background.color.light};
        }

        &.react-datepicker__day--selected,
        &.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-selecting-range) {
          border-radius: 5px;
          background-color: ${({ theme }) => theme.interactive.primary};
          color: ${({ theme }) => theme.text.color.white};
        }

        &.react-datepicker__day--in-range,
        &.react-datepicker__day--in-selecting-range {
          border-radius: 0;
          background-color: ${({ theme }) => theme.background.color.highlighted};
          margin-left: 0;
          margin-right: 0;
          width: 31px;

          &.react-datepicker__day--range-start,
          &.react-datepicker__day--selecting-range-start {
            color: ${({ theme }) => theme.text.color.white};
            background-color: ${({ theme }) => theme.interactive.primary};
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &.react-datepicker__day--range-end,
          &.react-datepicker__day--selecting-range-end {
            color: ${({ theme }) => theme.text.color.white};
            background-color: ${({ theme }) => theme.interactive.primary};
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        &.react-datepicker__day--outside-month,
        &.react-datepicker__day--disabled {
          color: ${({ theme }) => theme.text.color.placeholder};
        }
      }
    }

    .react-datepicker__time-container {
      border-color: ${({ theme }) => theme.interactive.primary};
      width: 70px;

      .react-datepicker__time {
        border-radius: ${({ theme }) => theme.border.radius.large};

        .react-datepicker__time-box {
          border-radius: ${({ theme }) => theme.border.radius.large};
          width: 70px;

          ul.react-datepicker__time-list {
            li.react-datepicker__time-list-item {
              height: 25px;
              padding: 6px 10px;
            }

            li.react-datepicker__time-list-item--selected {
              background-color: ${({ theme }) => theme.interactive.primary};
            }
          }
        }
      }
    }
  }
`;
