import { parse, parseISO } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { ISODateTime } from '../../../types/common';
import { Meridiem } from '../time-picker/TimePicker';
import { DateTimeValue } from './DateTimePicker.model';

export type TimeTuple = [string, Meridiem];

const defaultTime: TimeTuple = ['12:00', 'AM'];

/**
 * Extracts time from given date object in 12-hour format
 *
 * @returns ['hh:mm', 'AM | PM']
 */
export const getTime = (date: Date): TimeTuple => {
  const time = format(date, 'hh:mm');
  const meridiem = format(date, 'aa');

  return [time, meridiem as Meridiem];
};

/**
 * Combines ISO Date time string and 12-hour time format tuple into single Date object.
 */
export const combineDateAndTime = (isoDate: ISODateTime, meridiemTime: TimeTuple) => {
  // Convert 12 hour time format to military time
  return parse(meridiemTime.join(' '), 'hh:mm a', parseISO(isoDate));
};

/**
 * Convert ISO date string and 12-hour time format tuple
 * into UTC ISO date time with respect to selected IANA timezone.
 */
export const getNextDateTimeForDate = (
  date: ISODateTime,
  time: TimeTuple | null,
  timezone: string
): DateTimeValue => {
  const local = combineDateAndTime(date, time ?? defaultTime);
  const utcDate = zonedTimeToUtc(local, timezone);
  const isoDateTime = utcDate.toISOString();

  return { date: isoDateTime, timezone };
};

/**
 * Convert ISO date time string and 12-hour time format tuple
 * into UTC ISO date time with respect to selected IANA timezone.
 */
export const getNextDateTimeForUtcDateTime = (
  dateTime: ISODateTime,
  time: TimeTuple | null,
  timezone: string
): DateTimeValue => {
  const local = utcToZonedTime(dateTime, timezone);
  const isoDate = format(local, 'yyyy-MM-dd');

  return getNextDateTimeForDate(isoDate, time, timezone);
};

export const getDateTimeForNextTimezone = (
  date: ISODateTime,
  timezone: string,
  nextTimezone: string
): DateTimeValue => {
  const local = utcToZonedTime(date, timezone);
  const utcDate = zonedTimeToUtc(local, nextTimezone);
  const isoDateTime = utcDate.toISOString();

  return { date: isoDateTime, timezone: nextTimezone };
};
