import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export * from './mocks';
export * from './request-factory';
export * from './response-normalizers';

export type AuthInterceptor = (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
export type ClientResponseInterceptor<TResponseData = any> = (
  config: AxiosResponse<TResponseData>
) => AxiosResponse<TResponseData>;
export type ClientErrorInterceptor<TData> = (config: AxiosError<TData>) => AxiosError<TData>;
