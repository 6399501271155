import React from 'react';

import { SButtonsContainer } from './ButtonsContainer.styles';

export type Props = {
  /** gap in px between the buttons */
  margin?: number;
  /** row or column direction, defaults to row */
  direction?: 'row' | 'column';
  /** content alignment, defaults to left */
  justifyContent?: 'left' | 'right' | 'center';
  /** className to allow styling externally */
  className?: string;
};

/**
 * ButtonsContainer is a wrapper that should be used when displaying multiple buttons in a row or column format
 * it sets a consistent pixel gap between the buttons
 */
const ButtonsContainer: React.FC<Props> = ({
  direction = 'row',
  className,
  children,
  margin = 10,
  justifyContent = 'left',
}) => {
  return (
    <SButtonsContainer
      direction={direction}
      margin={margin}
      className={className}
      justifyContent={justifyContent}
    >
      {children}
    </SButtonsContainer>
  );
};

export default ButtonsContainer;
