import React from 'react';
import ReactDatePicker from 'react-datepicker';

import { ISODate } from '../../../types/common';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import InputWrapper from '../input-wrapper/InputWrapper';
import { FormControlEvents } from '../types';
import { formatDate, parseDate } from './DatePicker.model';
import { SWrapper } from './DatePicker.styles';
import { parseDateTime } from './DateTimePicker.model';

export interface Props
  extends FieldControlProps<ISODate | null, ISODate | null, HTMLInputElement>,
    FormControlEvents<HTMLInputElement> {
  placeholder?: string;
  showYearDropdown?: boolean;
  autoComplete?: boolean;
  maxDate?: ISODate;
  minDate?: ISODate;
  onCalendarClose?: () => void;
  testId?: string;
  timezone?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  flipDisabled?: boolean;
}

class DatePicker extends React.Component<Props> {
  handleChange = (date: Date | null) => {
    const { onChange } = this.props;

    const value = formatDate(date);
    onChange && onChange(value);
  };

  render() {
    const {
      hasError,
      placeholder = 'MM/DD/YYYY',
      value,
      disabled,
      autoComplete,
      minDate,
      maxDate,
      onCalendarClose,
      testId,
      required,
      fullWidth,
      timezone,
      prefix,
      suffix,
      flipDisabled,
      ...restProps
    } = this.props;

    const selectedDate = timezone
      ? parseDateTime({ date: value, timezone }, timezone)
      : parseDate(value);

    return (
      <InputWrapper disabled={disabled} hasError={hasError} prefix={prefix} suffix={suffix}>
        <SWrapper data-test-id={testId}>
          <ReactDatePicker
            {...restProps}
            popperModifiers={{
              flip: {
                enabled: !flipDisabled,
              },
            }}
            autoComplete={autoComplete ? 'on' : 'off'}
            minDate={parseDate(minDate)}
            maxDate={parseDate(maxDate)}
            dateFormat="MM/dd/yyyy"
            timeFormat="HH:mm"
            timeCaption="Time"
            disabled={disabled}
            disabledKeyboardNavigation
            dropdownMode="scroll"
            isClearable={!disabled}
            placeholderText={placeholder}
            selected={selectedDate}
            onChange={this.handleChange}
            onCalendarClose={onCalendarClose}
          />
        </SWrapper>
      </InputWrapper>
    );
  }
}

export default DatePicker;

/**
 * @todo: figure out how to set field touched here or in createFormikForm when day is selected
 * onBlur is not called when a day is selected
 * when validateOnChange: true & validateOnBlur: false in a form
 * use onCalendarClose callback to setFieldTouched to show error messages
 */
export const DatePickerField = createFormikField<
  ISODate | null,
  ISODate | null,
  HTMLInputElement,
  Props
>(DatePicker);
