import { PaginatedResponse } from '../../core';
import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makeGetRequest, makePostDownloadRequest } from '../../utils';
import { apiClient } from '../apiClient';
import {
  CalendarOffering,
  CalendarOfferingQuery,
  CalendarOfferingsRequestDto,
  DownloadCalendarOfferingsRequestBody,
} from './types/CalendarOffering';

/**
 * Retrieve the exhaustive list of offerings scoped to a single calendar category
 */
export const getCalendarOfferings = makeGetRequest<
  PaginatedResponse<CalendarOffering[]>,
  CalendarOfferingQuery
>(apiClient, () => '/calendarOfferings');

export type GetCalendarOfferingsResponse = UnpackPromise<ReturnType<typeof getCalendarOfferings>>;

/**
 * Download an xlsx of offerings scoped to a single calendar category
 */
export const downloadCalendarOfferingsLegacy = makePostDownloadRequest<
  DownloadCalendarOfferingsRequestBody,
  {}
>(apiClient, () => `/calendarOfferings/download`);

export type DownloadCalendarOfferingsResponseLegacy = UnpackPromise<
  ReturnType<typeof downloadCalendarOfferingsLegacy>
>;

/**
 * Download an xlsx of calendar offerings
 */
export const downloadCalendarOfferings = makePostDownloadRequest<CalendarOfferingsRequestDto, {}>(
  apiClient,
  () => `/offerings/download`
);

export type DownloadCalendarOfferingsResponse = UnpackPromise<
  ReturnType<typeof downloadCalendarOfferings>
>;
