import React from 'react';

import { PublicAuthState } from '../../types';
import useAuth from '../hooks/useAuth';

/**
 * AuthConsumer properties
 */
type Props = {
  /** children content that requires access to oidc data or actions */
  children: (props: PublicAuthState) => React.ReactElement<PublicAuthState>;
};

/**
 * AuthConsumer is used to provide access to the auth redux store and exposes
 * oidcUser attributes and duck actions that can be used by the consumer
 *
 * @example
 * <AuthConsumer>
 *   {({ oidcUserId, selfSubdomain, etc...})} => <div>{oidcUserId}</div>
 * </AuthConsumer>
 *  */
const AuthConsumer: React.FC<Props> = ({ children, ...props }) => children(useAuth());

export default AuthConsumer;
