import { loggerUtil, urlUtil } from '@cmg/common';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isObjectLike from 'lodash/isObjectLike';
import * as oidcClient from 'oidc-client-ts';

import { AuthProviderConfig } from '../types/api/AuthProviderConfig';
import { getManagers, setCallbackUserManager, setUserManager } from './userManagerStorage';

export type { User, UserManager } from './userManagerStorage';

const hostBaseUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

const getLogMessageAndContext = (...args: unknown[]) => {
  const message = args.filter(x => !isNil(x) && (isArray(x) || !isObject(x))).join(' ');
  const context = args.reduce((acc, curr) => {
    if (isObjectLike(curr) && !isArray(acc)) {
      return { ...(acc ?? {}), ...(curr ?? {}) };
    }

    return acc;
  }, {}) as object;

  return { message, context };
};

const getLogger = (): oidcClient.ILogger => {
  return {
    warn(...args) {
      const { message, context } = getLogMessageAndContext(...args);
      loggerUtil.warning(message, context);
    },
    info(...args) {
      const { message, context } = getLogMessageAndContext(...args);
      loggerUtil.info(message, context);
    },
    error(...args) {
      const { message, context } = getLogMessageAndContext(...args);
      loggerUtil.error(new Error(message), context);
    },
    debug(...args) {
      const { message, context } = getLogMessageAndContext(...args);
      loggerUtil.info(message, context);
    },
  };
};

export const initOidcLogger = (logLevel: oidcClient.Log) => {
  oidcClient.Log.setLevel(logLevel);
  oidcClient.Log.setLogger(getLogger());
};

/**
 * Gets the SPA base url
 * if the basename contains a trailing slash it will be trimmed out
 */
const getSpaBaseUrl = client => {
  const regex = /(.*)\/$/;
  const url = `${hostBaseUrl}${client.basename}`;
  const containsTrailingSlash = regex.test(url);
  // if the trailing slash exists trim it out
  return containsTrailingSlash ? url.slice(0, -1) : url;
};

/**
 * Concatenates account subdomain to the oidc authority base url
 * @param oidcAuthorityBaseUrl
 */
export const getAuthorityUrl = (oidcAuthorityBaseUrl: string) => {
  const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
  return urlUtil.addSubdomainToUrl(oidcAuthorityBaseUrl, accountSubdomain);
};

const getOidcUserManager = (
  authConfigAuth: AuthProviderConfig['auth'],
  authConfigClient: AuthProviderConfig['client']
) => {
  return new oidcClient.UserManager({
    authority: getAuthorityUrl(authConfigAuth.oidcAuthorityBaseUrl),
    client_id: authConfigAuth.clientId,
    scope: ['openid', 'email', 'profile', ...(authConfigAuth.scopes || [])].join(' '),
    response_type: 'code',
    redirect_uri: `${getSpaBaseUrl(authConfigClient)}/oidc-login-callback`,
    post_logout_redirect_uri: `${getSpaBaseUrl(authConfigClient)}/oidc-logout-callback`,
    // ## DO NOT CHANGE ## - matches client.json in the dotnet repo
    silent_redirect_uri: `${getSpaBaseUrl(authConfigClient)}/oidc_silent_callback.html`,
    // Instead, we control the renew lifecycle.
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    validateSubOnSilentRenew: false,
    includeIdTokenInSilentRenew: true,
    monitorSession: true,
    loadUserInfo: true,
    revokeTokensOnSignout: true,
  });
};

const getUserManager = (
  authConfigAuth: AuthProviderConfig['auth'],
  authConfigClient: AuthProviderConfig['client'],
  authConfigActions: AuthProviderConfig['actions']
) => {
  const { userManager } = getManagers();

  if (userManager) {
    return userManager;
  }

  const newUserManager = getOidcUserManager(authConfigAuth, authConfigClient);

  // If the user has signed out at the OP,
  // redirect to the logged out route.
  newUserManager.events.addUserSignedOut(async () => {
    await newUserManager.removeUser();
    authConfigActions.onLogout();
  });

  setUserManager(newUserManager);

  return newUserManager;
};

const getOidcClientCallbackManager = (authority: string, clientId: string, redirectUri: string) => {
  return new oidcClient.UserManager({
    authority,
    client_id: clientId,
    redirect_uri: redirectUri,
    loadUserInfo: true,
    response_type: 'query',
  });
};

/**
 * The user manager used on the callbacks don't need all the extra settings.
 * The necessary info is grabbed by the UserManager from the url
 */
export const getCallbackUserManager = (
  authConfigAuth: AuthProviderConfig['auth'],
  authConfigClient: AuthProviderConfig['client']
) => {
  const { callbackUserManager } = getManagers();

  if (callbackUserManager) {
    return callbackUserManager;
  }

  const authority = getAuthorityUrl(authConfigAuth.oidcAuthorityBaseUrl);
  const redirectUri = `${getSpaBaseUrl(authConfigClient)}/oidc-login-callback`;

  const newCallbackUserManager = getOidcClientCallbackManager(
    authority,
    authConfigAuth.clientId,
    redirectUri
  );

  setCallbackUserManager(newCallbackUserManager);

  return newCallbackUserManager;
};

export default getUserManager;
