import { checkPermissions } from '../../../common/util/authorization';
import useAuth from './useAuth';

export function useCheckPermissions(
  requiredPermissions: string[],
  requireAllPermissions: boolean = true
) {
  const { userPermissions } = useAuth();
  return checkPermissions(userPermissions, requiredPermissions, requireAllPermissions);
}

export default useCheckPermissions;
