import { createFormikField } from '../../formik/createFormikField';
import CurrencyInput from '../../numeric-currency-input/CurrencyInput';
import { createRangeInput, Props, RangeInputValue } from '../create-range-input/createRangeInput';

const CurrencyRangeInput = createRangeInput(CurrencyInput);
export default CurrencyRangeInput;

export const CurrencyRangeInputField = createFormikField<
  RangeInputValue,
  RangeInputValue,
  HTMLInputElement,
  Props
>(CurrencyRangeInput);
