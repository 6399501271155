import { combineReducers } from 'redux';

import auth, {
  ActionTypes as AuthActionTypes,
  ReducerState as AuthReducerState,
} from '../../auth/ducks';

export type RootState = {
  auth: AuthReducerState;
};

const appReducer = combineReducers<RootState>({
  auth,
});

const defaultReducer = (state, action) => {
  if (action.type === AuthActionTypes.LOGOUT_SUCCEEDED) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default defaultReducer;
