import React from 'react';

import { ISODate } from '../../../types/common';
import { getTimeZoneOptions, timeZoneLabels } from '../../../util/time/time-zone';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import Select from '../select/Select';
import { StyledConfig } from '../select/types';
import { FormControlEvents } from '../types';
import { STimeZoneLabel, STimeZoneOption } from './TimeZonePicker.styles';

export interface Props extends FieldControlProps<string | null, string | null>, FormControlEvents {
  date: ISODate | null | undefined;
  isClearable?: boolean;
  styledConfig?: StyledConfig;
  placeholder?: string;
}

const TimeZonePicker: React.FC<Props> = ({
  date,
  value,
  isClearable,
  disabled,
  styledConfig,
  placeholder,
  onChange,
  onClick,
  onFocus,
  onBlur,
  hasError,
  onMouseEnter,
  onMouseLeave,
}) => {
  const options = date ? getTimeZoneOptions(date) : [];

  return (
    <Select
      disabled={disabled || !date}
      isClearable={isClearable}
      options={options}
      styledConfig={styledConfig}
      value={value}
      placeholder={placeholder}
      hasError={hasError}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      renderSingleSelectedOption={option => option.label}
      renderOption={option => (
        <STimeZoneOption>
          <span>{option.label}</span>
          <STimeZoneLabel isSelected={option.value === value}>
            {timeZoneLabels[option.label]}
          </STimeZoneLabel>
        </STimeZoneOption>
      )}
    />
  );
};

export default TimeZonePicker;

export const TimeZonePickerField = createFormikField<
  string | null,
  string | null,
  HTMLInputElement,
  Props
>(TimeZonePicker);
