import React from 'react';

import Icon from '../../graphics/icon/Icon';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import { FormControlEvents } from '../types';
import { SCheckbox, SContainer, SContent, SIcon, SInput } from './Checkbox.styles';

export type CheckboxProps = {
  id?: string;
  testId?: string;
  indeterminate?: boolean;
  innerRef?: React.Ref<HTMLInputElement>;
  /** Children needs to be listed in props to allow children to be provided in the formik-wrapped checkbox component. */
  children?: React.ReactNode;
  className?: string;
  /**
   * Set to true when styles making the checkbox look disabled should be applied also to checkbox's icon wrapper
   * (setting this to true will render square representing the checkbox greyed out when it is disabled)
   */
  showDisabledIcon?: boolean;
};

export type Props = CheckboxProps &
  FieldControlProps<boolean, boolean, HTMLLabelElement | HTMLInputElement> &
  FormControlEvents<HTMLLabelElement | HTMLInputElement>;

export const CheckboxComponent: React.FC<Props> = ({
  id = '',
  className,
  value = false,
  indeterminate = false,
  onChange,
  innerRef,
  children, // passing in children inserts adjacent to checkbox and allows for checkability
  required,
  fullWidth,
  onFocus,
  onBlur,
  testId,
  showDisabledIcon = true,
  ...inputProps
}) => {
  const handleOnChange = () => {
    onChange && onChange(!value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ' ') {
      handleOnChange();
    }
  };

  return (
    <SContainer
      tabIndex={0}
      className={className}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
    >
      <SInput
        {...inputProps}
        id={id}
        type="checkbox"
        onChange={handleOnChange}
        checked={!!value}
        ref={innerRef}
        aria-checked={!!value}
      />

      <SCheckbox
        data-test-id={testId}
        checked={value}
        indeterminate={indeterminate}
        role="checkbox"
        aria-checked={value}
        disabled={showDisabledIcon && inputProps.disabled}
      >
        <SIcon disabled={inputProps.disabled}>
          <Icon name={indeterminate ? 'minus' : 'check'} variant="solid" size="xs" />
        </SIcon>
      </SCheckbox>

      {children && <SContent role="label">{children}</SContent>}
    </SContainer>
  );
};

const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <CheckboxComponent innerRef={ref} {...props} />;
});
Checkbox.displayName = 'Checkbox';

export default Checkbox;
export const CheckboxField = createFormikField<boolean, boolean, HTMLInputElement, Props>(Checkbox);
