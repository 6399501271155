// todo refactor

import { getOperationName } from '@apollo/client/utilities';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import routeFactory from '../../../../common/util/routeFactory';
import { Pill } from '../../../../obsolete/components/ui';
import {
  InternationalOfferingTypeLabels,
  offeringTypeLabels,
} from '../../../../types/domain/offering/constants';
import { Offering } from '../../../../types/domain/offering/offering';
import { Pricing } from '../../../../types/domain/offering/pricing';
import { CompanyProfileDocument } from '../../../company/graphql/__generated__/CompanyProfile';
import {
  isInternationalOfferingsOn,
  isInternationalOfferingTypesOn,
} from '../../../datalab/model/utils';
import { useCompaniesGrid_UpsertCustomSectorIssuersMutation } from '../../../settings/companies/companies-grid/graphql/__generated__/CompaniesGrid_Issuers';
import useFollowOfferingMutation from '../../../shared/follow-offering/useFollowOfferingMutation';
import ScreenSubHeader from '../../../shared/layout/ScreenSubHeader';
import { getCurrencyFormatInBillions } from '../../../shared/model/utils';
import SectorProvider from '../../../shared/sectors/SectorProvider';
import { onAssignSectorError } from '../../../shared/sectors/sectors.model';
import { fetchOfferingActions, selectOffering } from '../ducks';
import TopButton from './TopButton';

export const getFormattedOfferingSize = (
  pricing: Pricing,
  pricingCurrencyCode: string
): string | null => {
  const showInternational = isInternationalOfferingsOn();
  const value = showInternational ? pricing.sizeInLocalCurr : pricing.sizeInDollars;
  return getCurrencyFormatInBillions({ value, pricingCurrencyCode, showInternational });
};

const mapStateToProps = state => ({
  offering: selectOffering(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      refetchOffering: fetchOfferingActions.request,
    },
    dispatch
  ),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const TickerTitle: React.FC<Offering> = offering => {
  const {
    symbol,
    issuer: { ticker },
  } = offering;

  return <span className="ticker">&nbsp;({symbol && symbol.length > 0 ? symbol : ticker})</span>;
};

export const OfferingSubHeader: React.FC<Props> = ({ offering, actions }) => {
  const { setFollowingOffering } = useFollowOfferingMutation({
    refetch: () => actions.refetchOffering({ offeringId: offering.id }),
  });

  const handleOnToggleFollow = async () => {
    await setFollowingOffering(offering.id, !offering.userIsFollowing);
  };

  const [assignCustomSectorToIssuer, { loading: upsertLoading }] =
    useCompaniesGrid_UpsertCustomSectorIssuersMutation({
      refetchQueries: [getOperationName(CompanyProfileDocument)!],
      onError: onAssignSectorError,
    });

  const handleSubmit = async (sectorId: string) => {
    await assignCustomSectorToIssuer({
      variables: {
        input: {
          customSectorId: sectorId,
          issuerIds: [offering.issuer.firmId],
        },
      },
    });

    actions.refetchOffering({ offeringId: offering.id });
  };

  return (
    <SectorProvider onSubmit={handleSubmit} isSubmitting={upsertLoading}>
      {({ SectorToggle, SectorPicker }) => (
        <ScreenSubHeader
          title={
            <span>
              <Link to={routeFactory.companies.getUrlPath({ id: offering.issuer.firmId })}>
                {offering.issuer.firmName}
              </Link>
              <TickerTitle {...offering} />
              <Pill type="primary" uppercase>
                {offering.status}
              </Pill>
            </span>
          }
          bottomData={[
            {
              label: 'Offering Type',
              value:
                offering.pricingCurrencyCode !== 'USD' && isInternationalOfferingTypesOn()
                  ? InternationalOfferingTypeLabels[offering.type]
                  : offeringTypeLabels[offering.type],
            },
            {
              label: <SectorToggle />,
              value: (
                <SectorPicker
                  sector={offering.issuer.sector}
                  customSector={
                    offering.issuer.customSector && offering.issuer.customSector.sectorName
                  }
                />
              ),
            },
          ]}
          rightData={[
            {
              label: <div>Offering Size</div>,
              value: getFormattedOfferingSize(offering.pricing, offering.pricingCurrencyCode),
            },
          ]}
          rightButton={
            <div className="subheader-buttons">
              <TopButton
                primary={offering.userIsFollowing}
                hint={offering.userIsFollowing ? 'Unfollow' : 'Follow'}
                icon="thumbtack"
                onClick={handleOnToggleFollow}
              />
            </div>
          }
        />
      )}
    </SectorProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferingSubHeader);
