import styled from 'styled-components/macro';

import { SButton } from '../button/Button.styles';

export const SWrapper = styled.div`
  display: inline-block;
`;

export const SFlexWrapper = styled.div`
  display: flex;

  > ${SButton}:first-child:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  > :first-child:not(:last-child) ${SButton} {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  > ${SButton}:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
  }

  > :last-child:not(:first-child) ${SButton} {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;
  }

  > ${SButton}:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  > ${SButton} {
    position: relative;
  }
`;
