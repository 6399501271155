/**
 * Checks whenever user permissions are sufficient to required permissions
 * @param userPermissions an array of strings in <access-level>:<resource> format
 * @param requiredPermissions an array of strings in <access-level>:<resource> format
 * @param requireAllPermissions when true, requires 'userPermissions' to include ALL permissions in 'requiredPermissions'
 * @returns {boolean}
 */
export const checkPermissions = (
  userPermissions: string[],
  requiredPermissions: string[],
  requireAllPermissions: boolean = true
) => {
  const arrayLoopMethod = requireAllPermissions
    ? requiredPermissions.every.bind(requiredPermissions)
    : requiredPermissions.some.bind(requiredPermissions);
  return arrayLoopMethod(permission => {
    const [access, resource] = permission.split(':', 2);
    return (
      userPermissions.includes(`${access}:${resource}`) ||
      userPermissions.includes(`full:${resource}`)
    );
  });
};

/**
 * isAccessTokenExpired - checks if an expiration token is expired
 * @param tokenExpiration {number | null} unix timestamp specified in seconds
 */
export const isAccessTokenExpired = (tokenExpiration: number | null): boolean => {
  if (!tokenExpiration) {
    return false;
  }
  const tokenExpirationInMilliseconds = tokenExpiration * 1000;
  const nowInMilliseconds = Date.now();

  if (nowInMilliseconds >= tokenExpirationInMilliseconds) {
    return true;
  }

  return false;
};

/**
 * Checks whenever user has a specific trait or traits
 * @param userTraits an array of strings.
 * @param requiredTraits an array of strings.
 * @param requireAllTraits when true, requires 'userTraits' to include ALL traits in 'requiredTraits'
 * @returns {boolean}
 */
export const checkAccountTraits = (
  userTraits: string[],
  requiredTraits: string[],
  requireAllTraits: boolean = true
) => {
  const arrayLoopMethod = requireAllTraits
    ? requiredTraits.every.bind(requiredTraits)
    : requiredTraits.some.bind(requiredTraits);
  return arrayLoopMethod(trait => {
    return userTraits.includes(trait);
  });
};
