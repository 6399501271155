import * as oidcClient from 'oidc-client-ts';

export type User = oidcClient.User;
export type UserManager = oidcClient.UserManager;

let userManager: UserManager | undefined;
let callbackUserManager: UserManager | undefined;

export const getManagers = () => Object.freeze({ userManager, callbackUserManager });
export const setUserManager = (manager: UserManager) => {
  if (userManager) {
    throw Error('User manage is already set');
  }

  userManager = manager;
};
export const setCallbackUserManager = (manager: UserManager) => {
  if (callbackUserManager) {
    throw Error('Callback user manage is already set');
  }

  callbackUserManager = manager;
};
