import styled from 'styled-components/macro';

export const SContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const STextContent = styled.span`
  font-weight: 500;
`;

export const SaveButton = styled.a`
  opacity: 1 !important;
  font-weight: unset !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
