import React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components/macro';

import theme from '../../../styles/theme';

/**
 * The CMG theme is supplied to the styled-components ThemeProvider to provide access
 * to the theme to every component nested within it.
 * Usage: Wrap entire SPA react tree with this component.
 */
const ThemeProvider: React.FC<{ children: React.ReactChild }> = ({ children }) => (
  <SCThemeProvider theme={theme}>
    <React.Fragment>{children}</React.Fragment>
  </SCThemeProvider>
);

export default ThemeProvider;
