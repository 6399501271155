import React from 'react';
import styled from 'styled-components/macro';

import theme from '../../../../styles/theme';
import Icon from '../../../graphics/icon/Icon';

export const SPageOuterWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.background.color.light};
  font-weight: ${({ theme }) => theme.text.weight.light};
  color: ${({ theme }) => theme.text.color.dark};
`;
export const SPageInnerWrapper = styled.div`
  flex: 0 1 400px;
  text-align: center;
`;

/**
 * An ApplicationError UI with a light
 * theme.
 */
const LightErrorTemplate: React.FC = ({ children }) => {
  return (
    <SPageOuterWrapper>
      <SPageInnerWrapper>
        <Icon name="ban" size="7x" variant="solid" color={theme.text.color.light} />
        {children}
      </SPageInnerWrapper>
    </SPageOuterWrapper>
  );
};

export default LightErrorTemplate;
