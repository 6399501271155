import { RootState } from '../../../common/redux/rootReducer';
import { makeAuthConfig } from '../domain/auth-config/authConfig';
import { makeOidcUser } from '../domain/oidc-user/oidcUser';
import makeEntity from '../entityFactory';

export const makeState = makeEntity<RootState>({
  auth: {
    oidcUser: makeOidcUser(),
    authConfig: makeAuthConfig(),
  },
});
