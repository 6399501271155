import styled, { css } from 'styled-components/macro';

export const SDateTimeZonePicker = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      > * {
        flex: 1;
        min-width: unset;
        display: block;
      }
    `}
`;
