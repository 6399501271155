import React from 'react';

import { AccessTokenProp } from '../../types';
import useAccessToken from '../hooks/useAccessToken';

/**
 * withAccessToken is a HOC component that provides the AccessTokenProp to the wrapped component
 * @example
 *  const MyComponent = (props: AccessTokenProp) => { // console.log(props.accessToken) }
 *  withAccessToken(MyComponent);
 *
 * @param WrappedComponent
 */

export function withAccessToken<T>(
  WrappedComponent: React.ComponentType<T & AccessTokenProp>
): React.FunctionComponent<T> {
  const ComponentWithAccessToken = (props: T) => {
    const accessToken = useAccessToken();

    return <WrappedComponent {...props} accessToken={accessToken} />;
  };

  return ComponentWithAccessToken;
}

export default withAccessToken;
