import React from 'react';
import { ThemeConsumer } from 'styled-components/macro';

import BaseButton, { ButtonProps, StyledConfig } from './Button';

const InfoButton: React.FC<ButtonProps> = props => (
  <ThemeConsumer>
    {theme => {
      const styledConfig: StyledConfig = {
        color: theme.text.color.white,
        hoverColor: theme.text.color.white,
        backgroundColor: theme.brand.color.info,
        hoverBackgroundColor: theme.brand.color.infoAlternate,
        borderColor: theme.brand.color.info,
      };
      return <BaseButton {...props} styledConfig={styledConfig} />;
    }}
  </ThemeConsumer>
);

export default InfoButton;
