import { datalabApi } from '@cmg/api';
import { ColDef, CreateCellStyleFn, getCurrencySymbol, numericUtil, timeUtil } from '@cmg/common';
import { Column } from 'ag-grid-community';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';

import PerformancePercents from '../../../../common/components/format/performance-percents/PerformancePercents';
import { formatBoolean } from '../../../../common/helpers/helpers';
import routeFactory from '../../../../common/util/routeFactory';
import {
  AdviserRole,
  OfferingFilterInput,
  OfferingManagerRole,
  SecurityType,
  SortEnumType,
} from '../../../../graphql/__generated__/index';
import { NestedSortInput } from '../../../../graphql/types';
import {
  getFormattedPercentageRange,
  getFormattedPercentageValue,
  managerSortFn,
} from '../../../shared/model/utils';
import { AtmColumnKeys, AtmOffering } from '../../atm-offerings-report/types';
import { ConvertColumnKeys, ConvertOffering } from '../../convert-offerings-report/types';
import LeftLeadRenderer from '../leftlead-renderer/LeftleadRenderer';
import ManagersRenderer from '../managers-renderer/ManagersRenderer';
import SummaryHeader from '../summary-header/SummaryHeader';
import { ManagerAttributes, Offering, OfferingAggregations, SharedOfferingGridKeys } from './types';

const percentagePerformanceFormatter = (
  value?: number | null,
  precision: number = 2
): JSX.Element => <PerformancePercents value={value} precision={precision} />;

const currencyRenderer = ({
  value,
  pricingCurrencyCode,
}: {
  value: number | null;
  pricingCurrencyCode: string | null;
}): string =>
  !isNil(value) && pricingCurrencyCode
    ? numericUtil.formatCurrency(value, 2, getCurrencySymbol(pricingCurrencyCode))
    : '-';
const currencyInMillionRenderer = ({
  value,
  pricingCurrencyCode,
}: {
  value: number | null;
  pricingCurrencyCode: string | null;
}): string =>
  !isNil(value) && pricingCurrencyCode
    ? numericUtil.formatCurrencyInMillions(value, undefined, getCurrencySymbol(pricingCurrencyCode))
    : '-';
// define the columns that should be right aligned
const rightAligned = {
  type: 'rightAligned',
};

const booleanValueFormatter = (value?: boolean | null) =>
  value !== null && value !== undefined ? formatBoolean(value) : '-';

const booleanColumn = {
  valueFormatter: ({ value }) => booleanValueFormatter(value as boolean | undefined),
};

const formatDate = (v?: string | Date | null): string =>
  timeUtil.formatAsDisplayDate(v ?? '') ?? '-';

const dateColumn = {
  valueFormatter: ({ value }) => formatDate(value as Date | string),
};

const getAdviserNameByRole = (advisers: ConvertOffering['advisers'], role: AdviserRole) => {
  const advisersByRole = advisers?.filter(adviser => adviser.role === role) ?? [];
  return advisersByRole.length > 0 ? advisersByRole.map(adviser => adviser?.name).join(', ') : '-';
};

const getManagerNameByRole = (
  managers: ManagerAttributes[],
  role: OfferingManagerRole
): ManagerAttributes[] => {
  return managers.filter(manager => manager.role === role);
};

const managerRenderer = (managerNames?: readonly ManagerAttributes[]): string => {
  const sortedManagers = managerNames?.slice().sort(managerSortFn) ?? [];
  return sortedManagers.length > 0 ? sortedManagers.map(manager => manager?.name).join(', ') : '-';
};

const stringValueFormatter = (value?: string | null): string => value ?? '-';

const stringValueColumn = {
  valueFormatter: ({ value }) => stringValueFormatter(value),
};

const convertibleAttributesSortModel = (sortModel: NestedSortInput) => ({
  convertibleAttributes: sortModel,
});

const attributesSortModel = (sortModel: NestedSortInput) => ({
  attributes: sortModel,
});

const sortModelMappings: { [field: string]: (sort: SortEnumType) => NestedSortInput } = {
  issuer_name: sort => ({ issuer: { name: sort } }),
  issuer_sectorDisplayName: sort => ({ issuer: { sector: { name: sort } } }),
  typeDisplayName: sort => ({ type: { name: sort } }),
  securityTypeDisplayName: sort => ({ securityType: { name: sort } }),
  attributes_priceUsd: sort => attributesSortModel({ priceUsd: sort }),
  attributes_latestGrossProceedsBaseUsd: sort =>
    attributesSortModel({ latestGrossProceedsBaseUsd: sort }),
  attributes_latestGrossProceedsTotalUsd: sort =>
    attributesSortModel({ latestGrossProceedsTotalUsd: sort }),
  // attributes_notionalOverAllotmentExercised: sort =>
  //   attributesSortModel({ notionalOverAllotmentExercised: sort } ), // field not available yet
  attributes_pctGrossSpread: sort => attributesSortModel({ pctGrossSpread: sort }),
  attributes_grossSpreadTotalUsd: sort => attributesSortModel({ grossSpreadTotalUsd: sort }),
  exchangeRegionDisplayName: sort => ({
    exchangeRegion: { displayName: sort },
  }),
  exchangeCountryDisplayName: sort => ({
    exchangeCountry: { displayName: sort },
  }),
  exchangeMicDisplayName: sort => ({ exchangeMic: { displayName: sort } }),
  pricingCurrencyDisplayName: sort => ({
    pricingCurrency: { displayName: sort },
  }),
  leftLead: sort => attributesSortModel({ leftLeadName: sort }),
  // publicFilingDate: sort => ({ publicFilingDate: sort }),
  announcementDate: sort => ({ publicFilingDate: sort }),
  marketTimingDisplayName: sort => ({
    marketTiming: { displayName: sort },
  }),
  announcementTime: sort => ({
    marketTiming: { displayName: sort },
  }),
  attributes_terminatedDate: sort => attributesSortModel({ terminatedDate: sort }),
  issuer_primarySymbol: sort => ({ issuer: { primarySymbol: sort } }),
  issuer_cik: sort => ({ issuer: { cik: sort } }),
  statusDisplayName: sort => ({ status: { name: sort } }),
  attributes_latestSizeInSecuritiesTotal: sort => ({
    attributes: { latestSizeInSecuritiesTotal: sort },
  }),
  attributes_latestGrossProceedsTotal: sort =>
    attributesSortModel({ latestGrossProceedsTotal: sort }),
  attributes_marketCapPreOffering: sort => attributesSortModel({ marketCapPreOffering: sort }),
  attributes_pctMarketCapPreOffering: sort =>
    attributesSortModel({ pctMarketCapPreOffering: sort }),
  attributes_preOfferingAdtv: sort => attributesSortModel({ preOfferingAdtv: sort }),
  attributes_lastTradeBeforeFilingUsd: sort =>
    attributesSortModel({ lastTradeBeforeFilingUsd: sort }),
  convertibleAttributes_underlyingSymbol: sort =>
    convertibleAttributesSortModel({ underlyingSymbol: sort }),
  convertibleAttributes_securityNote: sort =>
    convertibleAttributesSortModel({ securityNote: sort }),
  convertibleAttributes_rankDisplayName: sort =>
    convertibleAttributesSortModel({ rank: { displayName: sort } }),
  convertibleAttributes_isPerpetual: sort => convertibleAttributesSortModel({ isPerpetual: sort }),
  convertibleAttributes_tenor: sort => convertibleAttributesSortModel({ tenor: sort }),
  convertibleAttributes_hasDividendProtection: sort =>
    convertibleAttributesSortModel({ hasDividendProtection: sort }),
  convertibleAttributes_dividendProtectionNote: sort =>
    convertibleAttributesSortModel({ dividendProtectionNote: sort }),
  convertibleAttributes_isCallable: sort => convertibleAttributesSortModel({ isCallable: sort }),
  convertibleAttributes_callableDate: sort =>
    convertibleAttributesSortModel({ callableDate: sort }),
  convertibleAttributes_callPrice: sort => convertibleAttributesSortModel({ callPrice: sort }),
  convertibleAttributes_hasProvisionalCall: sort =>
    convertibleAttributesSortModel({ hasProvisionalCall: sort }),
  convertibleAttributes_provisionalCallNote: sort =>
    convertibleAttributesSortModel({ provisionalCallNote: sort }),
  convertibleAttributes_isPuttable: sort => convertibleAttributesSortModel({ isPuttable: sort }),
  convertibleAttributes_putNote: sort => convertibleAttributesSortModel({ putNote: sort }),
  convertibleAttributes_hasContingentConversion: sort =>
    convertibleAttributesSortModel({ hasContingentConversion: sort }),
  convertibleAttributes_contingentConversionNote: sort =>
    convertibleAttributesSortModel({ contingentConversionNote: sort }),
  convertibleAttributes_hasContingentPayment: sort =>
    convertibleAttributesSortModel({ hasContingentPayment: sort }),
  convertibleAttributes_contingentPaymentNote: sort =>
    convertibleAttributesSortModel({ contingentPaymentNote: sort }),
  convertibleAttributes_isHedging: sort => convertibleAttributesSortModel({ isHedging: sort }),
  convertibleAttributes_hedgingNote: sort => convertibleAttributesSortModel({ hedgingNote: sort }),
  convertibleAttributes_changeOfControl: sort =>
    convertibleAttributesSortModel({ changeOfControl: sort }),
  atmAttributes_effectiveDate: sort => ({ atmAttributes: { effectiveDate: sort } }),
  atmAttributes_pctGrossSpread: sort => ({ atmAttributes: { pctGrossSpread: sort } }),
  atmAttributes_lastTradeBeforeFilingSplitAdjusted: sort => ({
    atmAttributes: { lastTradeBeforeFilingSplitAdjusted: sort },
  }),
  atmAttributes_totalProgramRemaining: sort => ({ atmAttributes: { totalProgramRemaining: sort } }),
  atmAttributes_totalProgramRemainingInSecurities: sort => ({
    atmAttributes: { totalProgramRemainingInSecurities: sort },
  }),
  atmAttributes_latestProgramSize: sort => ({ atmAttributes: { latestProgramSize: sort } }),
  atmAttributes_latestProgramSizeInSecurities: sort => ({
    atmAttributes: { latestProgramSizeInSecurities: sort },
  }),
  atmAttributes_announcedProgramSize: sort => ({ atmAttributes: { announcedProgramSize: sort } }),
  atmAttributes_announcedProgramSizeInSecurities: sort => ({
    atmAttributes: { announcedProgramSizeInSecurities: sort },
  }),
  attributes_pctOfferToOpen: sort => attributesSortModel({ pctOfferToOpen: sort }),
  attributes_pctOfferTo1Day: sort => attributesSortModel({ pctOfferTo1Day: sort }),
  attributes_pctOfferTo3Day: sort => attributesSortModel({ pctOfferTo3Day: sort }),
  attributes_pctOfferTo7Day: sort => attributesSortModel({ pctOfferTo7Day: sort }),
  attributes_pctOfferTo14Day: sort => attributesSortModel({ pctOfferTo14Day: sort }),
  attributes_pctOfferTo30Day: sort => attributesSortModel({ pctOfferTo30Day: sort }),
  attributes_pctOfferTo90Day: sort => attributesSortModel({ pctOfferTo90Day: sort }),
  attributes_pctOfferTo180Day: sort => attributesSortModel({ pctOfferTo180Day: sort }),
  attributes_pctOfferToCurrent: sort => attributesSortModel({ pctOfferToCurrent: sort }),
  // hasForwardAgreement: sort => ({ hasForwardAgreement: sort }),
};
export const getColumnById = (columnName: string): ColDef<Offering, any> =>
  dataGridColumns[columnName] ?? {};

export const getSortingModel = (colId: string, sort: SortEnumType): NestedSortInput => {
  if (getColumnById(colId)?.sortable === false) {
    return {};
  }
  return sortModelMappings[colId]
    ? sortModelMappings[colId](sort)
    : {
        [colId]: sort,
      };
};

export const convertColumns: Record<
  string,
  ColDef<ConvertOffering> & { field: ConvertColumnKeys }
> = {
  pricingDate: {
    field: 'pricingDate',
    headerName: 'Pricing Date',
    sortingOrder: ['asc', null],
    ...dateColumn,
  },
  convertPublicFilingDate: {
    field: 'publicFilingDate',
    headerName: 'Public Filing Date',
    hide: true,
    ...dateColumn,
  },
  launchDate: {
    field: 'launchDate',
    headerName: 'Launch Date',
    hide: true,
    ...dateColumn,
  },
  firstTradeDate: {
    field: 'firstTradeDate',
    headerName: 'First Trade Date',
    hide: true,
    ...dateColumn,
  },
  timingOfLaunch: {
    field: 'marketTimingDisplayName',
    headerName: 'Launch Timing',
    hide: true,
    ...stringValueColumn,
  },
  settlementDate: {
    field: 'settlementDate',
    headerName: 'Settlement Date',
    ...dateColumn,
    hide: true,
  },
  exchangeMicDisplayName: {
    field: 'exchangeMicDisplayName',
    headerName: 'Underlying Exchange',
    ...stringValueColumn,
    hide: true,
  },
  exchangeRegionDisplayName: {
    field: 'exchangeRegionDisplayName',
    headerName: 'Underlying Exchange Region',
    ...stringValueColumn,
    hide: true,
  },
  exchangeCountryDisplayName: {
    field: 'exchangeCountryDisplayName',
    headerName: 'Underlying Exchange Country',
    ...stringValueColumn,
    hide: true,
  },
  typeDisplayName: {
    field: 'typeDisplayName',
    headerName: 'Type',
    ...stringValueColumn,
    // display enum label
    hide: true,
  },
  pricingCurrencyDisplayName: {
    field: 'pricingCurrencyDisplayName',
    headerName: 'Currency',
    ...stringValueColumn,
    hide: true,
  },
  isRule144A: {
    field: 'isRule144A',
    headerName: 'Rule 144A',
    ...booleanColumn,
  },
  attributes_latestGrossProceedsBaseUsd: {
    field: 'attributes_latestGrossProceedsBaseUsd',
    headerName: 'Gross Proceeds Base',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsBaseUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
    hide: true,
  },
  maturityDate: {
    field: 'maturityDate',
    headerName: 'Maturity',
    ...dateColumn,
  },
  convertibleAttributes_underlyingSymbol: {
    field: 'convertibleAttributes_underlyingSymbol',
    headerName: 'Underlying Symbol',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.underlyingSymbol),
  },
  convertibleAttributes_securityNote: {
    field: 'convertibleAttributes_securityNote',
    maxWidth: 200,
    headerName: 'Instrument Description',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.securityNote),
  },
  convertibleAttributes_rank: {
    field: 'convertibleAttributes_rankDisplayName',
    headerName: 'Rank',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.rankDisplayName),
  },
  finalTerm_pctOfferPrice: {
    field: 'finalTerm_pctOfferPrice',
    headerName: 'Offer Price (%)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.pctOfferPrice),
    ...rightAligned,
    sortable: false,
  },
  attributes_priceUsd: {
    field: 'attributes_priceUsd',
    headerName: 'Offer Price ($)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.attributes?.priceUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  finalTerm_couponPercentage: {
    field: 'finalTerm_couponPercentage',
    headerName: 'Coupon',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.couponPercentage, 3),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_premiumPercentage: {
    field: 'finalTerm_premiumPercentage',
    headerName: 'Premium',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.premiumPercentage),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_couponTalkPercentageRange: {
    field: 'initialTerm_couponTalkPercentageRange',
    headerName: 'Coupon Talk (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.initialTerm?.couponTalkPercentageLow,
        data?.initialTerm?.couponTalkPercentageHigh,
        3
      ),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_premiumTalkPercentageRange: {
    field: 'initialTerm_premiumTalkPercentageRange',
    headerName: 'Premium Talk (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.initialTerm?.premiumTalkLowPercentage,
        data?.initialTerm?.premiumTalkHighPercentage
      ),
    ...rightAligned,
    sortable: false,
  },
  latestRevisedTerm_couponTalkPercentageRange: {
    field: 'latestRevisedTerm_couponTalkPercentageRange',
    headerName: 'Coupon Talk (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.latestRevisedTerm?.couponTalkPercentageLow,
        data?.latestRevisedTerm?.couponTalkPercentageHigh,
        3
      ),
    ...rightAligned,
    sortable: false,
    hide: true,
  },
  latestRevisedTerm_premiumTalkPercentageRange: {
    field: 'latestRevisedTerm_premiumTalkPercentageRange',
    headerName: 'Premium Talk (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.latestRevisedTerm?.premiumTalkLowPercentage,
        data?.latestRevisedTerm?.premiumTalkHighPercentage
      ),
    ...rightAligned,
    sortable: false,
    hide: true,
  },
  finalTerm_aggregatePrincipalAmount: {
    field: 'finalTerm_aggregatePrincipalAmount',
    headerName: 'Principal Amount',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.aggregatePrincipalAmount ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_aggregatePrincipalAmount: {
    field: 'initialTerm_aggregatePrincipalAmount',
    headerName: 'Principal Amount (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.initialTerm?.aggregatePrincipalAmount ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  latestRevisedTerm_aggregatePrincipalAmount: {
    field: 'latestRevisedTerm_aggregatePrincipalAmount',
    headerName: 'Principal Amount (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.latestRevisedTerm?.aggregatePrincipalAmount ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_principalAmountOverallotmentAuthorized: {
    field: 'finalTerm_principalAmountOverallotmentAuthorized',
    headerName: 'Principal Amount Ovlt (Authorized)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.principalAmountOverallotmentAuthorized ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  initialTerm_principalAmountOverallotmentAuthorized: {
    field: 'initialTerm_principalAmountOverallotmentAuthorized',
    headerName: 'Principal Amount Ovlt (Authorized) (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.initialTerm?.principalAmountOverallotmentAuthorized ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  latestRevisedTerm_principalAmountOverallotmentAuthorized: {
    field: 'latestRevisedTerm_principalAmountOverallotmentAuthorized',
    headerName: 'Principal Amount Ovlt (Authorized) (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.latestRevisedTerm?.principalAmountOverallotmentAuthorized ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_principalAmountOverallotmentExercised: {
    field: 'finalTerm_principalAmountOverallotmentExercised',
    headerName: 'Principal Amount Ovlt (Exercised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.principalAmountOverallotmentExercised ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_aggregatePrincipalInclOverallotmentExercised: {
    field: 'finalTerm_aggregatePrincipalInclOverallotmentExercised',
    headerName: 'Principal Amount (Inc. Ovlt. Exercised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.aggregatePrincipalInclOverallotmentExercised ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_principalAmountPerNote: {
    field: 'finalTerm_principalAmountPerNote',
    headerName: 'Par Value',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.principalAmountPerNote ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_principalAmountPerNote: {
    field: 'initialTerm_principalAmountPerNote',
    headerName: 'Par Value (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.initialTerm?.principalAmountPerNote ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  latestRevisedTerm_principalAmountPerNote: {
    field: 'latestRevisedTerm_principalAmountPerNote',
    headerName: 'Par Value (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.latestRevisedTerm?.principalAmountPerNote ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  //   attributes_notionalOverAllotmentExercised: {
  //     field: 'attributes_notionalOverAllotmentExercised',
  //     headerName: 'Overallotment Exercised Proceeds',
  //     valueGetter: ({ data }: { data?: ConvertOffering }) =>
  //       currencyRenderer({
  //         value: data?.attributes?.notionalOverAllotmentExercised ?? null,
  //         pricingCurrencyCode: data?.currency ?? null,
  //       }),
  //     ...rightAligned,
  //     hide: true,
  //   }, // this field is not available yet
  attributes_pctGrossSpread: {
    field: 'attributes_pctGrossSpread',
    headerName: 'Gross Spread (%) ',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctGrossSpread),
    ...rightAligned,
  },
  attributes_grossSpreadTotalUsd: {
    field: 'attributes_grossSpreadTotalUsd',
    headerName: 'Gross Spread',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.attributes?.grossSpreadTotalUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
    hide: true,
  },
  finalTerm_versusTalkDisplayName: {
    field: 'finalTerm_versusTalkDisplayName',
    headerName: 'Versus Talk',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.finalTerm?.versusTalkDisplayName),
    hide: true,
    sortable: false,
  },
  finalTerm_conversionPrice: {
    field: 'finalTerm_conversionPrice',
    headerName: 'Conversion Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.conversionPrice ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_conversionRatio: {
    field: 'finalTerm_conversionRatio',
    headerName: 'Initial Conversion Ratio',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      numericUtil.getDisplayValueForNumber(data?.finalTerm?.conversionRatio),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_conversionReferencePrice: {
    field: 'finalTerm_conversionReferencePrice',
    headerName: 'Conversion Reference Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.conversionReferencePrice ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_hasZeroCoupon: {
    field: 'finalTerm_hasZeroCoupon',
    headerName: 'Zero Coupon',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.finalTerm?.hasZeroCoupon),
    hide: true,
    sortable: false,
  },
  convertibleAttributes_hasDividendProtection: {
    field: 'convertibleAttributes_hasDividendProtection',
    headerName: 'Dividend Protection',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasDividendProtection),
  },
  convertibleAttributes_dividendProtectionNote: {
    field: 'convertibleAttributes_dividendProtectionNote',
    headerName: 'Dividend Protection Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.dividendProtectionNote),
  },
  convertibleAttributes_isCallable: {
    field: 'convertibleAttributes_isCallable',
    headerName: 'Callable',
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isCallable),
  },
  convertibleAttributes_callableDate: {
    field: 'convertibleAttributes_callableDate',
    headerName: 'Callable Date',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      formatDate(data?.convertibleAttributes?.callableDate),
  },
  convertibleAttributes_callPrice: {
    field: 'convertibleAttributes_callPrice',
    headerName: 'Call Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.convertibleAttributes?.callPrice),
    ...rightAligned,
  },
  convertibleAttributes_hasProvisionalCall: {
    field: 'convertibleAttributes_hasProvisionalCall',
    headerName: 'Provisional Call',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasProvisionalCall),
  },
  convertibleAttributes_provisionalCallNote: {
    field: 'convertibleAttributes_provisionalCallNote',
    headerName: 'Provisional Call Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.provisionalCallNote),
  },
  convertibleAttributes_isPuttable: {
    field: 'convertibleAttributes_isPuttable',
    headerName: 'Put',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isPuttable),
  },
  convertibleAttributes_putNote: {
    field: 'convertibleAttributes_putNote',
    headerName: 'Put Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.putNote),
  },
  convertibleAttributes_hasContingentConversion: {
    field: 'convertibleAttributes_hasContingentConversion',
    headerName: 'Contingent Conversion',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasContingentConversion),
  },
  convertibleAttributes_contingentConversionNote: {
    field: 'convertibleAttributes_contingentConversionNote',
    headerName: 'Contingent Conversion Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.contingentConversionNote),
  },
  convertibleAttributes_hasContingentPayment: {
    field: 'convertibleAttributes_hasContingentPayment',
    headerName: 'Contingent Payment',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasContingentPayment),
  },
  convertibleAttributes_contingentPaymentNote: {
    field: 'convertibleAttributes_contingentPaymentNote',
    headerName: 'Contingent Payment Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.contingentPaymentNote),
  },
  convertibleAttributes_isHedging: {
    field: 'convertibleAttributes_isHedging',
    headerName: 'Hedging',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isHedging),
  },
  convertibleAttributes_hedgingNote: {
    field: 'convertibleAttributes_hedgingNote',
    maxWidth: 200,
    headerName: 'Hedging Note',
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.hedgingNote),
  },
  finalTerm_effectiveConversionPremium: {
    field: 'finalTerm_effectiveConversionPremium',
    headerName: 'Effective Conversion Premium',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.effectiveConversionPremium),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_effectiveConversionPrice: {
    field: 'finalTerm_effectiveConversionPrice',
    headerName: 'Effective Conversion Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.effectiveConversionPrice ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  useOfProceedsDisplayNames: {
    field: 'useOfProceedsDisplayNames',
    headerName: 'Use Of Proceeds',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      data?.useOfProceedsDisplayNames?.length ? data?.useOfProceedsDisplayNames?.join(', ') : '-',
    hide: true,
    sortable: false,
  },
  convertibleAttributes_changeOfControl: {
    field: 'convertibleAttributes_changeOfControl',
    headerName: 'Change Of Control Note',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.changeOfControl),
    hide: true,
  },
  trustee: {
    field: 'trustee',
    headerName: 'Trustee',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getAdviserNameByRole(data?.advisers ?? [], AdviserRole.Trustee),
    hide: true,
    sortable: false,
  },
  issuerCounsel: {
    field: 'issuerCounsel',
    headerName: 'Issuer Counsel', // Counsil
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getAdviserNameByRole(data?.advisers ?? [], AdviserRole.IssuerCounsel),
    hide: true,
    sortable: false,
  },
  attributes_latestGrossProceedsTotalUsd: {
    field: 'attributes_latestGrossProceedsTotalUsd',
    headerName: 'Gross Proceeds Total',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsTotalUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
    hide: true,
  },
  convertibleAttributes_isPerpetual: {
    field: 'convertibleAttributes_isPerpetual',
    headerName: 'Perpetual',
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isPerpetual),
  },
  convertibleAttributes_tenor: {
    field: 'convertibleAttributes_tenor',
    headerName: 'Tenor',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      numericUtil.getDisplayValueForNumber(data?.convertibleAttributes?.tenor),
    ...rightAligned,
  },
};

export const atmColumns: Record<string, ColDef<AtmOffering> & { field: AtmColumnKeys }> = {
  announcementDate: {
    field: 'publicFilingDate',
    headerName: 'Announcement Date',
    ...dateColumn,
  },
  announcementTime: {
    field: 'marketTimingDisplayName',
    headerName: 'Announcement Time',
    ...stringValueColumn,
  },
  atmAttributes_effectiveDate: {
    field: 'atmAttributes_effectiveDate',
    headerName: 'Effective Date',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      formatDate(data?.atmAttributes?.effectiveDate),
  },
  atmAttributes_pctGrossSpread: {
    field: 'atmAttributes_pctGrossSpread',
    headerName: 'Gross Spread (%) ',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.atmAttributes?.pctGrossSpread),
    ...rightAligned,
  },
  atmAttributes_totalProgramRemaining: {
    field: 'atmAttributes_totalProgramRemaining',
    headerName: 'ATM Program Remaining',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.atmAttributes?.totalProgramRemaining ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
  },
  atmAttributes_totalProgramRemainingInSecurities: {
    field: 'atmAttributes_totalProgramRemainingInSecurities',
    headerName: 'ATM Program Remaining Securities',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      data?.atmAttributes?.totalProgramRemainingInSecurities
        ? numericUtil.formatInteger(data?.atmAttributes?.totalProgramRemainingInSecurities)
        : '-',
    ...rightAligned,
  },
  atmAttributes_latestProgramSize: {
    field: 'atmAttributes_latestProgramSize',
    headerName: 'ATM Program Size',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.atmAttributes?.latestProgramSize ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
  },
  atmAttributes_latestProgramSizeInSecurities: {
    field: 'atmAttributes_latestProgramSizeInSecurities',
    headerName: 'ATM Program Size In Securities',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      data?.atmAttributes?.latestProgramSizeInSecurities
        ? numericUtil.formatInteger(data?.atmAttributes?.latestProgramSizeInSecurities)
        : '-',
    ...rightAligned,
  },
  atmAttributes_announcedProgramSize: {
    field: 'atmAttributes_announcedProgramSize',
    headerName: 'ATM Program Size - Announced',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.atmAttributes?.announcedProgramSize ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    hide: true,
    ...rightAligned,
  },
  atmAttributes_announcedProgramSizeInSecurities: {
    field: 'atmAttributes_announcedProgramSizeInSecurities',
    headerName: 'ATM Program Size In Securities - Announced',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      data?.atmAttributes?.announcedProgramSizeInSecurities
        ? numericUtil.formatInteger(data?.atmAttributes?.announcedProgramSizeInSecurities)
        : '-',
    hide: true,
    ...rightAligned,
  },
  attributes_latestSizeInSecuritiesTotal: {
    field: 'attributes_latestSizeInSecuritiesTotal',
    headerName: 'Size in Securities Total',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      numericUtil.getDisplayValueForNumber(data?.attributes?.latestSizeInSecuritiesTotal, 0),
    ...rightAligned,
  },
  attributes_latestGrossProceedsTotal: {
    field: 'attributes_latestGrossProceedsTotal',
    headerName: 'Gross Proceeds Total',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsTotal ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_marketCapPreOffering: {
    field: 'attributes_marketCapPreOffering',
    headerName: 'Pre-Offering Mkt. Cap',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.marketCapPreOffering ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_pctMarketCapPreOffering: {
    field: 'attributes_pctMarketCapPreOffering',
    headerName: '% of Pre-Offering Market Cap ',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctMarketCapPreOffering),
    ...rightAligned,
  },
  attributes_preOfferingAdtv: {
    field: 'attributes_preOfferingAdtv',
    headerName: '30 Day ADTV',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      numericUtil.getDisplayValueForNumber(data?.attributes?.preOfferingAdtv, 0),
    ...rightAligned,
  },
  attributes_terminatedDate: {
    field: 'attributes_terminatedDate',
    headerName: 'Terminated Date',
    valueGetter: ({ data }: { data?: AtmOffering }) => formatDate(data?.attributes?.terminatedDate),
  },
  issuer_primarySymbol: {
    field: 'issuer_primarySymbol',
    headerName: 'Ticker',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      stringValueFormatter(data?.issuer?.primarySymbol),
  },
  attributes_lastTradeBeforeFilingUsd: {
    field: 'attributes_lastTradeBeforeFilingUsd',
    headerName: 'Last Trade Before Filing',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.lastTradeBeforeFilingUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  atmAttributes_lastTradeBeforeFilingSplitAdjusted: {
    field: 'atmAttributes_lastTradeBeforeFilingSplitAdjusted',
    headerName: 'Last Trade Before Filing Split Adjusted',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyRenderer({
        value: data?.atmAttributes?.lastTradeBeforeFilingSplitAdjusted ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_pctOfferToOpen: {
    field: 'attributes_pctOfferToOpen',
    headerName: 'Open',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferToOpen),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferToOpen),
    ...rightAligned,
  },
  attributes_pctOfferTo1Day: {
    field: 'attributes_pctOfferTo1Day',
    headerName: '1 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo1Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo1Day),
    ...rightAligned,
  },
  attributes_pctOfferTo3Day: {
    field: 'attributes_pctOfferTo3Day',
    headerName: '3 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo3Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo3Day),
    ...rightAligned,
    hide: true,
  },
  attributes_pctOfferTo7Day: {
    field: 'attributes_pctOfferTo7Day',
    headerName: '7 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo7Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo7Day),
    ...rightAligned,
  },
  attributes_pctOfferTo14Day: {
    field: 'attributes_pctOfferTo14Day',
    headerName: '14 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo14Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo14Day),
    ...rightAligned,
    hide: true,
  },
  attributes_pctOfferTo30Day: {
    field: 'attributes_pctOfferTo30Day',
    headerName: '30 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo30Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo30Day),
    ...rightAligned,
  },
  attributes_pctOfferTo90Day: {
    field: 'attributes_pctOfferTo90Day',
    headerName: '90 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo90Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo90Day),
    ...rightAligned,
    hide: true,
  },
  attributes_pctOfferTo180Day: {
    field: 'attributes_pctOfferTo180Day',
    headerName: '180 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo180Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo180Day),
    ...rightAligned,
  },
  attributes_pctOfferToCurrent: {
    field: 'attributes_pctOfferToCurrent',
    headerName: 'Current',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferToCurrent),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferToCurrent),
    ...rightAligned,
  },
};

// define columns with specific properties: ColDef<Offering, any>
export const dataGridColumns: Record<string, ColDef<Offering> & { field: SharedOfferingGridKeys }> =
  {
    issuer_name: {
      field: 'issuer_name',
      headerName: 'Issuer',
      // Column will be always visible
      suppressColumnsToolPanel: true,
      /*
      Since we are using a custom field id with cellRendererFramework
      we need to provide value getter so we can get re-renders working properly
    */
      valueGetter: ({ data }: { data?: Offering }) => data?.id ?? '',
      cellRendererFramework: ({ data, value }: { data?: Offering; value: string }) => {
        return data ? (
          <Link to={routeFactory.offerings.getUrlPath({ id: data.id })}>{data.issuer?.name}</Link>
        ) : (
          value ?? ''
        );
      },
    },
    issuer_sectorDisplayName: {
      field: 'issuer_sectorDisplayName',
      headerName: 'Sector',
      valueGetter: ({ data }: { data?: Offering }) =>
        stringValueFormatter(data?.issuer?.sectorDisplayName),
    },
    securityTypeDisplayName: {
      field: 'securityTypeDisplayName',
      headerName: 'Security type',
      valueGetter: ({ data }: { data?: Offering }) => {
        if (data?.securityType === SecurityType.ConvertibleNote) {
          return 'Note';
        }
        return stringValueFormatter(data?.securityTypeDisplayName);
      },
    },
    issuerAuditor: {
      field: 'issuerAuditor',
      headerName: 'Issuer Auditor',
      valueGetter: ({ data }: { data?: Offering }) =>
        getAdviserNameByRole(data?.advisers ?? [], AdviserRole.IssuerAuditor),
      hide: true,
      sortable: false,
    },
    leftLead: {
      field: 'leftLead',
      headerName: 'Left Lead',
      /*
      Since we are using a custom field id with cellRendererFramework
      we need to provide value getter so we can get re-renders working properly
    */
      valueGetter: ({ data }: { data?: Offering }) => data?.attributes?.leftLeadId ?? '',
      cellRendererFramework: ({ data }: { data?: Offering }) => <LeftLeadRenderer data={data} />,
    },
    underwriterCounsel: {
      field: 'underwriterCounsel',
      headerName: 'Underwriter Counsel', // Counsil
      valueGetter: ({ data }: { data?: Offering }) =>
        getAdviserNameByRole(data?.advisers ?? [], AdviserRole.UnderwriterCounsel),
      hide: true,
      sortable: false,
    },
    bookrunners: {
      field: 'bookrunners',
      headerName: 'Bookrunners',
      valueGetter: ({ data }: { data?: Offering }) => {
        const activeBookrunners = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.ActiveBookrunner
        );
        const passiveBookrunners = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.Bookrunner
        );
        return managerRenderer([...activeBookrunners, ...passiveBookrunners]);
      },
      sortable: false,
    },
    coLeads: {
      field: 'coLeads',
      headerName: 'Co-Leads',
      valueGetter: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(data?.managers ?? [], OfferingManagerRole.CoLead);
        return managerRenderer(managers);
      },
      hide: true,
      sortable: false,
    },
    coManagers: {
      field: 'coManagers',
      headerName: 'Co-Managers',
      valueGetter: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(data?.managers ?? [], OfferingManagerRole.CoManager);
        return managerRenderer(managers);
      },
      hide: true,
      sortable: false,
    },
    sellingGroupMembers: {
      field: 'sellingGroupMembers',
      headerName: 'Sales Agents',
      maxWidth: 400,
      valueGetter: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.SellingGroupMember
        );
        return managerRenderer(managers);
      },
      cellRendererFramework: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.SellingGroupMember
        );
        return <ManagersRenderer managers={managers} />;
      },
      sortable: false,
    },
    publicFilingDate: {
      field: 'publicFilingDate',
      headerName: 'Public Filing Date',
      ...dateColumn,
    },
    marketTimingDisplayName: {
      field: 'marketTimingDisplayName',
      headerName: 'Public Filing Time',
      ...stringValueColumn,
    },
    issuer_cik: {
      field: 'issuer_cik',
      headerName: 'CIK',
      valueGetter: ({ data }: { data?: Offering }) => stringValueFormatter(data?.issuer?.cik),
      ...rightAligned,
    },
    statusDisplayName: {
      field: 'statusDisplayName',
      headerName: 'Status',
      ...stringValueColumn,
    },
    hasForwardAgreement: {
      field: 'hasForwardAgreement',
      headerName: 'Forward Sale',
      ...booleanColumn,
    },
    ...atmColumns,
    ...convertColumns,
  };

export const alwaysVisibleColumns = ['issuer_name'];
export const aggregationMapping: {
  [colId: string]: { fieldName: string; formatter: (value: number) => string | JSX.Element };
} = {
  convertibleAttributes_callPrice: {
    fieldName: 'callPrice',
    formatter: value => getFormattedPercentageValue(value),
  },
  finalTerm_conversionPrice: {
    fieldName: 'conversionPrice',
    formatter: value => currencyRenderer({ value, pricingCurrencyCode: 'USD' }),
  },
  finalTerm_conversionRatio: {
    fieldName: 'conversionRatio',
    formatter: value => numericUtil.getDisplayValueForNumber(value),
  },
  finalTerm_conversionReferencePrice: {
    fieldName: 'conversionReferencePrice',
    formatter: value => currencyRenderer({ value, pricingCurrencyCode: 'USD' }),
  },
  finalTerm_pctOfferPrice: {
    fieldName: 'pctOfferPrice',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_priceUsd: {
    fieldName: 'priceUsd',
    formatter: value => currencyRenderer({ value, pricingCurrencyCode: 'USD' }),
  },
  finalTerm_couponPercentage: {
    fieldName: 'couponPercentage',
    formatter: value => getFormattedPercentageValue(value, 3),
  },
  finalTerm_premiumPercentage: {
    fieldName: 'premiumPercentage',
    formatter: value => getFormattedPercentageValue(value),
  },
  finalTerm_aggregatePrincipalAmount: {
    fieldName: 'aggregatePrincipalAmount',
    formatter: value => currencyInMillionRenderer({ value, pricingCurrencyCode: 'USD' }),
  },
  finalTerm_aggregatePrincipalInclOverallotmentExercised: {
    fieldName: 'aggregatePrincipalInclOverallotmentExercised',
    formatter: value => currencyInMillionRenderer({ value, pricingCurrencyCode: 'USD' }),
  },
  finalTerm_principalAmountPerNote: {
    fieldName: 'principalAmountPerNote',
    formatter: value => currencyRenderer({ value, pricingCurrencyCode: 'USD' }),
  },
  convertibleAttributes_tenor: {
    fieldName: 'tenor',
    formatter: value => numericUtil.getDisplayValueForNumber(value),
  },
  attributes_pctGrossSpread: {
    fieldName: 'pctGrossSpread',
    formatter: value => getFormattedPercentageValue(value),
  },
  attributes_pctOfferToOpen: {
    fieldName: 'pctOfferToOpen',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo1Day: {
    fieldName: 'pctOfferTo1Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo3Day: {
    fieldName: 'pctOfferTo3Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo7Day: {
    fieldName: 'pctOfferTo7Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo14Day: {
    fieldName: 'pctOfferTo14Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo30Day: {
    fieldName: 'pctOfferTo30Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo90Day: {
    fieldName: 'pctOfferTo90Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferTo180Day: {
    fieldName: 'pctOfferTo180Day',
    formatter: value => percentagePerformanceFormatter(value),
  },
  attributes_pctOfferToCurrent: {
    fieldName: 'pctOfferToCurrent',
    formatter: value => percentagePerformanceFormatter(value),
  },
};

// define style and renderer for pinned row that will show summary information (min, max, median, etc.)
export const getColumnsWithSummaryInfo = (
  columns?: ColDef<Offering, any>[],
  aggregations?: OfferingAggregations
): ColDef<Offering, any>[] => {
  const defaultCellStyle: CreateCellStyleFn<Offering, any> = () => ({ border: 0 });
  return (
    columns?.map(column => {
      const fieldName = aggregationMapping[column.field ?? '']?.fieldName;
      const formatter = aggregationMapping[column.field ?? '']?.formatter;
      if (aggregations && column.field && fieldName && aggregations[fieldName]) {
        return {
          ...column,
          cellStyle: params =>
            params.node?.isRowPinned()
              ? {
                  ...defaultCellStyle(params),
                  display: 'flex',
                  justifyContent: 'flex-end',
                  lineHeight: '13px',
                }
              : defaultCellStyle(params),
          pinnedRowCellRendererFramework: () => (
            <SummaryHeader aggregations={aggregations[fieldName]} formatter={formatter} />
          ),
        };
      }
      return {
        ...column,
        cellStyle: defaultCellStyle,
        pinnedRowCellRendererFramework: () => null,
      };
    }) ?? []
  );
};

export const additionalOptions = {
  initialTerm_couponTalkPercentageLow: {
    numberPrecision: 5,
  },
  initialTerm_couponTalkPercentageHigh: {
    numberPrecision: 5,
  },
  latestRevisedTerm_couponTalkPercentageLow: {
    numberPrecision: 5,
  },
  latestRevisedTerm_couponTalkPercentageHigh: {
    numberPrecision: 5,
  },
  initialTerm_premiumTalkLowPercentage: {
    numberPrecision: 2,
  },
  initialTerm_premiumTalkHighPercentage: {
    numberPrecision: 2,
  },
  latestRevisedTerm_premiumTalkLowPercentage: {
    numberPrecision: 2,
  },
  latestRevisedTerm_premiumTalkHighPercentage: {
    numberPrecision: 2,
  },
  finalTerm_couponPercentage: {
    numberPrecision: 5,
  },
  finalTerm_premiumPercentage: {
    numberPrecision: 2,
  },
  finalTerm_pctOfferPrice: {
    numberPrecision: 2,
  },
  convertibleAttributes_callPrice: {
    numberPrecision: 2,
  },
  attributes_pctGrossSpread: {
    numberPrecision: 2,
  },
  finalTerm_effectiveConversionPremium: {
    numberPrecision: 2,
  },
  atmAttributes_pctGrossSpread: {
    numberPrecision: 2,
  },
};

// Recursively extract field name into Json object, preserving gql schema structure
export const fieldNameToJson = (acc, input, index) => {
  if (index >= input.length) {
    return '';
  }
  const item = input[index];
  acc[item] = fieldNameToJson(acc[item] ?? {}, input, index + 1);
  return acc;
};

// We need to query these fields for initial/revised/final terms calculated fields to work
export const defaultBaseGqlFields = {
  initialTermId: '',
  latestRevisedTermId: '',
  finalTermId: '',
  terms: {
    id: '',
    aggregatePrincipalAmount: '',
    aggregatePrincipalInclOverallotmentExercised: '',
    principalAmountOverallotmentAuthorized: '',
    principalAmountPerNote: '',
    couponTalkPercentageLow: '',
    couponTalkPercentageHigh: '',
    premiumTalkLowPercentage: '',
    premiumTalkHighPercentage: '',
    conversionPrice: '',
    conversionRatio: '',
    conversionReferencePrice: '',
    principalAmountOverallotmentExercised: '',
    effectiveConversionPremium: '',
    effectiveConversionPrice: '',
    versusTalkDisplayName: '',
    premiumPercentage: '',
    couponPercentage: '',
    pctOfferPrice: '',
    hasZeroCoupon: '',
  },
};

export const excludeFromGql = [
  dataGridColumns.leftLead.field,
  dataGridColumns.bookrunners.field,
  dataGridColumns.coLeads.field,
  dataGridColumns.coManagers.field,
  dataGridColumns.trustee.field,
  dataGridColumns.issuerCounsel.field,
  dataGridColumns.underwriterCounsel.field,
  dataGridColumns.sellingGroupMembers.field,
  dataGridColumns.issuerAuditor.field,
  dataGridColumns.convertibleAttributes_putNote.field,
] as string[];

export const rangeColumns = {
  initialTerm_couponTalkPercentageRange: [
    {
      colId: 'initialTerm_couponTalkPercentageLow',
      colName: 'Coupon Talk (Initial) - Low',
    },
    {
      colId: 'initialTerm_couponTalkPercentageHigh',
      colName: 'Coupon Talk (Initial) - High',
    },
  ],
  initialTerm_premiumTalkPercentageRange: [
    {
      colId: 'initialTerm_premiumTalkLowPercentage',
      colName: 'Premium Talk (Initial) - Low',
    },
    {
      colId: 'initialTerm_premiumTalkHighPercentage',
      colName: 'Premium Talk (Initial) - High',
    },
  ],
  latestRevisedTerm_couponTalkPercentageRange: [
    {
      colId: 'latestRevisedTerm_couponTalkPercentageLow',
      colName: 'Coupon Talk (Revised) - Low',
    },
    {
      colId: 'latestRevisedTerm_couponTalkPercentageHigh',
      colName: 'Coupon Talk (Revised) - High',
    },
  ],
  latestRevisedTerm_premiumTalkPercentageRange: [
    {
      colId: 'latestRevisedTerm_premiumTalkLowPercentage',
      colName: 'Premium Talk (Revised) - Low',
    },
    {
      colId: 'latestRevisedTerm_premiumTalkHighPercentage',
      colName: 'Premium Talk (Revised) - High',
    },
  ],
};

type GetExcelDownloadArgs = {
  selectedColumns?: Column[];
  gqlFilterInput?: OfferingFilterInput;
  sortModel?: {
    colId?: string;
    sort?: string | null;
  }[];
  selectedOptions?: string[];
  defaultSortModel?: NestedSortInput;
  baseGqlFields?: Object;
};

// Turn selected fields, filter, and sort model into excel download args
export const getExcelDownloadArgs = ({
  selectedColumns = [],
  gqlFilterInput,
  sortModel = [],
  selectedOptions = [],
  defaultSortModel = {},
  baseGqlFields = defaultBaseGqlFields,
}: GetExcelDownloadArgs): datalabApi.ConvertibleOfferingRequestDto => {
  let index = 0;
  const columnOptions = selectedColumns.reduce((acc, item) => {
    const colDef = item.getColDef();
    const colId = item.getColId();
    // add multiple columns to excel if it is a (low - high) range
    if (rangeColumns[colId]) {
      rangeColumns[colId].forEach(rangeItem => {
        const rangeColId = rangeItem.colId.replaceAll('_', '.');
        acc[rangeColId] = {
          columnName: rangeItem.colName,
          displayOrder: index++,
          ...additionalOptions[rangeItem.colId],
        };
      });
    } else {
      acc[colId.replaceAll('_', '.')] = {
        columnName: colDef.headerName,
        displayOrder: index++,
        ...additionalOptions[colId],
      };
    }
    return acc;
  }, {});

  // Recursively turn selected fields into json object, following gql schema structure
  const selectionJson = Object.keys(columnOptions).reduce((acc, colId) => {
    const fieldId = colId.replaceAll('.', '_');
    return excludeFromGql.includes(fieldId) ? acc : fieldNameToJson(acc, colId.split('.'), 0);
  }, baseGqlFields);

  // Stringify json object and replace non-gql characters
  const selectionString = JSON.stringify(selectionJson)
    .replaceAll(/"|'|:/gi, '') // remove single ('), double quotes ("), and colon (:)
    .replaceAll(/,/gi, ' '); // replace comma (,) with space
  const selection = selectionString
    .substring(1, selectionString.length - 1) // remove first and last braces ({})
    .replaceAll(/{|}/gi, ' $& '); // add space around braces ({})

  const downloadArg = {
    selection,
    columnOptions,
    arguments: {
      order: [
        sortModel.length > 0 && sortModel[0].colId && sortModel[0].sort
          ? {
              ...getSortingModel(
                sortModel[0].colId,
                sortModel[0].sort.toUpperCase() as SortEnumType
              ),
            }
          : defaultSortModel,
      ],
      where: gqlFilterInput ?? {},
    },
    includeUnderwritersWorksheet: selectedOptions.includes('includeUnderwritersWorksheet'),
  };
  return downloadArg;
};
