import React from 'react';
import { ThemeConsumer } from 'styled-components/macro';

import BaseButton, { ButtonProps, StyledConfig } from './Button';

type StyleVariant = 'normal' | 'high-contrast';
export type PrimaryButtonProps = ButtonProps & {
  variant?: StyleVariant;
};

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ variant = 'normal', ...restProps }) => (
  <ThemeConsumer>
    {theme => {
      const variants: Record<StyleVariant, StyledConfig> = {
        'high-contrast': {
          color: theme.interactive.primaryLightContrast,
          backgroundColor: theme.interactive.primaryLight,
          hoverBackgroundColor: theme.interactive.primaryLightComplement,
          hoverColor: theme.interactive.primaryLightContrast,
          borderColor: theme.interactive.primaryLight,
          disabledBackgroundColor: theme.interactive.highContrast.disabled,
          disabledBorderColor: theme.interactive.highContrast.disabled,
          disabledColor: theme.interactive.highContrast.disabledContrast,
        },
        normal: {
          color: theme.interactive.primaryContrast,
          hoverColor: theme.interactive.primaryContrast,
          backgroundColor: theme.interactive.primary,
          hoverBackgroundColor: theme.interactive.primaryComplement,
          borderColor: theme.interactive.primary,
        },
      };

      return <BaseButton {...restProps} styledConfig={variants[variant]} />;
    }}
  </ThemeConsumer>
);

export default PrimaryButton;
