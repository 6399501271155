import { makeEntity } from '../../../utils';
import { PasswordPolicy } from '../types/PasswordPolicy';

export const makePasswordPolicy = makeEntity<PasswordPolicy>({
  minLength: 8,
  uniqueChars: 3,
  requireNonAlphanumeric: true,
  requireDigit: true,
  requireLowercase: true,
  requirePasswordExpiration: true,
  requireUniquePasswordOnReset: true,
  requireUppercase: true,
  passwordExpiresInDays: 180,
  passwordHistoryLength: 10,
});
