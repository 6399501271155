import styled, { css } from 'styled-components/macro';

import Popover from '../../overlays/popover/Popover';
import {
  StyledCheckIcon,
  StyledFilterInput,
  StyledLinkButton,
} from '../dropdown-select/DropdownSelect.styles';

export const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 3px 5px;
    border-radius: ${({ theme }) => theme.border.radius.small};
  }

  ${({ theme, variant = 'DARK' }) =>
    variant === 'LIGHT'
      ? css`
          ${StyledCheckIcon} {
            color: ${theme.brand.color.darkAlternate};
          }

          ${StyledLinkButton} {
            border: none;
            color: ${theme.text.color.dark};

            &:hover {
              background: ${theme.interactive.primary};
              color: ${theme.interactive.primaryContrast};
            }
          }

          ${StyledFilterInput} {
            input {
              color: ${theme.text.color.dark};
              background: ${theme.interactive.primary};
            }
          }
        `
      : css`
          ${StyledLinkButton} {
            color: ${theme.text.color.white};
          }
        `};
`;
