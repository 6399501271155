import styled from 'styled-components/macro';

export const STimeZoneOption = styled.div`
  display: flex;
  flex-direction: column;
`;

export const STimeZoneLabel = styled.span<{ isSelected?: boolean }>`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.text.color.white : theme.text.color.light};
  font-size: ${({ theme }) => theme.text.size.small};
`;
