import { ServiceErrorCode } from '../../../api/types/ServiceErrorCode';

/**
 * Map of Service Error Codes and the text
 * displayed in the ApplicationError component
 * for those codes.
 */
export const serviceErrorDetail = {
  [ServiceErrorCode.NOT_FOUND]: {
    title: 'Page not found',
    message: 'The page you requested could not be found.',
  },
  [ServiceErrorCode.IP_BLOCKED]: {
    title: 'IP Blocked',
    message: 'Your IP address is outside the allowed range for your account.',
  },
  [ServiceErrorCode.VALIDATION_ERROR]: null,
  [ServiceErrorCode.EDIT_CONFLICT]: null,
  [ServiceErrorCode.INVALID_CREDENTIALS]: null,
  [ServiceErrorCode.LOCKED_OUT]: null,
  [ServiceErrorCode.PASSWORD_EXPIRED]: null,
  [ServiceErrorCode.EMAIL_NOT_VALID]: null,
  [ServiceErrorCode.INVALID_PASSWORD]: null,
  [ServiceErrorCode.INVALID_FORMAT]: null,
  [ServiceErrorCode.INVALID_TOKEN]: null,
  [ServiceErrorCode.EMAIL_ERROR]: null,
  [ServiceErrorCode.DUPLICATE]: null,
  [ServiceErrorCode.UNHANDLED_ERROR]: null,
  [ServiceErrorCode.NETWORK_FAILURE]: null,
};

/**
 * The default error message shown in the ApplicationError
 * component.
 */
export const defaultErrorDetail = {
  title: 'Something went wrong',
};
