import styled, { css } from 'styled-components/macro';

export const SInput = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  visibility: hidden;

  &:disabled {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.color.gray3};
  }
`;

export const SIcon = styled.div<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.text.color.light : theme.text.color.dark)};
  vertical-align: top;
  text-align: center;
`;

export const SCheckbox = styled.div<{
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 12px;
  vertical-align: middle;
  background: ${({ theme }) => theme.background.color.white};
  border-radius: ${({ theme }) => theme.border.width.medium};
  border: ${({ theme }) => `${theme.border.width.small} solid ${theme.background.color.dark}`};

  ${SInput}:focus {
    border: ${({ theme }) => `${theme.border.width.small} solid ${theme.interactive.primary}`};
  }
  ${SInput}:disabled {
    border: ${({ theme }) => `${theme.border.width.small} solid ${theme.color.gray3}`};
  }

  ${SIcon} {
    visibility: ${({ checked, indeterminate }) =>
      checked || indeterminate ? 'visible' : 'hidden'};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border: ${theme.border.width.small} solid ${theme.designSystem.colors.gray['100']};
      background-color: ${theme.designSystem.colors.gray['000']};
    `}
`;

export const SContainer = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
`;

export const SContent = styled.div`
  flex: 1;
  padding-left: 10px;
`;
