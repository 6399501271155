import { AuthProviderConfig } from '../../../../types/api/AuthProviderConfig';
import makeEntity from '../../entityFactory';

export const makeAuthConfig = makeEntity<AuthProviderConfig>({
  env: {
    isDevelopment: false,
    rootDomain: 'local.oncmg.io',
    secureCookies: false,
  },
  client: {
    basename: '',
  },
  logging: {
    appName: 'mock-appName',
    mixPanelToken: 'test123',
  },
  auth: {
    clientId: 'cmg-app',
    oidcAuthorityBaseUrl: 'http://lvg.me:5001',
    scopes: ['xc:full', 'dl:full'],
  },
  actions: {
    onLogout: () => {},
  },
});
