/**
 * Regular svg icons package
 *
 * Due to how our Icon.tsx IconNameParts variable is constructed, all icons defined in
 * regularIcons and solidIcons must be present in lightIcons.ts.
 */
export { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
export { faChevronDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
export { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
export { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
export { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
export { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
export { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
export { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
export { faColumns } from '@fortawesome/pro-regular-svg-icons/faColumns';
export { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
export { faFileExport } from '@fortawesome/pro-regular-svg-icons/faFileExport';
export { faHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
export { faListCheck } from '@fortawesome/pro-regular-svg-icons/faListCheck';
export { faPollH } from '@fortawesome/pro-regular-svg-icons/faPollH';
export { faStar } from '@fortawesome/pro-regular-svg-icons/faStar';
export { faUserTie } from '@fortawesome/pro-regular-svg-icons/faUserTie';

export enum RegularIconName {
  'check-circle' = 'faCheckCircle',
  'chevron-double-left' = 'faChevronDoubleLeft',
  'chevron-double-right' = 'faChevronDoubleRight',
  'chevron-left' = 'faChevronLeft',
  'chevron-right' = 'faChevronRight',
  'chevron-down' = 'faChevronDown',
  'chevron-up' = 'faChevronUp',
  'star' = 'faStar',
  'circle' = 'faCircle',
  'file-export' = 'faFileExport',
  'columns' = 'faColumns',
  'ellipsis-v' = 'faEllipsisV',
  'user-tie' = 'faUserTie',
  'poll-h' = 'faPollH',
  'history' = 'faHistory',
  'list-check' = 'faListCheck',
}
