import React from 'react';
import {
  components,
  InputActionMeta,
  MenuProps,
  MultiValue,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select';

import { FormControlEvents } from '../types';
import { styles } from './styles';
import { Group, Option, RenderOption, StyledConfig } from './types';

export type SyncSelectProps<TOptionValue> = {
  options?: (Option<TOptionValue> | Group<TOptionValue>)[];
};

type TFilterOption<TOption, IsMulti extends boolean> = ReactSelectProps<
  TOption,
  IsMulti
>['filterOption'];

export type PublicProps<
  TOptionValue = string,
  IsMulti extends boolean = false
> = FormControlEvents & {
  // SelectProps &
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  renderGroupLabel?: (group: Group<TOptionValue>) => React.ReactNode;
  renderOption?: RenderOption<Option<TOptionValue>>;
  renderSingleSelectedOption?: (selectedOption: Option<TOptionValue>) => React.ReactNode;
  renderDropdownIndicator?: () => React.ReactElement;
  renderIndicatorsContainer?: () => React.ReactElement;
  renderMenuList?: (children: React.ReactNode) => React.ReactNode;
  renderMenu?: (
    props: MenuProps<Option<TOptionValue>, IsMulti, Group<TOptionValue>>
  ) => React.ReactElement;
  styledConfig?: StyledConfig;
  noOptionsMessage?: ({ inputValue }: { inputValue: string }) => string | null;
  renderNoOptionsMessage?: () => React.ReactElement;
  defaultInputValue?: string;
  autoFocus?: boolean;
  maxMenuHeight?: number;
  openMenuOnClick?: boolean;
  testId?: string;
  // Custom filter logic
  filterOption?: TFilterOption<Option<TOptionValue>, IsMulti>;
  /**
   * Binds menu portal with given element ref.
   * Useful when rendering Select inside parent with limited height and hidden overflow.
   */
  menuPortalTarget?: ReactSelectProps<
    Option<TOptionValue>,
    IsMulti,
    Group<TOptionValue>
  >['menuPortalTarget'];
  menuIsOpen?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
};

export type PublicMultiSelectProps = {
  /** Truncates values displayed in select input beyond this number. Instead a ...+number is displayed */
  maxDisplayedValues?: number;
  renderMultiValueLabel?: (children: React.ReactNode) => React.ReactNode;
};

export const baseFilterOption = {
  ignoreCase: true,
  ignoreAccents: true,
  stringify: option => `${option.label}`,
};

export function getReactSelectProps<TOptionValue, IsMulti extends boolean>({
  testId, // skipping
  className, // skipping
  required, // skipping
  fullWidth, // skipping
  disabled,
  hasError,
  styledConfig,
  renderOption,
  renderGroupLabel,
  renderDropdownIndicator,
  renderIndicatorsContainer,
  renderNoOptionsMessage,
  renderSingleSelectedOption,
  renderMenuList,
  renderMenu,
  ...restProps
}: PublicProps<TOptionValue, IsMulti>): ReactSelectProps<
  Option<TOptionValue>,
  IsMulti,
  Group<TOptionValue>
> {
  const reactSelectProps: ReactSelectProps<Option<TOptionValue>, IsMulti, Group<TOptionValue>> = {
    ...restProps,
    isDisabled: disabled,
    formatOptionLabel: renderOption,
    formatGroupLabel: renderGroupLabel,
    styles: styles({ hasError }, styledConfig),
    components: {
      ...(renderDropdownIndicator
        ? {
            DropdownIndicator: props => (
              <components.DropdownIndicator {...props}>
                {renderDropdownIndicator()}
              </components.DropdownIndicator>
            ),
          }
        : {}),
      ...(renderIndicatorsContainer
        ? {
            IndicatorsContainer: props => (
              <components.IndicatorsContainer {...props}>
                {renderIndicatorsContainer()}
              </components.IndicatorsContainer>
            ),
          }
        : {}),
      ...(renderNoOptionsMessage
        ? {
            NoOptionsMessage: props => (
              <components.NoOptionsMessage {...props}>
                {renderNoOptionsMessage()}
              </components.NoOptionsMessage>
            ),
          }
        : {}),
      ...(renderSingleSelectedOption
        ? {
            SingleValue: props => {
              return (
                <components.SingleValue {...props}>
                  {renderSingleSelectedOption(props.data)}
                </components.SingleValue>
              );
            },
          }
        : {}),
      ...(renderMenuList
        ? {
            MenuList: props => {
              return (
                <components.MenuList {...props}>
                  {renderMenuList(props.children)}
                </components.MenuList>
              );
            },
          }
        : {}),
      ...(renderMenu
        ? {
            Menu: props => {
              return <components.Menu {...props}>{renderMenu(props)}</components.Menu>;
            },
          }
        : {}),
    },
  };

  return reactSelectProps;
}

const getFlatOptions = <TOptionValue extends any>(
  options: (Option<TOptionValue> | Group<TOptionValue>)[]
): Option<TOptionValue>[] => {
  return options.reduce<Option<TOptionValue>[]>((acc, currentValue) => {
    if (currentValue.options) {
      return acc.concat(currentValue.options);
    }
    return acc.concat(currentValue);
  }, []);
};

/**
 * get a single selected option - useful for Select, AsyncSelect or YesNoSelect
 */
export const getSelectedOption = <TOptionValue extends any>(
  options: (Option<TOptionValue> | Group<TOptionValue>)[] = [],
  value?: TOptionValue | null
): SingleValue<Option<TOptionValue>> | undefined => {
  if (value === null) {
    return null;
  }

  return getFlatOptions(options).find(o => o.value === value);
};

/**
 * get selected options - useful for MultiSelect or AsyncMultiSelect
 */
export const getSelectedOptions = <TOptionValue extends any>(
  options: (Option<TOptionValue> | Group<TOptionValue>)[] = [],
  values: TOptionValue[] | null | undefined = null
): MultiValue<Option<TOptionValue>> => {
  return getFlatOptions(options).filter(o => (values ?? []).includes(o.value));
};
