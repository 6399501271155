import { Option, ToastManager } from '@cmg/common';
import React from 'react';

import { SortEnumType } from '../../../../../graphql/__generated__/index';
import { SearchFilterHookResult } from '../../../../../graphql/types';
import { useOfferingsFilterForm_UnderwritersQuery } from '../graphql/__generated__/OfferingsFilterForm_Underwriters';
import { useCreateUnderwriterOptions } from '../OfferingsFilterForm.model';

export const useSearchUnderwriters = (): SearchFilterHookResult => {
  const [searchText, setSearchText] = React.useState<string>();
  const { data, error, loading } = useOfferingsFilterForm_UnderwritersQuery({
    variables: {
      order: { name: SortEnumType.Asc },
      where: {
        name: searchText ? { like: searchText } : undefined,
      },
    },
  });
  const resolveRef = React.useRef<(value: Option<string>[]) => void>();
  const underwriterOptions = useCreateUnderwriterOptions(data?.managers?.items ?? [], searchText);

  React.useEffect(() => {
    if (error) {
      ToastManager.error('Error occurred while fetching underwriters');
    }
    if (!loading && data && resolveRef.current) {
      resolveRef.current(underwriterOptions);
    }
  }, [loading, data, error, underwriterOptions]);

  const loadOptions = React.useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    return new Promise<Option<string>[]>(resolve => {
      resolveRef.current = resolve;
    });
  }, []);

  return {
    defaultOptions: underwriterOptions,
    loadOptions,
  };
};
