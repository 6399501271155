import 'react-diff-view/style/index.css';

import * as jsdiff from 'diff';
import React from 'react';
import { Diff, Hunk, parseDiff } from 'react-diff-view';

export const createDiffText = (prevVersion: object, nextVersion: object) => {
  const prevText = JSON.stringify(prevVersion, undefined, 2);
  const nextText = JSON.stringify(nextVersion, undefined, 2);

  const originalDiff = jsdiff.createPatch('FILENAME', prevText, nextText);

  // jsdiff is equal to `diff` command from bash (without any other flag)
  // we need `diff --git` to make it compatible with react-diff-view
  // following code emulates `diff -git` header with some mocked data (eg commit ID)
  return originalDiff.replace(
    /Index: FILENAME\s=+/m,
    '\ndiff --git FILENAME FILENAME\nindex 000000000..000000001 100644'
  );
};

export type Props = {
  prevVersion: object;
  nextVersion: object;
};

const DiffView: React.FC<Props> = ({ prevVersion, nextVersion }) => {
  const diffText = createDiffText(prevVersion, nextVersion);
  const files = parseDiff(diffText);

  const renderFile = ({ oldRevision, newRevision, type, hunks }) => (
    <Diff key={oldRevision + '-' + newRevision} viewType="split" diffType={type} hunks={hunks}>
      {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
    </Diff>
  );

  return <div>{files.map(renderFile)}</div>;
};

export default DiffView;
