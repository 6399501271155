import { FlipProp, IconName, IconPrefix, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import setupIcons from './icon-provider/fontAwesomeIconProvider';
import { LightIconName } from './icon-provider/lightIcons';
import { RegularIconName } from './icon-provider/regularIcons';
import { SolidIconName } from './icon-provider/solidIcons';

export enum VariantMap {
  regular = 'far',
  solid = 'fas',
  light = 'fal',
}

export type IconNameParts =
  | { variant?: 'light'; name: keyof typeof LightIconName }
  | { variant: 'solid'; name: keyof typeof SolidIconName }
  | { variant: 'regular'; name: keyof typeof RegularIconName };

export type Props = {
  size?: SizeProp;
  color?: string;
  flip?: FlipProp;
  fixedWidth?: boolean;
  border?: boolean;
  spin?: boolean;
  pulse?: boolean;
  className?: string;
  title?: string;
} & IconNameParts;

// adds all the available fontawesome icons that we import individually to the Icon component
setupIcons();

const Icon: React.FC<Props> = ({
  name,
  color,
  size,
  fixedWidth,
  flip,
  border,
  spin,
  pulse,
  className,
  title,
  variant = 'light',
}) => (
  <FontAwesomeIcon
    icon={[VariantMap[variant] as IconPrefix, name as IconName]}
    color={color}
    size={size}
    fixedWidth={fixedWidth}
    flip={flip}
    border={border}
    spin={spin}
    pulse={pulse}
    className={className}
    title={title}
  />
);

Icon.displayName = 'Icon';

export { type SizeProp };

export default Icon;
