import axios from 'axios';

/**
 * Rolodex client
 **/
export const rolodexApiClient = axios.create({
  baseURL: '/dex/api/v1',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});
