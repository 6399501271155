export const emptyValue = '-';

export type SectionHeaderProps = {
  id: string;
  name: string;
};

export type CardRowItem = {
  name: string;
  info?: string;
  value: string | React.ReactNode;
  value2?: string | React.ReactNode;
};
export type TermsTableRowItem = {
  name: string;
  initial: string;
  revised: string;
  final: string;
};
export type ManagerRowItem = {
  name: string;
  role: string;
  underwritingBaseShares: string;
  pctUnderwritingBaseShares: string;
  estimatedFee?: string;
};
export type UnderwriterTermsSectionData = {
  underwriterTerms: CardRowItem[];
  underwriterNames: CardRowItem[];
  underwriterPct: CardRowItem[];
};
export type StructurePricingTableItem = {
  name: StructurePricingTermName;
  grossProceeds: string;
  offerPrice: string | React.ReactNode;
  sizeInSecurities: string;
  primary: string;
  secondary: string;
  ovltAuth: string | React.ReactNode;
};
export type StructurePricingSectionData = {
  structurePricingTable: StructurePricingTableItem[];
  structurePricingFOData: CardRowItem[];
  structurePricingRows: {
    column1: CardRowItem[];
    column2: CardRowItem[];
    column3: CardRowItem[];
  };
};
export enum StructurePricingTermName {
  Initial = 'Initial',
  Revised = 'Revised',
  Priced = 'Priced',
  OvltExercised = 'Ovlt. Exercised',
  Total = 'Total',
}
export enum StructurePricingTableHeader {
  grossProceeds = 'Gross Proceeds',
  offerPriceRange = 'Offer Price/Range',
  sizeInSecurities = 'Size in Securities',
  primary = 'Primary',
  secondary = 'Secondary',
  ovltAuth = 'Ovlt. Auth',
}
export enum StructurePricingRowLabels {
  sharesOutstandingPre = 'Shares Outstanding Pre-Offering',
  sharesOutstandingPost = 'Shares Outstanding Post-Offering',
  adrCommon = 'ADR/Common',
  marketCapPre = 'Market Cap Pre-Offering',
  marketCapPost = 'Market Cap Post-Offering',
  marketCapPrePct = 'Market Cap Pre Offering %',
  marketCapPrePostPct = 'Market Cap Pre/Post Offering %',
  marketCapPrePctNoCornerstone = 'Market Cap Pre-Offering % Excluding Cornerstone',
  pctCornerstone = '% Indicated by Cornerstone',
  pctSecondary = '% Secondary',
  splitAdjustedOfferPrice = 'Split Adjusted Offer Price',
  pctToMidpoint = '% to Midpoint',
  changeInBaseProceedsPct = 'Change in Base Proceeds %',
  priceVsRange = 'Price vs Range',
  carveOut = 'Carve-Out',
  companyRepurchase = 'Company Repurchase',
  concurrentOffering = 'Concurrent Offering',
  forwardAgreement = 'Forward Agreement',
  syntheticSecondary = 'Synthetic Secondary',
  useOfProceeds = 'Use of Proceeds',
  useOfProceedsNotes = 'Use of Proceeds Notes',
  // FO
  thirtyDayADTV = '30 Day ADTV',
  ADTVDateRange = 'ADTV Date Range',
  sizeAsMultipleOfADTV = 'Size as Multiple of ADTV',
  cleanUpTrade = 'Clean Up Trade',
  firstFO = 'First Follow-on',
  lastTradeBeforeFiling = 'Last Trade Before Filing',
  lastTradeBeforeFilingSplitAdjusted = 'Last Trade Before Filing\n(Split Adjusted)',
  lastTradeBeforeLaunch = 'Last Trade Before Launch',
  lastTradeBeforeOffer = 'Last Trade Before Offer',
  VWAPBeforeOffer = 'VWAP Before Offer',
  fiftyTwoWeekHigh = '52-Week High',
  REOffer = 'Re Offer',
  netPrice = 'Net Price',
  allInCost = 'All-in',
}
export type ManagerSectionData = {
  bookrunners: ManagerRowItem[];
  nonBookrunners: ManagerRowItem[];
  totalManagers?: number | null;
  totalBookrunners?: number | null;
  totalNonBookrunners?: number | null;
};
export type SECFeedRowItem = {
  title: string;
  description: string;
  url: string;
};

export type CornerstoneRowItem = {
  name: string;
  type: string;
  isNewInvestor: string | React.ReactNode;
  amount?: string | null;
};
export type CornerstoneInvestmentSection = {
  investorsCount: number;
  investmentAmount: string;
  individualInvestments: CornerstoneRowItem[];
  combinedAmount: string;
  combinedInvestments: CornerstoneRowItem[];
};
export type CornerstoneSectionData = {
  offeringParticipants: CornerstoneInvestmentSection;
  privatePlacement: CornerstoneInvestmentSection;
  totalInvestorsCount?: number | null;
  totalInvestmentAmount: string;
};
export enum OfferingProfileFieldName {
  Issuer = 'Issuer',
  Type = 'Type',
  OfferingType = 'Offering Type',
  OfferingStatus = 'Offering Status',
  SecurityType = 'Security Type',
  InstrumentDescription = 'Instrument Description',
  Rank = 'Rank',
  SellingRestrictions = 'Selling Restriction(s)',
  SellingRestrictions2 = 'Selling Restrictions',
  Sector = 'Sector',
  Symbol = 'Symbol',
  Exchange = 'Exchange',
  ExchangeRegionCountry = 'Exchange Region/Country',
  ExchRegionCountry = 'Exch. Country / Region',
  RegionCountry = 'Exchange Region/Country',
  Currency = 'Offering Currency',
  PublicFilingDate = 'Public Filing Date',
  PublicFilingTime = 'Public Filing Time',
  TerminationDate = 'Terminated Date',
  ExpirationDate = 'Expected Expiration Date',
  LaunchDate = 'Launch Date',
  PricingDate = 'Pricing Date',
  FirstTradeDate = 'First Trade Date',
  SettlementDate = 'Settlement Date',
  ConfidentialFilingDate = 'Confidential Filing Date',
  WithdrawnDate = 'Withdrawn Date',
  AnnouncementDate = 'Announcement Date',
  PostponedDate = 'Postponed Date',
  PrincipalAmount = 'Principal Amount',
  Accelerated = 'Accelerated',
  PrincipalAmountOvltAuthorized = 'Principal Amount Ovlt (Authorized)',
  PrincipalAmountOvltExercised = 'Principal Amount Ovlt (Exercised)',
  AggrPrincipalAmntInclOvltExercised = 'Aggr. Principal Amnt (Incl. Ovlt Exercised)',
  LatestGrossProceedsBase = 'Gross Proceeds (Base)',
  LatestGrossProceedsTotal = 'Gross Proceeds (Total)',
  Coupon = 'Coupon',
  Premium = 'Premium',
  OfferPrice = 'Offer Price',
  AtmProgramSize = 'ATM Program Size',
  AtmProgramSizeInSecurities = 'ATM Program Size in Securities',
  ParValue = 'Par Value',
  ConversionPrice = 'Conversion Price',
  InitialConversionRatio = 'Initial Conversion Ratio',
  EfftConversionPremium = 'Efft. Conversion Premium',
  EfftConversionPrice = 'Efft. Conversion Price',
  ReferencePrice = 'Reference Price',
  VersusTalk = 'Versus Talk',
  MaturityTenor = 'Maturity Tenor',
  MaturityDate = 'Maturity Date',
  Callable = 'Callable',
  CallDates = 'Call Date(s)',
  CallPrice = 'Call Price',
  ProvisionalCall = 'Provisional Call',
  Puttable = 'Puttable',
  DividendProtection = 'Dividend Protection',
  ContingentConversion = 'Contingent Conversion',
  ContingentPayment = 'Contingent Payment',
  Hedging = 'Hedging',
  ChangeOfControl = 'Change of Control',
  UseOfProceeds = 'Use of Proceeds',
  GrossSpread = 'Gross Spread',
  SellingConcessionUsd = 'Selling Concession',
  ManagementFee = 'Management Fee',
  UnderwritingFee = 'Underwriting Fee',
  LeftLead = 'Left Lead',
  StabilizationAgent = 'Stabilization Agent',
  SettlementAgent = 'Settlement Agent',
  LockupPeriod = 'Lock-up Period',
  LockupExpirations = 'Lock-up Expirations',
  ConditionalLockup = 'Conditional Lockup',
  MultipleLockup = 'Multiple Lockup',
  LockupEarlyRelease = 'Lock-up Early Release',
  UnderwriterCounsel = 'Underwriter Counsel',
  IssuerCounsel = 'Issuer Counsel',
  Trustee = 'Trustee',
  Advisory = 'Advisory',
  IssuerAuditor = 'Issuer Auditor',
  AdditionalListing = 'Additional Listing',
  UpListing = 'UpListing',
  SECRegidterd = 'SEC Registered',
  FDICRegistered = 'FDIC Registered',
  PricingCurrency = 'Pricing Currency',
  GrossProceedsTotal = 'Gross Proceeds Total',
  PctSecondary = '% Secondary',
  NetPrice = 'Net Price',
  EstimatedFee = 'Estimated Fee',
  PctToActiveBookrunners = '% To Active Bookrunners',
  PctToBookrunners = '% To Bookrunners',
  PctToNonBookrunners = '% To Non-Bookrunners',
  PctToLeftLead = '% To Left Lead',
}

export enum OfferingProfileTermFieldName {
  OfferingSize = 'Offering Size ($M)',
  OfferingSizeShares = 'Offering Size (Shares)',
  MarketCapital = 'Market Cap ($M)',
  MarketCapitalPercentage = '% of Market Cap',
  Adtv = 'ADTV',
  MultiplesAdtv = 'Multiples of ADTV',
  PriceVsRange = 'Prive vs Range',
  MidPointPercentage = '$ to Midpoint',
  PaymentDebt = 'Debt Payment',
  ForwardAgreement = 'Forward Agreement',
}

export enum OfferingProfileStructureFieldName {
  LatestSizeInSecuritiesTotal = 'Size in Securities Total',
  LatestGrossProceedsTotal = 'Gross Proceeds Total',
  PreOfferingMktCap = 'Pre-Offering Mkt. Cap',
  PctPreOfferingMarketCap = '% of Pre-Offering Market Cap',
  DayAdtv30 = '30 Day ADTV',
  LastTradeBeforeFiling = 'Last Trade Before Filing',
  LastTradeBeforeFilingSplitAdjusted = 'Last Trade Before Filing (Split Adjusted)',
  ForwardSale = 'Forward Sale',
}

export enum OfferingUnderwritingTermsFieldName {
  GrossSpreadPct = 'Gross Spread %',
}

export enum OfferingPerformanceFieldName {
  pctOfferToOpen = 'Open',
  pctOfferTo1DayVWAP = '1 Day VWAP',
  pctOfferTo1Day = '1 Day',
  pctOfferTo3Day = '3 Day',
  pctOfferTo7Day = '7 Day',
  pctOfferTo14Day = '14 Day',
  pctOfferTo30Day = '30 Day',
  pctOfferTo90Day = '90 Day',
  pctOfferTo180Day = '180 Day',
  pctOfferTo1Year = '1 Year',
  pctOfferToPriorQuarter = 'Prior Quarter',
  pctOfferToCurrent = 'Current',
}

export enum OfferingLockupsFieldName {
  LockupParty = 'Lock-up Party',
  Period = 'Lock-up Period',
  ExpDate = 'Lock-up Expiration',
  Benchmark = 'Benchmark',
  ConditionalLockups = 'Conditional Lock-ups',
  MultipleLockups = 'Multiple Lock-ups',
  EarlyRelease = 'Lock-up Early Release',
}
