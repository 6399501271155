/**
 * The noun portion of our permissions. These resources describe the surface area
 * of API and UI that can be protected via access control.
 */
export enum Resource {
  GlobalAccount = 'GlobalAccount',
  GlobalUser = 'GlobalUser',
  GlobalRole = 'GlobalRole',
  GlobalApiKey = 'GlobalApiKey',
  GlobalCrm = 'GlobalCrm',
  GlobalInstitutionalIndication = 'GlobalInstitutionalIndication',
  ApiKey = 'ApiKey',
  Firm = 'Firm',
  Account = 'Account',
  User = 'User',
  Role = 'Role',
  Team = 'Team',
  Conflict = 'Conflict',
  Contact = 'Contact',
  Offering = 'Offering',
  PublishedOffering = 'PublishedOffering',
  Investor = 'Investor',
  SyndicateInvite = 'SyndicateInvite',
  ReceivedSyndicateInvite = 'ReceivedSyndicateInvite',
  OrderBookParameter = 'OrderBookParameter',
  RetailOrder = 'RetailOrder',
  DspOrder = 'DspOrder',
  InstitutionalOrder = 'InstitutionalOrder',
  OrderAcknowledgement = 'OrderAcknowledgement',
  PostCloseOrderBook = 'PostCloseOrderBook',
  InvestorAllocation = 'InvestorAllocation',
  AllocationSet = 'AllocationSet',
  SettlementDetail = 'SettlementDetail',
  Ioi = 'Ioi',
  FundIoi = 'FundIoi',
  CustomSectors = 'CustomSectors',
  ApiDocs = 'ApiDocs' /** @deprecated */,
  SharedReports = 'SharedReports',
  SharedFollowedOfferings = 'SharedFollowedOfferings',
  Datalab = 'Datalab',
  DatalabUI = 'DatalabUI',
  CRM = 'CRM',
  CrmBasicInvestorFirm = 'CrmBasicInvestorFirm',
  SignupToken = 'SignupToken',
  Underwriter = 'Underwriter',
  FullSyndicateLineUp = 'FullSyndicateLineUp',

  // XC
  OrderBook = 'OrderBook',
  InstitutionalIndication = 'InstitutionalIndication',
  InstitutionalAllocation = 'InstitutionalAllocation',
  RetailDemand = 'RetailDemand',
  RetailRetention = 'RetailRetention',
  CoveredAccountsCrm = 'CoveredAccountsCrm',
  CoveredAccountsInstitutionalIndication = 'CoveredAccountsInstitutionalIndication',
  CoveredAccountsInstitutionalAllocation = 'CoveredAccountsInstitutionalAllocation',
  RegulatoryFiling = 'RegulatoryFiling',
  SyndicateWire = 'SyndicateWire',
  DeliveryInstructions = 'DeliveryInstructions',
  Company = 'Company',
  OffPlatformOffering = 'OffPlatformOffering',
  InternationalOffering = 'InternationalOffering',
  Trade = 'Trade',
  Designation = 'Designation',
  Expense = 'Expense',
  Stabilization = 'Stabilization',
  Accounting = 'Accounting',
  Letter = 'Letter',
  SalesCredit = 'SalesCredit',
  ConvertsOffering = 'ConvertsOffering',
  SyndicateMember = 'SyndicateMember',
  Atm = 'ATM',
  AtmSelldown = 'ATMSelldown',
  Converts = 'Converts',
  DlExcelDownload = 'DLExcelDownload',
  CertificateLibrary = 'CertificateLibrary',
  CertificateReview = 'CertificateReview',
}
