import { apiUtil, UnpackPromise, urlUtil } from '@cmg/common';
import axios, { AxiosRequestTransformer, AxiosResponse, AxiosResponseTransformer } from 'axios';

import {
  encodeQueryCamelToSnake,
  objectCamelToSnake,
  objectSnakeToCamel,
} from '../common/helpers/url-helpers';
import { getAppSettings } from '../config/appSettings';
import { ApiResponse } from '../types/api/ApiResponse';
import { UUID } from '../types/common';
import {
  OfferingNote,
  OfferingNoteCreate,
  OfferingNoteUpdate,
} from '../types/domain/offering/offering-note';
import {
  UserReport,
  UserReportCreate,
  UserReportShare,
  UserReportUpdate,
} from '../types/domain/report/userReport';
import {
  UserReportPartialsFetch,
  UserReportPartialsResult,
} from '../types/domain/report/userReportPartial';
import { UnderwriterCreditsAllocation } from '../types/domain/underwriter-credits/underwriterCreditsAllocation';
import { UnderwriterCreditsManager } from '../types/domain/underwriter-credits/underwriterCreditsManager';
import { UnderwriterCreditsOffering } from '../types/domain/underwriter-credits/underwriterCreditsOffering';
import { OrganizationUsersResult } from '../types/domain/user/organizationUser';

export const DATALAB_API_CALL_ID = 'DATALAB_API_CALL_ID';
export const ADVISORY_OPTIONS = 'datalab_api_call/ADVISORY_OPTIONS';
export const SPONSOR_OPTIONS = 'datalab_api_call/SPONSOR_OPTIONS';
export const SPONSORS = 'datalab_api_call/SPONSORS';
export const MANAGER_ROLE__OPTIONS = 'datalab_api_call/MANAGER_ROLE__OPTIONS';
export const UNDERWRITER_OPTIONS = 'datalab_api_call/UNDERWRITER_OPTIONS';
export const USER_REPORTS = 'datalab_api_call/USER_REPORTS';
export const FUNDS = 'datalab_api_call/FUNDS';

/**
 * @deprecated Use datalabGatewayApi or GraphQL instead. We are transitioning away from this ruby hosted api.
 */
export const dlApiClient = axios.create({
  responseType: 'json',
  headers: {
    'Client-App-Version': getAppSettings().client.appVersion,
    'Content-Type': 'application/json',
  },
  transformRequest: [
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    data => {
      if (data) {
        const dataObj = JSON.parse(data);

        return typeof dataObj === 'object' ? JSON.stringify(objectCamelToSnake(dataObj)) : data;
      }

      return data;
    },
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    data => {
      if (data && typeof data === 'object') {
        return objectSnakeToCamel(data);
      }

      return data;
    },
  ],
});

const datalab = {
  fetchChart: (section, filters) =>
    // TODO: fix typings
    dlApiClient.post<any, ApiResponse<any>>(`/datalabs/${section}`, filters, {
      // @ts-ignore
      apiCallId: DATALAB_API_CALL_ID,
      transformRequest: data => JSON.stringify(data),
      transformResponse: data => data,
    }),
  fetchTable: (section, filters) =>
    dlApiClient.post(`/datalabs/${section}/table`, filters, {
      // @ts-ignore
      apiCallId: DATALAB_API_CALL_ID,
      transformRequest: data => JSON.stringify(data),
      transformResponse: data => data,
    }),
  download: (datalabType, params) =>
    dlApiClient.post(`/datalabs/${datalabType}/download`, params, {
      responseType: 'blob',
      headers: { Accept: apiUtil.FILE_MIME_TYPES.XLSX },
      transformResponse: data => data,
    }),
};

const offering = {
  fetch: offeringId => dlApiClient.get(`/offerings/${offeringId}`),
  fetchAdvisoryOptions: () =>
    dlApiClient.get(`/offerings/advisory_options`, {
      // @ts-ignore
      apiCallId: ADVISORY_OPTIONS,
    }),
  fetchSponsorOptions: () =>
    dlApiClient.get(`/offerings/sponsor_options`, {
      // @ts-ignore
      apiCallId: SPONSOR_OPTIONS,
    }),
  fetchSponsors: (query: {
    search?: string;
    sponsorIds?: string[];
    page: number;
    perPage: number;
  }) =>
    dlApiClient.get(`/sponsors${urlUtil.queryStringify(query)}`, {
      // @ts-ignore
      apiCallId: SPONSORS,
    }),
  fetchUnderwriterOptions: () =>
    // TODO: fix typings
    dlApiClient.get<any, ApiResponse<any>>(`/offerings/underwriter_options`, {
      // @ts-ignore
      apiCallId: UNDERWRITER_OPTIONS,
    }),
  fetchUserNote: offeringId => dlApiClient.get(`/offerings/${offeringId}/user_notes`),
  saveUserNote: (offeringId, userNote) =>
    dlApiClient.post(`/offerings/${offeringId}/user_notes`, { userNote }),
  fetchOfferingNotes: offeringId =>
    dlApiClient.get<OfferingNote[]>(`/offerings/${offeringId}/offering_notes`),
  createOfferingNote: (offeringId, offeringNote) =>
    dlApiClient.post<{ offeringNote: OfferingNoteCreate }, AxiosResponse<OfferingNoteCreate>>(
      `/offerings/${offeringId}/offering_notes`,
      {
        offeringNote,
      }
    ),
  updateOfferingNote: (offeringId, noteId, offeringNote) =>
    dlApiClient.put<{ offeringNote: OfferingNoteUpdate }, AxiosResponse<OfferingNoteUpdate>>(
      `/offerings/${offeringId}/offering_notes/${noteId}`,
      {
        offeringNote,
      }
    ),
  deleteOfferingNote: (offeringId, noteId) =>
    dlApiClient.delete(`/offerings/${offeringId}/offering_notes/${noteId}`),
  fetchManagerRoleOptions: () =>
    dlApiClient.get(`/offerings/manager_role_options`, {
      // @ts-ignore
      apiCallId: MANAGER_ROLE__OPTIONS,
    }),
};
export type FetchUnderwriterOptionsResponse = UnpackPromise<
  ReturnType<typeof offering.fetchUnderwriterOptions>
>;

const user = {
  fetchPermissions: () => dlApiClient.get(`/users/user_permissions`),
  fetchInformation: () => dlApiClient.get(`/users/information`),
  supportRequest: data => dlApiClient.post(`/users/support`, data),
  update: data => dlApiClient.put(`/users/update`, data),
};

const organization = {
  fetchUsers: (params: { canShareReportWith?: boolean; useCustomSectors?: boolean }) =>
    dlApiClient.get<OrganizationUsersResult>(`/users/index?${encodeQueryCamelToSnake(params)}`),
};

export type FetchOrganizationUsersResponse = UnpackPromise<
  ReturnType<typeof organization.fetchUsers>
>;

const userReport = {
  fetchUserReportPartials: ({
    searchQuery,
    favorites,
    paginationParams,
  }: UserReportPartialsFetch) =>
    dlApiClient.get<UserReportPartialsResult, ApiResponse<UserReportPartialsResult>>(
      `/user_reports?${encodeQueryCamelToSnake({
        ...paginationParams,
        ...(searchQuery
          ? {
              query: searchQuery,
            }
          : {}),
        ...(favorites
          ? {
              favorites,
            }
          : {}),
      })}`,
      {
        // @ts-ignore
        apiCallId: USER_REPORTS,
      }
    ),
  fetch: id => dlApiClient.get(`/user_reports/${id}`),
  create: (data: UserReportCreate) =>
    dlApiClient.post<UserReportCreate, AxiosResponse<UserReport>>(`/user_reports`, data),
  update: (reportId: string, data: UserReportUpdate) =>
    dlApiClient.patch<UserReportUpdate, AxiosResponse<UserReport>>(
      `/user_reports/${reportId}`,
      data
    ),
  delete: (id: string) => dlApiClient.delete(`/user_reports/${id}`),
  share: (reportId: string, data: UserReportShare) =>
    dlApiClient.post<UserReportShare, AxiosResponse<UserReport>>(
      `/user_reports/${reportId}/share`,
      data
    ),
  favorite: (reportId: string, isFavorite: boolean) =>
    dlApiClient.put<never, AxiosResponse<UserReport>>(
      `/user_reports/${reportId}/favorite?is_favorite=${isFavorite}`
    ),
};

export type CreateReportResponse = UnpackPromise<ReturnType<typeof userReport.create>>;
// TODO
// Why isn't the AxiosResponse type including the "ok" field here,
// while it is including it in Axios requests that include the third
// AxiosConfig parameter (e.g. userReport.create).
// It's worth noting that response.ok is a field specific to the Fetch
// API, which may be why it's not included in Axios' type definitions.
export type UpdateReportResponse = UnpackPromise<ReturnType<typeof userReport.update>> & {
  ok: boolean;
};
export type ShareReportResponse = UnpackPromise<ReturnType<typeof userReport.share>> & {
  ok: boolean;
};
export type FavoriteReportResponse = UnpackPromise<ReturnType<typeof userReport.favorite>> & {
  ok: boolean;
};

const fund = {
  fetchAll: () =>
    dlApiClient.get(`/funds`, {
      // @ts-ignore
      apiCallId: FUNDS,
    }),
  create: name => dlApiClient.post('/funds', { name }),
};

const ioi = {
  fetchAll: offeringId => dlApiClient.get(`/iois/${offeringId}`),
  submit: (offeringId, data) => dlApiClient.post(`/iois/${offeringId}`, data),
};

const fundIoi = {
  fetchAll: offeringId => dlApiClient.get(`/fund_iois/${offeringId}`),
  submit: (offeringId, iois, allocations) =>
    dlApiClient.post(`/fund_iois/${offeringId}`, { iois, allocations }),
};

export type UnderwriterCreditsSubmitData = {
  allocation: UnderwriterCreditsAllocation;
  managers: UnderwriterCreditsManager[];
};
export type UnderwriterCreditsFetchResponse = {
  offering: UnderwriterCreditsOffering;
  allocation: UnderwriterCreditsAllocation;
  managers: UnderwriterCreditsManager[];
};
export type UnderwriterCreditsSubmitSuccessData = {
  data: {
    offering: UnderwriterCreditsOffering;
    allocation: UnderwriterCreditsAllocation;
    managers: UnderwriterCreditsManager[];
  };
};
const underwriterCredits = {
  fetch: (offeringId: UUID) =>
    dlApiClient.get<UnderwriterCreditsFetchResponse>(`/underwriter_credits/${offeringId}`),
  submit: (offeringId: UUID, data: UnderwriterCreditsSubmitData) =>
    dlApiClient.post<
      UnderwriterCreditsSubmitData,
      AxiosResponse<UnderwriterCreditsSubmitSuccessData>
    >(`/underwriter_credits/${offeringId}`, data),
};

const datalabApi = {
  // datalab
  datalab: datalab,
  fetchDatalab: datalab.fetchChart, // todo @deprecated
  fetchDatalabTable: datalab.fetchTable, // todo @deprecated
  downloadDatalabTable: datalab.download, // todo @deprecated

  // offering
  offering: offering,
  fetchOffering: offering.fetch, // todo @deprecated
  fetchAdvisoryOptions: offering.fetchAdvisoryOptions, // todo @deprecated
  fetchSponsorOptions: offering.fetchSponsorOptions, // todo @deprecated
  fetchSponsors: offering.fetchSponsors, // todo @deprecated
  fetchUnderwriterOptions: offering.fetchUnderwriterOptions, // todo @deprecated
  fetchUserNote: offering.fetchUserNote, // todo @deprecated
  saveUserNote: offering.saveUserNote, // todo @deprecated
  fetchOfferingNotes: offering.fetchOfferingNotes, // todo @deprecated
  createOfferingNote: offering.createOfferingNote, // todo @deprecated
  updateOfferingNote: offering.updateOfferingNote, // todo @deprecated
  deleteOfferingNote: offering.deleteOfferingNote, // todo @deprecated

  // users
  user: user,
  fetchDatalabUserPermissions: user.fetchPermissions, // todo @deprecated
  supportRequest: user.supportRequest, // todo @deprecated

  // organization
  fetchOrganizationUsers: organization.fetchUsers, // todo @deprecated

  // user reports
  userReport: userReport,
  fetchDatalabUserReportPartials: userReport.fetchUserReportPartials, // todo @deprecated
  fetchDatalabReport: userReport.fetch, // todo @deprecated
  createDatalabReport: userReport.create, // todo @deprecated
  updateDatalabReport: userReport.update, // todo @deprecated
  deleteDatalabReport: userReport.delete, // todo @deprecated
  shareDatalabReport: userReport.share, // todo @deprecated
  favoriteDatalabReport: userReport.favorite, // todo @deprecated

  // fund
  fund: fund,
  fetchFundOptions: fund.fetchAll, // todo @deprecated
  addFund: fund.create, // todo @deprecated

  // ioi
  ioi: ioi,
  fetchIoi: ioi.fetchAll, // todo @deprecated
  saveAllocationAndIois: ioi.submit, // todo @deprecated

  // fund ioi
  fundIoi: fundIoi,
  fetchIoiFunds: fundIoi.fetchAll, // todo @deprecated
  updateIoiFunds: fundIoi.submit, // todo @deprecated

  // underwriter credits
  underwriterCredits,
};

export default datalabApi;
