import React from 'react';
import styled from 'styled-components/macro';

import { ServiceErrorCode } from '../../../api/types/apiTypes';
import PrimaryButton from '../../buttons/button/PrimaryButton';
import CenteredPage from '../../layout/centered-page/CenteredPage';
import { defaultErrorDetail, serviceErrorDetail } from './constants';
import LightErrorTemplate from './templates/LightErrorTemplate';

export const SHeading = styled.div`
  font-size: ${({ theme }) => theme.text.size.large};
  margin: 25px 0 10px;
`;
export const SMessage = styled.div`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  margin-top: 15px;
`;
export const SButtonWrapper = styled.div`
  margin-top: 25px;
`;

export type Props = {
  /**
   * An error code.  If the value matches a ServiceErrorCode
   * key or a key within the customErrorDetails prop, the appropriate
   * text for that error code will be displayed.
   */
  errorCode?: string;
  /**
   * Provides the ability to support custom error codes and corresponding
   * on-screen text.  By supplying a map via this prop and setting the errorCode
   * prop to a key that exists in that map, the corresponding title and message
   * for that custom code is displayed.
   */
  customErrorDetails?: { [key: string]: { title?: string; message?: string | JSX.Element } };
  /**
   * When present, a "Return to Home Page" button is displayed.
   * Clicking the button will redirect to the provided URL.
   */
  returnUrl?: string;
  /**
   * The UI variant/theme.
   */
  variant?: 'DARK' | 'LIGHT';
};

const VariantWrapper: React.FC<{ variant: Props['variant'] }> = ({ variant, children }) => {
  switch (variant) {
    case 'LIGHT':
      return <LightErrorTemplate>{children}</LightErrorTemplate>;
    case 'DARK':
    default:
      return <CenteredPage>{children}</CenteredPage>;
  }
};

/**
 * Displays a full-screen error message.
 */
const ApplicationError = ({
  errorCode,
  customErrorDetails = {},
  returnUrl,
  variant = 'DARK',
}: Props) => {
  const errorDetails = {
    ...serviceErrorDetail,
    ...customErrorDetails,
  };
  const errorDetail =
    errorCode && errorDetails[errorCode] ? errorDetails[errorCode] : defaultErrorDetail;

  return (
    <VariantWrapper variant={variant}>
      {errorDetail.title && <SHeading>{errorDetail.title}</SHeading>}
      {errorDetail.message && <SMessage>{errorDetail.message}</SMessage>}
      <SButtonWrapper>
        {errorCode !== ServiceErrorCode.IP_BLOCKED && returnUrl && (
          <PrimaryButton variant="high-contrast" href={returnUrl} fullWidth size="large">
            Return to Home Page
          </PrimaryButton>
        )}
      </SButtonWrapper>
    </VariantWrapper>
  );
};

export default ApplicationError;
