import isEqual from 'lodash/isEqual';
import memoize from 'memoize-one';
import React, { useState } from 'react';
import ReactSelect, { ActionMeta, InputActionMeta, SingleValue } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import {
  getReactSelectProps,
  getSelectedOption,
  PublicProps,
  SyncSelectProps,
} from './BaseSelect.model';
import { ExpandableGroup } from './ExpandableGroup';
import { ExpandableGroupLabel } from './ExpandableGroupLabel';
import { expandableSelectStyles } from './ExpandableSelect.model';
import { Group, Option } from './types';

export type Props<TOptionValue = string> = PublicProps<TOptionValue, false> &
  FieldControlProps<TOptionValue | null, TOptionValue | null> &
  SyncSelectProps<TOptionValue>;

/**
 * Sync select component with expand/collapse logic, intended to be used with option groups only.
 */
export default function ExpandableSelect<TOptionValue = string>(props: Props<TOptionValue>) {
  const [groups, setGroups] = useState((props.options || []) as Group<TOptionValue>[]);

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (!props.isSearchable || meta.action !== 'input-change') {
      return;
    }

    const nextGroups = groups.map(group => ({
      ...group,
      expanded: !!value,
    }));

    setGroups(nextGroups);
  };

  const handleOnChange = (
    value: SingleValue<Option<TOptionValue>>,
    action: ActionMeta<Option<TOptionValue>>
  ) => {
    const { onChange } = props;

    if (action.action === 'clear') {
      setGroups(groups.map(group => ({ ...group, expanded: false })));
    }

    if (action.action === 'select-option') {
      const groupLabel = value && value.group;

      setGroups(
        groups.map(({ expanded, ...rest }) => ({
          ...rest,
          expanded: groupLabel === rest.label,
        }))
      );
    }

    onChange && onChange(value ? value.value : null);
  };

  const handleGroupToggle = (selectedGroup: Group<TOptionValue>) => {
    const nextGroups = groups.map(group =>
      isEqual(group, selectedGroup)
        ? {
            ...group,
            expanded: !group.expanded,
          }
        : group
    );

    setGroups(nextGroups);
  };

  const getSelected = memoize(getSelectedOption<TOptionValue>);

  const { value, renderGroupLabel } = props;

  const { components, ...selectProps } = getReactSelectProps(props);

  const enhancedComponents: Partial<
    SelectComponents<Option<TOptionValue>, false, Group<TOptionValue>>
  > = {
    ...components,
    Group: props => (
      <ExpandableGroup {...props} onToggle={handleGroupToggle}>
        {props.children}
      </ExpandableGroup>
    ),
  };

  return (
    <ReactSelect
      {...selectProps}
      options={groups}
      components={enhancedComponents}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      value={getSelected(groups, value)}
      styles={expandableSelectStyles(selectProps.styles)}
      formatGroupLabel={option => {
        const selfGroup = groups.find(group => isEqual(group, option));
        const enhancedGroup = {
          ...option,
          data: {
            ...option.data,
            expanded: Boolean(selfGroup && selfGroup.expanded),
          },
        };

        return renderGroupLabel ? (
          renderGroupLabel(enhancedGroup)
        ) : (
          <ExpandableGroupLabel group={enhancedGroup} />
        );
      }}
    />
  );
}

export const ExpandableSelectField = createFormikField<any, any, HTMLInputElement, Props<any>>(
  ExpandableSelect
);
