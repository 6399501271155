import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { ConvertibleOfferingRequestDto } from './types/ConvertibleOffering';

/**
 * Download an xlsx of convertible offerings
 */
export const downloadConvertibleOfferings = makePostDownloadRequest<ConvertibleOfferingRequestDto>(
  apiClient,
  () => `/converts/download`
);

export type DownloadConvertibleOfferingsResponse = UnpackPromise<
  ReturnType<typeof downloadConvertibleOfferings>
>;
