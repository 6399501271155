import { Calendar_OfferingFieldsFragment } from '../../calendar-graphql/graphql';

/**
 * Note:
 * This is a BE concern that was exposed because we exposed the UserOfferings collection on GQL. Ideally how the BE
 * saves the fact that the user is following the offering is not a FE concern and should just be a boolean on the offering
 * rather than the userOfferings collection
 **/
export const checkIsFollowingOffering = (offering: Calendar_OfferingFieldsFragment) =>
  !!offering.userOfferings?.length;
