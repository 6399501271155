import React from 'react';
import { CommonProps, components, GroupBase, StylesConfig } from 'react-select';

const shouldShowMenu = <
  TOptionValue,
  IsMulti extends boolean,
  Group extends GroupBase<TOptionValue>
>(
  props: CommonProps<TOptionValue, IsMulti, Group>
) => {
  const { isLoading, inputValue } = props.selectProps;

  return !!(props.options.length || isLoading || (inputValue && !props.options.length));
};

/**
 * Get react-select components prop override.
 */
export const getAsyncSelectComponents = () => ({
  Menu: props => {
    return shouldShowMenu(props) ? <components.Menu {...props} /> : null;
  },
});

/**
 * Get react-select styles prop override.
 *
 * @param config - existing style configuration
 */
export const getAsyncSelectStyles = <
  TOptionValue,
  IsMulti extends boolean,
  Group extends GroupBase<TOptionValue>
>(
  config: StylesConfig<TOptionValue, IsMulti, Group> = {}
): StylesConfig<TOptionValue, IsMulti, Group> => ({
  ...config,
  control: (base, props) =>
    config.control
      ? config.control(base, {
          ...props,
          menuIsOpen: props.menuIsOpen && shouldShowMenu(props),
        })
      : {},
});
