import React, { useState } from 'react';
import styled from 'styled-components/macro';

import IconButton from '../../buttons/button/IconButton';
import Collapse from '../collapse/Collapse';
import { SContentContainer } from './MobileNavigation.styles';
import Navigation from './Navigation';

const StyledMobileNavigation = styled(Navigation)`
  display: flex;
  padding: 0 32px;
  min-height: 64px;
  gap: 0px;

  /** hide the mobile navigation menu on larger screens */
  ${({ theme }) => theme.mediaQuery.mediumUp} {
    display: none;
    gap: 32px;
  }
`;

// displays the logo and menu button
const STopRow = styled.div`
  padding: 0 25px 5px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
// wraps around the entire content of the mobile navigation
const SBottomRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const STopRowColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

// we should this white horizontal line when the mobile navigation menu is expanded
const SHorizontalLine = styled.div`
  width: 100%;
  border-bottom: ${({ theme }) =>
    `${theme.border.width.small} solid ${theme.background.color.white}`};
`;

export type Props = {
  /** e2e test id */
  testId?: string;
  /** optional Navigation.Logo */
  logo?: React.ReactElement<typeof Navigation.Logo>;
  /** navigation items to be rendered within a expand/collapse container typically includes top level navigation + logout navigation item */
  children: React.ReactNode;
};

/**
 * MobileNavigation is a styled Navigation container that displays whenever the view port is < 768px
 * It renders a navigation items as children within an expand/collapse container.
 *
 * @requires react-router-dom to be installed in the consumer application
 */
const MobileNavigation: React.FC<Props> = ({ logo, testId, children }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <StyledMobileNavigation testId={testId}>
      <STopRow>
        <STopRowColumn>{logo}</STopRowColumn>
        <STopRowColumn>
          <IconButton
            onClick={() => setExpanded(!expanded)}
            icon={{
              name: expanded ? 'times' : 'bars',
              color: 'white',
              size: '2x',
              variant: 'light',
            }}
          />
        </STopRowColumn>
      </STopRow>
      {expanded && <SHorizontalLine />}
      <SBottomRow>
        <Collapse isExpanded={expanded}>
          <SContentContainer>{children}</SContentContainer>
        </Collapse>
      </SBottomRow>
    </StyledMobileNavigation>
  );
};

export default MobileNavigation;
