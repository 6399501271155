import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

import { terrainBgImage1600 } from '../../../assets';
import CMGLogo from '../../graphics/cmg-logo/CMGLogo';

export const SPageWrapper = styled.div<{ hideBackground: boolean }>`
  min-height: 100vh;
  ${props =>
    !props.hideBackground &&
    css`
      background-image: url(${terrainBgImage1600});
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
    `}

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, hideBackground }) =>
    hideBackground ? theme.text.color.darkGray : theme.text.color.white};
`;
export const SContent = styled.div`
  flex: 0 1 400px;
  font-weight: ${({ theme }) => theme.text.weight.light};
`;
export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SHeaderText = styled.div<{ hideBackground: boolean }>`
  margin-top: auto;
  text-align: right;
  font-size: ${({ hideBackground }) => (hideBackground ? '32px' : '24px')};
  font-weight: ${({ theme, hideBackground }) =>
    hideBackground ? theme.text.weight.regular : theme.text.weight.light};
  font-family: ${({ theme }) => theme.text.family.slab};
`;
export const SFooter = styled.div`
  margin-top: 35px;
  text-align: center;
  font-size: ${({ theme }) => theme.text.size.small};
`;
export const SLinks = styled.div`
  margin-top: 7px;
  a {
    color: ${({ theme }) => theme.text.color.linkLight};
    padding: 0 7px;
  }
`;

/**
 * Provides uniformity in displaying a route that uses the big blue arrow background
 * renders logo & footer and centers content within.
 */

export type Props = {
  testId?: string;
  title?: ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideBackground?: boolean;
};

const CenteredPage: React.FC<Props> = ({
  children,
  testId,
  title,
  hideHeader,
  hideFooter,
  hideBackground = false,
}) => {
  return (
    <SPageWrapper data-test-id={testId} hideBackground={hideBackground}>
      <SContent>
        {!hideHeader && (
          <SHeader>
            <CMGLogo hideBackground={hideBackground} />
            {title && <SHeaderText hideBackground={hideBackground}>{title}</SHeaderText>}
          </SHeader>
        )}

        {children}

        {!hideFooter && (
          <SFooter>
            Want to learn more?
            <SLinks>
              <a href="mailto:support@cmgx.io">Get in touch</a> &middot;
              <a href="https://cmgx.io">Visit cmgx.io</a>
            </SLinks>
          </SFooter>
        )}
      </SContent>
    </SPageWrapper>
  );
};

export default CenteredPage;
