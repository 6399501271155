import axios from 'axios';

/**
 * The system management client
 **/
export const systemManagementApiClient = axios.create({
  baseURL: '/api/v1',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});
