import React from 'react';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import { Option } from '../select/types';
import { FormControlEvents } from '../types';
import { SOption, SToggleContainer } from './Toggle.styles';

type OwnProps = {
  options: Option[];
  className?: string;
};

export type Props = OwnProps & FieldControlProps<string, string> & FormControlEvents;

/**
 * Switch between set of two different option values
 */
const ToggleComponent: React.FC<Props> = ({
  options,
  onChange,
  value,
  onBlur,
  onFocus,
  disabled,
  className,
}) => {
  const handleOnChange = (nextValue: string) => {
    onChange && onChange(nextValue);
  };

  return (
    <SToggleContainer className={className} onFocus={onFocus} onBlur={onBlur} disabled={disabled}>
      {options.map(option => (
        <SOption
          key={option.value}
          onClick={() => handleOnChange(option.value)}
          isSelected={option.value === value}
        >
          {option.label}
        </SOption>
      ))}
    </SToggleContainer>
  );
};

export default ToggleComponent;
export const ToggleField = createFormikField<string, string, HTMLInputElement, Props>(
  ToggleComponent,
  {
    trigger: 'hover',
  }
);
