import { BrowserStorageProps, useBrowserStorage } from '@cmg/common';

import useAuth from './useAuth';

/**
 * Hook that encapsulates the storage aspect of user preferences.
 * This hook can be used to store any json value for use across application sessions.
 *
 * Example preferences - grid sorting, filters set on a grid, color theme
 *
 * @param key Unique key of preference
 * @param initialValue Initial value of the preference
 * @param storageType Type of storage to use. Default is local storage.
 * @returns The JS value for the preference. Null if it does not exist.
 */
const useStoredPreference = <T>({ key, initialValue, storageType }: BrowserStorageProps<T>) => {
  const { oidcUserId } = useAuth();
  const [preference, setPreference, isPreferenceSet] = useBrowserStorage<T>({
    key: `${oidcUserId}_preference_${key}`,
    initialValue,
    storageType,
  });

  return [preference, setPreference, isPreferenceSet] as const;
};

export default useStoredPreference;
