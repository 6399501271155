import React from 'react';
import AnimateHeight from 'react-animate-height';

export type Props = {
  isExpanded?: boolean;
  duration?: number;
  children: React.ReactNode;
};

const DEFAULT_DURATION = 250;

const Collapse: React.FC<Props> = ({ isExpanded, duration = DEFAULT_DURATION, children }) => (
  <AnimateHeight duration={duration} height={isExpanded ? 'auto' : 0}>
    {children}
  </AnimateHeight>
);

export default Collapse;
