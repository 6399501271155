import { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';

import { makeEntity } from './entity';

export const makeAxiosResponse = makeEntity<AxiosResponse<{}>>({
  request: {},
  status: 200,
  statusText: '',
  headers: {},
  config: {
    headers: {} as AxiosRequestHeaders,
  },
  data: {},
});

export const makeAxiosError = makeEntity<AxiosError<{}>>({
  toJSON: () => {
    return {};
  },
  response: makeAxiosResponse(),
  config: {
    headers: {} as AxiosRequestHeaders,
  },
  isAxiosError: true,
  message: 'UNKNOWN_ERROR',
  name: 'Unknown Error',
  request: {},
});
