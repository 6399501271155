import React from 'react';
import { ThemeConsumer } from 'styled-components/macro';

import BaseButton, { ButtonProps, StyledConfig } from './Button';

/**
 * Styled variation of a button to use in potentially destructive or hightly impactful action.
 */
const DangerButton: React.FC<ButtonProps> = props => (
  <ThemeConsumer>
    {theme => {
      const styledConfig: StyledConfig = {
        color: theme.interactive.dangerContrast,
        hoverColor: theme.interactive.dangerContrast,
        backgroundColor: theme.interactive.danger,
        hoverBackgroundColor: theme.interactive.dangerComplement,
        borderColor: theme.interactive.danger,
      };
      return <BaseButton {...props} styledConfig={styledConfig} />;
    }}
  </ThemeConsumer>
);

export default DangerButton;
