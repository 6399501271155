import styled from 'styled-components/macro';

import PrimaryButton from '../../buttons/button/PrimaryButton';

export const SPage = styled.div`
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding: 20px;
  min-height: 250px;
  background-color: ${({ theme }) => theme.background.color.white};
  align-items: center;
  text-align: center;
`;

export const STitle = styled.div`
  font-size: ${({ theme }) => theme.text.size.xlarge};
  text-transform: capitalize;
`;

export const SDescription = styled.div`
  margin: 10px 0;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 20px 0;
  width: 350px;
`;
