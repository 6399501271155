/**
 * setupIcons should be imported and called
 * Icons MUST be imported as a named import to minimize impact on our bundle size
 */
import { library } from '@fortawesome/fontawesome-svg-core';

import * as lightIcons from './lightIcons';
/**
 * Regular svg icons package
 */
import * as regularIcons from './regularIcons';
import * as solidIcons from './solidIcons';

const setupIcons = () => {
  const solid = Object.values(solidIcons.SolidIconName).map(icon => solidIcons[icon]);
  const regular = Object.values(regularIcons.RegularIconName).map(icon => regularIcons[icon]);
  const light = Object.values(lightIcons.LightIconName).map(icon => lightIcons[icon]);

  library.add(...solid, ...regular, ...light);
};

export default setupIcons;
