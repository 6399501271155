import React from 'react';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import { Option } from '../select/types';
import { FormControlEvents } from '../types';
import Radio from './Radio';
import { SWrapper } from './RadioGroup.styles';

export type RadioGroupProps = {
  options: Option<string>[];
  innerRef?: React.Ref<HTMLDivElement>;
  variant?: 'horizontal' | 'vertical';
};

export type Props = RadioGroupProps &
  FieldControlProps<string | null, string | null> &
  FormControlEvents;

export const RadioGroupComponent: React.FC<Props> = ({
  options,
  value,
  disabled,
  innerRef,
  onChange,
  hasError,
  variant = 'horizontal',
  required,
  fullWidth,
  ...inputProps
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };
  return (
    <SWrapper ref={innerRef} variant={variant}>
      {options.map(option => (
        <Radio
          key={option.label}
          checked={value === option.value}
          value={option.value}
          disabled={disabled || option.disabled}
          hasError={hasError}
          onChange={handleOnChange}
          {...inputProps}
        >
          {option.label}
        </Radio>
      ))}
    </SWrapper>
  );
};

const RadioGroup = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <RadioGroupComponent innerRef={ref} {...props} />
));
RadioGroup.displayName = 'RadioGroup';
export default RadioGroup;

export const RadioGroupField = createFormikField<
  string | null,
  string | null,
  HTMLInputElement,
  Props
>(RadioGroup, {
  trigger: 'hover',
});
