import React from 'react';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import InputWrapper from '../input-wrapper/InputWrapper';
import { FormControlEvents } from '../types';

export interface Props extends FieldControlProps<string, File | null>, FormControlEvents {
  textAlign?: 'left' | 'right';
  className?: string;
  size?: 'medium' | 'large';
  rounded?: 'top' | 'bottom' | 'left' | 'right' | 'none';
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  innerRef?: React.Ref<HTMLInputElement>;
  autoFocus?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * File upload input allows the user to select a file and handle the selected file.
 */
export const FileUploadInputComponent: React.FC<Props> = ({
  onChange,
  className,
  size = 'medium',
  rounded,
  disabled,
  hasError,
  prefix,
  suffix,
  textAlign,
  innerRef,
  value,
  required,
  fullWidth,
  ...restProps
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const file =
        event.currentTarget.files && event.currentTarget.files.length
          ? event.currentTarget.files[0]
          : null;
      onChange(file);
    }
  };

  return (
    <InputWrapper
      className={className}
      disabled={disabled}
      hasError={hasError}
      prefix={prefix}
      suffix={suffix}
      textAlign={textAlign}
      size={size}
      rounded={rounded}
    >
      <input
        {...restProps}
        type="file"
        ref={innerRef}
        disabled={disabled}
        onChange={handleOnChange}
      />
    </InputWrapper>
  );
};

const FileUploadInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <FileUploadInputComponent innerRef={ref} {...props} />;
});
FileUploadInput.displayName = `FileUploadInput`;

export default FileUploadInput;

export const FileUploadInputField = createFormikField<string, File | null, HTMLInputElement, Props>(
  FileUploadInput,
  {
    trigger: 'hover',
  }
);
