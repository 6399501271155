import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import ToastManager from '../../components/overlays/toast-manager/ToastManager';
import { assertIsError } from '../../util/error';
import { SaveToastError } from '../components/SaveToastError';

export type AutoSaveProps = {
  /**
   * Callback function called when auto save starts
   * @callback saveStarted
   */
  saveStarted?: () => void;
  /**
   * Callback function called once auto save finishes
   * @callback saveFinished
   */
  saveFinished?: () => void;
  /**
   * Callback function called on error
   * @callback saveFinished
   */
  onError?: (error: Error) => void;
  /**
   * Debounce ms for each submit
   */
  debounceMs?: number;
};
/**
 * Hook to be used in a form component and triggers auto save on value changes.
 * This hook can only be called inside a formik context.
 * Its receives saveFinished and saveStarted callbacks that are called as part of the auto save flow.
 * @param {saveStarted} saveStarted Callback function called when auto save starts
 * @param {saveFinished} saveFinished Callback function called once auto save finishes
 * @example
 * const FormComponent = () => {
 *    useAutoSaveForm()
 *    return (
 *       <Form>
 *          ....form field components
 *       </Form>
 *    )
 * };
 */
export const useAutoSaveForm = <T extends any = any>({
  saveStarted,
  saveFinished,
  onError,
  debounceMs = 1000,
}: AutoSaveProps = {}) => {
  const formikContext = useFormikContext<T>();
  const [error, setError] = React.useState<Error | null>();

  const submitForm = useDebouncedCallback(async () => {
    try {
      saveStarted?.();
      await new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            await formikContext.submitForm();
            resolve(null);
          } catch (error) {
            reject(error);
          }
        }, 0);
      });
      setError(null);
      saveFinished?.();
      ToastManager.closeAll();
    } catch (error) {
      ToastManager.closeAll();
      ToastManager.error(
        <SaveToastError
          onClickSave={() => {
            submitForm();
            submitForm.flush();
            ToastManager.closeAll();
          }}
        />,
        { timeout: 'none' }
      );

      assertIsError(error);
      setError(error);
      onError?.(error);
    }
  }, debounceMs);

  React.useEffect(() => {
    if (isEqual(formikContext.initialValues, formikContext.values)) {
      return;
    }
    submitForm();
  }, [formikContext.initialValues, formikContext.values, submitForm]);

  return { submitForm, error, cancelDebounce: submitForm.cancel };
};
