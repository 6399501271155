import React, { CSSProperties } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

import BaseButton, { ButtonProps, StyledConfig } from './Button';

const StyledIconButton = styled(BaseButton)<{ inline?: boolean }>`
  ${({ inline }) =>
    inline &&
    css`
      padding: 0;
      margin: 0;
    `}

  border: none;

  /* Icon buttons have no uniform visible border, so our default box-shadow active style on buttons looks bad.
    Instead, we zoom the icon a bit for the active effect. */
  &:active {
    box-shadow: none;
    transform: scale(1.1, 1.1);
  }
`;

export type IconType = Exclude<ButtonProps['iconLeft'], undefined>;

type OwnProps = {
  icon: IconType;
  inline?: boolean;
  color?: CSSProperties['color'];
  hoverColor?: CSSProperties['color'];
};

export type IconButtonProps = OwnProps &
  Exclude<ButtonProps, 'iconLeft' | 'iconRight' | 'children'>;

/**
 * This button should only be used whenever we need to render an icon with a click event
 * with no border or background.
 */
const IconButton: React.FC<IconButtonProps> = ({
  icon,
  color,
  hoverColor,
  inline = true,
  ...props
}) => {
  const theme = useTheme();

  const styledConfig: StyledConfig = {
    color: color ?? theme.text.color.dark,
    hoverColor: hoverColor ?? theme.interactive.primary,
    backgroundColor: 'transparent',
    disabledBackgroundColor: 'transparent',
    disabledBorderColor: 'transparent',
  };

  return (
    <StyledIconButton {...props} inline={inline} iconLeft={icon} styledConfig={styledConfig} />
  );
};

export default IconButton;
