import { UUID } from '@cmg/api';
import { ToastManager } from '@cmg/common';

import { useShared_FollowOfferingMutation } from './graphql/__generated__/Shared_FollowOffering';

type Params = { refetch?: () => void };

export default function useFollowOfferingMutation({ refetch }: Params = {}) {
  const [followOffering] = useShared_FollowOfferingMutation({
    onCompleted: !!refetch ? refetch : undefined,
  });

  const setFollowingOffering = async (offeringId: UUID, follow: boolean) => {
    try {
      await followOffering({
        variables: {
          input: {
            offeringId,
            isFollowing: follow,
          },
        },
      });
      ToastManager.success(`Offering ${follow ? 'followed' : 'un-followed'}.`);
    } catch (error) {
      ToastManager.error(
        `An error has occurred while trying to ${
          !follow ? 'follow' : 'un-follow'
        } the offering. Please try again later.`
      );
    }
  };

  return {
    setFollowingOffering,
  };
}
