import React from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { ThemeConsumer } from 'styled-components/macro';

import theme from '../../../styles/theme';
import { Theme } from '../../../styles/types';

export type QueryKey = keyof Pick<Theme, 'mediaQuery'>['mediaQuery'];

type MediaQueries = { [key in QueryKey]: React.FC };

type MediaHooks = { [key in QueryKey]: () => boolean };

type MediaProps = {
  queryKey: QueryKey;
};

export const normalizeQuery = (query: string) => query.replace('@media ', '');

export const MediaContainer: React.FC<MediaProps> = ({ queryKey, children }) => (
  <ThemeConsumer>
    {({ mediaQuery }: Theme) => (
      <MediaQuery query={normalizeQuery(mediaQuery[queryKey])}>{children}</MediaQuery>
    )}
  </ThemeConsumer>
);

/** export for testing */
export const MediaQueryComponents: MediaQueries = Object.keys(theme.mediaQuery).reduce(
  (acc, key) => {
    acc[key] = ({ children }) => (
      <MediaContainer queryKey={key as QueryKey}>{children}</MediaContainer>
    );
    acc[key].displayName = `Is${key.charAt(0).toUpperCase() + key.slice(1)}`;

    return acc;
  },
  {} as MediaQueries
);

const MediaQueryHooks: MediaHooks = Object.keys(theme.mediaQuery).reduce((acc, key) => {
  acc[key] = useMediaQuery.bind(null, { query: normalizeQuery(theme.mediaQuery[key]) });

  return acc;
}, {} as MediaHooks);

const mediaQuery = {
  IsMediumUp: MediaQueryComponents.mediumUp,
  useIsMediumUp: MediaQueryHooks.mediumUp,
  IsMediumDown: MediaQueryComponents.mediumDown,
  useIsMediumDown: MediaQueryHooks.mediumDown,
  IsSmallUp: MediaQueryComponents.smallUp,
  useIsSmallUp: MediaQueryHooks.smallUp,
  IsSmallDown: MediaQueryComponents.smallDown,
  useIsSmallDown: MediaQueryHooks.smallDown,
  IsLargeUp: MediaQueryComponents.largeUp,
  useIsLargeUp: MediaQueryHooks.largeUp,
  IsLargeDown: MediaQueryComponents.largeDown,
  useIsLargeDown: MediaQueryHooks.largeDown,
  IsXLargeUp: MediaQueryComponents.xlargeUp,
  useIsXLargeUp: MediaQueryHooks.xlargeUp,
  IsXLargeDown: MediaQueryComponents.xlargeDown,
  useIsXLargeDown: MediaQueryHooks.xlargeDown,
  IsXXLargeUp: MediaQueryComponents.xxlargeUp,
  useIsXXLargeUp: MediaQueryHooks.xxlargeUp,
  IsXXLargeDown: MediaQueryComponents.xxlargeDown,
  useIsXXLargeDown: MediaQueryHooks.xxlargeDown,
};

export default mediaQuery;
