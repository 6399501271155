import { AccountType } from '@capital-markets-gateway/api-client-identity';

import { getUserProfileLogContext } from '../../../common/authService';
import makeEntity from '../entityFactory';

export const makeUserProfileLogContext = makeEntity<ReturnType<typeof getUserProfileLogContext>>({
  userId: 'mock-userId',
  firmId: 'mock-firmId',
  accountType: AccountType.SELL_SIDE,
  userPermissions: ['mock:permission'],
  accessTokenExpiration: 1637334375652,
  systemTime: 1455091936.189,
});
