/**
 * Solid svg icons package
 *
 * Due to how our Icon.tsx IconNameParts variable is constructed, all icons defined in
 * regularIcons and solidIcons must be present in lightIcons.ts.
 */
export { faBan } from '@fortawesome/pro-solid-svg-icons/faBan';
export { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
export { faCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
export { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
export { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
export { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
export { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
export { faChartLine } from '@fortawesome/pro-solid-svg-icons/faChartLine';
export { faChartPie } from '@fortawesome/pro-solid-svg-icons/faChartPie';
export { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
export { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
export { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
export { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
export { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
export { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
export { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
export { faColumns } from '@fortawesome/pro-solid-svg-icons/faColumns';
export { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
export { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
export { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
export { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory';
export { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
export { faListCheck } from '@fortawesome/pro-solid-svg-icons/faListCheck';
export { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
export { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
export { faMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle';
export { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
export { faPollH } from '@fortawesome/pro-solid-svg-icons/faPollH';
export { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
export { faShareAlt } from '@fortawesome/pro-solid-svg-icons/faShareAlt';
export { faSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
export { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
export { faTachometerFast } from '@fortawesome/pro-solid-svg-icons/faTachometerFast';
export { faThumbtack } from '@fortawesome/pro-solid-svg-icons/faThumbtack';
export { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
export { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
export { faUserTie } from '@fortawesome/pro-solid-svg-icons/faUserTie';

export enum SolidIconName {
  'star' = 'faStar',
  'share-alt' = 'faShareAlt',
  'check' = 'faCheck',
  'times' = 'faTimes',
  'times-circle' = 'faTimesCircle',
  'check-circle' = 'faCheckCircle',
  'chevron-right' = 'faChevronRight',
  'chevron-left' = 'faChevronLeft',
  'chevron-up' = 'faChevronUp',
  'chevron-down' = 'faChevronDown',
  'plus-circle' = 'faPlusCircle',
  'minus-circle' = 'faMinusCircle',
  'filter' = 'faFilter',
  'caret-right' = 'faCaretRight',
  'caret-down' = 'faCaretDown',
  'caret-up' = 'faCaretUp',
  'chart-pie' = 'faChartPie',
  'chart-bar' = 'faChartBar',
  'calendar' = 'faCalendar',
  'chart-line' = 'faChartLine',
  'building' = 'faBuilding',
  'sliders-h' = 'faSlidersH',
  'thumbtack' = 'faThumbtack',
  'search' = 'faSearch',
  'circle' = 'faCircle',
  'minus' = 'faMinus',
  'tachometer-fast' = 'faTachometerFast',
  'ban' = 'faBan',
  'info-circle' = 'faInfoCircle',
  'columns' = 'faColumns',
  'exclamation-triangle' = 'faExclamationTriangle',
  'exclamation-circle' = 'faExclamationCircle',
  'user-tie' = 'faUserTie',
  'poll-h' = 'faPollH',
  'history' = 'faHistory',
  'lock-alt' = 'faLockAlt',
  'list-check' = 'faListCheck',
}
