export enum UserJobFunction {
  ANALYST = 'ANALYST',
  PORTFOLIO_MANAGER = 'PORTFOLIO_MANAGER',
  TRADER = 'TRADER',
  SYNDICATE = 'SYNDICATE',
  ADMIN = 'ADMIN',
  LEGAL_COMPLIANCE = 'LEGAL_COMPLIANCE',
  TECHNOLOGY = 'TECHNOLOGY',
  ASSISTANT = 'ASSISTANT',
  SUPPORT = 'SUPPORT',
  ECM = 'ECM',
  SALES = 'SALES',
  SALES_SUPERVISOR = 'SALES_SUPERVISOR',
  SALES_TRADER = 'SALES_TRADER',
  SALES_TRADER_SUPERVISOR = 'SALES_TRADER_SUPERVISOR',
  ADMINISTRATOR = 'ADMINISTRATOR',
  OTHER = 'OTHER',
  SYNDICATE_OPERATIONS = 'SYNDICATE_OPERATIONS',
  SYNDICATE_ACCOUNTING = 'SYNDICATE_ACCOUNTING',
  PRODUCT_CONTROL = 'PRODUCT_CONTROL',
}
