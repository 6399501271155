import React from 'react';

import { createAgEditor } from '../../lists/data-grid/aggrid/createAgEditor';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import NumericInput from '../numeric-input/NumericInput';
import { FormControlEvents } from '../types';

export function realToPercent(
  realValue: number | null | undefined,
  precision: number
): number | undefined {
  if (realValue === null || realValue === undefined) {
    return undefined;
  }

  const percentValue = realValue * 100;
  const scale = 10 ** precision;
  return Math.round(percentValue * scale) / scale;
}

export function percentToReal(percentValue: number | null, precision: number): number | null {
  if (percentValue === null) {
    return null;
  }

  const realValue = percentValue / 100;
  const scale = 10 ** (precision + 2);
  return Math.round(realValue * scale) / scale;
}

export interface Props extends FieldControlProps<number, number | null>, FormControlEvents {
  className?: string;
  placeholder?: string;
  precision?: number;
  innerRef?: React.Ref<HTMLInputElement>;
  displayPrecision?: boolean;
  displayPrecisionOnBlur?: boolean;
  maxLength?: number;
}

const PercentInputComponent: React.FC<Props> = ({
  precision = 2,
  value,
  onChange,
  innerRef,
  required,
  fullWidth,
  ...restProps
}) => {
  const handleOnChange = (percentValue: number | null) => {
    const realValue = percentToReal(percentValue, precision);
    onChange && onChange(realValue);
  };

  const percentValue = realToPercent(value, precision);

  return (
    <NumericInput
      {...restProps}
      precision={precision}
      suffix="%"
      value={percentValue}
      onChange={handleOnChange}
      ref={innerRef}
    />
  );
};

const PercentInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <PercentInputComponent innerRef={ref} {...props} />;
});
PercentInput.displayName = `PercentInput`;

export default PercentInput;

export const PercentInputField = createFormikField<number, number | null, HTMLInputElement, Props>(
  PercentInput
);

const options = {
  // fixes directly entered value in agGrid (without this 1 would display as 100)
  processCharPress: (charPress: string) => (parseInt(charPress, 10) / 100).toString(),
};
export const PercentAgEditor = createAgEditor(PercentInput, options);
