import React from 'react';
import { useTheme } from 'styled-components/macro';

import Icon from '../../graphics/icon/Icon';
import Popover from '../../overlays/popover/Popover';
import { SInfoIconWrapper, SLabel } from './FormLabel.styles';

export type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  /** Information text that is displayed in information popover */
  renderInfo?: () => React.ReactNode;
};

const FormLabel: React.FC<Props> = ({ children, disabled, required, renderInfo }) => {
  const theme = useTheme();

  return (
    <SLabel required={required} disabled={disabled}>
      {children}
      {renderInfo && (
        <Popover trigger="hover" disabled={!renderInfo} content={renderInfo()} variant="DARK">
          <SInfoIconWrapper>
            <Icon
              name="info-circle"
              variant="solid"
              size="sm"
              color={theme.background.color.dark}
            />
          </SInfoIconWrapper>
        </Popover>
      )}
    </SLabel>
  );
};

export default FormLabel;
