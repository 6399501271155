import React from 'react';
import styled, { css } from 'styled-components/macro';

/**
 * BoxButton is note parenting BaseButton because most of props would not make sense and that's more staightforward for now
 */

type BoxButtonProps = {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  testId?: string;
  // add more props if you use them
};

export const SBoxButton = styled.button<{ disabled?: boolean }>`
  height: 40px;
  padding: 9px 13px 5px;
  border: none;
  border-radius: 0;
  border-left: 1px solid ${({ theme }) => theme.border.color.light};
  color: ${({ theme }) => theme.text.color.light};
  background: none;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.border.color.light};
    color: ${({ theme }) => theme.interactive.primary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      &:hover {
        background-color: ${({ theme }) => theme.background.color.white};
        color: ${({ theme }) => theme.text.color.disabled};
        cursor: not-allowed;
      }
    `}
`;

const BoxButton: React.FC<BoxButtonProps> = ({ testId, ...restProps }) => (
  <SBoxButton {...restProps} data-test-id={testId} />
);

export default BoxButton;
