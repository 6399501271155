import { checkPermissions, permissionsByEntity, useAuth } from '@cmg/auth';
import { Box } from '@cmg/common';
import { calendarContainerTestId } from '@cmg/e2e-selectors';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';

import { PrivateRoute } from '../../../common/components/routing/private-route/PrivateRoute';
import { useDecisionShowLiveAndFiled } from '../../../common/hooks/useDecisionShowLiveAndFiled';
import routeFactory from '../../../common/util/routeFactory';
import { DownloadReportProps } from '../../datalab/containers/DatalabContainer';
import { isDownloadExcelOn } from '../../datalab/model/utils';
import ScreenContent from '../../shared/layout/ScreenContent';
import { SWrapper } from '../../shared/layout/ScreenContent.styles';
import ScreenHeader from '../../shared/layout/ScreenHeader';
import Spinner from '../../shared/spinner/Spinner';
import { downloadCalendarActions, selectFilterValues, selectIsCalendarLoading } from '../ducks';
import { CalendarTabType, getCalendarTabs } from '../model/calendar-tabs';
import FiledOfferingsCalendar from '../tabs/FiledOfferingsCalendar';
import LiveOfferingsCalendar from '../tabs/LiveOfferingsCalendar';
import LockupExpirationsOfferingsCalendar from '../tabs/LockupExpirationsOfferingsCalendar';
import MyOfferingsCalendar from '../tabs/MyOfferingsCalendar';
import MyOfferingsWithAllocationsCalendar from '../tabs/MyOfferingsWithAllocationsCalendar';
import PostponedOfferingsCalendar from '../tabs/PostponedOfferingsCalendar';
import PricedOfferingsCalendar from '../tabs/PricedOfferingsCalendar';

const SCalendarScreen = styled.div`
  ${SWrapper} {
    /* ECM-1375: iPad scrolling bug */
    -webkit-overflow-scrolling: touch;
  }
`;

const SContainer = styled.div`
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: calc((100vh - 88px) - 86px);
`;

const mapStateToProps = state => ({
  filters: selectFilterValues(state),
  isLoading: selectIsCalendarLoading(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onExport: downloadCalendarActions.request,
    },
    dispatch
  ),
});

export type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export const CalendarContainerComponent: React.FC<Props> = ({
  actions,
  filters,
  isLoading,
  history,
}) => {
  const { userPermissions, oidcUserId } = useAuth();
  const showLiveAndFiled = useDecisionShowLiveAndFiled();

  const handleOnExport = ({
    includeOfferingNotes = false,
    includeIoiNotes = false,
    includeUnderwriters = false,
    includeSponsors = false,
    includeFundIoi = false,
    includeAdvisors = false,
    screen = undefined,
  }: DownloadReportProps) => {
    actions.onExport({
      includeOfferingNotes,
      includeIoiNotes,
      includeUnderwriters,
      includeSponsors,
      includeFundIoi,
      includeAdvisors,
      screen,
    });
  };

  const handleOnTabChanged = (tab: CalendarTabType) => {
    history.push(tab.routePath);
  };

  const canViewIoi = checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]);
  const tabs = getCalendarTabs({ canViewIoi: canViewIoi, showLiveAndFiled: showLiveAndFiled });

  const tabProps = {
    filters,
    tabs,
    oidcUserId,
    onExport: isDownloadExcelOn() ? handleOnExport : undefined,
    onTabChange: handleOnTabChanged,
  };

  const defaultRoute = showLiveAndFiled
    ? routeFactory.calendarLiveOfferings.routePath
    : routeFactory.calendarPricedOfferings.routePath;

  return (
    <Spinner show={isLoading}>
      <SCalendarScreen data-test-id={calendarContainerTestId.testId}>
        <ScreenHeader />
        <ScreenContent>
          <SContainer>
            <Box>
              {/* Datalab routes */}
              <Switch>
                {showLiveAndFiled && (
                  <Route path={routeFactory.calendarLiveOfferings.routePath}>
                    <LiveOfferingsCalendar {...tabProps} />
                  </Route>
                )}
                <Route path={routeFactory.calendarPricedOfferings.routePath}>
                  <PricedOfferingsCalendar {...tabProps} />
                </Route>
                {showLiveAndFiled && (
                  <Route path={routeFactory.calendarFiledOfferings.routePath}>
                    <FiledOfferingsCalendar {...tabProps} />
                  </Route>
                )}
                <Route path={routeFactory.calendarPostponedOfferings.routePath}>
                  <PostponedOfferingsCalendar {...tabProps} />
                </Route>
                <Route path={routeFactory.calendarLockupExpirationOfferings.routePath}>
                  <LockupExpirationsOfferingsCalendar {...tabProps} />
                </Route>

                {/**
                 * This route is mutually exclusive with MyOfferingsWithAllocationsCalendar
                 * and should be accessible only if user does not heave READ permission
                 */}
                <PrivateRoute
                  path={routeFactory.calendarMyOfferings.routePath}
                  renderNoAccess={() => <Redirect to={routeFactory.forbidden.routePath} />}
                  accessCheck={userPermissions =>
                    !checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ])
                  }
                  render={() => <MyOfferingsCalendar {...tabProps} />}
                />
                <PrivateRoute
                  path={routeFactory.calendarMyOfferingsWithAllocation.routePath}
                  requiredPermissions={[permissionsByEntity.Ioi.READ]}
                  renderNoAccess={() => <Redirect to={routeFactory.forbidden.routePath} />}
                  render={() => <MyOfferingsWithAllocationsCalendar {...tabProps} />}
                />

                <Redirect to={defaultRoute} />
              </Switch>
            </Box>
          </SContainer>
        </ScreenContent>
      </SCalendarScreen>
    </Spinner>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainerComponent);
