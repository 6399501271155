import styled, { css } from 'styled-components/macro';

import Popover from '../../overlays/popover/Popover';
import { SWrapper } from '../date-picker/DatePicker.styles';

export const StyledPopover = styled(Popover)`
  /* important, because otherwise it's prioritize style from Popover for some reason */
  z-index: ${({ theme }) => theme.zIndex.popover} !important;

  .ant-popover-inner {
    border: 1px solid ${({ theme }) => theme.interactive.primary};
    border-top-left-radius: 0;
    box-shadow: none;
    position: relative;
    top: -6px;
  }
`;

export const SRangeWrapper = styled(SWrapper)`
  display: flex;

  .react-datepicker {
    border: none;
    border-radius: 0;
    margin: 10px 0 0;
    min-height: 265px;
  }
  .react-datepicker-popper {
    width: 500px;
  }
  .react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
  }
`;

export const STopGreyLine = styled.div`
  border-top: 1px solid ${({ theme }) => theme.border.color.light};
  width: 248px;
  top: -1px;
  left: 0;
  position: absolute;
`;

export const SButtons = styled.div`
  /* IE requires min-width to be defined in order to render flex-box correctly */
  min-width: 160px;
  width: 160px;
  position: relative;
  left: -16px;
  top: -10px;
  border-right: 1px solid ${({ theme }) => theme.border.color.light};
  flex: 1;
`;

export const SButton = styled.button<{ active?: boolean }>`
  display: block;
  border: none;
  width: 100%;
  padding: 2px 6px 2px 16px;
  line-height: 1.15;
  text-align: left;
  background: ${({ active, theme }) =>
    active ? `${theme.background.color.highlighted} !important` : 'none'};
  color: ${({ theme }) => theme.text.color.dark};

  &:hover {
    background: ${({ theme }) => theme.background.color.light};
  }
`;

export const SInputWrapper = styled.span<{ visiblePopup?: boolean }>`
  div input {
    padding-right: 3px;

    ${({ visiblePopup, theme }) =>
      visiblePopup &&
      css`
        border-color: ${theme.interactive.primary};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `}
  }
`;
