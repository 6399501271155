import React from 'react';
import { ThemeConsumer } from 'styled-components/macro';

import BaseButton, { ButtonProps, StyledConfig } from './Button';

const SuccessButton: React.FC<ButtonProps> = props => (
  <ThemeConsumer>
    {theme => {
      const styledConfig: StyledConfig = {
        color: theme.interactive.successContrast,
        hoverColor: theme.interactive.successContrast,
        backgroundColor: theme.interactive.success,
        hoverBackgroundColor: theme.interactive.successComplement,
        borderColor: theme.interactive.success,
      };
      return <BaseButton {...props} styledConfig={styledConfig} />;
    }}
  </ThemeConsumer>
);

export default SuccessButton;
