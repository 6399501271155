/**
 * Contains all the UI building blocks for our navigation (e.g. building a navbar)
 */
import React, { ReactNode } from 'react';
import { NavLinkProps } from 'react-router-dom';

import {
  SBreadcrumb,
  SNavGroup,
  SNavigationWrapper,
  SNavItem,
  StyledBreadcrumbIcon,
  StyledNavLink,
} from './Navigation.styles';
import NavigationLogo from './NavigationLogo';

export type OwnNavigationProps = {
  /** e2e test id */
  testId?: string;
  children?: ReactNode;
};

/**
 * Link is a styled NavLink from react-router-dom
 */
const Link: React.FC<NavLinkProps> = ({ children, ...rest }) => (
  <StyledNavLink activeClassName="activeLink" {...rest}>
    {children}
  </StyledNavLink>
);

const Breadcrumb: React.FC = ({ children, ...rest }) => (
  <SBreadcrumb {...rest}>
    <StyledBreadcrumbIcon name="chevron-right" />
    {children}
  </SBreadcrumb>
);

/**
 * Navigation is used for our top level app navigation
 * it serves as the main container for navigation groups and items.
 *
 * Navigation.Group will display a list of items in a row
 * Navigation.Item will add spacing around Navigation.Link
 *
 * @requires react-router-dom to be installed in the consumer application
 */
class Navigation extends React.Component<OwnNavigationProps> {
  /**
   * Group is used as a container for Item or Link components
   * it will display its children in a row and align them in the center
   */
  static Group = SNavGroup;
  /**
   * Item is a wrapper around Link that gives it some additional spacing
   */
  static Item = SNavItem;
  static Link = Link;
  static Breadcrumb = Breadcrumb;
  static Logo = NavigationLogo;

  render() {
    const { children, testId, ...rest } = this.props;
    return (
      <SNavigationWrapper data-test-id={testId} {...rest}>
        {children}
      </SNavigationWrapper>
    );
  }
}

export default Navigation;
