import 'antd/lib/grid/style/index.css';

import { Col as AntCol } from 'antd';
import React from 'react';
import styled from 'styled-components/macro';

export type Props = {
  className?: string;
  testId?: string;
  offset?: number;
  flex?: number | string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

const Column: React.FC<Props> = ({ testId, ...restProps }) => (
  <AntCol {...restProps} data-test-id={testId} />
);

export const ColumnComponent = Column;
export default styled(Column)``;
