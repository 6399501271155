/**
 * Standard Pagination structure used in API requests and
 * responses for lists
 */
export type Pagination = {
  activePage: number;
  perPage: number;
  totalPages: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
};

/**
 * Valid order direction values for Paginated
 * lists.
 */
export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

/**
 * Endpoints that return a list of entities are manipulated via this set of query params
 */
export type ListParams = {
  page: number;
  perPage?: number;
  includeTotals?: boolean;
  orderField?: string | null;
  orderDirection?: SortDirection;
};
