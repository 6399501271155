import { AxiosError, AxiosResponse } from 'axios';

import {
  ApiClientFailure,
  ApiClientSuccess,
  GenericResponseDataFailure,
} from '../types/ApiClientResponses';
import { defaultDataFailure } from './constants';

/**
 * Transforms a successful axios response into a typesafe standard structure.
 * Usage: Supply as parameter to `then` of an axios promise.
 */
export function transformSuccessResponse<TSuccessBody>(
  response: AxiosResponse<TSuccessBody>
): ApiClientSuccess<TSuccessBody> {
  return {
    ok: true,
    data: response.data,
  };
}

/**
 * Transforms a failed axios response into a typesafe standard structure.
 * Usage: Supply as parameter to `catch` of an axios promise.
 */
export function transformFailureResponse<TFailureBody = GenericResponseDataFailure>(
  error: AxiosError
): ApiClientFailure<TFailureBody | GenericResponseDataFailure> {
  const data = error.response ? (error.response.data as TFailureBody) : defaultDataFailure;
  return {
    ok: false,
    data,
  };
}

/**
 * Create a failure transformer if you are expecting the endpoint to return a non generic
 * response body for failed responses.
 */
export function makeTransformFailureResponse<TFailureBody>() {
  return (error: AxiosError) => transformFailureResponse<TFailureBody>(error);
}
