import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';

import { ISODateTime } from '../../../types/common';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import FormLabel from '../label/FormLabel';
import TimePicker, { MeridiemTimeTuple } from '../time-picker/TimePicker';
import { TimeZonePickerField } from '../time-zone-picker/TimeZonePicker';
import { FormControlEvents } from '../types';
import { DatePickerField } from './DatePicker';
import { DateTimeValue } from './DateTimePicker.model';
import {
  getDateTimeForNextTimezone,
  getNextDateTimeForDate,
  getNextDateTimeForUtcDateTime,
  getTime,
} from './DateTimeZonePicker.model';
import { SDateTimeZonePicker } from './DateTimeZonePicker.styles';

export interface Props
  extends FieldControlProps<DateTimeValue | null, DateTimeValue>,
    FormControlEvents {
  defaultTimezone?: string;
  children?: (context: Context) => React.ReactElement;
  datePickerLabel?: string;
  timePickerLabel?: string;
  timeZonePickerLabel?: string;
  name: string;
}

type Context = {
  value: Props['value'];
  handleChangeDate: (dateTime: ISODateTime | null) => void;
  handleChangeTime: (time: MeridiemTimeTuple) => void;
  handleChangeTimeZone: (timezone: string | null) => void;
};

const DateTimeZonePicker: React.FC<Props> = ({
  value,
  disabled = false,
  onChange,
  required,
  fullWidth,
  children,
  defaultTimezone = 'America/Chicago',
  datePickerLabel,
  timePickerLabel,
  timeZonePickerLabel,
  name,
}) => {
  const selectedTimezone = value?.timezone ? value?.timezone : defaultTimezone;
  const selectedDate = value?.date ? utcToZonedTime(value.date, selectedTimezone) : null;
  const selectedTime: MeridiemTimeTuple = selectedDate ? getTime(selectedDate) : [null, 'AM'];

  const handleChangeDate = (dateTime: ISODateTime | null) => {
    if (!dateTime) {
      onChange?.({ date: null, timezone: null });
    } else {
      onChange?.(
        getNextDateTimeForDate(dateTime, selectedDate && getTime(selectedDate), selectedTimezone)
      );
    }
  };

  const handleChangeTime = ([time, meridiem]: MeridiemTimeTuple) => {
    if (selectedDate && value?.date && time) {
      onChange?.(getNextDateTimeForUtcDateTime(value.date, [time, meridiem], selectedTimezone));
    }
  };

  const handleChangeTimeZone = (nextTimezone: string | null) => {
    if (value?.date && nextTimezone) {
      onChange?.(getDateTimeForNextTimezone(value.date, selectedTimezone, nextTimezone));
    }
  };

  if (children) {
    return children({ value, handleChangeDate, handleChangeTime, handleChangeTimeZone });
  }

  return (
    <SDateTimeZonePicker fullWidth={fullWidth}>
      <DatePickerField
        name={`${name}.date`}
        disabled={disabled}
        required={required}
        label={datePickerLabel}
        onChange={handleChangeDate}
        timezone={selectedTimezone}
      />

      <div>
        {timePickerLabel && <FormLabel required={required}>{timePickerLabel}</FormLabel>}
        <TimePicker
          value={selectedTime}
          onChange={handleChangeTime}
          disabled={disabled || !value?.date}
        />
      </div>

      <TimeZonePickerField
        name={`${name}.timezone`}
        date={value?.date}
        disabled={disabled || !value?.date}
        required={required}
        label={timeZonePickerLabel}
        onChange={handleChangeTimeZone}
        isClearable={false}
      />
    </SDateTimeZonePicker>
  );
};

export default DateTimeZonePicker;

export const DateTimeZonePickerField = createFormikField<
  DateTimeValue | null,
  DateTimeValue,
  HTMLInputElement,
  Props
>(DateTimeZonePicker);
