import { selectAccessToken } from '../../ducks';
import { useSelector } from '../auth-provider/AuthProvider';

/**
 * useAccessToken custom hook selects oidcUser access token
 * should be used in a functional component
 */
function useAccessToken() {
  return useSelector(selectAccessToken);
}

export default useAccessToken;
