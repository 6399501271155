import React from 'react';
import TimePicker from 'react-time-picker';
import styled from 'styled-components/macro';

import Select from '../select/Select';

export const StyledMeridiemPeriodSelect = styled(Select)`
  margin-top: auto;
  min-width: unset;
  width: 80px;
  flex-shrink: 0;

  div[class*='-control'] {
    border-radius: 0 4px 4px 0;
    height: 34px;
  }

  input {
    height: auto;
    padding: 0;
  }
`;

export const StyledTimePicker = styled(TimePicker)<{
  hasFocus?: boolean;
  hasError?: boolean;
  onBlur?: (event: React.FocusEvent<Element>) => void;
  onFocus?: (event: React.FocusEvent<Element>) => void;
}>`
  width: 100%;
  border-radius: 4px 0 0 4px;
  border: ${({ theme, hasFocus, hasError }) => {
    if (hasFocus) {
      return `${theme.border.width.small} solid ${theme.interactive.primary}`;
    }

    if (hasError) {
      return `${theme.border.width.small} solid ${theme.border.color.error}`;
    }

    return `${theme.border.width.small} solid ${theme.border.color.dark}`;
  }};

  .react-time-picker__clear-button {
    display: none;
  }

  .react-time-picker__wrapper {
    border: none;
    padding: 0 12px;
  }

  .react-time-picker__inputGroup {
    padding: 0;
  }

  input {
    border: none;
    height: 32px;

    &:focus {
      outline: none;
    }
  }
`;

export const SInputWrapper = styled.div`
  display: flex;
  height: 34px;
`;
