import xor from 'lodash/xor';
import React, { useState } from 'react';

import {
  SIconContainer,
  SList,
  SPopoverContent,
  StyledCheckIcon,
  StyledFilterInput,
  StyledInputIcon,
  StyledLinkButton,
} from './DropdownSelect.styles';
import { DropdownSelectItem } from './DropdownSelectItem';

// Exported for testing only
export type Props<TValue> = {
  items: DropdownSelectItem<TValue>[];
  onChange: (values: TValue[]) => void;
  values?: TValue[];
  multiple?: boolean;
  searchable?: boolean;
  setVisible: (isVisible: boolean) => void;
};

const DropdownSelectContent: <TValue = string>(
  p: Props<TValue>
) => React.ReactElement<Props<TValue>> = ({
  items,
  onChange,
  values = [],
  multiple,
  searchable,
  setVisible,
}) => {
  const [filter, setFilter] = useState('');

  const handleFilterChange = (value: string | null) => setFilter(value || '');

  const handleClick = item => {
    if (multiple) {
      onChange(xor(values, [item.value]));
    } else {
      setVisible(false);
      onChange([item.value]);
    }
  };

  const filteredItems =
    filter !== ''
      ? items.filter(item => item.label.toLowerCase().includes(filter.toLowerCase()))
      : items;

  return (
    <SPopoverContent>
      {searchable && (
        <StyledFilterInput
          autoFocus
          value={filter}
          onChange={handleFilterChange}
          placeholder="Filter"
          prefix={<StyledInputIcon name="filter" />}
          suffix={
            <span data-testid="clear" onClick={() => setFilter('')}>
              <StyledInputIcon name="times-circle" />
            </span>
          }
        />
      )}
      <SList>
        {filteredItems.map(item => (
          <StyledLinkButton onClick={() => handleClick(item)} key={`${item.value}`}>
            {values.includes(item.value) && (
              <SIconContainer>
                <StyledCheckIcon name="check" />
              </SIconContainer>
            )}
            <span>{item.label}</span>
          </StyledLinkButton>
        ))}
      </SList>
    </SPopoverContent>
  );
};

export default DropdownSelectContent;
