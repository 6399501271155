import { ErrorApiResponse, GenericServerError, SuccessApiResponse } from '../core';
import { makeEntity } from '../utils';

export const makeGenericServerError = makeEntity<GenericServerError>({
  code: '',
  message: '',
  target: '',
  details: [],
});

export const makeSuccessApiResponse = <T extends any>(payload?: { data: T }) => {
  const factory = makeEntity<SuccessApiResponse<T>>({
    ok: true,
    data: { id: 'Some ID', value: 'Some value' } as unknown as T,
    headers: {},
  });

  return factory(payload);
};

export const makeErrorApiResponse = makeEntity<ErrorApiResponse>({
  ok: false,
  data: { error: '' },
});
