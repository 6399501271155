import React from 'react';
import styled, { css, ThemeConsumer } from 'styled-components/macro';

import BaseButton, { ButtonProps, StyledConfig } from './Button';

const StyledBaseButton = styled(BaseButton)<{ inline?: boolean }>`
  user-select: auto;

  ${({ inline }) =>
    inline &&
    css`
      padding: 0;
      margin: 0;
    `}

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    box-shadow: none;
    text-decoration: underline;
  }
`;

type StyleVariant = 'normal' | 'high-contrast';

type OwnProps = {
  inline?: boolean;
  variant?: StyleVariant;
};

export type LinkButtonProps = ButtonProps & OwnProps;

const LinkButton: React.FC<LinkButtonProps> = ({
  inline = false,
  variant = 'normal',
  ...props
}) => (
  <ThemeConsumer>
    {theme => {
      const variants: Record<StyleVariant, StyledConfig> = {
        'high-contrast': {
          color: theme.text.color.linkLight,
          hoverColor: theme.text.color.linkLight,
          backgroundColor: 'transparent',
          disabledBackgroundColor: 'transparent',
          borderColor: 'transparent',
          disabledBorderColor: 'transparent',
          disabledColor: theme.interactive.highContrast.disabled,
        },
        normal: {
          color: theme.text.color.link,
          hoverColor: theme.text.color.link,
          backgroundColor: 'transparent',
          disabledBackgroundColor: 'transparent',
          borderColor: 'transparent',
          disabledBorderColor: 'transparent',
        },
      };

      return <StyledBaseButton {...props} inline={inline} styledConfig={variants[variant]} />;
    }}
  </ThemeConsumer>
);

export default LinkButton;
