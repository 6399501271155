import styled from 'styled-components/macro';

import Icon from '../../graphics/icon/Icon';

export const SLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: default;

  &:hover {
    background-color: ${({ theme }) => theme.background.color.highlighted};
  }
`;
export const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;
