import React from 'react';

import { PublicAuthState } from '../../types';
import useAuth from '../hooks/useAuth';

/**
 * WithAuth is a HOC component that provides the PublicAuthStateProps to the wrapped component
 * @example
 *  const MyComponent = (props: PublicAuthStateProps) => { // console.log(props.oidcUserId) }
 *  withAuth(MyComponent);
 *
 * @param WrappedComponent
 */

export function withAuth<T>(
  WrappedComponent: React.ComponentType<T & PublicAuthState>
): React.FunctionComponent<T> {
  const WithAuth = (props: T) => {
    const authProps = useAuth();

    return <WrappedComponent {...props} {...authProps} />;
  };

  return WithAuth;
}

export default withAuth;
