import styled, { css } from 'styled-components/macro';

export const SInput = styled.input.attrs({ type: 'radio' })`
  border: 0;
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  visibility: hidden;
`;

export const SIcon = styled.div<{ disabled?: boolean; hasError?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme }) => theme.form.field.radio.baseColor};
  ${({ disabled, hasError, theme }) =>
    css`
      ${!disabled &&
      css`
        ${SContainer}:hover:active & {
          background: ${theme.form.field.radio.mouseDownColor};
        }
      `}

      ${hasError &&
      css`
        background: ${theme.form.control.errorColor};
      `}

      ${disabled &&
      css`
        background: ${theme.interactive.disabledContrast};
      `}
    `}
`;

export const SContainer = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 8px;
`;

export const SRadio = styled.div<{ checked?: boolean; disabled?: boolean; hasError?: boolean }>`
  ${({ disabled, checked, hasError, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: ${theme.interactive.primaryContrast};
    border-radius: 50%;
    border: 1px solid
      ${() => {
        if (hasError) {
          return theme.form.control.errorColor;
        }

        if (disabled) {
          return theme.interactive.disabledContrast;
        }

        if (checked) {
          return theme.form.field.radio.baseColor;
        }

        return theme.form.control.placeholderColor;
      }};

    ${!disabled &&
    !hasError &&
    css`
      ${SContainer}:hover & {
        border: ${theme.border.width.small} solid ${theme.form.field.radio.hoverColor};
        background: ${theme.form.field.radio.backgroundColor};
      }
      ${SContainer}:hover:active & {
        border: ${theme.border.width.small} solid
          ${checked ? theme.form.field.radio.mouseDownColor : theme.form.field.radio.baseColor};
      }
    `}

    ${SIcon} {
      visibility: ${checked ? 'visible' : 'hidden'};
    }
  `}
`;

export const SContent = styled.div`
  flex: 1;
  padding-left: 8px;
`;
