import React from 'react';

import { createAgEditor } from '../../lists/data-grid/aggrid/createAgEditor';
import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import InputWrapper from '../input-wrapper/InputWrapper';
import { InputWrapperVariant } from '../input-wrapper/InputWrapper.styles';
import { FormControlEvents } from '../types';

export interface Props
  extends FieldControlProps<string | null, string>,
    FormControlEvents,
    Pick<React.AriaAttributes, 'aria-label'> {
  type?: 'text' | 'password';
  textAlign?: 'left' | 'right';
  className?: string;
  size?: 'medium' | 'large';
  rounded?: 'top' | 'bottom' | 'left' | 'right' | 'none';
  placeholder?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  innerRef?: React.Ref<HTMLInputElement>;
  variant?: InputWrapperVariant;
  autoFocus?: boolean;
  autoComplete?: string; // to prevent autofilling: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#Preventing_autofilling_with_autocompletenew-password
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  testId?: string;
  maxLength?: number | undefined;
}

function fixControlledValue(value?: string | null) {
  if (value === null) {
    return '';
  }
  return value;
}

export const TextInputComponent: React.FC<Props> = ({
  onChange,
  className,
  size = 'medium',
  rounded,
  disabled,
  hasError,
  prefix,
  suffix,
  textAlign,
  type = 'text',
  innerRef,
  variant,
  testId,
  value,
  required,
  fullWidth,
  ...restProps
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <InputWrapper
      className={className}
      disabled={disabled}
      hasError={hasError}
      prefix={prefix}
      suffix={suffix}
      textAlign={textAlign}
      size={size}
      rounded={rounded}
      variant={variant}
    >
      {/* TODO: go through all usages of TextInputField and replace `data-test-id` with `testId` */}
      <input
        data-test-id={testId}
        {...restProps}
        value={fixControlledValue(value)}
        type={type}
        ref={innerRef}
        disabled={disabled}
        onChange={handleOnChange}
      />
    </InputWrapper>
  );
};

const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <TextInputComponent innerRef={ref} {...props} />;
});
TextInput.displayName = `TextInput`;

export default TextInput;

export const TextInputField = createFormikField<string | null, string, HTMLInputElement, Props>(
  TextInput
);

export const TextAgEditor = createAgEditor(TextInput);
