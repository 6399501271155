import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { ColDef } from '@cmg/common';
import { useMemo } from 'react';

import { getFeatureToggles } from '../../../config/appSettings';
import {
  AtmAttributesFilterInput,
  CustomSectorIssuerFilterInput,
  IssuerFilterInput,
  OfferingFilterInput,
  OfferingStatus,
  OfferingType,
  Sector,
  SortEnumType,
  SubSector,
} from '../../../graphql/__generated__/index';
import { CategorizedColumn, NestedSortInput } from '../../../graphql/types';
import { columnCategoryLabel, DatalabTableColumnCategory } from '../../datalab/constants';
import { OfferingsFilterFormType } from '../components/offerings-filter-form/OfferingsFilterForm.model';
import { dataGridColumns } from '../components/offerings-report-table/OfferingsReportTable.model';
import { Offering } from '../components/offerings-report-table/types';

const ONE_MILLION = 1000000;
export const alwaysVisibleColumns = ['issuer_name'];

// OfferingsReportTable.model has the generic model for all screen,
// ATMOfferingsScreen.model has specific model for ATM screen
export const useGetOrderedATMColumns = (): ColDef<Offering, any>[] => {
  const { isAtmSelldownOn } = getFeatureToggles();
  const canReadSellDown = useCheckPermissions([permissionsByEntity.ATMSelldown.READ]);

  return useMemo(
    () => [
      ...(isAtmSelldownOn
        ? [dataGridColumns.announcementDate, dataGridColumns.announcementTime]
        : [dataGridColumns.publicFilingDate, dataGridColumns.marketTimingDisplayName]),
      // dataGridColumns.effectiveDate, POST MPV
      // Effective Time // Post MVP
      dataGridColumns.attributes_terminatedDate,
      dataGridColumns.issuer_name,
      dataGridColumns.issuer_primarySymbol,
      dataGridColumns.issuer_cik,
      dataGridColumns.issuer_sectorDisplayName,
      dataGridColumns.securityTypeDisplayName,
      dataGridColumns.statusDisplayName,
      ...(isAtmSelldownOn
        ? [
            dataGridColumns.atmAttributes_latestProgramSize,
            dataGridColumns.atmAttributes_latestProgramSizeInSecurities,
            dataGridColumns.atmAttributes_announcedProgramSize,
            dataGridColumns.atmAttributes_announcedProgramSizeInSecurities,
          ]
        : [
            dataGridColumns.attributes_latestSizeInSecuritiesTotal,
            dataGridColumns.attributes_latestGrossProceedsTotal,
          ]),
      ...(isAtmSelldownOn && canReadSellDown
        ? [
            dataGridColumns.atmAttributes_totalProgramRemaining,
            dataGridColumns.atmAttributes_totalProgramRemainingInSecurities,
          ]
        : []),
      dataGridColumns.attributes_marketCapPreOffering,
      dataGridColumns.attributes_pctMarketCapPreOffering,
      dataGridColumns.attributes_preOfferingAdtv,
      dataGridColumns.atmAttributes_pctGrossSpread,
      dataGridColumns.attributes_lastTradeBeforeFilingUsd,
      dataGridColumns.atmAttributes_lastTradeBeforeFilingSplitAdjusted,
      dataGridColumns.leftLead,
      dataGridColumns.sellingGroupMembers,
      dataGridColumns.issuerCounsel,
      dataGridColumns.underwriterCounsel,
      dataGridColumns.issuerAuditor,
      dataGridColumns.hasForwardAgreement,
      dataGridColumns.attributes_pctOfferToOpen,
      dataGridColumns.attributes_pctOfferTo1Day,
      dataGridColumns.attributes_pctOfferTo3Day,
      dataGridColumns.attributes_pctOfferTo7Day,
      dataGridColumns.attributes_pctOfferTo14Day,
      dataGridColumns.attributes_pctOfferTo30Day,
      dataGridColumns.attributes_pctOfferTo90Day,
      dataGridColumns.attributes_pctOfferTo180Day,
      dataGridColumns.attributes_pctOfferToCurrent,
    ],
    [canReadSellDown, isAtmSelldownOn]
  );
};

export const useGetCategorizedATMColumns = (): CategorizedColumn => {
  const { isAtmSelldownOn } = getFeatureToggles();
  const canReadSellDown = useCheckPermissions([permissionsByEntity.ATMSelldown.READ]);

  return useMemo(
    () => ({
      [columnCategoryLabel[DatalabTableColumnCategory.ISSUER]]: [
        dataGridColumns.issuer_name,
        dataGridColumns.issuer_primarySymbol,
        dataGridColumns.issuer_cik,
        dataGridColumns.issuer_sectorDisplayName,
      ],
      [columnCategoryLabel[DatalabTableColumnCategory.OFFERING_TERMS]]: [
        dataGridColumns.securityTypeDisplayName,
        ...(!isAtmSelldownOn
          ? [
              dataGridColumns.attributes_latestGrossProceedsTotal,
              dataGridColumns.attributes_latestSizeInSecuritiesTotal,
            ]
          : []),
        dataGridColumns.statusDisplayName,
        dataGridColumns.attributes_marketCapPreOffering,
        dataGridColumns.attributes_pctMarketCapPreOffering,
        dataGridColumns.attributes_preOfferingAdtv,
        dataGridColumns.hasForwardAgreement,
        ...(isAtmSelldownOn
          ? [
              dataGridColumns.atmAttributes_latestProgramSize,
              dataGridColumns.atmAttributes_latestProgramSizeInSecurities,
              dataGridColumns.atmAttributes_announcedProgramSize,
              dataGridColumns.atmAttributes_announcedProgramSizeInSecurities,
            ]
          : []),
        ...(isAtmSelldownOn && canReadSellDown
          ? [
              dataGridColumns.atmAttributes_totalProgramRemaining,
              dataGridColumns.atmAttributes_totalProgramRemainingInSecurities,
            ]
          : []),
      ],
      [columnCategoryLabel[DatalabTableColumnCategory.TIMING]]: [
        ...(isAtmSelldownOn
          ? [dataGridColumns.announcementDate, dataGridColumns.announcementTime]
          : [dataGridColumns.publicFilingDate, dataGridColumns.marketTimingDisplayName]),
        dataGridColumns.attributes_terminatedDate,
        // dataGridColumns.effectiveDate, POST MVP
        // effectiveTime
      ],
      [columnCategoryLabel[DatalabTableColumnCategory.PRICING_DISCOUNT]]: [
        dataGridColumns.attributes_lastTradeBeforeFilingUsd,
        dataGridColumns.atmAttributes_lastTradeBeforeFilingSplitAdjusted,
      ],
      [columnCategoryLabel[DatalabTableColumnCategory.UNDERWRITING]]: [
        dataGridColumns.leftLead,
        dataGridColumns.sellingGroupMembers,
        dataGridColumns.atmAttributes_pctGrossSpread,
      ],
      [columnCategoryLabel[DatalabTableColumnCategory.ADVISORS]]: [
        dataGridColumns.issuerCounsel,
        dataGridColumns.underwriterCounsel,
        dataGridColumns.issuerAuditor,
      ],
      [columnCategoryLabel[DatalabTableColumnCategory.PERFORMANCE]]: [
        dataGridColumns.attributes_pctOfferToOpen,
        dataGridColumns.attributes_pctOfferTo1Day,
        dataGridColumns.attributes_pctOfferTo3Day,
        dataGridColumns.attributes_pctOfferTo7Day,
        dataGridColumns.attributes_pctOfferTo14Day,
        dataGridColumns.attributes_pctOfferTo30Day,
        dataGridColumns.attributes_pctOfferTo90Day,
        dataGridColumns.attributes_pctOfferTo180Day,
        dataGridColumns.attributes_pctOfferToCurrent,
      ],
    }),
    [canReadSellDown, isAtmSelldownOn]
  );
};

export const defaultSortModel: NestedSortInput = {
  // atm: {
  //   effectiveDate: SortEnumType.Desc,
  // }, Post MVP
  publicFilingDate: SortEnumType.Desc,
};

export const mapFormTypeToATMFilterInput = (
  fields: OfferingsFilterFormType
): OfferingFilterInput => {
  const {
    date,
    marketCap,
    grossProceedsBase,
    latestProgramSize,
    sellingShareholderPct,
    leftleads,
    sectors: sectorsField,
    customSectors: customSectorsField,
    countries,
    underwriters,
    shareholders,
    advisers,
  } = fields;

  const marketCapAtPricingUsd =
    marketCap?.min || marketCap?.max
      ? {
          gte: marketCap?.min ? marketCap?.min * ONE_MILLION : undefined,
          lte: marketCap?.max ? marketCap?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestGrossProceedsTotal =
    grossProceedsBase?.min || grossProceedsBase?.max
      ? {
          gte: grossProceedsBase?.min ? grossProceedsBase?.min * ONE_MILLION : undefined,
          lte: grossProceedsBase?.max ? grossProceedsBase?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestPctSecondaryShares =
    sellingShareholderPct?.min || sellingShareholderPct?.max
      ? {
          gte: sellingShareholderPct?.min ? sellingShareholderPct?.min : undefined,
          lte: sellingShareholderPct?.max ? sellingShareholderPct?.max : undefined,
        }
      : undefined;
  const leftLeadId = (leftleads ?? []).length > 0 ? { in: leftleads } : undefined;
  const exchangeCountry = (countries ?? []).length > 0 ? { in: countries } : undefined;

  const sectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SECTOR'))
    .map(s => s.split(':')[1] as Sector);
  const sector = sectors.length > 0 ? { sector: { in: sectors } } : undefined;
  const subSectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SUB_SECTOR'))
    .map(s => s.split(':')[1] as SubSector);
  const subSector = subSectors.length > 0 ? { subSector: { in: subSectors } } : undefined;
  const sectorOrSubSector =
    sector || subSector
      ? { or: [sector, subSector].filter(s => !!s) as IssuerFilterInput[] }
      : undefined;

  const customSectors =
    (customSectorsField ?? []).length > 0
      ? {
          or: Object.entries(
            customSectorsField!.reduce((result, value) => {
              const [type, id] = value.split(':');
              const key = type === 'SUB_SECTOR' ? 'customSubsectorId' : 'customSectorId';
              result[key] = result[key] ?? { in: [] };
              result[key]!.in!.push(id); // `!` required because of how CustomSectorIssuerFilterInput is typed
              return result;
            }, {} as CustomSectorIssuerFilterInput)
          ).map(([key, filter]) => ({ [key]: filter })),
        }
      : undefined;

  const atmAttributes: AtmAttributesFilterInput | undefined = {
    latestProgramSize:
      latestProgramSize?.min || latestProgramSize?.max
        ? {
            gte: latestProgramSize?.min ? latestProgramSize?.min * ONE_MILLION : undefined,
            lte: latestProgramSize?.max ? latestProgramSize?.max * ONE_MILLION : undefined,
          }
        : undefined,
  };

  return {
    // we're using publicFilingDate for now, would change to effectiveDate Post MVP
    // atm: {
    //   effectiveDate:
    //     date?.start || date?.end
    //       ? {
    //           gte: date?.start ? date?.start : undefined,
    //           lte: date?.end ? date?.end : undefined,
    //         }
    //       : undefined,
    // },
    publicFilingDate:
      date?.start || date?.end
        ? {
            gte: date?.start ? date?.start : undefined,
            lte: date?.end ? date?.end : undefined,
          }
        : undefined,
    type: { eq: OfferingType.Atm },
    status: { in: [OfferingStatus.Filed, OfferingStatus.Terminated] },
    attributes: {
      ...(marketCapAtPricingUsd ||
      latestGrossProceedsTotal ||
      latestPctSecondaryShares ||
      leftLeadId ||
      exchangeCountry
        ? {
            marketCapAtPricingUsd,
            latestGrossProceedsTotal,
            latestPctSecondaryShares,
            leftLeadId,
            exchangeCountry,
          }
        : undefined),
      isSupersededBy: { eq: null },
    },
    atmAttributes,
    issuer:
      sectorOrSubSector || customSectors
        ? {
            ...sectorOrSubSector,
            customSectors,
          }
        : undefined,
    managers:
      (underwriters ?? []).length > 0
        ? { some: { manager: { id: { in: underwriters } } } }
        : undefined,
    shareholders:
      (shareholders ?? []).length > 0
        ? { some: { shareholderId: { in: shareholders } } }
        : undefined,
    advisers: (advisers ?? []).length > 0 ? { some: { adviserId: { in: advisers } } } : undefined,
  };
};
