import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import CMGLogo from '../../graphics/cmg-logo/CMGLogo';

const SLogoSize = styled.div`
  max-width: 80%;
  height: auto;
`;

const StyledLogoLink = styled(Link)`
  flex-direction: column;
  display: flex;
  margin: 5px 0;

  &:hover {
    text-decoration: none;
  }
`;

const SApiDocs = styled.span`
  color: ${({ theme }) => theme.text.color.white};
  font-weight: ${({ theme }) => theme.text.weight.light};
`;

type Props = {
  /** Application name */
  name?: string;
  /** Url path  */
  to: string;
};

const NavigationLogo: React.FC<Props> = ({ name, to }) => (
  <StyledLogoLink to={to}>
    <SLogoSize>
      <CMGLogo />
    </SLogoSize>
    {name && <SApiDocs>{name}</SApiDocs>}
  </StyledLogoLink>
);

export default NavigationLogo;
