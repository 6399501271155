import { format, parseISO, startOfDay } from 'date-fns';

import { ISODate } from '../../..';

export function parseDate(iso: ISODate | undefined | null): Date | null {
  if (!iso) {
    return null;
  }

  const isoDate = parseISO(iso);
  return startOfDay(isoDate);
}

export function formatDate(date: Date | null): string | null {
  if (!date) {
    return null;
  }

  return format(date, 'yyyy-MM-dd');
}
