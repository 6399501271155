import { createFormikField } from '../../formik/createFormikField';
import PercentInput from '../../numeric-percent-input/PercentInput';
import { createRangeInput, Props, RangeInputValue } from '../create-range-input/createRangeInput';

const PercentRangeInput = createRangeInput(PercentInput);
export default PercentRangeInput;

export const PercentRangeInputField = createFormikField<
  RangeInputValue,
  RangeInputValue,
  HTMLInputElement,
  Props
>(PercentRangeInput);
