import styled, { css } from 'styled-components/macro';

export type InputWrapperVariant = 'filter';

type Props = {
  variant?: InputWrapperVariant;
  textAlign?: string;
  hasPrefix?: boolean;
  hasSuffix?: boolean;
  size?: string;
  disabled?: boolean;
  hasError?: boolean;
  rounded?: string;
  width?: number;
};

type PrefixProps = {
  readonly size?: 'medium' | 'large';
};

export const SInputWrapper = styled.div<Props>`
  display: block;
  position: relative;
  font-size: 14px;

  input {
    height: 34px;
  }

  input,
  textarea {
    box-shadow: none;
    -webkit-appearance: none;
    padding: 6px 12px;
    background-color: ${({ theme }) => theme.background.color.white};
    background-image: none;
    border: 1px solid ${({ theme }) => theme.form.control.borderColor};
    font-size: 14px;
    border-radius: ${({ theme }) => theme.form.control.borderRadius};
    width: ${({ width }) => width || '100%'};

    &:focus {
      border-color: ${({ theme }) => theme.interactive.primary};
    }

    /* Form inputs uses same style for focus and focus-visible state in order to maintain consistent look */
    &.focus-visible {
      outline: none;
    }

    &::placeholder {
      /* Internet Explorer 10+ */
      color: ${({ theme }) => theme.text.color.placeholder};
    }

    ${({ textAlign }) =>
      textAlign === 'right' &&
      css`
        text-align: right;
      `}

    ${({ hasPrefix, size }) =>
      hasPrefix &&
      css`
        padding-left: ${size === 'large' ? '40px' : '30px'};
      `}

    ${({ hasSuffix, size }) =>
      hasSuffix &&
      css`
        padding-right: ${size === 'large' ? '40px' : '30px'};
      `}

    ${({ size }) =>
      size === 'large' &&
      css`
        height: 60px;
        font-size: 18px;
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${({ theme }) => theme.background.color.light};
        color: ${({ theme }) => theme.text.color.light};
      `}

    ${({ hasError }) =>
      hasError &&
      css`
        &,
        &:focus {
          border-color: ${({ theme }) => theme.brand.color.error} !important;
        }
      `}

    ${({ rounded, theme }) =>
      rounded === 'top' &&
      css`
        border-radius: ${theme.form.control.borderRadius} ${theme.form.control.borderRadius} 0 0;
      `}

    ${({ rounded, theme }) =>
      rounded === 'bottom' &&
      css`
        border-radius: 0 0 ${theme.form.control.borderRadius} ${theme.form.control.borderRadius};
      `}

    ${({ rounded, theme }) =>
      rounded === 'left' &&
      css`
        border-radius: ${theme.form.control.borderRadius} 0 0 ${theme.form.control.borderRadius};
      `}

    ${({ rounded, theme }) =>
      rounded === 'right' &&
      css`
        border-radius: 0 ${theme.form.control.borderRadius} ${theme.form.control.borderRadius} 0;
      `}

    ${({ rounded }) =>
      rounded === 'none' &&
      css`
        border-radius: 0;
      `}

    /* variant='filter' is for Sidebar.tsx and PortalDropdown.js at the moment */
    ${({ variant, theme }) =>
      variant === 'filter' &&
      css`
        height: 25px;
        padding: 4px 5px 3px 20px;
        background-color: transparent;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid ${theme.border.color.white};
        color: ${theme.color.gray1};
        font-size: 14px;
        font-weight: ${({ theme }) => theme.text.weight.regular};
        margin: 0px;
      `}
  }
`;

export const SPrefix = styled.div<PrefixProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  min-width: ${({ size }) => (size === 'large' ? '48px' : '18px')};
  font-size: ${({ size, theme }) =>
    size === 'large' ? theme.text.size.mediumLarge : theme.text.size.medium};
  color: ${({ theme }) => theme.color.gray4};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SSuffix = styled.div<PrefixProps>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  min-width: ${({ size }) => (size === 'large' ? '48px' : '18px')};
  font-size: ${({ size, theme }) =>
    size === 'large' ? theme.text.size.mediumLarge : theme.text.size.medium};
  color: ${({ theme }) => theme.color.gray4};

  display: flex;
  justify-content: center;
  align-items: center;
`;
