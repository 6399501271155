import { createFormikField } from '../../formik/createFormikField';
import NumericInput from '../../numeric-input/NumericInput';
import { createRangeInput, Props, RangeInputValue } from '../create-range-input/createRangeInput';

const NumericRangeInput = createRangeInput(NumericInput);
export default NumericRangeInput;

export const NumericRangeInputField = createFormikField<
  RangeInputValue,
  RangeInputValue,
  HTMLInputElement,
  Props
>(NumericRangeInput);
