import { makeBaseServerEntity } from '../../../core/BaseServerEntity.mock';
import { makeEntity } from '../../../utils';
import { makeRole } from '../../roles/mocks/Role.mock';
import { User } from '../types/User';
import { UserJobFunction } from '../types/UserJobFunction';
import { UserNotificationFormat } from '../types/UserNotificationFormat';
import { UserStatus } from '../types/UserStatus';

export const makeUser = makeEntity<User>({
  email: 'mock-email@cmg.com',
  accountConfirmed: true,
  firstName: 'mock-firstName',
  lastName: 'mock-lastName',
  jobFunction: UserJobFunction.SALES_SUPERVISOR,
  lastIpAddress: '10.0.0.1',
  lastLoginTime: '2018-11-29T00:00:00+00:00',
  createdOn: '2018-06-25T12:30:00+00:00',
  notificationFormat: UserNotificationFormat.EMAIL,
  status: UserStatus.ACTIVE,
  roles: [makeRole()],
  isLockedOut: false,
  lockoutEnd: null,
  ...makeBaseServerEntity(),
});
