import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import React, { useState } from 'react';
import { useTheme } from 'styled-components/macro';

import { UserSettings } from '../../../../../../types/domain/user/userSettings';
import { SectorToggleComponent } from '../../../../../shared/sectors/SectorToggle';
import { OfferingProfile_ListQuery } from '../../../graphql/__generated__/OfferingProfile';
import { StyledHighlightsTextItem } from './SectorHeader.styles';

type Issuer = NonNullable<NonNullable<OfferingProfile_ListQuery['offeringById']>['issuer']>;

export const SectorHeader: React.FC<{ issuer: Issuer; userSettings: UserSettings }> = ({
  issuer,
  userSettings,
}) => {
  const theme = useTheme();
  const hasMySectorsPermission = useCheckPermissions([permissionsByEntity.CustomSectors.READ]);
  const [useCustomSectors, setUseCustomSectors] = useState(
    hasMySectorsPermission ? !!userSettings.useCustomSectors : false
  );
  const handleToggle = (useCustomSectors: boolean) => setUseCustomSectors(useCustomSectors);
  const { customSector, customSubSector } = issuer?.customSectors ?? {};

  return (
    <React.Fragment>
      {hasMySectorsPermission && (
        <SectorToggleComponent
          value={useCustomSectors}
          onChange={handleToggle}
          color={theme.color.blue2}
          theme={theme}
        />
      )}
      {useCustomSectors &&
        (customSubSector?.name ? (
          <StyledHighlightsTextItem
            name="Sector/Sub Sector"
            text={`${customSector?.name ?? ''}${
              customSubSector?.name ? `/${customSubSector?.name}` : ''
            }`}
          />
        ) : (
          <StyledHighlightsTextItem name="Sector" text={customSector?.name ?? ''} />
        ))}
      {!useCustomSectors &&
        (issuer?.subSectorDisplayName ? (
          <StyledHighlightsTextItem
            name="Sector/Sub Sector"
            text={`${issuer?.sectorDisplayName}${
              issuer?.subSectorDisplayName ? `/${issuer?.subSectorDisplayName}` : ''
            }`}
          />
        ) : (
          <StyledHighlightsTextItem name="Sector" text={issuer?.sectorDisplayName ?? ''} />
        ))}
    </React.Fragment>
  );
};
