import React from 'react';

import Popover from '../../overlays/popover/Popover';
import FormLabel from '../label/FormLabel';
import { SError, SFormField } from './FormField.styles';

export type Props = {
  children: React.ReactNode;
  label?: React.ReactNode;
  disablePopover?: boolean;
  /** Content that is displayed in a popover, displayed in a popover if there is no error */
  hint?: React.ReactNode;
  /** Information text that is displayed in information popover */
  renderInfo?: () => React.ReactNode;
  /** Content that gets basic error styling, displayed in a popover */
  error?: React.ReactNode;
  name?: string;
  className?: string;
  /** Adds margin to the right and bottom. To have standard margin when displaying next to another component. */
  withMargin?: boolean;
  /** FormField naturally displays at a set width. When true, takes up parents width. */
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  /** Interaction for displaying the hint and error popover */
  trigger?: 'focus' | 'hover';
};

const FormField: React.FC<Props> = ({
  children,
  label,
  className,
  hint,
  error,
  name,
  withMargin,
  fullWidth,
  disabled,
  required,
  trigger = 'focus',
  renderInfo,
  disablePopover,
}) => {
  const finalHint = error ? <SError>{error}</SError> : hint;

  return (
    <SFormField
      className={className}
      withMargin={withMargin}
      fullWidth={fullWidth}
      data-field-name={name}
    >
      {label && (
        <FormLabel required={required} disabled={disabled} renderInfo={renderInfo}>
          {label}
        </FormLabel>
      )}
      {!disablePopover ? (
        <Popover trigger={trigger} disabled={!finalHint} content={finalHint}>
          {children}
        </Popover>
      ) : (
        children
      )}
    </SFormField>
  );
};

export default FormField;
