import { UnpackPromise } from '../../types/language/UnpackPromise';
import { makePostDownloadRequest } from '../../utils';
import { apiClient } from '../apiClient';
import { IssuersDownloadRequestDto } from './types/Issuer';

/**
 * Download an xlsx of convertible offerings
 */
export const downloadIssuers = makePostDownloadRequest<IssuersDownloadRequestDto>(
  apiClient,
  () => `/issuers/download`
);

export type DownloadIssuersResponse = UnpackPromise<ReturnType<typeof downloadIssuers>>;
