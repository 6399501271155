import React from 'react';

import { AutoSaveProps, useAutoSaveForm } from '../hooks/useAutoSaveForm';

type Props = AutoSaveProps;
/**
 * Wrapper component to enable auto save for the form. It needs to be declared inside a formik context/provider.
 * Its receives saveFinished and saveStarted callbacks that are called as part of the auto save flow.
 * @component
 * @param {saveStarted} saveStarted Callback function called when auto save starts
 * @param {saveFinished} saveFinished Callback function called once auto save finishes
 * @param {onError} onError Callback function called on error thrown by the submit function
 * @param {debounceMs} debounceMs Debounce ms for each submit
 * @example
 * const Component = () => (
 * <FormikProvider>
 *    <AutoSave>
 *        ...form fields
 *    </AutoSave>
 * </FormikProvider>
 * );
 */
export const EnableAutoSave: React.FC<Props> = ({ children, ...rest }) => {
  useAutoSaveForm(rest);
  return <React.Fragment>{children}</React.Fragment>;
};
