import axios, { AxiosError, AxiosResponse } from 'axios';

import { ErrorApiResponse, GenericServerError, SuccessApiResponse } from '../core';

export function normalizeSuccessResponse<TResponseData = any>(
  response: AxiosResponse<TResponseData>
): SuccessApiResponse<TResponseData> {
  return {
    ok: true,
    data: response.data,
    headers: response.headers,
  };
}

/**
 * Convert string error message to standard server error object.
 */
function normalizeError(error: GenericServerError | string): GenericServerError {
  return typeof error === 'string' ? { code: '', details: [], message: error, target: '' } : error;
}

function getInnerError(error: AxiosError<{ error: GenericServerError }>) {
  const hasError = error.response && error.response.data && error.response.data.error;
  // if response is empty, fallback to generic server error with fallback text
  return normalizeError(hasError || 'Error Fallback');
}

export function normalizeErrorResponse(error: unknown): ErrorApiResponse {
  if (!axios.isAxiosError(error)) {
    throw error;
  }

  return {
    ok: false,
    data: {
      error: getInnerError(error as AxiosError<{ error: GenericServerError }>),
    },
  };
}
