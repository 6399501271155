import {
  addDays,
  differenceInDays,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  isValid,
  parse,
  parseISO as fnsParseISO,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';
import escapeRegExp from 'lodash/escapeRegExp';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import memoize from 'memoize-one';

import { isSameDayEqualityFn } from '../../../util/time/utils';
import { DateRangeValue } from './DateRange';

export type Preset = {
  label: string;
  short: string;
  value: {
    type: string;
    start?: string;
    end?: string;
  };
};

export type PresetOptions = { [key: string]: Preset };

export enum PresetTypes {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
  N7D = 'N7D',
  N14D = 'N14D',
  N30D = 'N30D',
  N90D = 'N90D',
  N180D = 'N180D',
  L7D = 'L7D',
  L14D = 'L14D',
  L30D = 'L30D',
  L90D = 'L90D',
  LTM = 'LTM',
  MTD = 'MTD',
  YTD = 'YTD',
  TW = 'TW',
  P2W = 'P2W',
  PM = 'PM',
  PQ = 'PQ',
  PYR = 'PYR', // "R" means Real, because PY without R means PY+LTD (historically)
  PY = 'PY',
  P2Y = 'P2Y',
  P3Y = 'P3Y',
  P4Y = 'P4Y',
  PREV_W = 'PREV_W',
  PREV_2W = 'PREV_2W',
  PREV_M = 'PREV_M',
  PREV_Q = 'PREV_Q',
  PREV_YTD = 'PREV_YTD',
}

const parseUS = (usDate: string): Date => parse(usDate, 'MM/dd/yyyy', new Date());

export const memoizeIsEqual = (newArgs, oldArgs) => isEqual(newArgs, oldArgs);
export const parseISO = (date?: string) => (date !== undefined ? fnsParseISO(date) : undefined);
export const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

// Dates are regenerated multiple times during app and has to reflect current date and time
export const getDefaultPresetOptionsWithDate = (currentDate: Date): PresetOptions => ({
  CUSTOM: { label: 'Custom', short: 'Custom', value: { type: PresetTypes.CUSTOM } },
  DEFAULT: {
    label: 'Default',
    short: 'Default',
    value: {
      type: PresetTypes.DEFAULT,
      start: formatDate(subDays(currentDate, 14)),
      end: formatDate(addDays(currentDate, 14)),
    },
  },
  L7D: {
    label: 'Last 7 Days',
    short: 'L7D',
    value: {
      type: PresetTypes.L7D,
      start: formatDate(subDays(currentDate, 6)),
      end: formatDate(currentDate),
    },
  },
  L14D: {
    label: 'Last 14 Days',
    short: 'L14D',
    value: {
      type: PresetTypes.L14D,
      start: formatDate(subDays(currentDate, 13)),
      end: formatDate(currentDate),
    },
  },
  L30D: {
    label: 'Last 30 Days',
    short: 'L30D',
    value: {
      type: PresetTypes.L30D,
      start: formatDate(subDays(currentDate, 29)),
      end: formatDate(currentDate),
    },
  },
  L90D: {
    label: 'Last 90 Days',
    short: 'L90D',
    value: {
      type: 'L90D',
      start: formatDate(subDays(currentDate, 89)),
      end: formatDate(currentDate),
    },
  },
  LTM: {
    label: 'Last 12 Months',
    short: 'LTM',
    value: {
      type: PresetTypes.LTM,
      start: formatDate(addDays(subYears(currentDate, 1), 1)),
      end: formatDate(currentDate),
    },
  },
  MTD: {
    label: 'Month to Date',
    short: 'MTD',
    value: {
      type: PresetTypes.MTD,
      start: formatDate(startOfMonth(currentDate)),
      end: formatDate(currentDate),
    },
  },
  YTD: {
    label: 'Year to Date',
    short: 'YTD',
    value: {
      type: PresetTypes.YTD,
      start: formatDate(startOfYear(currentDate)),
      end: formatDate(currentDate),
    },
  },
  PM: {
    label: 'Prior Month',
    short: 'PM',
    value: {
      type: PresetTypes.PM,
      start: formatDate(startOfMonth(subMonths(currentDate, 1))),
      end: formatDate(endOfMonth(subMonths(currentDate, 1))),
    },
  },
  PQ: {
    label: 'Prior Quarter',
    short: 'PQ',
    value: {
      type: PresetTypes.PQ,
      start: formatDate(startOfQuarter(subQuarters(currentDate, 1))),
      end: formatDate(endOfQuarter(subQuarters(currentDate, 1))),
    },
  },
  PYR: {
    label: 'Prior Year',
    short: 'PY',
    value: {
      type: PresetTypes.PYR,
      start: formatDate(startOfYear(subYears(currentDate, 1))),
      end: formatDate(endOfYear(subYears(currentDate, 1))),
    },
  },
  PY: {
    label: 'Prior Year + YTD',
    short: 'PY+YTD',
    value: {
      type: PresetTypes.PY,
      start: formatDate(startOfYear(subYears(currentDate, 1))),
      end: formatDate(currentDate),
    },
  },
  P2Y: {
    label: 'Prior 2 Years + YTD',
    short: 'P2Y+YTD',
    value: {
      type: PresetTypes.P2Y,
      start: formatDate(startOfYear(subYears(currentDate, 2))),
      end: formatDate(currentDate),
    },
  },
  P3Y: {
    label: 'Prior 3 Years + YTD',
    short: 'P3Y+YTD',
    value: {
      type: PresetTypes.P3Y,
      start: formatDate(startOfYear(subYears(currentDate, 3))),
      end: formatDate(currentDate),
    },
  },
  P4Y: {
    label: 'Prior 4 Years + YTD',
    short: 'P4Y+YTD',
    value: {
      type: PresetTypes.P4Y,
      start: formatDate(startOfYear(subYears(currentDate, 4))),
      end: formatDate(currentDate),
    },
  },
});

export const getDefaultPresetOptions: (currentDate: Date) => PresetOptions = memoize(
  getDefaultPresetOptionsWithDate,
  isSameDayEqualityFn
);

// Dates are regenerated multiple times during app and has to reflect current date and time
export const getFuturePresetOptionsWithDate = (currentDate: Date): PresetOptions => ({
  CUSTOM: { label: 'Custom', short: 'Custom', value: { type: PresetTypes.CUSTOM } },
  N7D: {
    label: 'Next 7 Days',
    short: 'N7D',
    value: {
      type: PresetTypes.N7D,
      start: formatDate(currentDate),
      end: formatDate(addDays(currentDate, 6)),
    },
  },
  N14D: {
    label: 'Next 14 Days',
    short: 'N14D',
    value: {
      type: PresetTypes.N14D,
      start: formatDate(currentDate),
      end: formatDate(addDays(currentDate, 13)),
    },
  },
  N30D: {
    label: 'Next 30 Days',
    short: 'N30D',
    value: {
      type: PresetTypes.N30D,
      start: formatDate(currentDate),
      end: formatDate(addDays(currentDate, 29)),
    },
  },
  N90D: {
    label: 'Next 90 Days',
    short: 'N90D',
    value: {
      type: PresetTypes.N90D,
      start: formatDate(currentDate),
      end: formatDate(addDays(currentDate, 89)),
    },
  },
  N180D: {
    label: 'Next 180 Days',
    short: 'N180D',
    value: {
      type: PresetTypes.N180D,
      start: formatDate(currentDate),
      end: formatDate(addDays(currentDate, 179)),
    },
  },
});

export const getFuturePresetOptions: (currentDate: Date) => PresetOptions = memoize(
  getFuturePresetOptionsWithDate,
  isSameDayEqualityFn
);

export const getPresetsArray = (presetOptions = getDefaultPresetOptions(new Date())): Preset[] =>
  Object.keys(presetOptions).map(key => presetOptions[key]);

export const getPreset = (type: string, presets: Preset[] = getPresetsArray()): Preset =>
  presets.find(preset => preset.value.type === type) || getDefaultPresetOptions(new Date()).CUSTOM;

export const getValueFromPreset = (value: DateRangeValue, presets: Preset[]): DateRangeValue => {
  if (value.type && value.type !== PresetTypes.CUSTOM) {
    return { ...value, ...getPreset(value.type, presets).value };
  }

  return value;
};

export const labelFromValue = (
  value: DateRangeValue,
  presets: Preset[],
  omitPrefix: boolean = false
): string => {
  if (isNil(value.type)) {
    return '';
  }
  const preset = getPreset(value.type ? value.type : PresetTypes.CUSTOM, presets);
  let label = !omitPrefix ? preset.short + ': ' : '';

  if (value.start) {
    // @ts-ignore possibly undefined, but it's not possible to turn that error of by any condition for some reason (eg: `parseISO(value.start) && `)
    label = label + format(parseISO(value.start), 'MM/dd/yyyy');
  }
  if (value.end && value.start !== value.end) {
    // @ts-ignore --||--
    label = label + ' - ' + format(parseISO(value.end), 'MM/dd/yyyy');
  }

  return label;
};

// exported only for testing
export const displayValueIsAcceptable = (
  displayValue: string | null,
  presets: Preset[]
): boolean => {
  if (displayValue !== null) {
    if (displayValue === '') {
      return true;
    }

    const presetsCodes = presets.map(preset => escapeRegExp(preset.short));
    const pattern = new RegExp(`^((${presetsCodes.join('|')}):)?[0-9 -/]*$`);

    return displayValue.search(pattern) !== -1;
  }

  return false;
};

export const filterTypedValue = (typedValue: string | null, presets: Preset[]) => {
  if (displayValueIsAcceptable(typedValue, presets)) {
    // if value doesn't contain prefix -> will add CUSTOM
    if (!typedValue || typedValue.search(/^\w+:/i) === -1) {
      return labelFromValue({ type: PresetTypes.CUSTOM }, presets) + typedValue;
    } else {
      return typedValue;
    }
  }
  return false;
};

export const parseDisplayValue = (displayValue: string, rangeLimit?: number) => {
  const parts = displayValue.match(/[\d/]{8,}/g);

  if (parts !== null) {
    const [startDate, endDate] = parts.map(p => parseUS(p));

    if (rangeLimit && Math.abs(differenceInDays(endDate, startDate)) > rangeLimit) {
      return false;
    }

    if (isValid(startDate) && endDate === undefined) {
      return { start: formatDate(startDate), end: formatDate(startDate) };
    }

    if (isValid(startDate) && isValid(endDate) && startDate <= endDate) {
      return { start: formatDate(startDate), end: formatDate(endDate) };
    }
  }

  return false;
};

export const dateRangeUtil = {
  getPreset,
  getPresetsArray,
  getDefaultPresetOptions,
  getFuturePresetOptions,
};
