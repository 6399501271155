import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

import Icon from '../../graphics/icon/Icon';

export const SNavigationWrapper = styled.nav`
  display: none;
  background-color: ${({ theme }) => theme.brand.color.dark};
  flex-wrap: wrap;
  align-items: center;
  padding: 0 32px;
  min-height: 64px;
  gap: 32px;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQuery.mediumUp} {
    display: flex;
  }
`;

export const SNavGroup = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

export const SNavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledNavLink = styled(NavLink)`
  font-size: ${({ theme }) => theme.text.size.mediumLarge};
  color: ${({ theme }) => theme.text.color.white};
  font-weight: ${({ theme }) => theme.text.weight.light};
  text-transform: uppercase;

  &.activeLink {
    font-weight: ${({ theme }) => theme.text.weight.medium};
    color: ${({ theme }) => theme.text.color.link};
  }

  &:hover {
    color: ${({ theme }) => theme.color.blue1};
    text-decoration: none;
  }
`;

export const SBreadcrumb = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBreadcrumbIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.white};
  margin: 0 10px 2px;
`;
