/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalEcmOfferings_Terms_PartFragment = {
  __typename?: 'Term';
  aggregatePrincipalAmount?: number | null;
  aggregatePrincipalInclOverallotmentExercised?: number | null;
  principalAmountOverallotmentAuthorized?: number | null;
  principalAmountPerNote?: number | null;
  couponTalkPercentageLow?: number | null;
  couponTalkPercentageHigh?: number | null;
  premiumTalkLowPercentage?: number | null;
  premiumTalkHighPercentage?: number | null;
  conversionPrice?: number | null;
  conversionRatio?: number | null;
  conversionReferencePrice?: number | null;
  principalAmountOverallotmentExercised?: number | null;
  effectiveConversionPremium?: number | null;
  effectiveConversionPrice?: number | null;
  versusTalkDisplayName?: string | null;
  premiumPercentage?: number | null;
  couponPercentage?: number | null;
  pctOfferPrice?: number | null;
  hasZeroCoupon?: boolean | null;
};

export type GlobalEcmOfferings_ListQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.OfferingSortInput> | Types.OfferingSortInput>;
  where?: Types.InputMaybe<Types.OfferingFilterInput>;
}>;

export type GlobalEcmOfferings_ListQuery = {
  __typename?: 'Query';
  offerings?: {
    __typename?: 'OfferingCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Offering';
      id: string;
      pricingDate?: string | null;
      exchangeRegionDisplayName?: string | null;
      exchangeCountryDisplayName?: string | null;
      exchangeMicDisplayName?: string | null;
      pricingCurrencyDisplayName?: string | null;
      hasForwardAgreement?: boolean | null;
      maturityDate?: string | null;
      type: Types.OfferingType;
      typeDisplayName?: string | null;
      securityType?: Types.SecurityType | null;
      securityTypeDisplayName?: string | null;
      isRule144A?: boolean | null;
      price?: number | null;
      grossSpreadTotal?: number | null;
      settlementDate?: string | null;
      useOfProceeds?: Array<Types.UseOfProceeds> | null;
      useOfProceedsDisplayNames?: Array<string | null> | null;
      symbol?: string | null;
      pricingCurrency?: Types.Currency | null;
      initialTermId?: string | null;
      latestRevisedTermId?: string | null;
      finalTermId?: string | null;
      issuer?: {
        __typename?: 'Issuer';
        cik?: string | null;
        name?: string | null;
        sector?: Types.Sector | null;
        sectorDisplayName?: string | null;
      } | null;
      managers: Array<{
        __typename?: 'OfferingManager';
        pctUnderwritingBaseShares?: number | null;
        underwritingBaseShares?: number | null;
        name?: string | null;
        role?: Types.OfferingManagerRole | null;
        roleDisplayName?: string | null;
        sequence?: number | null;
      }>;
      advisers?: Array<{
        __typename?: 'OfferingAdviser';
        role?: Types.AdviserRole | null;
        roleDisplayName?: string | null;
        name?: string | null;
      }> | null;
      attributes?: {
        __typename?: 'OfferingAttributes';
        priceUsd?: number | null;
        latestGrossProceedsBaseUsd?: number | null;
        latestGrossProceedsTotalUsd?: number | null;
        pctGrossSpread?: number | null;
        overAllotmentExercised?: number | null;
        grossSpreadTotalUsd?: number | null;
        leftLeadName?: string | null;
        leftLeadId?: string | null;
      } | null;
      initialTerm?: {
        __typename?: 'Term';
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      } | null;
      latestRevisedTerm?: {
        __typename?: 'Term';
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      } | null;
      finalTerm?: {
        __typename?: 'Term';
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      } | null;
      terms: Array<{
        __typename?: 'Term';
        id?: string | null;
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      }>;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      offset: number;
      pageSize: number;
    };
    summaries?: {
      __typename?: 'OfferingListSummary';
      aggregations?: {
        __typename?: 'OfferingAggregations';
        callPrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        conversionPrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        conversionRatio?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        conversionReferencePrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferPrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        priceUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        couponPercentage?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        premiumPercentage?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        aggregatePrincipalAmount?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        aggregatePrincipalInclOverallotmentExercised?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        principalAmountPerNote?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        tenor?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctGrossSpread?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GlobalEcmOfferings_Terms_PartFragmentDoc = gql`
  fragment GlobalEcmOfferings_Terms_Part on Term {
    aggregatePrincipalAmount
    aggregatePrincipalInclOverallotmentExercised
    principalAmountOverallotmentAuthorized
    principalAmountPerNote
    couponTalkPercentageLow
    couponTalkPercentageHigh
    premiumTalkLowPercentage
    premiumTalkHighPercentage
    conversionPrice
    conversionRatio
    conversionReferencePrice
    principalAmountOverallotmentExercised
    effectiveConversionPremium
    effectiveConversionPrice
    versusTalkDisplayName
    premiumPercentage
    couponPercentage
    pctOfferPrice
    hasZeroCoupon
  }
`;
export const GlobalEcmOfferings_ListDocument = gql`
  query GlobalEcmOfferings_List(
    $skip: Int
    $take: Int
    $order: [OfferingSortInput!]
    $where: OfferingFilterInput
  ) {
    offerings(skip: $skip, take: $take, order: $order, where: $where) {
      items {
        id
        pricingDate
        exchangeRegionDisplayName
        exchangeCountryDisplayName
        exchangeMicDisplayName
        pricingCurrencyDisplayName
        hasForwardAgreement
        maturityDate
        issuer {
          cik
          name
          sector
          sectorDisplayName
        }
        type
        typeDisplayName
        securityType
        securityTypeDisplayName
        isRule144A
        price
        grossSpreadTotal
        settlementDate
        useOfProceeds
        useOfProceedsDisplayNames
        symbol
        pricingCurrency
        managers {
          pctUnderwritingBaseShares
          underwritingBaseShares
          name
          role
          roleDisplayName
          sequence
        }
        advisers {
          role
          roleDisplayName
          name
        }
        attributes {
          priceUsd
          latestGrossProceedsBaseUsd
          latestGrossProceedsTotalUsd
          pctGrossSpread
          overAllotmentExercised
          grossSpreadTotalUsd
          leftLeadName
          leftLeadId
        }
        initialTermId
        latestRevisedTermId
        finalTermId
        initialTerm {
          ...GlobalEcmOfferings_Terms_Part
        }
        latestRevisedTerm {
          ...GlobalEcmOfferings_Terms_Part
        }
        finalTerm {
          ...GlobalEcmOfferings_Terms_Part
        }
        terms {
          id
          ...GlobalEcmOfferings_Terms_Part
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        offset
        pageSize
      }
      summaries {
        aggregations {
          callPrice {
            min
            max
            mean
          }
          conversionPrice {
            min
            max
            mean
          }
          conversionRatio {
            min
            max
            mean
          }
          conversionReferencePrice {
            min
            max
            mean
          }
          pctOfferPrice {
            min
            max
            mean
          }
          priceUsd {
            min
            max
            mean
          }
          couponPercentage {
            min
            max
            mean
          }
          premiumPercentage {
            min
            max
            mean
          }
          aggregatePrincipalAmount {
            min
            max
            mean
          }
          aggregatePrincipalInclOverallotmentExercised {
            min
            max
            mean
          }
          principalAmountPerNote {
            min
            max
            mean
          }
          tenor {
            min
            max
            mean
          }
          pctGrossSpread {
            min
            max
            mean
          }
        }
      }
    }
  }
  ${GlobalEcmOfferings_Terms_PartFragmentDoc}
`;

/**
 * __useGlobalEcmOfferings_ListQuery__
 *
 * To run a query within a React component, call `useGlobalEcmOfferings_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalEcmOfferings_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalEcmOfferings_ListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGlobalEcmOfferings_ListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GlobalEcmOfferings_ListQuery,
    GlobalEcmOfferings_ListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalEcmOfferings_ListQuery, GlobalEcmOfferings_ListQueryVariables>(
    GlobalEcmOfferings_ListDocument,
    options
  );
}
export function useGlobalEcmOfferings_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalEcmOfferings_ListQuery,
    GlobalEcmOfferings_ListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalEcmOfferings_ListQuery, GlobalEcmOfferings_ListQueryVariables>(
    GlobalEcmOfferings_ListDocument,
    options
  );
}
export function useGlobalEcmOfferings_ListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GlobalEcmOfferings_ListQuery,
        GlobalEcmOfferings_ListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GlobalEcmOfferings_ListQuery,
    GlobalEcmOfferings_ListQueryVariables
  >(GlobalEcmOfferings_ListDocument, options);
}
export type GlobalEcmOfferings_ListQueryHookResult = ReturnType<
  typeof useGlobalEcmOfferings_ListQuery
>;
export type GlobalEcmOfferings_ListLazyQueryHookResult = ReturnType<
  typeof useGlobalEcmOfferings_ListLazyQuery
>;
export type GlobalEcmOfferings_ListSuspenseQueryHookResult = ReturnType<
  typeof useGlobalEcmOfferings_ListSuspenseQuery
>;
export type GlobalEcmOfferings_ListQueryResult = Apollo.QueryResult<
  GlobalEcmOfferings_ListQuery,
  GlobalEcmOfferings_ListQueryVariables
>;
