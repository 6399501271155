import React from 'react';
import styled, { css } from 'styled-components/macro';

import Icon, { IconNameParts } from '../../graphics/icon/Icon';

type BannerTypes = 'error' | 'warning' | 'success' | 'info';

export type Props = {
  variant: BannerTypes;
  showIcon?: boolean;
  className?: string;
  testId?: string;
};

export const SIconWrapper = styled.div`
  width: 20px;
  margin: -1px 8px 0 0;
`;

export const SBannerContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const SBannerWrapper = styled.div<{ variant: BannerTypes }>`
  display: flex;
  margin: 15px 0;
  padding: 15px;
  border-radius: ${({ theme }) => theme.border.radius.large};
  border-width: 0;
  border-style: solid;
  background-color: ${({ theme }) => theme.background.color.white};
  position: relative;
  font-size: ${({ theme }) => theme.text.size.medium};

  ${({ variant }) =>
    (variant === 'error' &&
      css`
        background-color: ${({ theme }) => theme.brand.color.dangerAlternate};
        color: ${({ theme }) => theme.text.color.white};
      `) ||
    (variant === 'warning' &&
      css`
        background-color: ${({ theme }) => theme.brand.color.warningAlternate};
        color: ${({ theme }) => theme.text.color.white};
      `) ||
    (variant === 'success' &&
      css`
        background-color: ${({ theme }) => theme.brand.color.successAlternate};
        color: ${({ theme }) => theme.text.color.white};
      `) ||
    (variant === 'info' &&
      css`
        background-color: ${({ theme }) => theme.brand.color.infoBanner};
        color: ${({ theme }) => theme.text.color.darkGray};
      `)}
`;

const getIconConfig = (variant: BannerTypes): IconNameParts => {
  switch (variant) {
    case 'error':
      return { name: 'times-circle', variant: 'light' };
    case 'warning':
      return { name: 'exclamation-triangle', variant: 'light' };
    case 'success':
      return { name: 'check-circle', variant: 'light' };
    case 'info':
      return { name: 'info-circle', variant: 'solid' };
  }
};

/**
 * Display errors in the flow of a page that take up the width of the parent element.
 * Use in cases for unexpected failures like a failed api call.
 *
 * @param variant - the variant of the button to use
 * @param showIcon - whether or not to show an appropriate icon for the variant
 */
const Banner: React.FC<Props> = ({
  variant: bannerVariant,
  showIcon = true,
  children,
  className,
  testId,
}) => {
  return (
    <SBannerWrapper
      role="alert"
      variant={bannerVariant}
      data-test-id={testId}
      className={className}
    >
      {showIcon && (
        <SIconWrapper>
          <Icon {...getIconConfig(bannerVariant)} size="lg" />
        </SIconWrapper>
      )}
      <SBannerContent>{children}</SBannerContent>
    </SBannerWrapper>
  );
};

export default Banner;
