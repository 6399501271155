import React from 'react';

import { formatAsDisplayTime } from '../../util/time/utils';
import { SavingStatusType } from '../context/SavingStatusProvider';
import { useSavingStatusContext } from '../hooks/useSavingStatus';
import { SContainer, SContent, StyledCheckCircle, StyledSavingIcon } from './SavingStatus.styles';

/**
 * Component to render the current auto save status. This needs to be used inside a <SavingStatusProvider/>.
 * @example
 * const Component = () => (
 * <SavingStatusProvider>
 *    <SavingStatus/>
 *    <SomeFormikForm />
 * </SavingStatusProvider>
 * );
 */
export const SavingStatus: React.FC = () => {
  const { lastSavedAt, savingStatus } = useSavingStatusContext();

  const saveStatus = React.useMemo(() => {
    switch (savingStatus) {
      case SavingStatusType.SAVED:
      case SavingStatusType.ERROR:
        return (
          <SContainer>
            {lastSavedAt ? (
              <React.Fragment>
                <StyledCheckCircle />
                Last saved {formatAsDisplayTime(lastSavedAt)}
              </React.Fragment>
            ) : null}
          </SContainer>
        );
      case SavingStatusType.SAVING:
        return (
          <SContainer>
            <StyledSavingIcon />
            Saving...
          </SContainer>
        );
      default:
        return null;
    }
  }, [savingStatus, lastSavedAt]);

  return <SContent>{saveStatus}</SContent>;
};
