import { ColDef } from '@cmg/common';

import {
  CustomSectorIssuerFilterInput,
  IssuerFilterInput,
  OfferingFilterInput,
  OfferingStatus,
  Sector,
  SortEnumType,
  SubSector,
} from '../../../graphql/__generated__/index';
import { CategorizedColumn, NestedSortInput } from '../../../graphql/types';
import { columnCategoryLabel, DatalabTableColumnCategory } from '../../datalab/constants';
import { OfferingsFilterFormType } from '../components/offerings-filter-form/OfferingsFilterForm.model';
import { dataGridColumns } from '../components/offerings-report-table/OfferingsReportTable.model';
import { Offering } from '../components/offerings-report-table/types';

export const alwaysVisibleColumns = ['issuer_name'];

export const orderedGlobalEcmColumns: ColDef<Offering, any>[] = [
  dataGridColumns.pricingDate,
  dataGridColumns.settlementDate,
  dataGridColumns.issuer_name,
  dataGridColumns.exchangeMicDisplayName,
  dataGridColumns.exchangeRegionDisplayName,
  dataGridColumns.exchangeCountryDisplayName,
  dataGridColumns.issuer_sectorDisplayName,
  dataGridColumns.typeDisplayName,
  dataGridColumns.securityTypeDisplayName,
  dataGridColumns.pricingCurrencyDisplayName,
  dataGridColumns.isRule144A,
  dataGridColumns.finalTerm_pctOfferPrice,
  dataGridColumns.attributes_priceUsd,
  dataGridColumns.finalTerm_couponPercentage,
  dataGridColumns.finalTerm_premiumPercentage,
  dataGridColumns.initialTerm_couponTalkPercentageRange,
  dataGridColumns.initialTerm_premiumTalkPercentageRange,
  dataGridColumns.latestRevisedTerm_couponTalkPercentageRange,
  dataGridColumns.latestRevisedTerm_premiumTalkPercentageRange,
  dataGridColumns.finalTerm_aggregatePrincipalAmount,
  dataGridColumns.initialTerm_aggregatePrincipalAmount,
  dataGridColumns.latestRevisedTerm_aggregatePrincipalAmount,
  dataGridColumns.finalTerm_principalAmountOverallotmentAuthorized,
  dataGridColumns.initialTerm_principalAmountOverallotmentAuthorized,
  dataGridColumns.latestRevisedTerm_principalAmountOverallotmentAuthorized,
  dataGridColumns.finalTerm_principalAmountOverallotmentExercised,
  dataGridColumns.finalTerm_aggregatePrincipalInclOverallotmentExercised,
  dataGridColumns.finalTerm_principalAmountPerNote,
  dataGridColumns.initialTerm_principalAmountPerNote,
  dataGridColumns.latestRevisedTerm_principalAmountPerNote,
  dataGridColumns.attributes_latestGrossProceedsBaseUsd,
  dataGridColumns.attributes_latestGrossProceedsTotalUsd,
  dataGridColumns.maturityDate,
  dataGridColumns.attributes_pctGrossSpread,
  dataGridColumns.attributes_grossSpreadTotalUsd,
  dataGridColumns.finalTerm_versusTalkDisplayName,
  dataGridColumns.finalTerm_conversionPrice,
  dataGridColumns.finalTerm_conversionRatio,
  dataGridColumns.finalTerm_conversionReferencePrice,
  dataGridColumns.finalTerm_hasZeroCoupon,
  dataGridColumns.finalTerm_effectiveConversionPremium,
  dataGridColumns.finalTerm_effectiveConversionPrice,
  dataGridColumns.useOfProceedsDisplayNames,
  dataGridColumns.trustee,
  dataGridColumns.issuerCounsel,
  dataGridColumns.underwriterCounsel,
  dataGridColumns.leftLead,
  dataGridColumns.bookrunners,
  dataGridColumns.coLeads,
  dataGridColumns.coManagers,
];

export const categorizedGlobalEcmColumns: CategorizedColumn = {
  [columnCategoryLabel[DatalabTableColumnCategory.ISSUER]]: [
    dataGridColumns.issuer_name,
    dataGridColumns.issuer_sectorDisplayName,
    dataGridColumns.exchangeRegionDisplayName,
    dataGridColumns.exchangeCountryDisplayName,
    dataGridColumns.exchangeMicDisplayName,
    dataGridColumns.pricingCurrencyDisplayName,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.OFFERING_TERMS]]: [
    dataGridColumns.typeDisplayName,
    dataGridColumns.securityTypeDisplayName,
    dataGridColumns.isRule144A,
    dataGridColumns.finalTerm_aggregatePrincipalAmount,
    dataGridColumns.initialTerm_aggregatePrincipalAmount,
    dataGridColumns.latestRevisedTerm_aggregatePrincipalAmount,
    dataGridColumns.finalTerm_principalAmountOverallotmentAuthorized,
    dataGridColumns.initialTerm_principalAmountOverallotmentAuthorized,
    dataGridColumns.latestRevisedTerm_principalAmountOverallotmentAuthorized,
    dataGridColumns.finalTerm_principalAmountOverallotmentExercised,
    dataGridColumns.finalTerm_aggregatePrincipalInclOverallotmentExercised,
    dataGridColumns.finalTerm_principalAmountPerNote,
    dataGridColumns.initialTerm_principalAmountPerNote,
    dataGridColumns.latestRevisedTerm_principalAmountPerNote,
    dataGridColumns.finalTerm_pctOfferPrice,
    dataGridColumns.attributes_priceUsd,
    dataGridColumns.attributes_latestGrossProceedsBaseUsd,
    dataGridColumns.attributes_latestGrossProceedsTotalUsd,
    // dataGridColumns.attributes_notionalOverAllotmentExercised,
    dataGridColumns.initialTerm_couponTalkPercentageRange,
    dataGridColumns.latestRevisedTerm_couponTalkPercentageRange,
    dataGridColumns.initialTerm_premiumTalkPercentageRange,
    dataGridColumns.latestRevisedTerm_premiumTalkPercentageRange,
    dataGridColumns.finalTerm_couponPercentage,
    dataGridColumns.finalTerm_premiumPercentage,
    dataGridColumns.finalTerm_versusTalkDisplayName,
    dataGridColumns.finalTerm_conversionPrice,
    dataGridColumns.finalTerm_conversionRatio,
    dataGridColumns.finalTerm_conversionReferencePrice,
    dataGridColumns.finalTerm_hasZeroCoupon,
    dataGridColumns.finalTerm_effectiveConversionPremium,
    dataGridColumns.finalTerm_effectiveConversionPrice,
    dataGridColumns.useOfProceedsDisplayNames,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.TIMING]]: [
    dataGridColumns.pricingDate,
    dataGridColumns.maturityDate,
    dataGridColumns.settlementDate,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.UNDERWRITING]]: [
    dataGridColumns.attributes_pctGrossSpread,
    dataGridColumns.attributes_grossSpreadTotalUsd,
    dataGridColumns.leftLead,
    dataGridColumns.bookrunners,
    dataGridColumns.coLeads,
    dataGridColumns.coManagers,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.ADVISORS]]: [
    dataGridColumns.trustee,
    dataGridColumns.issuerCounsel,
    dataGridColumns.underwriterCounsel,
  ],
};

export const defaultSortModel: NestedSortInput = {
  pricingDate: SortEnumType.Desc,
};

export const downloadOptions = [
  {
    value: 'includeUnderwritersWorksheet',
    label: 'Underwriters',
  },
];

const ONE_MILLION = 1000000;
export const mapFormTypeToGlobalEcmFilterInput = (
  fields: OfferingsFilterFormType
): OfferingFilterInput => {
  const {
    date,
    marketCap,
    grossProceedsBase,
    sellingShareholderPct,
    leftleads,
    sectors: sectorsField,
    customSectors: customSectorsField,
    countries,
    underwriters,
    shareholders,
    advisers,
    include144A,
  } = fields;
  const marketCapAtPricingUsd =
    marketCap?.min || marketCap?.max
      ? {
          gte: marketCap?.min ? marketCap?.min * ONE_MILLION : undefined,
          lte: marketCap?.max ? marketCap?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestGrossProceedsBaseUsd =
    grossProceedsBase?.min || grossProceedsBase?.max
      ? {
          gte: grossProceedsBase?.min ? grossProceedsBase?.min * ONE_MILLION : undefined,
          lte: grossProceedsBase?.max ? grossProceedsBase?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestPctSecondaryShares =
    sellingShareholderPct?.min || sellingShareholderPct?.max
      ? {
          gte: sellingShareholderPct?.min ? sellingShareholderPct?.min : undefined,
          lte: sellingShareholderPct?.max ? sellingShareholderPct?.max : undefined,
        }
      : undefined;
  const leftLeadId = (leftleads ?? []).length > 0 ? { in: leftleads } : undefined;
  const exchangeCountry = (countries ?? []).length > 0 ? { in: countries } : undefined;

  const sectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SECTOR'))
    .map(s => s.split(':')[1] as Sector);
  const sector = sectors.length > 0 ? { sector: { in: sectors } } : undefined;
  const subSectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SUB_SECTOR'))
    .map(s => s.split(':')[1] as SubSector);
  const subSector = subSectors.length > 0 ? { subSector: { in: subSectors } } : undefined;
  const sectorOrSubSector =
    sector || subSector
      ? { or: [sector, subSector].filter(s => !!s) as IssuerFilterInput[] }
      : undefined;

  const customSectors =
    (customSectorsField ?? []).length > 0
      ? {
          or: Object.entries(
            customSectorsField!.reduce((result, value) => {
              const [type, id] = value.split(':');
              const key = type === 'SUB_SECTOR' ? 'customSubsectorId' : 'customSectorId';
              result[key] = result[key] ?? { in: [] };
              result[key]!.in!.push(id); // `!` required because of how CustomSectorIssuerFilterInput is typed
              return result;
            }, {} as CustomSectorIssuerFilterInput)
          ).map(([key, filter]) => ({ [key]: filter })),
        }
      : undefined;

  return {
    status: { eq: OfferingStatus.Priced },
    pricingDate:
      date?.start || date?.end
        ? {
            gte: date?.start ? date?.start : undefined,
            lte: date?.end ? date?.end : undefined,
          }
        : undefined,
    attributes: {
      ...(marketCapAtPricingUsd ||
      latestGrossProceedsBaseUsd ||
      latestPctSecondaryShares ||
      leftLeadId ||
      exchangeCountry
        ? {
            marketCapAtPricingUsd,
            latestGrossProceedsBaseUsd,
            latestPctSecondaryShares,
            leftLeadId,
            exchangeCountry,
          }
        : undefined),
      isSupersededBy: { eq: null },
    },
    issuer:
      sectorOrSubSector || customSectors
        ? {
            ...sectorOrSubSector,
            customSectors,
          }
        : undefined,
    managers:
      (underwriters ?? []).length > 0
        ? { some: { manager: { id: { in: underwriters } } } }
        : undefined,
    shareholders:
      (shareholders ?? []).length > 0
        ? { some: { shareholderId: { in: shareholders } } }
        : undefined,
    advisers: (advisers ?? []).length > 0 ? { some: { adviserId: { in: advisers } } } : undefined,
    isRule144A: !include144A ? { eq: false } : undefined,
  };
};
