import { selectOidcUserAccountType } from '../../ducks';
import { useSelector } from '../auth-provider/AuthProvider';

/**
 * useCheckAccountType custom hook checks if the oidc user account type matches the type argument
 */
function useCheckAccountType(type: 'BUY_SIDE' | 'SELL_SIDE' | 'SYSTEM'): boolean {
  const oidcUserAccountType = useSelector(selectOidcUserAccountType);

  return oidcUserAccountType === type;
}

export default useCheckAccountType;
