import React from 'react';
import styled from 'styled-components/macro';

const SStackedGroup = styled.div`
  > div:not(:first-child) input {
    border-top: none;
  }
`;

export type Props = { children: React.ReactElement<{ rounded: string }>[] };

/**
 * Displays a set of TextInputs or any element that accepts a rounded prop and stacks them on top
 * of eachother.
 */
const StackedInputGroup: React.FC<Props> = ({ children }) => (
  <SStackedGroup>
    {React.Children.map(children, (child, index) => {
      let rounded = 'none';
      if (index === 0) {
        rounded = 'top';
      }
      if (index === children.length - 1) {
        rounded = 'bottom';
      }
      // @ts-ignore
      return React.cloneElement(child, {
        rounded,
      });
    })}
  </SStackedGroup>
);

export default StackedInputGroup;
