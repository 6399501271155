import styled, { css } from 'styled-components/macro';

export const SWrapper = styled.div<{
  fillViewport: boolean;
}>`
  margin: 15px;
  padding: 20px;
  background: ${({ theme }) => theme.background.color.white};

  ${({ fillViewport }) =>
    fillViewport &&
    css`
      flex: 1;
      display: flex;
      flex-direction: column;
    `}
`;
