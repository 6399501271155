import React from 'react';
import styled, { css } from 'styled-components/macro';

import { Props as IconProps } from '../../graphics/icon/Icon';
import { StyledConfig } from './Button';

export const SButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;

// will be fixed by https://github.com/facebook/create-react-app/issues/7005
// eslint-disable-next-line no-unexpected-multiline
export const SContent = styled.div<{
  withRightMargin?: boolean;
  withLeftMargin?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  margin-right: ${props => (props.withRightMargin ? '2px' : 0)};
  margin-left: ${props => (props.withLeftMargin ? '2px' : 0)};
`;

type SButtonProps = {
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  iconLeft?: IconProps;
  iconRight?: IconProps;
  styledConfig: StyledConfig;
  to?: any;
  withBorder?: boolean;
};

/**
 * When using `as` from styled-components on SButton we don't want any of our React style
 * specific props to pass down to that `as` component. This can be used to wrap the `as`
 * component and pass down everything except for those aforementioned props.
 * Some more info: https://github.com/styled-components/styled-components/issues/2131#issuecomment-441155616
 */
export const withSButtonPropsStopper =
  (Component: React.ComponentType<any>) =>
  ({
    styledConfig,
    iconLeft,
    iconRight,
    size,
    fullWidth,
    withBorder = true,
    ...restProps
  }: SButtonProps) =>
    <Component {...restProps} />;

// will be fixed by https://github.com/facebook/create-react-app/issues/7005
export const SButton = styled.button<SButtonProps>`
  display: inline-block;
  color: ${({ styledConfig }) => styledConfig.color};
  background-color: ${({ styledConfig }) => styledConfig.backgroundColor};
  border: ${({ styledConfig, withBorder }) =>
    styledConfig.borderColor && withBorder ? `1px solid ${styledConfig.borderColor}` : 'none'};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.text.weight.medium};
  text-align: center;
  white-space: nowrap;
  line-height: normal;
  user-select: none;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${props =>
    props.size === 'large' &&
    css`
      padding: 12px;
      font-size: ${({ theme }) => theme.text.size.mediumLarge};
    `}

  ${props =>
    props.size === 'medium' &&
    css`
      padding: 8px;
      font-size: ${({ theme }) => theme.text.size.medium};
    `}

  ${props =>
    props.size === 'small' &&
    css`
      padding: 4px 10px;
      font-size: ${({ theme }) => theme.text.size.small};
    `}

  &:hover {
    text-decoration: none;
    color: ${({ styledConfig }) => styledConfig.hoverColor};
    border-color: ${({ styledConfig }) =>
      styledConfig.hoverBorderColor || styledConfig.borderColor || 'none'};
    background-color: ${({ styledConfig }) => styledConfig.hoverBackgroundColor};
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: ${({ theme, styledConfig }) =>
      styledConfig.disabledColor || theme.interactive.disabledContrast};
    background-color: ${({ theme, styledConfig }) =>
      styledConfig.disabledBackgroundColor || theme.interactive.disabled};
    border-color: ${({ theme, styledConfig }) =>
      styledConfig.disabledBorderColor || theme.interactive.disabled};
  }

  &:active {
    ${({ styledConfig, theme }) => {
      const color =
        styledConfig.hoverBorderColor ||
        styledConfig.hoverBackgroundColor ||
        styledConfig.hoverColor ||
        theme.border.color.dark;

      return css`
        box-shadow: 0 0 0 2px ${color};
      `;
    }};
  }
`;
