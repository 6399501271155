import React from 'react';

import { SWrapper } from './Panel.styles';
import PanelHeader from './PanelHeader';

export type Props = {
  /**
   * React children for the Panel
   */
  children?: React.ReactNode;
  /**
   * A data-test-id value
   */
  testId?: string;
  /**
   * A CSS class name
   */
  className?: string;
  /**
   * Fill the viewport
   */
  fillViewport?: boolean;
};

/**
 * A uniform way of framing sections of content.
 */
export default class Panel extends React.Component<Props> {
  render() {
    const { children, className, testId, fillViewport = false } = this.props;

    return (
      <SWrapper className={className} data-test-id={testId} fillViewport={fillViewport}>
        {children}
      </SWrapper>
    );
  }

  static Header = PanelHeader;
}
