import React from 'react';
import ReactSelect, { SingleValue } from 'react-select';

import { createFormikField, FieldControlProps } from '../formik/createFormikField';
import { getReactSelectProps, PublicProps } from './BaseSelect.model';
import { SSelectWrapper } from './BaseSelect.styles';
import { Option } from './types';

type BoolOption = Option<boolean>;

const defaultOptions: BoolOption[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export type Props = PublicProps<boolean> &
  FieldControlProps<boolean | null, boolean | null> & {
    options?: Option<boolean>[];
  };

export default class YesNoSelect extends React.PureComponent<Props> {
  getSelectedOption = (options: BoolOption[] = [], value?: boolean | null): BoolOption | null => {
    if (value === null || value === undefined || !options) {
      return null;
    }
    return options.find(o => o.value === value) || null;
  };

  handleOnChange = (value: SingleValue<BoolOption>) => {
    const { onChange } = this.props;

    onChange && onChange(value !== undefined && value !== null ? value.value : null);
  };

  render() {
    const { className, options, testId, value } = this.props;

    const selectProps = getReactSelectProps({
      ...this.props,
      isSearchable: false,
    });

    return (
      <SSelectWrapper className={className} data-test-id={testId}>
        <ReactSelect
          {...selectProps}
          options={options || defaultOptions}
          onChange={this.handleOnChange}
          value={this.getSelectedOption(options || defaultOptions, value)}
        />
      </SSelectWrapper>
    );
  }
}

export const YesNoSelectField = createFormikField<
  boolean | null,
  boolean | null,
  HTMLInputElement,
  Props
>(YesNoSelect);
