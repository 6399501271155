import { makeEntity } from '../../../utils/mocks';
import { Ioi } from '../types/Ioi';

export const ioi = makeEntity<Ioi>({
  calculateShares: 150,
  dollars: 3000,
  id: 'mock-ioi-id',
  limitPrice: null,
  offeringId: 'mock-offering-id',
  percentage: null,
  pricingType: 'MARKET',
  realDemandDollars: null,
  realDemandPercentage: null,
  realDemandShares: null,
  shares: null,
  unitType: 'DOLLARS',
});
